<div class="container mt-2 mb-3" >
    <div class="row">
        <div class="col-md-12">
            <div class="card shadow rounded-top rounded-lg">
                <div class="card-header panel-american-coral">
                    <div class="row">
                        <div class="col-10">
                            <span>Manage DNR Records</span>
                        </div>
                    </div>
                </div>
                <div class="card-body" *ngIf="!loading ">

                    <app-membersearch [searchSource]="'DNR'" (onMemberSearchComplete)="handleSearchResults($event)"></app-membersearch>

                    <div class="row" *ngIf="searchComplete && !hasMemberSearchResults ">
                        <div class="col-sm-12">
                            <p>I'm sorry but no records were found matching your results.</p>
                        </div>
                    </div>
                    <div *ngIf="searchComplete && hasMemberSearchResults && !loading">
                        <div class="card mt-2  border border-info" *ngFor="let member of searchResults; let i = index;">
                            <div class="card-header bg-ava-info">
                                <div class="row">
                                    <div class="col-sm-8">
                                        (<a href="http://stats.vatsim.net/search_id.php?id={{member.vatsimId}}" target="_blank">{{member.vatsimId}}</a>) - 
                                        {{member.firstName}} {{member.lastName}} <br/>
                                        <b>Reason:</b> {{member.reason}}
                                    </div>
                                    <div class="col-sm-4 ">
                                        <button type="button" class="btn btn-danger btn-sm float-right " 
                                            (click)="onRemoveFromDNR(member,i)">
                                            <i class="fas fa-times"></i>
                                            Remove {{member.firstName }} from DNR List
                                        </button>                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
        </div>
    </div>
</div>

<app-avamodal [modalName]="modalName" [modalMessage]="modalMessage" [modalTitle]="modalTitle" [showMiniClose]="showMiniClose"
[showCancelButton]="showCancelButton" [modalStatus]="modalStatus" [buttonText]="'Ok'"
[modalPurpose]="modalPurpose" (modalClosed)="onModalClosed($event)"></app-avamodal>
