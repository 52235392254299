import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TerminationRequestModel } from 'src/app/models/termination.model.';
import { TerminationService } from 'src/app/services/termination.service';

@Component({
  selector: 'app-terminationdetail',
  templateUrl: './terminationdetail.component.html',
  styleUrls: ['./terminationdetail.component.css']
})
export class TerminationdetailComponent implements OnInit {
  REJECT = 0;
  ACCEPT = 1;
  showCancel: boolean = false;
  loading: boolean = false;
  isRejecting : boolean = false;
  isAccepting : boolean = false;
  isShowingDefaultButtons : boolean = true;
  status : number = 0;

  @Output() onTerminationUpdate = new EventEmitter<string>();
  @Input() termination: TerminationRequestModel;
  constructor(private router : Router, private termService : TerminationService) { }

  ngOnInit(): void {
  }

  onSubmit(terminationForm) {
    console.log(terminationForm);
    console.log(this.isRejecting);
    console.log(this.isAccepting);
    console.log(terminationForm.comment);
    console.log(terminationForm.isDNR);
    if (terminationForm.isDNR=='') {
      terminationForm.isDNR = false;
    }

    let payload = {
      comment : terminationForm.comment,
      isDNR : terminationForm.isDNR
    }

    console.log(payload);

    this.termService.updateTerminationStatus(this.termination.attributes.terminationId,payload, this.status).subscribe(
      (responseData) => {
        console.log(responseData);
        this.onTerminationUpdate.emit('rejected');
      },
      (error) => {
        this.router.navigate(['/techerror']);
      }
    )

  }

  termRejectStart() {
    console.log('reject started');
    this.isRejecting=true;
    this.isAccepting=false;
    this.status=this.REJECT;
  }

  termAcceptStart() {
    console.log('accept started');
    this.isRejecting=false;
    this.isAccepting=true;
    this.status=this.ACCEPT;
  }
}
