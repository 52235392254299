import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbDateStruct, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { EventModel } from 'src/app/models/event.model';
import { DataSharingService } from 'src/app/services/datasharing.service';
import { EventService } from 'src/app/services/event.service';

@Component({
  selector: 'app-eventdetail',
  templateUrl: './eventdetail.component.html',
  styleUrls: ['./eventdetail.component.css']
})
export class EventdetailComponent implements OnInit {
  showCancel: boolean = false;
  showDelete: boolean = false;
  loading: boolean = false;
  isPreview : boolean = false;

  @Output() onEventUpdateComplete = new EventEmitter<string>();
  @Input() event: EventModel;
  @Input() isNew: boolean;

  eventDate: NgbDateStruct;
  eventTime: NgbTimeStruct;
  eventIdToDelete : number;

  @Output() modalMessage: string = "";
  @Output() modalTitle: string = "";
  @Output() modalStatus: string = "";
  @Output() modalPurpose: string = "";
  @Output() showCancelButton: boolean = false;
  @Output() modalName : string;
  showMiniClose: boolean = false;

  constructor(private eventService: EventService, private router: Router, private dss : DataSharingService) { }

  ngOnInit(): void {
    this.modalName = 'eventDeleteConfirmationModal' + this.event.attributes.eventId;
    console.log(this.event);
    console.log(this.isNew);

    this.showCancel = false;
    this.showDelete = true;

    if (this.isNew) {
      this.showCancel = true;
      this.showDelete = false;
    }

    let eventDateNumber = new Date(this.event.attributes.startDate + ' ' + this.event.attributes.startTime);
    this.eventDate = { year: eventDateNumber.getFullYear(), month: eventDateNumber.getMonth() + 1, day: eventDateNumber.getDate() }
    this.eventTime = { hour: eventDateNumber.getHours(), minute: eventDateNumber.getMinutes(), second: 0 }
  }

  onSubmit(eventDetailForm, eventId) {
    console.log(eventDetailForm);


    let updatedEvent = {
      name: eventDetailForm.txtName,
      description: eventDetailForm.txtDescription,
      url: eventDetailForm.txtUrl,
      startDate: this.convertDate(eventDetailForm.startDate),
      startTime: this.convertTime(eventDetailForm.startTime),
      duration: eventDetailForm.duration,
      bannerUrl: eventDetailForm.txtBannerUrl, //(eventDetailForm.txtBannerUrl != null) ? eventDetailForm.txtBannerUrl : ' ',
      trackingTag: eventDetailForm.txtTrackingTag
    }

    console.log(updatedEvent);

    //open the spinner/loading screen
    this.loading = true;

    if (this.isNew) {
      this.postEvent(updatedEvent);
    } else {
      this.putEvent(updatedEvent, eventId);
    }
  }

  postEvent(updatedEvent) {
    this.eventService.postEvent(updatedEvent).subscribe(
      (responseData) => {
        this.loading = false;
        //emit event
        this.onEventUpdateComplete.emit("eventUpdated");
      },
      (error) => {
        console.log(error.message);
        this.router.navigate(['techerror']);
      }
    );

  }

  putEvent(updatedEvent, eventId) {
    this.eventService.putEvent(updatedEvent, eventId).subscribe(
      (responseData) => {
        this.loading = false;
        //emit event
        this.onEventUpdateComplete.emit("eventUpdated");
      },
      (error) => {
        console.log(error.message);
        this.router.navigate(['techerror']);
      }
    );
  }

  convertDate(dateToConvert: NgbDateStruct) {
    let convertedDateString = dateToConvert.year + '-' + dateToConvert.month + '-' + dateToConvert.day;
    return convertedDateString;
  }

  convertTime(timeToConvert: NgbTimeStruct) {
    var hourPad = "";
    var minutePad = "";
    if (timeToConvert.hour < 10) {
      hourPad = "0";
    }
    if (timeToConvert.minute < 10) {
      minutePad = "0";
    }
    let convertedTime = hourPad + timeToConvert.hour + ':' + minutePad + timeToConvert.minute;
    return convertedTime;
  }

  onDateTimeChange() {
    console.log("time change");
  }

  // onFileSelected(event) {
  //   console.log(event.target.files[0]);
  // }

  cancelEventAdd() {
    this.onEventUpdateComplete.emit("eventCancelled");
  }

  onDeleteClick(eventId) {
    this.eventIdToDelete=eventId;
    this.modalStatus='danger';
    this.showMiniClose=true;
    this.showCancelButton=true;
    this.modalTitle = 'Delete Event Confirmation';
    this.modalMessage = 'Are you sure you would like to delete Event # ' + this.event.attributes.eventId + '?';
    this.modalPurpose = "deleteevent";
    this.dss.showModal.next({modalName : this.modalName, showModal :true});
  }

  onModalClosed(returnMessage: {status: string, purpose: string}) {
    this.showCancelButton=false;
    if (returnMessage.purpose == 'deleteevent' && returnMessage.status == 'ok') {
      this.deleteEvent();
    } else if (returnMessage.status == 'cancel'){
      console.log('canceled out of the modal');
    }
    this.dss.showModal.next({modalName : this.modalName, showModal :false});
  }

  deleteEvent() {
    this.eventService.deleteEvent(this.eventIdToDelete).subscribe(
      (responseData) => {
        this.loading = false;
        //emit event
        this.onEventUpdateComplete.emit("eventDeleted");
      },
      (error) => {
        console.log(error.message);
        this.router.navigate(['techerror']);
      }
    );
  }

  previewClicked() {
    this.isPreview=!this.isPreview;
    return;
  }
}
