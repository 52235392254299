import { Component, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ActiveLOAModel } from 'src/app/models/activeloa.model';
import { LOARequestModel } from 'src/app/models/loarequestmodel';
import { AuthService } from 'src/app/services/auth.service';
import { DataSharingService } from 'src/app/services/datasharing.service';
import { LoaService } from 'src/app/services/loa.service';
import { PilotService } from 'src/app/services/pilot.service';

@Component({
  selector: 'app-loa',
  templateUrl: './loa.component.html',
  styleUrls: ['./loa.component.css']
})
export class LoaComponent implements OnInit {
  loading : boolean = false;
  pilotId : number = 0;

  activeLOAs : ActiveLOAModel[] = [];
  hasActiveLOAs : boolean = false;

  loaRequests : LOARequestModel[] = [];
  hasLOARequests : boolean = false;

  pilotIdToClear : number = 0;

  constructor(private router : Router, private pilotService : PilotService, private authService : AuthService, private dss : DataSharingService,
              private loaService : LoaService) { }

  ngOnInit(): void {
    this.loading=true;
    this.activeLOAs = [];
    this.loaRequests = [];
    this.hasActiveLOAs = false;
    this.hasLOARequests = false;

    this.loadLOARequests();
    this.loadActiveLoas();
  }

  loadActiveLoas() {
    if (this.authService.isAuthenticated()) {
      this.pilotId = this.authService.getPilotId();
      this.loaService.getActiveLOAs().subscribe(
        (responseData) => {
          this.activeLOAs = responseData;
          console.log(this.activeLOAs);
          if (this.activeLOAs.length>0) {
            this.hasActiveLOAs=true;
          }
          this.loading=false;
        },
        (error) => {
            console.log('WARNING: ' + error.message);
            if (error.status === 500) {
              this.router.navigate(['/techerror']);
            }
            this.loading=false;
          }
      );
    }
  }

  loadLOARequests() {
    if (this.authService.isAuthenticated()) {
      this.pilotId = this.authService.getPilotId();
      this.loaService.getLOARequests().subscribe(
        (responseData) => {
          this.loaRequests = responseData;
          console.log(this.loaRequests);
          if (this.loaRequests.length>0) {
            this.hasLOARequests=true;
          }
          this.loading=false;
        },
        (error) => {
            console.log('WARNING: ' + error.message);
            if (error.status === 500) {
              this.router.navigate(['/techerror']);
            }
            this.loading=false;
          }
      );
    }
  }

  onToggle(collapseId) {
    console.log(collapseId, this.loaRequests[collapseId]);
    this.loaRequests[collapseId].toggledOpen=!this.loaRequests[collapseId].toggledOpen;
  }


  loaUpdated(event) {
    console.log(event);
    this.ngOnInit();
  }


}
