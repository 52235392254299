import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ResignationRequestModel } from 'src/app/models/resignation.model';
import { ResignationService } from 'src/app/services/resignation.service';

@Component({
  selector: 'app-resignationdetail',
  templateUrl: './resignationdetail.component.html',
  styleUrls: ['./resignationdetail.component.css']
})
export class ResignationdetailComponent implements OnInit {
  REJECT = 0;
  ACCEPT = 1;
  showCancel: boolean = false;
  loading: boolean = false;
  isRejecting : boolean = false;
  isAccepting : boolean = false;
  isShowingDefaultButtons : boolean = true;
  status : number = 0;

  @Output() onResignationUpdate = new EventEmitter<string>();
  @Input() resignation: ResignationRequestModel;


  constructor(private router : Router, private resignationService : ResignationService) { }

  ngOnInit(): void {
  }

  onSubmit(resignationForm) {
    console.log(resignationForm);
    console.log(this.isRejecting);
    console.log(this.isAccepting);
    console.log(resignationForm.comment);
    console.log(resignationForm.isDNR);
    if (resignationForm.isDNR=='') {
      resignationForm.isDNR = false;
    }

    let payload = {
      comment : resignationForm.comment,
      isDNR : resignationForm.isDNR
    }

    console.log(payload);

    this.resignationService.updateResignationStatus(this.resignation.attributes.resignationId,payload, this.status).subscribe(
      (responseData) => {
        console.log(responseData);
        this.onResignationUpdate.emit('rejected');
      },
      (error) => {
        this.router.navigate(['/techerror']);
      }
    )

  }

  resRejectStart() {
    console.log('reject started');
    this.isRejecting=true;
    this.isAccepting=false;
    this.status=this.REJECT;
  }

  resAcceptStart() {
    console.log('accept started');
    this.isRejecting=false;
    this.isAccepting=true;
    this.status=this.ACCEPT;
  }
}
