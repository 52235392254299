<div class="border border-rounded pl-2 pt-1 mb-1 pr-2" *ngIf="personnelRecords.length>0">
    <div class="row">
        <div class="col-md-12">
            <table class="table table-bordered table-hover responsive" style="font-size: .75em">
                <thead>
                    <tr>
                        <th scope="col">Remove?</th>
                        <th scope="col">#</th>
                        <th scope="col">Date</th>
                        <th scope="col">Action</th>
                        <th scope="col">Comments</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let prec of personnelRecords;let i = index">
                        <td class="text-center">
                            <!-- <a href id="btnDeleteRecord" (click)="deletePR(i)" title="Delete record: {{i}}">
                                <i class="fa fa-remove" style="color:red;font-size:18px"></i>
                            </a> -->
                            <button type="button" class="btn btn-danger"
                                (click)="onDeletePR(i)">
                                <i class="fas fa-times"></i>
                            </button>
                        </td>
                        <td scope="row">{{i+1}}</td>
                        <td>{{prec.Date_Action}}</td>
                        <td>{{prec.Action}}</td>
                        <td>{{prec.comments}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<app-avamodal [modalName]="modalName" [modalMessage]="modalMessage" [modalTitle]="modalTitle" [showMiniClose]="showMiniClose"
[showCancelButton]="showCancelButton" [modalStatus]="modalStatus" [buttonText]="'Ok'"
[modalPurpose]="modalPurpose" (modalClosed)="onModalClosed($event)"></app-avamodal>
