import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StatsService {
  HOUR_STATS_SVC = '/hourstats';
  FLIGHT_STATS_SVC = '/flightstats';
  LANDING_RATE_STATS_SVC = '/landingratestats';
  AIRPORT_STATS_SVC = '/airportstats';

  constructor(private httpClient: HttpClient) { }

  getFlightStats() {
    return this.httpClient.get(environment.API_URL + this.FLIGHT_STATS_SVC).pipe(map(
      (data) => {
        console.log(data);
        return data;
      }));
  }
  getHourStats() {
    return this.httpClient.get(environment.API_URL + this.HOUR_STATS_SVC).pipe(map(
      (data) => {
        console.log(data);
        return data;
      }));
  }

  getLandingStats() {
    return this.httpClient.get(environment.API_URL + this.LANDING_RATE_STATS_SVC).pipe(map(
      (data) => {
        console.log(data);
        return data;
      }));
  }

  getAirportStats() {
    return this.httpClient.get(environment.API_URL + this.AIRPORT_STATS_SVC).pipe(map(
      (data) => {
        console.log(data);
        return data;
      }));
  }}
