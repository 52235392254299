import { Component, Input, OnInit } from '@angular/core';
import { MessageModel } from 'src/app/models/message.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css']
})
export class CarouselComponent implements OnInit {
  @Input() welcomeMessage : MessageModel;
  
  images = environment.HERO_IMAGES;
  
  constructor() { }

  ngOnInit(): void {
    // this.images.sort((a,b)=> Math.random() -0.3);
    this.shuffle();
    this.images.unshift('welcomemessage');
  }

  shuffle() {
    let array = this.images;
    let currentIndex = array.length,  randomIndex;
  
    // While there remain elements to shuffle...
    while (currentIndex != 0) {
  
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
  }
}
