<div class="table-responsive">
    <table
        class="table table-bordered table-hover table-striped text-center">
        <thead>
            <th class="text-center">#</th>
            <th class="text-center">Due Date</th>
            <th class="text-center">Callsign</th>
            <th class="text-center">Name</th>
            <th class="text-center">Hub</th>
            <th class="text-center">VatsimID</th>
            <th class="text-center">Clear</th>
        </thead>
        <tbody>
            <tr *ngFor="let activeLoa of activeLOAs; let i=index">
                <td>{{i+1}}</td>
                <td>
                    <i class="fas fa-skull-crossbones mr-3"
                        style="color:red" *ngIf="activeLoa.overdue"></i>
                    <i class="fas fa-exclamation-triangle mr-3"
                        style="color:orange"
                        *ngIf="activeLoa.nearOverdue"></i>
                    {{activeLoa.endDate}}
                </td>
                <td>{{activeLoa.callsign}}</td>
                <td>{{activeLoa.firstName}} {{activeLoa.lastName}}</td>
                <td>{{activeLoa.hub}}</td>
                <td>{{activeLoa.vatsimId}}</td>
                <td><i class="fas fa-eraser"
                        style="color:blue;cursor:pointer"
                        (click)="clear(activeLoa);"></i></td>
            </tr>
        </tbody>
    </table>

    <app-avamodal [modalName]="modalName" [modalMessage]="modalMessage" [modalTitle]="modalTitle" [showMiniClose]="showMiniClose"
    [showCancelButton]="showCancelButton" [modalStatus]="modalStatus" [buttonText]="'Ok'"
    [modalPurpose]="modalPurpose" (modalClosed)="onModalClosed($event)"></app-avamodal>
</div>

