import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { LOARequestModel } from 'src/app/models/loarequestmodel';
import { DataSharingService } from 'src/app/services/datasharing.service';
import { LoaService } from 'src/app/services/loa.service';
import { PilotService } from 'src/app/services/pilot.service';

@Component({
  selector: 'app-loaresponseform',
  templateUrl: './loaresponseform.component.html',
  styleUrls: ['./loaresponseform.component.css']
})
export class LoaresponseformComponent implements OnInit {
  @Output() onLOAUpdate = new EventEmitter<string>();

  LOA_ACCEPTED_STATUS = 'accepted';
  LOA_REJECTED_STATUS = 'rejected';

  @Input() loa : LOARequestModel;
  loading : boolean = false;
  loaStatus : string = '';

  //modal vars
  @Output() modalMessage: string = "";
  @Output() modalTitle: string = "";
  @Output() modalStatus: string = "";
  @Output() modalPurpose: string = "";
  @Output() showCancelButton: boolean = false;
  @Output() showMiniClose: boolean = false;
  @Output() modalName : string = 'loaResponseFormModal';

  constructor(private router : Router, private loaService : LoaService, private pilot : PilotService, private dss : DataSharingService) { }

  ngOnInit(): void {
  }

  approve(loaForm) {
    console.log('accept');
    this.loading=true;
    this.loaStatus=this.LOA_ACCEPTED_STATUS;
    this.setStatus(loaForm.comments);
  }

  reject(loaForm) {
    console.log('reject');
    this.loading=true;
    this.loaStatus=this.LOA_REJECTED_STATUS;
    this.setStatus(loaForm.comments);
  }

  setStatus(comments) {
    this.loaService.updateLOAStatus(comments, this.loa.loaId, this.loaStatus).subscribe(
      (responseData) => {
        console.log(responseData);
        this.showSuccessModal(responseData);
        this.loading=false;
      },
      (error) => {
        if (error.status === 500 ) {
          this.router.navigate(['/techerror']);
        } else {
          // this.showFailureModal(error);
        }
        this.loading=false;
      }
    )
  }

  onModalClosed(returnMessage: {status: string, purpose: string}) {
    if (returnMessage.purpose == 'loastatussuccess' && returnMessage.status == 'ok') {
      this.onLOAUpdate.emit('statusupdated');
    }
    this.dss.showModal.next({modalName : this.modalName, showModal :false});

  }

  showSuccessModal(data) {
    this.dss.showModal.next({modalName : this.modalName, showModal :false});
    this.modalStatus='success';
    this.showMiniClose=false;
    this.showCancelButton=false;
    this.modalTitle = 'LOA Status Update Success';
    this.modalMessage = 'LOA ' + this.loa.loaId + ' has been successfully ' + this.loaStatus;
    this.modalPurpose = "loastatussuccess";
    this.dss.showModal.next({modalName : this.modalName, showModal :true});
  }

  showFailureModal(error) {
    this.dss.showModal.next({modalName : this.modalName, showModal :false});
    this.modalStatus='danger';
    this.showMiniClose=false;
    this.showCancelButton=false;
    this.modalTitle = 'LOA Request Failed';
    this.modalMessage = 'Your LOA request failed to be processed with the following error:<br/>';
    if (error.error.hasOwnProperty("messages")) {
      for (const key in error.error.messages) {
        this.modalMessage += '<br/>' + error.error.messages;
      }
    }
    console.log(error.message + ' == ' + error.status);
    this.modalPurpose = "loasuccess";
    this.dss.showModal.next({modalName : this.modalName, showModal :true});
  }
}
