<div *ngIf="!schedulingAFlight">
    <div class="card ">
        <div class="card-header panel-american-blue-grey-red text-light">Search Routes & Book A Flight</div>
        <div class="card-body">

            <form name="searchform" class="css-form" (ngSubmit)="onSubmit(searchform.value)" #searchform="ngForm"
                *ngIf="!loading">
                <div class="row" *ngIf="currentAirport.id != 0">
                    <div class="col-md-12">
                        You are currently located at {{currentAirport.name}} ({{currentAirport.icao}} -
                        {{currentAirport.iata}}).
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-md-3">
                        <div ng-if="hasAirports==1">
                            <label for="departureIcao">Departing</label>
                            <select name="departureIcao" id="departureIcao" class="form-control" ngModel
                                #departureIcao="ngModel">
                                <option *ngFor="let airport of scheduleAirports;let i = index"
                                    [value]="scheduleAirports[i].icao">
                                    {{airport.icao}}
                                    ({{airport.name}})
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div ng-if="hasAirports==1">
                            <label for="arrivalIcao">Arriving</label>
                            <select name="arrivalIcao" id="arrivalIcao" class="form-control" ngModel
                                #arrivalIcao="ngModel">
                                <option *ngFor="let airport of scheduleAirports;let i = index"
                                    [value]="scheduleAirports[i].icao">
                                    {{airport.icao}}
                                    ({{airport.name}})
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-sm-2">
                        <div ng-if="hasAircraft==1">
                            <label for="acIcao">Equipment</label>
                            <select name="acIcao" id="acIcao" class="form-control" ngModel #acIcao="ngModel">
                                <option *ngFor="let aircraft of scheduleAircraft;let i = index"
                                    [value]="scheduleAircraft[i].icao">
                                    {{aircraft.icao}}
                                    ({{aircraft.fullname}})
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-sm-2">
                        <div>
                            <label for="duration">Max Duration</label>
                            <select name="duration" id="duration" class="form-control" ngModel #duration="ngModel">
                                <option value="" selected></option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="13">13</option>
                                <option value="14">14</option>
                                <option value="15">15</option>
                                <option value="16">16</option>
                                <option value="17">17</option>
                                <option value="18">18</option>
                                <option value="19">19</option>
                                <option value="20">20</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-sm-2">
                        <div>
                            <label for="slctFirstHubChoice">&nbsp;</label><br />
                            <button type="submit" class="btn btn-info btn-sm text-truncate"
                                [disabled]="!searchform.valid || (!searchform.touched && !searchform.dirty) || loadingFlights">
                                Route Search <i class="fas fa-search"></i>
                            </button>

                            <button type="button" class="btn btn-link btn-sm" (click)="clearForm(searchform);"
                                [disabled]="!searchform.valid || (!searchform.touched && !searchform.dirty)">
                                Clear Search <i class="fas fa-eraser"></i>
                            </button>

                        </div>
                    </div>
                </div>
            </form>
            <hr class="aa-line" *ngIf="!loading" />
            <div class="row no-gutters ">
                <div class="col-md-3" *ngIf="hasFlights">
                    <p class="flightBoardH4">
                        <b>{{departureAirport.icao}} to {{destinationAirport.icao}} Flights</b>
                        <i class="fas fa-cloud float-right mr-2 metaricon" (click)="showMetar = !showMetar"
                            title="Click to view weather for {{destinationAirport.icao}}"></i>
                    </p>
                    <span style="color: #2989d8" *ngIf="hasMetar && showMetar">{{metar}}</span>
                    <div class="well" style="padding-top: 0px">
                        <div class="table-responsive">
                            <table class="table table-condensed " style="font-size:.8em">
                                <thead>
                                    <tr>
                                        <th>Flight #</th>
                                        <th>Aircraft</th>
                                        <th>Dep Time</th>
                                        <th>Duration</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let flight of flights" class="flightrow"
                                        (click)="onFlightSelect(flight)">
                                        <td>{{flight.callSign}}</td>
                                        <td>{{flight.aircraftIcao}}</td>
                                        <td>{{flight.depTime}}z</td>
                                        <td>{{flight.flightTime}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="no-gutters" [ngClass]="{'col-md-12' : !hasFlights, 'col-md-9' : hasFlights}"
                    *ngIf="hasRoutes">
                    <h4>
                        <b>{{currentAirport.icao}} Route Map</b>
                        <i class="far fa-question-circle ml-2" (click)="showHelp = !showHelp"></i>
                        <p class="helpBlock ml-2" style="color: #2989d8" *ngIf="showHelp">
                            <br />
                            Click any of the destination airports connected by route lines. The system will
                            automatically load up
                            the available flights for that particular route in the left hand table. Just click on the
                            flight you want
                            and the flight schedule screen will appear.
                        </p>
                    </h4>
                    <google-map [options]="gOptions" width="100%" [center]="center"
                        (tilesLoaded)="loadWeatherMap();loadingFlights=false;">
                        <map-marker #mElem *ngFor="let marker of avaMarkers" [position]="marker.position"
                            [label]="marker.label" [title]="marker.title" [options]="marker.options"
                            (mapMouseover)="onMarkerHover(mElem, marker)" (mapMouseout)="onMarkerOut(mElem, marker)"
                            (mapClick)="onMarkerClick(mElem, marker)" fit="true">
                        </map-marker>
                        <map-polyline #lineElem *ngFor="let line of polyLines; index as i" [options]="line">
                        </map-polyline>
                    </google-map>
                </div>
            </div>
            <p *ngIf="!hasRoutes && !loadingFlights && currentAirport.id != 0">No routes found for that search.</p>
        </div>
    </div>
</div>


<div *ngIf="schedulingAFlight">
    <app-scheduleflight [flightDetails]="selectedFlight" (onScheduleComplete)="onScheduleComplete($event)"></app-scheduleflight>
</div>


<span *ngIf="loading">
    <div class="d-flex justify-content-center " style="margin-top: 10em;margin-bottom: 10em;">
        <div class="spinner-border text-primary" style="width: 6rem; height: 6rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</span>
