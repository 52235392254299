import { Component, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { PersonnelRecordModel } from 'src/app/models/personnelrecord.model';
import { DataSharingService } from 'src/app/services/datasharing.service';
import { PersonnelrecordService } from 'src/app/services/personnelrecord.service';

@Component({
  selector: 'app-personnelrecordetail',
  templateUrl: './personnelrecordetail.component.html',
  styleUrls: ['./personnelrecordetail.component.css']
})
export class PersonnelrecordetailComponent implements OnInit {
  loading : boolean = false;
  @Input() member;
  personnelRecords : PersonnelRecordModel[] = [];
  hasPersonnelRecords : boolean = false;
  precToDelete : PersonnelRecordModel;

  @Output() modalMessage: string = "";
  @Output() modalTitle: string = "";
  @Output() modalStatus: string = "";
  @Output() modalPurpose: string = "";
  @Output() showCancelButton: boolean = false;
  @Output() modalName : string;
  showMiniClose: boolean = false;

  constructor(private router : Router, private prsService : PersonnelrecordService, private dss : DataSharingService) { }

  ngOnInit(): void {
    this.modalName = 'precDeleteConfirmationModal' + this.member.vatsimId;
    this.loading = true;
    this.loadPersonnelRecords();
  }

  loadPersonnelRecords() {
    this.prsService.getPersonnelRecords(this.member.pilotId).subscribe(
      (responseData) => {
        this.personnelRecords = responseData;
        console.log(this.personnelRecords);
        this.loading = false;
        this.hasPersonnelRecords = true;
      },
      (error) => {
        this.loading = false;
        this.hasPersonnelRecords=false;
        this.personnelRecords=[];
        if (error.status = 404) {
          this.hasPersonnelRecords=false;
        } else {
          this.router.navigate(['/techerror']);
        }
      }
    );
  }

  onDeletePR(prId) {
    this.loading=true;
    console.log(prId);
    this.precToDelete = this.personnelRecords[prId];
    console.log(this.precToDelete);

    this.modalStatus='danger';
    this.showMiniClose=false;
    this.showCancelButton=true;
    this.modalTitle = 'Delete Personnel Record';
    this.modalMessage = 'Are you sure you would like to delete Personnel Record # ' + this.precToDelete.prId + '?';
    this.modalPurpose = "deleteprec";
    this.dss.showModal.next({modalName : this.modalName, showModal :true});
  }

  onModalClosed(returnMessage: {status: string, purpose: string}) {
    this.showCancelButton=true;
    if (returnMessage.purpose == 'deleteprec' && returnMessage.status == 'ok') {
      this.deletePrec()
    } else if (returnMessage.status == 'cancel'){
      console.log('canceled out of the modal');
    }
    this.dss.showModal.next({modalName : this.modalName, showModal :false});
  }

  deletePrec() {
    console.log(this.precToDelete);

    this.prsService.deletePersonnelRecord(this.precToDelete.prId).subscribe(
      (responseData) => {
        this.loading=false;
        this.precToDelete = null;
        this.ngOnInit();
      },
      (error) => {
        console.log(error.message);
        this.router.navigate(['techerror']);
      }
    );
  }
}
