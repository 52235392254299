import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { RejectedPirepModel } from 'src/app/models/rejectedpirep.model';
import { DataSharingService } from 'src/app/services/datasharing.service';
import { PirepService } from 'src/app/services/pirep.service';

@Component({
  selector: 'app-rejectedpirepdetails',
  templateUrl: './rejectedpirepdetails.component.html',
  styleUrls: ['./rejectedpirepdetails.component.css']
})
export class RejectedpirepdetailsComponent implements OnInit {
  @Input() rp : RejectedPirepModel;
  @Output() onRPUpdateComplete = new EventEmitter<string>();

  @Output() modalMessage: string = "";
  @Output() modalTitle: string = "";
  @Output() modalStatus: string = "";
  @Output() modalPurpose: string = "";
  @Output() showCancelButton: boolean = false;
  @Output() modalName : string;
  showMiniClose: boolean = false;
  reversalReason : string = '';

  constructor(private rpService: PirepService, private router: Router, private dss : DataSharingService) { }

  ngOnInit(): void {
    console.log(this.rp);
    this.reversalReason='';
    this.modalName = 'rpConfirmationModal' + this.rp.id;
  }

  onSubmit(rpDetailForm, rpId) {
    console.log(rpDetailForm);
    console.log(rpId);
  }

  onModalClosed(returnMessage: {status: string, purpose: string}) {
    this.showCancelButton=false;
    console.log(returnMessage.purpose);
    if (returnMessage.purpose == 'acceptRejection' && returnMessage.status == 'ok') {
      this.executeRejectionAcceptance();
    } else if (returnMessage.purpose == 'reverseRejection' && returnMessage.status == 'ok') {
      this.executeRejectionReverse();
    } else if (returnMessage.status == 'cancel'){
      console.log('canceled out of the modal');
    }
    this.dss.showModal.next({modalName : this.modalName, showModal :false});
  }

  executeRejectionAcceptance() {
    this.rpService.deleteRejected(this.rp.id).subscribe(
      (responseData) => {
        this.onRPUpdateComplete.emit("rejectAccepted");
      },
      (error) => {
        console.log(error.message);
        this.router.navigate(['techerror']);
      }
    );
  }

  executeRejectionReverse() {
    var payload = {"action":"reverse","reason":this.reversalReason};
    console.log(payload);
    this.rpService.reverseRejected(this.rp.id,payload).subscribe(
      (responseData) => {
        this.onRPUpdateComplete.emit("rejectReversed");
      },
      (error) => {
        console.log(`WARN ${error}`);
        this.router.navigate(['/techerror']);
      }
    );
  }
  acceptRejection(rpDetailForm) {
    console.log(this.rp.id);

    this.modalStatus='danger';
    this.showMiniClose=false;
    this.showCancelButton=true;
    this.modalTitle = 'Confirm Accept Rejection';
    this.modalMessage = 'Are you sure you would like to Accept the Rejection of PIREP: ' + this.rp.id + '?<br/>';
    this.modalMessage += 'This will permanently delete the PIREP and is not reversible.';
    this.modalPurpose = "acceptRejection";
    this.dss.showModal.next({modalName : this.modalName, showModal :true});
  }

  reverseRejection(rpDetailForm) {
    console.log(rpDetailForm)
    console.log(this.rp.id);
    this.reversalReason = rpDetailForm.reason;
    console.log(this.reversalReason);
    this.modalStatus='warning';
    this.showMiniClose=false;
    this.showCancelButton=true;
    this.modalTitle = 'Confirm Accept Reversal';
    this.modalMessage = 'Are you sure you would like to Reverse the Rejection of PIREP: ' + this.rp.id + '?<br/>';
    this.modalMessage += 'This will put the PIREP in accepted status and move it back into the Pilot\' logbook.';
    this.modalPurpose = "reverseRejection";
    this.dss.showModal.next({modalName : this.modalName, showModal :true});
  }
}
