<div class="container mt-2 mb-3">
    <div class="row">
        <div class="col-md-12">
            <div class="card shadow rounded-top rounded-lg">
                <div class="card-header panel-american-coral">
                    <div class="row">
                        <div class="col-10">
                            <span>Welcome Message</span>
                        </div>
                    </div>
                </div>
                <div class="card-body" *ngIf="!loading ">
                    <form name="wdDetailForm" class="css-form" #wdDetailForm="ngForm" (ngSubmit)="onSubmit(wdDetailForm.value)">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <label for="txtWDTitle">Title:</label>
                                    <input type="text" id="txtWDTitle" name="txtWDTitle" class="form-control" #txtWDTitle="ngModel" ngModel [(ngModel)]="welcomeMessage.title" minlength="3" maxlength="100" pattern="^[\w\-,.?#!/'()\s]+$" required />
                                    <div class="alert alert-danger" *ngIf="txtWDTitle.invalid && (txtWDTitle.dirty || txtWDTitle.touched)">
                                        <div *ngIf="txtWDTitle.errors?.required">
                                            You must enter a Welcome Message Title.
                                        </div>
                                        <div *ngIf="txtWDTitle.errors?.minlength || txtWDTitle.errors?.maxlength || txtWDTitle.errors?.pattern">
                                            Title is invalid: must be 10 to 100 alphanumeric characters plus -,.?#!()
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <label for="txtWDMessage">Message:</label>
                                    <textarea id="txtWDMessage" name="txtWDMessage" class="form-control" #txtWDMessage="ngModel" ngModel [(ngModel)]="welcomeMessage.message" rows=7 minlength="4" maxlength="3000" required>
                                        </textarea>
                                    <span *ngIf="txtWDMessage.dirty || showCounter">{{welcomeMessage.message.length}} of 3000</span>
                                    <div class="alert alert-danger" *ngIf="txtWDMessage.invalid && (txtWDMessage.dirty || txtWDMessage.touched)">
                                        <div *ngIf="txtWDMessage.errors?.required">
                                            You must enter Welcome Message.
                                        </div>
                                        <div *ngIf="txtWDMessage.errors?.minlength || txtWDMessage.errors?.maxlength ">
                                            Message is invalid: must be 4 to 3000 alphanumeric characters plus -,.?#!()
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div [ngClass]="{'col-sm-1 || offset-sm-11 ' : !isPreview, 'col-sm-12' : isPreview}">
                                <button class="btn btn-success btn-sm float-right" id="btnSaveFDMessage" [disabled]="(txtWDMessage.invalid || !txtWDMessage.dirty) && (txtWDTitle.invalid || !txtWDTitle.dirty)">
                                    Save
                                </button>
                            </div>
                        </div>
                    </form>
                    <button class="btn btn-secondary btn-sm" (click)="isPreview=!isPreview"><i class="fas fa-search"></i> Preview</button>
                    <div class="row border border-warning well mt-3" *ngIf="isPreview">
                        <div class="col-sm-12" >
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <label for="txtWDTitlePreview">Title:</label>
                                        <div [innerHTML]="txtWDTitle.value"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <label for="txtWDMessagePreview">Message:</label>
                                        <div [innerHTML]="txtWDMessage.value"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container text-center" *ngIf="loading">
        <div class="lds-hourglass"></div>
        <p>Loading...</p>
    </div>