<form name="transferRequestForm" class="css-form" #transferRequestForm="ngForm">
    <div class="card border-info">
        <div class="card-header ">
            <span><b># {{transferRequest.transferId}}</b> -- </span>
            <span>
                ({{transferRequest.callsign}}) {{transferRequest.firstName}} {{transferRequest.lastName}}
            </span>
            <span class="float-right">
                {{transferRequest.obHubName}} <i class="fas fa-arrow-right"></i> {{transferRequest.ibHubName}}
            </span>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-md-12">
                    <label>Reason:</label>
                    <p>{{transferRequest.reason}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-9">
                    <label>Comments:</label>
                    <input type="text" id="comments" name="comments" class="form-control" ngModel
                        #comments="ngModel" required minlength="3" maxlength="100"
                        pattern="^[\w\-,.?#!()\s]+$" />
                    <div class="alert alert-danger"
                        *ngIf="comments.invalid && (comments.dirty || comments.touched)">
                        <div *ngIf="comments.errors?.required">
                            You must enter a Manager's Comment for your Transfer.
                        </div>
                        <div
                            *ngIf="comments.errors?.minlength || comments.errors?.maxlength || comments.errors?.pattern">
                            Manager's Comment is invalid: must be 10 to 100 alphanumeric characters plus -,.?#!()
                        </div>
                    </div>
                </div>
                <div class="col-md-3" *ngIf="comments.dirty">
                    <label>New Callsign:</label>
                    <select name="newCallsign" id="newCallsign" class="form-control" ngModel 
                        #newCallsign="ngModel" required>
                        <option *ngFor="let callsign of transferRequest.availableCallsigns;let i = index"
                            [value]="callsign">
                            {{callsign}}
                        </option>
                    </select> 
                    <div class="alert alert-danger" *ngIf="newCallsign.invalid && (newCallsign.dirty || newCallsign.touched)">
                        <div *ngIf="newCallsign.errors?.required">
                            You must enter a Manager's Comment for your Transfer.
                        </div>
                    </div>                                       
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-md-12 ">
                    <button class="btn btn-danger" id="btnAppReject" *ngIf="!loading" (click)="isRejecting=true;reject(transferRequestForm.value)" [disabled]="comments.invalid">
                        <i class="fas fa-thumbs-down"></i> Reject
                    </button>
                    <button type="button" class="btn btn-danger disabled" *ngIf="loading && isRejecting">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Rejecting...
                    </button>                    
                    <button type="button" class="btn btn-success float-right" *ngIf="!loading" (click)="isAccepting=true;approve(transferRequestForm.value)" [disabled]="!transferRequestForm.valid || (!transferRequestForm.touched && !transferRequestForm.dirty)">
                        <span class="spinner-border spinner-border-sm" *ngIf="loading" role="status" aria-hidden="true"></span>
                        <i class="fas fa-thumbs-up"></i> Accept
                    </button>
                    <button type="button" class="btn btn-success float-right disabled" *ngIf="loading && isAccepting">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Accepting...
                    </button>                     
                </div>
            </div>            
        </div>
    </div>
</form>

<app-avamodal [modalName]="modalName" [modalTitle]="modalTitle" [modalMessage]="modalMessage" [modalTitle]="modalTitle"
    [showMiniClose]="showMiniClose" [showCancelButton]="showCancelButton" [modalStatus]="modalStatus"
    [buttonText]="'Ok'" [modalPurpose]="modalPurpose" (modalClosed)="onModalClosed($event)"></app-avamodal>
