<div class="container-fluid no-gutters mt-2 mb-2" *ngIf="!loading">
    <div class="card shadow rounded-top rounded-lg">
        <div class="card-header panel-american-coral">
            <div class="row">
                <div class="col-6">
                    HR Pilot Profile for {{profilePilot.callSign}}
                </div>
                <div class="col-6 d-flex justify-content-end">
                    <span>Hired on {{profilePilot.hireDate}}</span>
                </div>
            </div>
        </div>
        <div class="card-body ">
            <div class="row ">
                <div class="col-md-12">
                    <div class="card ">
                        <div class="card-header bg-ava-info">
                            <div class="row">
                                <div class="col-6">
                                    Pilot Details
                                </div>
                                <div class="col-6 d-flex justify-content-end">
                                    Status:
                                    <span class="ml-1" *ngIf="profilePilot.status=='Leave of Absence'"
                                        style="color:orange">
                                        {{profilePilot.status}} until {{profilePilot.statusEndDate}}
                                    </span>

                                    <span class="ml-1"
                                        *ngIf="profilePilot.status!='ACTIVE' && profilePilot.status!='Leave of Absence' && profilePilot.status!='Probation'"
                                        style="color:red">
                                        {{profilePilot.status}} requested on {{profilePilot.statusEndDate}}
                                    </span>

                                    <span class="ml-1"
                                        *ngIf="profilePilot.status=='ACTIVE' || profilePilot.status=='Leave of Absence' || profilePilot.status=='Probation'"
                                        style="color:green">
                                        {{profilePilot.status}}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <app-hrpilotprofile [profilePilot]="profilePilot" [profilePilotId]="profilePilotId"
                                [staffMember]="staffPilot"></app-hrpilotprofile>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-md-12">
                    <div class="card ">
                        <div class="card-header bg-ava-info">
                            Pilot Hours & Rating
                        </div>
                        <div class="card-body ">
                            <div class="row mb-2">
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="vatsimId">VATSIM ID:</label>
                                        <input type="text" id="vatsimId" name="vatsimId" class="form-control"
                                            #vatsimId="ngModel" [ngModel]="profilePilot.vatsimId" disabled />
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="txtFirstName">CAT Rating:</label>
                                        <input type="text" id="catRating" name="catRating" class="form-control"
                                            #catRating="ngModel" [ngModel]="profilePilot.catRating" disabled />
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="txtLastName">Hours:</label>
                                        <input type="text" id="hours" name="hours" class="form-control" #hours="ngModel"
                                            [ngModel]="profilePilot.totalHours" disabled />
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="txtVatsimId">PIREPs:</label>
                                        <input type="text" id="pireps" name="pireps" class="form-control"
                                            #pireps="ngModel" [ngModel]="profilePilot.totalPireps" disabled />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-md-6">
                    <div class="card ">
                        <div class="card-header bg-ava-info">
                            External Stats Resources <span style="font-size: .75em;color:orangered"> - (opens in new
                                window)</span>
                        </div>
                        <div class="card-body">
                            <div class="row mb-2">
                                <div class="col-md-12">
                                    <span class="ml-3" *ngFor="let statLink of statsLinks">
                                        <a href="{{statLink.href}}" target="_blank">
                                            <img src="{{statLink.meta.icon}}" alt="{{statLink.meta.text}}"
                                                height="32em" />
                                        </a>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="card ">
                        <div class="card-header bg-ava-info">
                            HR Info & Actions
                        </div>
                        <div class="card-body">
                            <div class="row mb-2">
                                <div class="col-md-12">
                                    <button class="btn btn-link btn-block text-left" type="button"
                                        data-toggle="collapse" href="#collapseExample" role="button"
                                        aria-expanded="false" aria-controls="collapseExample"
                                        (click)="toggleCollapse()">
                                        Personnel Records
                                        <span class="float-right">
                                            <i class="fas fa-angle-up" *ngIf="collapseUp"></i><i
                                                class="fas fa-angle-down" *ngIf="!collapseUp"></i>
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div class="collapse" id="collapseExample">
                                <div class="row">
                                    <div class="col-md-12" style="font-size:.75em">
                                        <table class="table table-hover responsive">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Date</th>
                                                    <th scope="col">Action</th>
                                                    <th scope="col">Comments</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let pr of profilePilot.personnelRecords;let i = index">
                                                    <th scope="row">{{i+1}}</th>
                                                    <td>{{pr.Date_Action}}</td>
                                                    <td>{{pr.Action}}</td>
                                                    <td>{{pr.comments}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-md-12">
                    <app-hrlogbookprofile [profilePilot]="profilePilot" [profilePilotId]="profilePilotId" [staffPilot]="staffPilot" [perms]="perms"></app-hrlogbookprofile>
                </div>
            </div>
        </div>
    </div>