<div class="row">

    <div class="col-sm-4">
        <canvas baseChart [data]="pieChartData" [labels]="pieChartLabels" [chartType]="pieChartType"
            [options]="pieChartOptions" [plugins]="pieChartPlugins" [colors]="pieChartColors" [legend]="pieChartLegend">
        </canvas>
    </div>
    <div class="col-sm-4">
        <div class="hhbox active-pilots"></div>
        <div>&nbsp;&nbsp;{{hubHealthInfo.activePilots}} - Active Pilots</div><br />
        <div class="hhbox inactive-pilots"></div>
        <div>&nbsp;&nbsp;{{hubHealthInfo.inactivePilots}} - Inactive Pilots</div><br />
        <div class="hhbox warned-pilots"></div>
        <div>&nbsp;&nbsp;{{hubHealthInfo.warnedPilots}} - Warned Pilots</div><br />
        <div class="hhbox loa-pilots"></div>
        <div>&nbsp;&nbsp;{{hubHealthInfo.loaPilots}} - LOA Pilots</div><br />
        <!-- <div class="hhbox probie-pilots"></div>
        <div>&nbsp;&nbsp;{{hubHealthInfo.probiePilots}} - Probie Pilots</div> -->
    </div>
    <div class="col-sm-4">
        <div class="row-fluid" ng-if="!isLoadAll">
            <div class="col-sm-12">
                <b>Manager:</b>
            </div>
            <div class="col-sm-12">
                {{hubHealthInfo.hubManager.callsign}} - {{hubHealthInfo.hubManager.firstName}}
                {{hubHealthInfo.hubManager.lastName}}
            </div>
        </div>
        <div class="row-fluid" ng-if="!isLoadAll">
            <div class="col-sm-12">
                <b>Asst. Manager:</b>
            </div>
            <div class="col-sm-12">
                {{hubHealthInfo.assistantHubManager.callsign}} - {{hubHealthInfo.assistantHubManager.firstName}}
                {{hubHealthInfo.assistantHubManager.lastName}}
            </div>
        </div>
        <div class="row-fluid">
            <div class="col-sm-12">
                <b>Total Pilots:</b>
            </div>
            <div class="col-sm-12">
                {{hubHealthInfo.totalPilots}}
            </div>
        </div>
        <div class="row-fluid">
            <div class="col-sm-12">
                <b>Flights Last 31 Days:</b>
            </div>
            <div class="col-sm-12">
                {{hubHealthInfo.totalFlights}}
            </div>
        </div>
    </div>
</div>