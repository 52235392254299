import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ChartConfiguration, ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { Color } from 'ng2-charts/lib/color';
import { AvAStatModel } from 'src/app/models/avastat.model';
import { StatsService } from 'src/app/services/stats.service';

@Component({
  selector: 'app-flightstats',
  templateUrl: './flightstats.component.html',
  styleUrls: ['./flightstats.component.css']
})
export class FlightstatsComponent implements OnInit {
  loading: boolean = false;
  constructor(private router: Router, private statsService: StatsService) { }
  flightStats: AvAStatModel[] = [];

  monthlyByPilotChartData: ChartDataSets[];
  dailyTrendChartData;
  dailyTrendLabels;
  yearlyByPilotChartData: ChartDataSets[];
  monthlyByHubChartData: ChartDataSets[];
  yearlyByHubChartData: ChartDataSets[];
  monthlyTotalsChartData: ChartDataSets[];
  yearlyTotalsChartData: ChartDataSets[];
  windowScrolled = false;

  COLOR_GRID = ['#2989d8', '#FDB45C', '#00ADF9', '#ed042b', '#46BFBD', '#949FB1', '#4D5360', '#bc5090', '#ffa600', '#003f5c', '#4D4567','#ed1980'];

  lineChartLegend = false;
  horizonaltalBarChartType: ChartType = 'horizontalBar';
  lineChartType: ChartType = 'line';
  barChartType: ChartType = 'bar';

  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

  ngOnInit(): void {
    //add listener for scroll up button
    window.addEventListener('scroll', () => {
      this.windowScrolled = window.pageYOffset > 200;
    });
    this.loading = true;
    this.loadFlightStats();
  }

  tabTop() {
    window.scrollTo(0, 0);
  }

  public lineChartOptions: (ChartOptions) = {
    scales: {
      xAxes: [{}],
      yAxes: [
        {
          id: 'y-axis-1',
          type: 'linear',
          display: true,
          position: 'left',
          scaleLabel: {
            display: true,
            labelString: ''
          }
        },
      ],
    },
  };

  loadFlightStats() {
    this.statsService.getFlightStats().subscribe(
      (responseData: AvAStatModel[]) => {
        console.log(responseData)
        this.flightStats = responseData;
        this.loadUpDataSets();
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        if (error.status = 404) {
        } else {
          this.router.navigate(['/techerror']);
        }
      }
    );
  }

  loadUpDataSets() {

    this.loadMonthlyByPilot();
    this.loadDailyTrend();
    this.loadYearlyByPilot();
    this.loadMonthlyByHubData();
    this.loadYearlyByHubData();
    this.loadMonthlyTotalsChartData();
    this.loadYearlyTotalsData();


  }

  loadMonthlyByPilot() {
    this.monthlyByPilotChartData = [
      {
        data: this.flightStats['monthlyByPilot'].data,
        label: '',
        backgroundColor: this.COLOR_GRID
      }
    ];
  }

  loadDailyTrend() {
    this.dailyTrendChartData = [{
      data: this.flightStats['dailyTrend'].data,
      label: this.flightStats['dailyTrend'].series,
      backgroundColor: 'rgba(41,137,216,0.3)',
      borderColor: '#2989d8',
      pointBackgroundColor: '#2989d8',
      pointBorderColor: '#2989d8',
      pointHoverBackgroundColor: '#2989d8',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)',
      yAxisID: 'y-axis-1'
    }]
    this.lineChartType = 'line';
    this.dailyTrendLabels = this.flightStats['dailyTrend'].labels;
  }

  loadYearlyByPilot() {
    this.yearlyByPilotChartData = [
      {
        data: this.flightStats['yearlyByPilot'].data,
        label: '',
        backgroundColor: this.COLOR_GRID
      }
    ];
  }
  loadMonthlyByHubData() {
    this.monthlyByHubChartData = [
      {
        data: this.flightStats['monthlyByHub'].data,
        label: '',
        backgroundColor: this.COLOR_GRID
      }
    ];
  }

  loadYearlyByHubData() {
    this.yearlyByHubChartData = [
      {
        data: this.flightStats['yearlyByHub'].data,
        label: '',
        backgroundColor: this.COLOR_GRID
      }
    ];
  }

  loadMonthlyTotalsChartData() {
    this.monthlyTotalsChartData =[];
    this.monthlyTotalsChartData = [
      {
        data: this.flightStats['monthlyTotals'].data,
        label: '',
        backgroundColor: this.COLOR_GRID
      }
    ];
  }

  loadYearlyTotalsData() {
    this.yearlyTotalsChartData = [
      {
        data: this.flightStats['yearlyTotals'].data,
        label: '',
        backgroundColor: this.COLOR_GRID
      }
    ];
  }
}
