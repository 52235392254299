import { Component, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ProfileModel } from 'src/app/models/profile.model';
import { AuthService } from 'src/app/services/auth.service';
import { DataSharingService } from 'src/app/services/datasharing.service';
import { ResignationService } from 'src/app/services/resignation.service';

@Component({
  selector: 'app-resignationform',
  templateUrl: './resignationform.component.html',
  styleUrls: ['./resignationform.component.css']
})
export class ResignationformComponent implements OnInit {
  @Input() profile : ProfileModel;
  @Input() pilotId : number;
  loading : boolean = false;

  @Output() modalMessage: string = "";
  @Output() modalTitle: string = "";
  @Output() modalStatus: string = "";
  @Output() modalPurpose: string = "";
  @Output() showCancelButton: boolean = false;
  @Output() showMiniClose: boolean = false;
  @Output() modalName : string = 'resignationFormModal';

  constructor(private resignationService : ResignationService, private authService : AuthService, private router : Router, private dss : DataSharingService) { }

  ngOnInit(): void {

  }



  onSubmit(resForm) {
    this.loading=true;
    console.log(resForm, this.profile);

    this.resignationService.postResignation(resForm, this.pilotId).subscribe(
      (responseData) => {
        console.log(responseData);
        this.showSuccessModal(responseData);
        this.loading=false;
      },
      (error) => {
        if (error.status === 500 ) {
          this.router.navigate(['/techerror']);
        } else {
          this.showFailureModal(error);
        }
        this.loading=false;
      }
    )
  }

  onModalClosed(returnMessage: {status: string, purpose: string}) {
    if (returnMessage.purpose == 'resignationsuccess' && returnMessage.status == 'ok') {
      this.authService.invalidateToken();
      this.dss.isAuthenticated.next(false);
      this.dss.isManager.next(false);
      this.router.navigate(['/']);
    }

    this.ngOnInit();
  }

  showSuccessModal(data) {
    this.modalStatus='success';
    this.showMiniClose=false;
    this.showCancelButton=false;
    this.modalTitle = 'Resignation Submitted';
    this.modalMessage = 'Your Resignation has been successfully submitted as Resignation #:' + data['resignationId'];
    this.modalMessage += '<br/>You will be immediately logged out of the system upon clicking OK.';
    this.modalPurpose = "resignationsuccess";
    this.dss.showModal.next({modalName : this.modalName, showModal :true});
  }

  showFailureModal(error) {
    this.modalStatus='danger';
    this.showMiniClose=false;
    this.showCancelButton=false;
    this.modalTitle = 'Resignation Request Failed';
    this.modalMessage = 'Your Resignation request failed to be processed with the following error:<br/>';
    if (error.error.hasOwnProperty("messages")) {
      for (const key in error.error.messages) {
        this.modalMessage += '<br/>' + error.error.messages[key];
      }
    }
    console.log(error.message + ' == ' + error.status);
    this.modalPurpose = "resignationfailure";
    this.dss.showModal.next({modalName : this.modalName, showModal :true});
  }
}
