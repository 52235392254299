import { Component, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataSharingService } from 'src/app/services/datasharing.service';
import { PwdresetService } from 'src/app/services/pwdreset.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pwdreset',
  templateUrl: './pwdreset.component.html',
  styleUrls: ['./pwdreset.component.css'],
})
export class PwdresetComponent implements OnInit {
  siteKey=environment.RECAPTCHA_KEY;
  isCaptchaResolved=false;
  isCaptchaSkippable=false;
  skipCaptcha=false;
  catpchaResponse : string = "";

  //modal vars
  @Output() modalMessage: string = "";
  @Output() modalTitle: string = "";
  @Output() modalStatus: string = "";
  @Output() modalPurpose: string = "";
  @Output() showCancelButton: boolean = false;
  @Output() showMiniClose: boolean = false;
  @Output() modalName : string = 'pwdresetmodal';

  constructor(private router : Router, private dss : DataSharingService,private route: ActivatedRoute, private pwdResetService : PwdresetService) {}

  ngOnInit(): void {
    if (!environment.production) {
      this.isCaptchaSkippable=true;
    } else {
      this.isCaptchaSkippable=false;
    }

    this.dss.isResetPasswordActive.subscribe(
      routerPackage => {
        console.log(routerPackage);
        if (!routerPackage.hasOwnProperty('route')) {
          this.router.navigate(['/']);
        } else if (routerPackage['route'] === '') {
          this.router.navigate(['/signup']);
        }
      }
    )
  }

  onSubmit(resetPasswordForm) {
    let payload = {
      gresponse : this.catpchaResponse,
      vatsimId : resetPasswordForm.vatsimId,
      email : resetPasswordForm.email,
      skipCaptcha : resetPasswordForm.skipCaptcha
    }

    this.pwdResetService.resetPassword(payload).subscribe(
      (responseData) => {
        console.log(responseData);
        this.showMessageModal();
      },
      (error) => {
        // TODO: why is there no way to show an error?????
        console.log(error);
      }
    );
  }

  resolved(captchaResponse: string) {
    this.isCaptchaResolved=true;
    this.catpchaResponse=captchaResponse;
  }

  ngOnDestroy() : void {
    this.dss.isResetPasswordActive.next({route : 'internal', id : -1});
  }

  showMessageModal() {
    this.dss.showModal.next({modalName : this.modalName, showModal :false});
    this.modalStatus='success';
    this.showMiniClose=false;
    this.showCancelButton=false;
    this.modalTitle = 'Password Reset Request Successful!';
    this.modalMessage = 'Your password reset has been accepted for processing by AvA. An email will be sent to your registered email.  If you fail to receive it, check your junk mail folder first, then contact your hub manager for assistance.';
    this.modalPurpose = "pwdresetmodal";
    this.dss.showModal.next({modalName : this.modalName, showModal :true});
  }

  onModalClosed(returnMessage: {status: string, purpose: string}) {
    this.dss.showModal.next({modalName : this.modalName, showModal :false});
    this.router.navigate(['/']);
  }
}
