import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CompetitionService } from 'src/app/services/competition.service';

@Component({
  selector: 'app-leadingedge',
  templateUrl: './leadingedge.component.html',
  styleUrls: ['./leadingedge.component.css']
})
export class LeadingedgeComponent implements OnInit {
  hasLandings : boolean = false;
  lcResults;

  constructor(private router : Router, private compService : CompetitionService) { }

  ngOnInit(): void {
    this.hasLandings = false;
    this.loadLCResults();
  }

  loadLCResults() {
    this.compService.getLcResults().subscribe(
      (responseData) => {
        console.log(responseData);
        if(Object.keys(responseData).length>0) {
          this.lcResults = responseData;
          this.hasLandings=true;
        } else {
          this.hasLandings=false;
        }
      },
      (error) => {
        this.hasLandings=false;
        if (error.status = 404) {
        } else {
          this.router.navigate(['/techerror']);
        }
      }
    );
  }

}
