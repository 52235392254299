import { Component, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { DataSharingService } from 'src/app/services/datasharing.service';
import { DismissedService } from 'src/app/services/dismissed.service';

@Component({
  selector: 'app-dismisseds',
  templateUrl: './dismisseds.component.html',
  styleUrls: ['./dismisseds.component.css']
})
export class DismissedsComponent implements OnInit {
  loading : boolean = false;
  searchSuccessful : boolean = false;
  searchResults : any[] = [];
  hasMemberSearchResults : boolean = false;
  searchComplete : boolean = false;
  dismissedToRemove : number = 0;
  dismissedIndexToRemoveFromResults : number = 0;

  @Output() modalMessage: string = "";
  @Output() modalTitle: string = "";
  @Output() modalStatus: string = "";
  @Output() modalPurpose: string = "";
  @Output() showCancelButton: boolean = false;
  @Output() modalName : string;
  showMiniClose: boolean = false;

  constructor(private dss : DataSharingService, private disService : DismissedService, private router : Router) { }

  ngOnInit(): void {
    this.dismissedToRemove=0;
    this.searchResults = [];
    this.hasMemberSearchResults= false;
    this.dismissedToRemove=0;
    this.modalName = 'dismissedRemoveConfirmationModal';
  }

  handleSearchResults(searchResults) {
    this.searchResults = [];
    this.searchComplete=true;
    if (searchResults.length>0) {
      this.hasMemberSearchResults=true;
      this.searchResults = searchResults;
    } else {
      this.hasMemberSearchResults=false;
    }
    console.log(searchResults);
  }

  onRemoveFromDismissed(member, index) {
    this.loading=true;
    console.log(member.dsmId);
    this.dismissedToRemove = member.dsmId;
    this.dismissedIndexToRemoveFromResults = index;

    console.log(this.dismissedToRemove);

    this.modalStatus='danger';
    this.showMiniClose=false;
    this.showCancelButton=true;
    this.modalTitle = 'Remove from Dismissed List?';
    this.modalMessage = 'Are you sure you would like to remove ' + member.firstName + ' ' + member.lastName + '\'s Dismissed Record?  This will remove any historical data we have on them. <br/>DSMID: ' + this.dismissedToRemove;
    this.modalPurpose = "removedismissed";
    this.dss.showModal.next({modalName : this.modalName, showModal :true});
  }

  onModalClosed(returnMessage: {status: string, purpose: string}) {
    this.showCancelButton=true;
    if (returnMessage.purpose == 'removedismissed' && returnMessage.status == 'ok') {
      this.removeMemberFromDismissedList();
    } else if (returnMessage.status == 'cancel'){
      console.log('canceled out of the modal');
    }
    this.dss.showModal.next({modalName : this.modalName, showModal :false});
  }

  removeMemberFromDismissedList() {
    console.log(this.dismissedToRemove);

    this.disService.removeDismissedListRecord(this.dismissedToRemove).subscribe(
      (responseData) => {
        this.loading=false;
        this.dismissedToRemove = 0;
        this.searchResults.splice(this.dismissedIndexToRemoveFromResults,1);
        if (this.searchResults.length<1) {
          this.ngOnInit();
        }
      },
      (error) => {
        console.log(error.message);
        this.router.navigate(['techerror']);
      }
    );
  }
}
