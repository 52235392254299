



export const environment = {
  production: false,

  // Update these urls to match where the 'lara_api' project is being hosted
  API_URL : 'https://testapi.joinava.org/api',
  MAPPING_INFO_URL : 'https://testapi.joinava.org/api/',

  // Note there are different recaptcha keys for dev and prod, but only one maps key
  RECAPTCHA_KEY: '6LfaSyUTAAAAAERJcBYqKJjcurQg7IGGtmsHfh9B',
  GOOGLE_MAP_KEY: 'AIzaSyBpQFvvZ3iMIqIhD1_-U0XUVj2tLpdY1Pw',
  GOOGLE_MAP_URL: 'https://maps.googleapis.com/maps/api/js?key=',


  PILOT_STATUS: {
    ACTIVE_STATUS_ID: 0,
    TERMINATION_WARNING_STATUS_ID: 1,
    TERMINATION_IN_PROGRESS_STATUS_ID: 2
  },
  TERMINATION_REASONS: [
    {
      "id": "1",
      "name": "Failure to file 2 PIREPs a month."
    },
    {
      "id": "2",
      "name": "You have repeatedly failed to complete your probationary requirements."
    },
    {
      "id": "3",
      "name": "You have repeatedly been hired and terminated/resigned."
    },
    {
      "id": "4",
      "name": "You have exhibited inappropriate behavior that is detrimental to AvA."
    },
    {
      "id": "5",
      "name": "You have filed false PIREPs."
    },
    {
      "id": "6",
      "name": "You do not have the minimum age of 15."
    },
    {
      "id": "7",
      "name": "No VATSIM record was found for the VATSIM id in the application."
    },
    {
      "id": "8",
      "name": "VATSIM record was found, but not VATSIM activity was found. (Must have at least 15 hours)"
    },
    {
      "id": "9",
      "name": "AvA Training Hub is currently closed until further notice.  Pilots with less than 100 hours on VATSIM are not being hired."
    },
    {
      "id": "99",
      "name": "Other"
    }
  ],

  MAPINFO : {
    planeIcon : 'M 194.67321,2.8421709e-14 L 70.641958,53.625 C 60.259688,46.70393 36.441378,32.34961 31.736508,30.17602 C -7.7035221,11.95523 -5.2088921,44.90709 11.387258,54.78122 C 15.926428,57.48187 39.110778,71.95945 54.860708,81.15624 L 72.766958,215.09374 L 94.985708,228.24999 L 106.51696,107.31249 L 178.04821,143.99999 L 181.89196,183.21874 L 196.42321,191.84374 L 207.51696,149.43749 L 207.64196,149.49999 L 238.45446,117.96874 L 223.57946,109.96874 L 187.95446,126.87499 L 119.67321,84.43749 L 217.36071,12.25 L 194.67321,2.8421709e-14 z',
    iconOffset : 58.5,
    runwaySymbol : {
        cirle : 'm512 256c0 141.386719-114.613281 256-256 256s-256-114.613281-256-256 114.613281-256 256-256 256 114.613281 256 256zm0 0',
        path1 : 'm147.390625 71.625c-8.195313-1.210938-15.820313 4.441406-17.035156 12.640625l-46.726563 315.070313c-1.21875 8.191406 4.441406 15.820312 12.636719 17.035156.746094.113281 1.484375.164062 2.21875.164062 7.3125 0 13.714844-5.351562 14.820313-12.800781l46.726562-315.070313c1.214844-8.195312-4.445312-15.820312-12.640625-17.039062zm0 0',
        path2 : 'm428.375 399.335938-46.726562-315.070313c-1.214844-8.195313-8.84375-13.855469-17.039063-12.640625-8.195313 1.21875-13.851563 8.84375-12.636719 17.039062l46.726563 315.070313c1.101562 7.449219 7.507812 12.800781 14.816406 12.800781.730469 0 1.476563-.050781 2.21875-.164062 8.195313-1.214844 13.855469-8.839844 12.640625-17.035156zm0 0'
    }
  },
  //google map configuration for last flight map -- landing page flight map just uses default styling for now
  GOOGLE_MAP_STYLES: [
    {
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#1d2c4d"
        }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#FFFFFF" //"#8ec3b9"
        }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#1a3646"
        }
      ]
    },
    {
      "featureType": "administrative",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "off"
        }
      ]
    },
    {
      "featureType": "administrative.country",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "color": "#4b6878"
        }
      ]
    },
    {
      "featureType": "administrative.land_parcel",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "off"
        }
      ]
    },
    {
      "featureType": "administrative.neighborhood",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "administrative.province",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "color": "#4b6878"
        }
      ]
    },
    {
      "featureType": "landscape.man_made",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "color": "#334e87"
        }
      ]
    },
    {
      "featureType": "landscape.natural",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#023e58"
        }
      ]
    },
    {
      "featureType": "poi",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#304a7d"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "labels",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#98a5be"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#1d2c4d"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#2c6675"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "color": "#255763"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "labels",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#b0d5ce"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#023e58"
        }
      ]
    },
    {
      "featureType": "road.local",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "transit",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "transit",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#98a5be"
        }
      ]
    },
    {
      "featureType": "transit",
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#1d2c4d"
        }
      ]
    },
    {
      "featureType": "transit.line",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#283d6a"
        }
      ]
    },
    {
      "featureType": "transit.station",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#3a4762"
        }
      ]
    },
    {
      "featureType": "transit.station.airport",
      "stylers": [
        {
          "color": "#ffeb3b"
        },
        {
          "weight": 2.5
        }
      ]
    },
    {
      "featureType": "transit.station.bus",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "transit.station.rail",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "water",
      "stylers": [
        {
          "color": "#ffeb3b"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#0e1626"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#4e6d70"
        }
      ]
    }
  ],

  HERO_IMAGES : [
    '/assets/img/hero/General___JoinAvA_-_Discord_2_5_2024_8_56_14_PM.jpg',
    '/assets/img/hero/backup/archived-2024-02/hero44.png',
    '/assets/img/hero/backup/archived-2024-02/hero38.png',
    '/assets/img/hero/backup/archived-2024-02/hero42.png',
    '/assets/img/hero/backup/archived-2024-02/hero50.png',
    '/assets/img/hero/backup/archived-2024-02/hero52.png',
  ]
};


