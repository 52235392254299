<form name="eventDetailForm" class="css-form" #eventDetailForm="ngForm" (ngSubmit)="onSubmit(eventDetailForm.value, event.attributes.eventId)">
    <div class="row">
        <div class="col-sm-6">
            <div class="form-group">
                <label for="txtName">Event Name:</label>
                <input type="text" id="txtName" name="txtName" class="form-control" #txtName="ngModel" ngModel [(ngModel)]="event.attributes.name" minlength="10" maxlength="100" pattern="[\w\s\-,.?#!\/'\(\)]+" required />
                <div class="alert alert-danger" *ngIf="txtName.invalid && (txtName.dirty || txtName.touched)">
                    <div *ngIf="txtName.errors?.required">
                        You must enter an Event Name.
                    </div>
                    <div *ngIf="txtName.errors?.minlength || txtName.errors?.maxlength || txtName.errors?.pattern">
                        Event Name is invalid: must be 10 to 100 alphanumeric characters plus -,.?#!/'()
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-3">
            <div class="form-group">
                <label for="txtTrackingTag">
                    Event Tracking Tag:
                    <ng-template #evETT>
                        The ETT will be used by pilots for the achievement system
                    </ng-template>
                    <i class="fas fa-info-circle" [ngbPopover]="evETT" popoverTitle="Used to track events for achievements" style="cursor: pointer;font-size: .85rem;"></i>
                </label>
                <input type="text" id="txtTrackingTag" name="txtTrackingTag" class="form-control" #txtTrackingTag="ngModel" ngModel [(ngModel)]="event.attributes.trackingTag" minlength="3" maxlength="50" pattern="[\w\s\-,.?#!\/'\(\)]+" required />
                <div class="alert alert-danger" *ngIf="txtTrackingTag.invalid && (txtTrackingTag.dirty || txtTrackingTag.touched)">
                    <div *ngIf="txtTrackingTag.errors?.required">
                        You must enter an Event Tracking Tag.
                    </div>
                    <div *ngIf="txtTrackingTag.errors?.minlength || txtTrackingTag.errors?.maxlength || txtTrackingTag.errors?.pattern">
                        Event Tracking Tag is invalid: must be 3 to 50 alphanumeric characters plus -,.?#!/'()
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-3 justify-content-right">
            <label>Event Id:</label><br />
            <span>{{event.attributes.eventId}}</span>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12">
            <div class="form-group">
                <label for="txtDescription">Event Description:</label>
                <ng-template #evDesc>
                    Feel free to use HTML and then hit the Preview button to see the description.
                </ng-template>
                <i class="fas fa-info-circle" [ngbPopover]="evDesc" popoverTitle="You can use HTML" style="cursor: pointer;font-size: .85rem;"></i>

                <textarea id="txtDescription" name="txtDescription" class="form-control" #txtDescription="ngModel" ngModel [(ngModel)]="event.attributes.description" rows=10 minlength="4" maxlength="3000" required>
                            </textarea>
                <span *ngIf="txtDescription.dirty">{{txtDescription.value.length}} of 3000</span>
                <div class="alert alert-danger" *ngIf="txtDescription.invalid && (txtDescription.dirty || txtDescription.touched)">
                    <div *ngIf="txtDescription.errors?.required">
                        You must enter an Event Description.
                    </div>
                    <div *ngIf="txtDescription.errors?.minlength || txtDescription.errors?.maxlength ">
                        Event Description is invalid: must be 4 to 3000 alphanumeric characters plus -,.?#!()
                    </div>
                </div>
            </div>
            <button type="button" class="btn btn-secondary btn-sm float-right" (click)="previewClicked()"><i class="fas fa-glasses"></i> Preview</button>
            <div class="row border border-warning well mt-3 mb-2" *ngIf="isPreview">
                <div class="row float-left">
                    <div class="col-sm-12">
                        <div class="form-group p-3">
                            <label for="txtDescriptonPreview">Description:</label>
                            <p  [innerHTML]="event.attributes.description"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr class="sexy-line"/>
    <div class="row">
        <div class="col-md-3 ">
            <div class="form-group">
                <label for="startDate">Event Date:</label>
                <div class="input-group ngb-dp-today ">
                    <input class="form-control" placeholder="yyyy-mm-dd" name="startDate" [(ngModel)]="eventDate" ngbDatepicker #d="ngbDatepicker" required>
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"><i class="far fa-calendar-alt"></i></button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="form-group">
                <label for="startTime">Start Time (UTC):</label>
                <i class="fas fa-info-circle ml-2" data-toggle="tooltip" data-placement="top" title="All times must be in UTC!" style="color:grey"></i>
                <ngb-timepicker name="startTime" id="startTime" [(ngModel)]="eventTime" required [spinners]="false" #startTime (change)="onDateTimeChange()">
                </ngb-timepicker>
                <div class="alert alert-danger" *ngIf="startTime.invalid && (startTime.dirty || startTime.touched)">
                    <div *ngIf="startTime.errors?.required">
                        You must enter start time.
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-4">
            <div class="form-group">
                <label>Event Duration (hours):</label>
                <input type="number" class="form-control" id="duration" name="duration" ngModel [(ngModel)]='event.attributes.duration' required #duration />
                <div class="alert alert-danger" *ngIf="duration.invalid && (duration.dirty || duration.touched)">
                    <div *ngIf="duration.errors?.required">
                        You must enter a duration.
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12">
            <div class="form-group">
                <label for="txtUrl">Event URL (optional - note: url format is NOT validated):</label>
                <input type="text" id="txtUrl" name="txtUrl" class="form-control" #txtUrl="ngModel" ngModel [(ngModel)]="event.attributes.url" minlength="10" maxlength="150"/>
                <div class="alert alert-danger" *ngIf="txtUrl.invalid && (txtUrl.dirty || txtUrl.touched)">
                    <div *ngIf="txtUrl.errors?.minlength || txtUrl.errors?.maxlength">
                        Event URL is invalid: length must be between 10 and 150 characters
                    </div>
                    <div *ngIf="txtBannerUrl.errors?.pattern">
                        Event URL is invalid: must be a properly formed URL
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12">
            <div class="form-group">
                <label for="txtBannerUrl">Event Banner URL (optional - note: url format is NOT validated):</label>
                <i class="fas fa-info-circle ml-2" data-toggle="tooltip" data-placement="top" title="The URL to the image or banner used by the event." style="color:grey"></i>
                <input type="text" id="txtBannerUrl" name="txtBannerUrl" class="form-control" #txtBannerUrl="ngModel" ngModel [(ngModel)]="event.attributes.bannerUrl" minlength="10" maxlength="200"/>
                <div class="alert alert-danger" *ngIf="txtBannerUrl.invalid && (txtBannerUrl.dirty || txtBannerUrl.touched)">
                    <div *ngIf="txtBannerUrl.errors?.minlength || txtBannerUrl.errors?.maxlength">
                        Banner URL is invalid: length must be between 10 and 200 characters
                    </div>
                    <div *ngIf="txtBannerUrl.errors?.pattern">
                        Banner URL is invalid: must be a properly formed URL
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="row">
                    <div class="col-sm-12">
                        <div class="form-group" >
                            <label for="fileBannerImg">Upload Image:</label><br/>
                            <input type="file" id="fileBannerImg" name="fileBannerImg" (change)="onFileSelected($event)" #fileBannerImg ngModel />
                        </div>
                    </div>
                </div>     not included in V22.1-->
    <div class="row mt-3">
        <div class="col-sm-12 ">
            <button type="button" class="btn btn-secondary" *ngIf="showCancel" (click)="cancelEventAdd()">
                Cancel
            </button>
            <button type="button" class="btn btn-danger" *ngIf="showDelete" (click)="onDeleteClick(event.attributes.eventId)">
                <i class="fas fa-times mr-2"></i>Delete
            </button>
            <button type="submit" class="btn btn-success float-right" [disabled]="eventDetailForm.invalid || !eventDetailForm.dirty">
                <i class="fas fa-check mr-2"></i>
                <span *ngIf="!isNew">Update</span>
                <span *ngIf="isNew">Add</span>
            </button>
        </div>
    </div>
</form>

<app-avamodal [modalName]="modalName" [modalMessage]="modalMessage" [modalTitle]="modalTitle" [showMiniClose]="showMiniClose" [showCancelButton]="showCancelButton" [modalStatus]="modalStatus" [buttonText]="'Ok'" [modalPurpose]="modalPurpose" (modalClosed)="onModalClosed($event)"></app-avamodal>
