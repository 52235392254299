<div class="container mt-2 mb-5">
    <div class="row">
        <div class="col-md-12">
            <div class="card shadow rounded-top rounded-lg">
                <div class="card-header panel-american-coral">
                    <div class="row">
                        <div class="col-12">
                            Email Center
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <form name="emailForm" class="css-form" (ngSubmit)="onSubmit(emailForm.value)" #emailForm="ngForm"
                        *ngIf="!loading">
                            <div class="row mt-2">
                                <div class="col-sm-2">
                                    <label class="control-label float-right">From:</label>
                                </div>
                                <div class="col-sm-10">
                                    <span style="text-decoration: underline;"><b>{{staffDetail.email}}&#64;joinava.org</b> -
                                        {{staffDetail.staffDetail}}</span>
                                </div>
                            </div>
                            <div class="row mt-2" *ngIf="!hasPickedToOption">
                                <div class="col-sm-2">
                                    <label class="control-label float-right">To:</label>
                                </div>
                                <div class="col-sm-10">
                                    <div class="row" *ngFor="let toOption of staffDetail.toOptions">
                                        <div class="col-sm-12">
                                            <input type="radio" name="optionsRadios" id="{{toOption}}" value="$index" (click)="pickToOption(toOption)">
                                            {{toOption.name}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-2" *ngIf="isSingleMember && isAddressesLoaded">
                                <div class="col-sm-2">
                                    <label class="control-label float-right">To:</label>
                                </div>
                                <div class="col-sm-5">
                                    <select name="slctMemberToAddress" id="slctMemberToAddress" class="form-control" ngModel
                                        #slctMemberToAddress="ngModel" required (change)="addMemberToList(slctMemberToAddress.value)" >
                                        <option *ngFor="let pilot of pilots;let i = index"
                                            [value]="i">
                                            {{pilot.attributes.callsign}} - {{pilot.attributes.firstName}} {{pilot.attributes.lastName}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="row mt-2" *ngIf="toPilots.length>0">
                                <div class="offset-sm-2 col-sm-10">
                                    <p>PILOTS:</p>
                                    <button *ngFor="let pilot of toPilots; let i = index" class="btn btn-sm rounded border-secondary" style="margin-right: 2px" (click)="false">
                                        <span class="bg">({{pilot.attributes.callsign}}) {{pilot.attributes.lastName}}</span>
                                        <a href id="rmvToPilot" (click)="removeToPilot(i)" title="Remove To Pilot">
                                            <i class="fas fa-user-times ml-2" style="color:red;font-size:12px"></i>
                                        </a>
                                    </button>
                                </div>
                            </div>
                            <div class="row mt-2" *ngIf="isGroups && isGroupsLoaded">
                                <div class="col-sm-2">
                                    <label class="control-label float-right">To:</label>
                                </div>
                                <div class="col-sm-5">
                                    <select name="slcGroup" id="slctMemberToAddress" class="form-control" ngModel
                                        #slcGroup="ngModel" required (change)="addGroupToList(slcGroup.value)" >
                                        <option *ngFor="let group of emailGroups;let i = index"
                                            [value]="i">
                                            {{group.attributes.name}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="row mt-2" *ngIf="toGroups.length>0">
                                <div class="offset-sm-2 col-sm-10">
                                    <p>GROUPS:</p>
                                    <button *ngFor="let group of toGroups; let i = index" class="btn btn-sm rounded border-secondary" style="margin-right: 2px" (click)="false">
                                        <span class="bg">{{group.attributes.name}}</span>
                                        <a href id="rmvToGroup" (click)="removeToGroup(i)" title="Remove To Group">
                                            <i class="fas fa-user-times ml-2" style="color:red;font-size:12px"></i>
                                        </a>
                                    </button>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-sm-2">
                                    <label class="control-label float-right">Subject:</label>
                                </div>
                                <div class="col-sm-6">
                                    <input type="text" class="form-control" id="txtSubject" name="txtSubject" ngModel
                                        #txtSubject="ngModel" minlength="10" maxlength="50"
                                        pattern="[\w\s\-,.?#!\/'\(\)]+" required />
                                    <div class="alert alert-danger"
                                        *ngIf="txtSubject.invalid && (txtSubject.dirty || txtSubject.touched)">
                                        <div *ngIf="txtSubject.errors?.required">
                                            You must enter an email subject.
                                        </div>
                                        <div
                                            *ngIf="txtSubject.errors?.minlength || txtSubject.errors?.maxlength || txtSubject.errors?.pattern">
                                            Subject is invalid: must be 10 to 50 alphanumeric characters plus -,.?#!/'()
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-sm-2">
                                    <label class="control-label float-right">Body:</label>
                                </div>
                                <div class="col-sm-10">
                                    <textarea class="form-control" rows="10" id="txtBody" name="txtBody" ngModel
                                        #txtBody="ngModel" minlength="10" maxlength="2500"
                                        pattern="[\w\s\-,.?#!\/'\(\)]+" value="" required>
                                    </textarea>
                                    <span *ngIf="txtBody.dirty">{{txtBody.value.length}} of 2500</span>
                                    <div class="alert alert-danger"
                                        *ngIf="txtBody.invalid && (txtBody.dirty || txtBody.touched)">
                                        <div *ngIf="txtBody.errors?.required">
                                            You must enter an email body.
                                        </div>
                                        <div
                                            *ngIf="txtBody.errors?.minlength || txtBody.errors?.maxlength || txtBody.errors?.pattern">
                                            Body is invalid: must be 10 to 2500 alphanumeric characters plus -,.?#!/'()
                                        </div>
                                    </div>
                                </div>
                            </div>
                        <div class="row mt-2" >
                            <div class="col-md-12">
                                <button type="submit" class="btn btn-success float-right"
                                    [disabled]="!hasPickedToOption || emailForm.invalid || (toOption.name=='Selected Members' && toPilots.length<1) || (toOption.name=='Selected Groups' && toGroups.length<1)">
                                    <i class="far fa-share-square"></i>&nbsp;Send
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<app-avamodal [modalName]="modalName" [modalMessage]="modalMessage" [modalTitle]="modalTitle" [showMiniClose]="showMiniClose"
[showCancelButton]="showCancelButton" [modalStatus]="modalStatus" [buttonText]="'Ok'"
[modalPurpose]="modalPurpose" (modalClosed)="onModalClosed($event)"></app-avamodal>

<span *ngIf="loading">
    <div class="d-flex justify-content-center " style="margin-top: 10em;margin-bottom: 10em;">
        <div class="spinner-border text-primary" style="width: 6rem; height: 6rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</span>
