import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StaffService } from 'src/app/services/staff.service';

@Component({
  selector: 'app-staffpositions',
  templateUrl: './staffpositions.component.html',
  styleUrls: ['./staffpositions.component.css']
})
export class StaffpositionsComponent implements OnInit {
  loading: boolean = false;
  staffPositions : any[] = [];
  levels : any[] = [];

  constructor(private router: Router, private staffService : StaffService) { }

  ngOnInit(): void {
    this.loading=true;
    this.loadStaffPositions();
  }

  loadStaffPositions() {
    this.staffService.getStaffPositions().subscribe(
      (responseData) => {
        this.staffPositions = responseData["data"];
        console.log(this.staffPositions);
        this.levels = responseData["levels"];
        console.log(this.levels);
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        if (error.status = 404) {
        } else {
          this.router.navigate(['/techerror']);
        }
      }
    );
  }

  onPositionUpdateComplete($position) {
    console.log($position);
    this.ngOnInit();
  }
}
