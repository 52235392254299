<div class="card shadow rounded-top rounded-lg " style="margin: 5px 10px 30px 10px" *ngIf="!loading">
    <div class="card-header panel-american-blue" *ngIf="!loading">
        <div class="row">
            <div class="col-md-12 col-xs-12">
                Type Certifications  
                <span class="float-right" style="text-decoration: underline;cursor: pointer;"
                    (click)="showCertHelp=!showCertHelp">What is this?</span>
            </div>
        </div>
    </div>
    <div class="card-body">
        <div class="row" *ngIf="showCertHelp">
            <div class="col-md-12 float-right">
                <div class="alert alert-primary" role="alert">
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close"
                        (click)="showCertHelp=false;">
                        <span aria-hidden="true">&times;</span>
                    </button>                    
                    <p>
                        This is your Type Certifications page outlining your AvA certfications for given aircraft in our
                        inventory.
                    </p>
                    <p>
                        As you progress at AvA, you will accumulate hours in aircraft types. As you get more and more,
                        you will recieve Type Certifications.  Silver wings first, Gold wings second, and then a Gold Star as you accummulate more and more. 
                    </p>
                    <p>
                        Type variants of an aircraft are rolled together, e.g. hours flown in the following: Boeing
                        737-200, 737-800, 737-900 Max will all count towards the Boeing 737 type certifications.
                    </p>
                    <p>
                        The odometer tells you how many hours you have in a certain aircraft type and how many are left
                        to the next level.
                    </p>
                    <p>
                        Levels are: Silver Wings, Gold Wings, and Stars.  Here is a table showing the hours:
                    </p>
                    <p>
                        <img src="assets/img/certtable.png"/>
                    </p>
                </div>
            </div>
        </div>
        <div class="row no-gutters">
            <div class="col-sm-12 " *ngIf="certs.length==0">
                No aircraft type certifications found.
            </div>
            <div class="col-sm-3 col-xs-3" *ngFor="let cert of certs"
                style="text-align: center;height:220px;border: 1px silver dotted ">
                <h3>
                    {{cert.attributes.description}}
                </h3>
                <div *ngIf="cert.attributes.starCount>0">
                    <img src="assets/img/wings/star.svg" height="24px" color="silver" *ngFor="let in of getStarCount(cert);let i = index" />
                </div>
                <div *ngIf="cert.attributes.certType==0"><img src="assets/img/wings/blackwings.svg" height="64px"
                        color="silver" /></div>
                <div *ngIf="cert.attributes.certType==1"><img src="assets/img/wings/silverwings.svg" height="64px"
                        color="silver" /></div>
                <div *ngIf="cert.attributes.certType==2"><img src="assets/img/wings/goldwings.svg" height="64px"
                        color="silver" /></div>

                <!-- <canvas id="{{cert.attributes.id}}" class="chart chart-doughnut" chart-data="cert.chartHours"
                        chart-labels="certDonutLabels" chart-colors="certChartColors" height="64px"
                        *ngIf="cert.attributes.certType<2"></canvas> -->


                <div style="display: block;">
                    <canvas baseChart width="400" height="150px" [data]="cert.chartHours" [labels]="certDonutLabels"
                        [colors]="certChartColors" [chartType]="certChartType" [options]="certChartOptions"
                        *ngIf="cert.attributes.certType<2" height="64px">
                    </canvas>
                </div>

                <label *ngIf="cert.attributes.certType<2">
                    {{cert.attributes.loggedHours}} of
                    {{cert.attributes.remainingHours+cert.attributes.loggedHours}}
                </label>
                <label *ngIf="cert.attributes.certType>1">
                    {{cert.attributes.loggedHours}} Total Hours in Type
                </label>
            </div>
        </div>
    </div>
</div>

<span *ngIf="loading">
    <div class="d-flex justify-content-center " style="margin-top: 10em;margin-bottom: 10em;">
        <div class="spinner-border text-primary" style="width: 6rem; height: 6rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</span>