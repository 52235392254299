<div class="container mt-2">
    <div class="row">
        <div class="col-md-12">
            <div class="card shadow rounded-top rounded-lg">
                <div class="card-header panel-american-coral">
                    <div class="row">
                        <div class="col-12">
                            Manage Rosters
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <app-hubselector [hubId]="hubIdToLoad" (onHubSelected)="onHubSelected($event)" >
                    </app-hubselector>
                    <div class="row" *ngIf="hasHubHealthInfo">
                        <div class="col-md-12">
                            <div class="card shadow rounded-top rounded-lg" *ngIf="!loading"> 
                                <div class="card-header text-white bg-dark">
                                    <div class="row">
                                        <div class="col-md-12">
                                            {{hubHealthInfo.hubLongName}} Roster:
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body ">
                                    <app-hubhealth [hubHealthInfo]="hubHealthInfo"></app-hubhealth>
                                    <hr class="aa-line"/>
                                    <app-hrhubroster [hubRoster]="hubRoster" [hubDetails]="hubDetails" [staffMember]="pilot" *ngIf="hasHubRoster" (onHRHubRosterUpdated)="onHRHubRosterUpdated($event)"></app-hrhubroster>
                                </div>
                            </div>   
                        </div>
                    </div>
                    <div class="card shadow rounded-top rounded-lg" *ngIf="loading">
                        <div class="d-flex justify-content-center " style="margin-top: 10em;margin-bottom: 10em;">
                            <div class="spinner-border text-primary" style="width: 6rem; height: 6rem;" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>                       
                </div>                
            </div>
        </div>
    </div>
</div>

