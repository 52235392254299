import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HubService } from 'src/app/services/hub.service';

@Component({
  selector: 'app-hubs',
  templateUrl: './hubs.component.html',
  styleUrls: ['./hubs.component.css']
})
export class HubsComponent implements OnInit {
  loading : boolean = false;
  hubs ;

  constructor(private hubService : HubService, private router : Router) { }

  ngOnInit(): void {
    this.loading = true;
    this.hubs = [];
    this.loadHubInfoDetails();

  }

  loadHubInfoDetails() {
    this.hubService.getHubDetails().subscribe(
      (responseData) => {
        this.hubs = responseData;
        console.log(this.hubs);
        this.loading = false;
      },
      (error) => {
        this.router.navigate(['/techerror']);
        this.loading = false;

      }
    );
  }

  onHubUpdateComplete($hub) {
    console.log($hub);
    this.ngOnInit();
  }
}
