import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PilotService } from 'src/app/services/pilot.service';

@Component({
  selector: 'app-genericroster',
  templateUrl: './genericroster.component.html',
  styleUrls: ['./genericroster.component.css']
})
export class GenericrosterComponent implements OnInit {
  loading: boolean = false;
  pilots;

  constructor(private router: Router, private pilotService: PilotService) { }

  ngOnInit(): void {
    this.loading = true;
    this.pilots = [];
    this.loadRoster();
  }

  loadRoster() {
    this.pilotService.getActiveRoster().subscribe(
      (responseData) => {
        this.pilots = responseData;

        console.log(this.pilots);

        this.loading = false;
      },
      (error) => {
        this.loading = false;
        if (error.status = 404) {
        } else {
          this.router.navigate(['/techerror']);
        }
      }
    );
  }
}
