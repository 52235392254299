import { Component, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { DataSharingService } from 'src/app/services/datasharing.service';
import { PwdresetService } from 'src/app/services/pwdreset.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-resetpwd',
  templateUrl: './resetpwd.component.html',
  styleUrls: ['./resetpwd.component.css']
})
export class ResetpwdComponent implements OnInit {
  token : string = '';
  siteKey=environment.RECAPTCHA_KEY;
  isCaptchaResolved=false;
  catpchaResponse : string = "";
  loading : boolean = false;

  //modal vars
  @Output() modalMessage: string = "";
  @Output() modalTitle: string = "";
  @Output() modalStatus: string = "";
  @Output() modalPurpose: string = "";
  @Output() showCancelButton: boolean = false;
  @Output() showMiniClose: boolean = false;
  @Output() modalName : string = 'resetpwdmodal';

  constructor(private router : Router, private dss : DataSharingService,private route: ActivatedRoute, private pwdResetService : PwdresetService, private authService : AuthService) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(
      params => {
        console.log(params);
        this.token = params.token;
        console.log(this.token);
      }
    )
    console.log(this.token);
  }
  onSubmit(resetPasswordForm) {
    this.loading = true;
    console.log('submitted' + resetPasswordForm);
    let payload = {
      gresponse : this.catpchaResponse,
      vatsimId : resetPasswordForm.vatsimId,
      email : resetPasswordForm.email,
      confirmPassword : resetPasswordForm.confirmPassword,
      password : resetPasswordForm.passwordTxt
    }

    console.log(payload);

    this.authService.setToken(this.token);
    this.pwdResetService.changePassword(payload).subscribe(
      (responseData) => {
        this.loading=false;
        console.log(responseData);
        this.authService.invalidateToken();
        this.showMessageModal();
      },
      (error) => {
        this.loading=false;
        console.log(error);
        this.showErrorMessageModal(error.message);
      }
    );
  }

  resolved(captchaResponse: string) {
    this.isCaptchaResolved=true;
    this.catpchaResponse=captchaResponse;
  }


  showMessageModal() {
    this.dss.showModal.next({modalName : this.modalName, showModal :false});
    this.modalStatus='success';
    this.showMiniClose=false;
    this.showCancelButton=false;
    this.modalTitle = 'Password Change Successful!';
    this.modalMessage = 'You will now be sent back to the home page where you can login normally using your new password.';
    this.modalPurpose = "resetpwdmodal";
    this.dss.showModal.next({modalName : this.modalName, showModal :true});
  }


  showErrorMessageModal(errorMsg) {
    this.dss.showModal.next({modalName : this.modalName, showModal :false});
    this.modalStatus='danger';
    this.showMiniClose=false;
    this.showCancelButton=false;
    this.modalTitle = 'Password Change Failed!';
    this.modalMessage = 'The attempt to change your password failed with the following error: <br/>' + errorMsg  ;
    this.modalPurpose = "resetpwdmodal";
    this.dss.showModal.next({modalName : this.modalName, showModal :true});
  }

  onModalClosed(returnMessage: {status: string, purpose: string}) {
    this.dss.showModal.next({modalName : this.modalName, showModal :false});
    this.router.navigate(['/']);

  }
}
