import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApplicationModel } from 'src/app/models/application.model';

@Component({
  selector: 'app-applicationdetail',
  templateUrl: './applicationdetail.component.html',
  styleUrls: ['./applicationdetail.component.css']
})
export class ApplicationdetailComponent implements OnInit {
  showCancel: boolean = false;
  showDelete: boolean = false;
  loading: boolean = false;
  isATC : boolean = false;
  isRejecting : boolean = false;
  isAccepting : boolean = false;
  isShowingDefaultButtons : boolean = true;

  @Output() onApplicationUpdate = new EventEmitter<string>();
  @Input() application: ApplicationModel;
  constructor() { }

  ngOnInit(): void {
  }

  appRejectStart() {
    this.isRejecting=true;
    this.isAccepting = false;
    this.isShowingDefaultButtons=false;
  }
  appAcceptStart() {
    this.isRejecting=false;
    this.isShowingDefaultButtons=false;
    this.isAccepting = true;
  }

  onAppRectionUpdate(event) {
    console.log("event");
    if (event=='cancel') {
      this.isRejecting=false;
      this.isAccepting=false;
      this.isShowingDefaultButtons=true;
    } else {
      this.onApplicationUpdate.emit(event);
    }
  }
}
