import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ActiveLOAModel } from 'src/app/models/activeloa.model';
import { AuthService } from 'src/app/services/auth.service';
import { DataSharingService } from 'src/app/services/datasharing.service';
import { LoaService } from 'src/app/services/loa.service';
import { PilotService } from 'src/app/services/pilot.service';

@Component({
  selector: 'app-activeloatable',
  templateUrl: './activeloatable.component.html',
  styleUrls: ['./activeloatable.component.css']
})
export class ActiveloatableComponent implements OnInit {
  @Output() onLOACleared = new EventEmitter<string>();
  @Input() activeLOAs : ActiveLOAModel[];
  loading : boolean = false;
  pilotId : number =0;
  pilotIdToClear : number = 0;

  //modal vars
  @Output() modalMessage: string;
  @Output() modalTitle: string;
  @Output() modalStatus: string;
  @Output() modalPurpose: string;
  @Output() showCancelButton: boolean;
  @Output() showMiniClose: boolean;
  @Output() modalName : string = "activeLOAClearModal";

  constructor(private router : Router, private pilotService : PilotService, private authService : AuthService, private dss : DataSharingService,
              private loaService : LoaService) { }

  ngOnInit(): void {
  }



  clear(loa) {
    console.log(loa);
    this.pilotIdToClear = loa.pilotId;
    this.showClearConfirmation(loa);
  }

  clearActiveLOA(loa) {
    this.loading=true;
    if (this.authService.isAuthenticated()) {
      this.pilotId = this.authService.getPilotId();
      this.loaService.clear(loa).subscribe(
        (responseData) => {
          this.loading=false;
          this.onLOACleared.emit('statusupdated');
        },
        (error) => {
            console.log('WARNING: ' + error.message);
            if (error.status === 500) {
              this.router.navigate(['/techerror']);
            }
            this.loading=false;
          }
      );
    }
  }

  onModalClosed(returnMessage: {status: string, purpose: string}) {
    if (returnMessage.purpose == 'confirmation' && returnMessage.status == 'ok') {
      this.clearActiveLOA(this.pilotIdToClear);
    }
  }

  showClearConfirmation(loa) {
    this.modalStatus='warning';
    this.showMiniClose=false;
    this.showCancelButton = true;
    this.modalTitle = 'Are you sure you ';
    this.modalMessage = 'Would you like me to take ' + loa.callsign + ' off of LOA by clearing this LOA?';
    this.modalPurpose = "confirmation";
    this.dss.showModal.next({modalName : this.modalName, showModal :true});
  }
}
