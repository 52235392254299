<div class="container-fluid no-gutters mt-2" *ngIf="!loading">
    <div class="card shadow rounded-top rounded-lg">
        <div class="card-header panel-american-blue-grey-red">
            <div class="row">
                <div class="col-12">
                    Active Pilots
                </div>
            </div>
        </div>
        <div class="card-body ">
            <div class="table-responsive">
                <table class="table table-sm table-striped table-hover table-bordered">
                    <caption>Total Active Pilots: {{pilots.length}}</caption>
                    <thead class="thead-dark">
                        <tr>
                            <th scope="col">VatsimID</th>
                            <th scope="col">Hub</th>
                            <th scope="col">Callsign</th>
                            <th scope="col">First</th>
                            <th scope="col">Last</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let pilot of pilots">
                            <td><a href="http://stats.vatsim.net/search_id.php?id={{pilot.vatsimID}}" target="_blank">{{pilot.vatsimID}}</a></td>
                            <td>{{pilot.hub}}</td>
                            <td>{{pilot.callsign}}</td>
                            <td>{{pilot.firstName}}</td>
                            <td>{{pilot.lastName}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>


<div class="container text-center" *ngIf="loading">
    <div class="lds-hourglass"></div>
    <p>Loading...</p>
</div>