<div class="container mt-2 mb-3">
    <div class="row">
        <div class="col-md-12">
            <div class="card shadow rounded-top rounded-lg">
                <div class="card-header panel-american-coral">
                    <div class="row">
                        <div class="col-10">
                            <span>Manage Personnel Records</span>
                        </div>
                    </div>
                </div>
                <div class="card-body" *ngIf="!loading ">
                    <app-membersearch (onMemberSearchComplete)="handleSearchResults($event)"></app-membersearch>
                    <div class="row" *ngIf="searchComplete && !hasMemberSearchResults ">
                        <div class="col-sm-12">
                            <p>I'm sorry but no records were found matching your results.</p>
                        </div>
                    </div>
                    <div *ngIf="searchComplete && hasMemberSearchResults && !loading">
                        <div class="card mt-2  border border-info" *ngFor="let member of searchResults">
                            <div class="card-header bg-ava-info">
                                <div class="row">
                                    <div class="col-sm-8">
                                        ({{member.callsign}})  {{member.firstName}} {{member.lastName}}
                                    </div>
                                    <div class="col-sm-4">
                                        <button class="btn btn-info btn-sm float-right" type="button"
                                            data-toggle="collapse"
                                            [attr.data-target]="'#memberDetailCollapse-' + member.pilotId"
                                            aria-expanded="true"
                                            aria-controls="memberDetailCollapse-{{member.pilotId}}">
                                            <i class="fas fa-binoculars"></i> View
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="collapse" id="memberDetailCollapse-{{member.pilotId}}">
                                <div class="card-body">
                                    <app-personnelrecordetail [member]="member" ></app-personnelrecordetail>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
        </div>
    </div>
</div>

