<form name="hubDetailForm" class="css-form" #hubDetailForm="ngForm" (ngSubmit)="onSubmit(hubDetailForm.value, hub.attributes.hubId)">
    <div class="row">
        <div class="col-sm-6">
            <b>Hub Id:</b> {{hub.attributes.hubId}}
        </div>
        <div class="col-sm-6">
            <span class="pull-right">
                <b>Hub Manager:</b> {{hub.attributes.staffPosition}} - {{hub.attributes.hubManager.F_Name}} {{hub.attributes.hubManager.L_Name}}
            </span>
        </div>
    </div>
    <hr class="aa-line"/>
    <div class="row">
        <div class="col-sm-2">
            <div class="form-group">
                <label for="txtShortName">Short Name:</label>
                <input type="text" id="txtShortName" name="txtShortName" class="form-control" #txtShortName="ngModel" ngModel [(ngModel)]="hub.attributes.hubHealth.attributes.hubShortName" minlength="3" maxlength="3" pattern="[a-zA-Z]+" required />
                <div class="alert alert-danger" *ngIf="txtShortName.invalid && (txtShortName.dirty || txtShortName.touched)">
                    <div *ngIf="txtShortName.errors?.required">
                        You must enter an Hub Short Name.
                    </div>
                    <div *ngIf="txtShortName.errors?.minlength || txtShortName.errors?.maxlength || txtShortName.errors?.pattern">
                        Hub Name is invalid: must be exactly 3 alpha characters
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-4">
            <div class="form-group">
                <label for="txtLongName">Long Name:</label>
                <input type="text" id="txtLongName" name="txtLongName" class="form-control" #txtLongName="ngModel" ngModel [(ngModel)]="hub.attributes.hubHealth.attributes.hubLongName" minlength="5" maxlength="30" pattern="^[\w',\(\)\s]+$" required />
                <div class="alert alert-danger" *ngIf="txtLongName.invalid && (txtLongName.dirty || txtLongName.touched)">
                    <div *ngIf="txtLongName.errors?.required">
                        You must enter an Hub Long Name.
                    </div>
                    <div *ngIf="txtLongName.errors?.minlength || txtLongName.errors?.maxlength || txtLongName.errors?.pattern">
                        Hub Name is invalid: must be 5 to 30 alphanumeric characters plus ',()
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="form-group">
                <label for="txtLocation">Location:</label>
                <input type="text" id="txtLocation" name="txtLocation" class="form-control" #txtLocation="ngModel" ngModel [(ngModel)]="hub.attributes.location" minlength="5" maxlength="50" pattern="^[\w',\(\)\s]+$" required />
                <div class="alert alert-danger" *ngIf="txtLocation.invalid && (txtLocation.dirty || txtLocation.touched)">
                    <div *ngIf="txtLocation.errors?.required">
                        You must enter a Location.
                    </div>
                    <div *ngIf="txtLocation.errors?.minlength || txtLocation.errors?.maxlength || txtLocation.errors?.pattern">
                        Location is invalid: must be 10 to 100 alphanumeric characters plus ',()
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12">
            <div class="form-group">
                <div class="col-sm-6 ">
                    <label class="mr-3">Open for new Hires?</label>
                    <input type="radio" id="rdoIsHiring" name="rdoIsHiring" [(ngModel)]="hub.attributes.isHiring" required #rdoIsHiring="ngModel"  [value]="1" /> Yes
                    <input type="radio" id="rdoIsHiring" name="rdoIsHiring" [(ngModel)]="hub.attributes.isHiring" required #rdoIsHiring="ngModel"  [value]="0" /> No
                </div>
                <div class="alert alert-danger" *ngIf="rdoIsHiring.invalid && (rdoIsHiring.dirty || rdoIsHiring.touched)">
                    <div *ngIf="rdoIsHiring.errors?.required">
                        You must select an option.
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="row">
        <div class="col-sm-12">
            <div class="form-group">
                <label for="txtUrl">Hub URL (optional):</label>
                <input type="text" id="txtUrl" name="txtUrl" class="form-control" #txtUrl="ngModel" ngModel [(ngModel)]="hub.attributes.url" minlength="3" maxlength="200" pattern="^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$" />
                <div class="alert alert-danger" *ngIf="txtUrl.invalid && (txtUrl.dirty || txtUrl.touched)">
                    <div *ngIf="txtUrl.errors?.minlength || txtUrl.errors?.maxlength || txtUrl.errors?.pattern">
                        Hub URL is invalid: must be 3 to 200 alphanumeric characters
                    </div>
                    <div *ngIf="txtBannerUrl.errors?.pattern">
                        Hub URL is invalid: must be a properly formed URL.
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12">
            <div class="form-group">
                <label for="txtBannerUrl">Hub Banner URL (optional):</label>
                <i class="fas fa-info-circle ml-2" data-toggle="tooltip" data-placement="top" title="The URL to the image or banner used by the hub." style="color:grey"></i>
                <input type="text" id="txtBannerUrl" name="txtBannerUrl" class="form-control" #txtBannerUrl="ngModel" ngModel [(ngModel)]="hub.attributes.bannerUrl" minlength="3" maxlength="200" pattern="^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$" />
                <div class="alert alert-danger" *ngIf="txtBannerUrl.invalid && (txtBannerUrl.dirty || txtBannerUrl.touched)">
                    <div *ngIf="txtBannerUrl.errors?.minlength || txtBannerUrl.errors?.maxlength">
                        URL is invalid: must be 3 to 200 alphanumeric characters
                    </div>
                    <div *ngIf="txtBannerUrl.errors?.pattern">
                        URL is invalid: must be a properly formed URL.
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <div class="row mt-3">
        <div class="col-sm-12 ">
            <button type="submit" class="btn btn-success float-right" [disabled]="hubDetailForm.invalid || !hubDetailForm.dirty">
                <i class="fas fa-check mr-2"></i>
                <span >Update</span>
            </button>
        </div>
    </div>
</form>

