import { Component, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ProfileModel } from 'src/app/models/profile.model';
import { AuthService } from 'src/app/services/auth.service';
import { DataSharingService } from 'src/app/services/datasharing.service';
import { PilotService } from 'src/app/services/pilot.service';
import { OutputFileType } from 'typescript';

@Component({
  selector: 'app-changepasswordform',
  templateUrl: './changepasswordform.component.html',
  styleUrls: ['./changepasswordform.component.css']
})
export class ChangepasswordformComponent implements OnInit {
  @Input() profile : ProfileModel;
  @Input() pilotId : number;
  loading : boolean = false;

  @Output() modalMessage: string = "";
  @Output() modalTitle: string = "";
  @Output() modalStatus: string = "";
  @Output() modalPurpose: string = "";
  @Output() showCancelButton: boolean = false;
  @Output() showMiniClose: boolean = false;
  @Output() modalName : string = 'changePasswordModal';

  passwords : { oldPassword : string, newPassword : string, confirmPassword: string} = {
    oldPassword : "",
    newPassword : "",
    confirmPassword : ""
  }

  constructor(private pilotService : PilotService,private authService : AuthService, private router : Router, private dss : DataSharingService) { }

  ngOnInit(): void {
  }

  onSubmit(passwordForm) {
    this.loading=true;
    this.profile['oldPassword']=passwordForm.oldPassword;
    this.profile['password']=passwordForm.newPassword;
    this.profile['confirmPassword']=passwordForm.confirmPassword;
    console.log(passwordForm, this.profile);

    this.pilotService.putProfile(this.pilotId,this.profile).subscribe(
      (responseData) => {
        console.log(responseData);
        var isPWCSuccessful : boolean = true;
        var isProfSuccessful : boolean = false;

        if (responseData.hasOwnProperty("isSuccessfulPasswordChange")) {
          console.log(responseData['isSuccessfulPasswordChange']);
          if (responseData['isSuccessfulPasswordChange']=='failed') {
            isPWCSuccessful=false;
          }
        }

        if (responseData.hasOwnProperty("isSuccessfulProfileUpdate")) {
          console.log(responseData['isSuccessfulProfileUpdate']);
          if (responseData['isSuccessfulProfileUpdate']=='success') {
            isProfSuccessful=true;
          }
        }

        this.loading=false;

        if (!isPWCSuccessful) {
          //display bad modal and do not logoff
          this.showPWCErrorModal();
        } else if (isPWCSuccessful) {
          //display error modal, but still logoff
          this.showPWCSuccessModal();
        } else if (isPWCSuccessful) {
          //display good modal and then do the following
          this.showPWCSuccessModal();
        }
      },
      (error) => {
        if (error.status === 500 ) {
          this.router.navigate(['/techerror']);
        }
        this.loading=false;
      }
    )
  }

  onModalClosed(returnMessage: {status: string, purpose: string}) {
    this.showCancelButton=false;
    if (returnMessage.purpose == 'pwcerror' && returnMessage.status == 'ok') {
      //reset the form elements
      this.passwords = {
        oldPassword : "",
        newPassword : "",
        confirmPassword : ""
      }
    } else if (returnMessage.purpose == 'pwcsuccess' && returnMessage.status == 'ok'){
      this.authService.invalidateToken();
      this.dss.isAuthenticated.next(false);
      this.dss.isManager.next(false);
      this.router.navigate(['/']);
    } else {
      this.router.navigate(['/pilot', this.pilotId, 'logbook']);
    }
  }

  onCancelClick() {
    this.dss.showModal.next({modalName : this.modalName, showModal :false});
    this.router.navigate(['/pilot', this.pilotId, 'logbook']);
  }

  showPWCErrorModal() {
    this.modalStatus='danger';
    this.showMiniClose=false;
    this.showCancelButton=false;
    this.modalTitle = 'Password Change Failed';
    this.modalMessage = 'I am sorry, but there was an issue with your password change and I could not reset it.  Most likely the Current Password you typed in is not correct.';
    this.modalPurpose = "pwcerror";
    this.dss.showModal.next({modalName : this.modalName, showModal :true});
  }

  showPWCSuccessModal() {
    this.modalStatus='success';
    this.showMiniClose=false;
    this.showCancelButton=false;
    this.modalTitle = 'Password Change Succeeded';
    this.modalMessage = 'Your password has been successfully changed.  You will now be automatically logged out.  Please log back in with your new password.  Thanks.';
    this.modalPurpose = "pwcsuccess";
    this.dss.showModal.next({modalName : this.modalName, showModal :true});
  }
}
