<div class="container mt-2 mb-3">
    <div class="row">
        <div class="col-md-12">
            <div class="card shadow rounded-top rounded-lg">
                <div class="card-header panel-american-coral">
                    <div class="row">
                        <div class="col-6 mt-2">
                            Manage Rejected Pireps 
                        </div>
                    </div>
                </div>
                <div class="card-body" *ngIf="!loading ">
                    <div class="col-md-12">
                        <div class="row" *ngIf="!hasRejectedPireps">
                            <div class="col-sm-12">
                                <p>I'm sorry but there are no Rejected Pireps at this time.</p>
                            </div>
                        </div>
                        <div *ngIf="hasRejectedPireps">
                            <div class="card mt-2 " *ngFor="let rp of rejectedPireps">
                                <div class="card-header bg-ava-info">
                                    <div class="row">
                                        <div class="col-sm-8">
                                            {{rp.attributes.pilot}} - {{rp.attributes.depAirport}} to {{rp.attributes.arrAirport}} on {{rp.attributes.flightDate}}
                                        </div>
                                        <div class="col-sm-4">
                                            <button class="btn btn-info btn-sm float-right" type="button" data-toggle="collapse" 
                                            [attr.data-target]="'#rpDetailCollapse-' + rp.id" aria-expanded="true" aria-controls="rpDetailCollapse"
                                            title="Review Rejected Pirep #{{rp.attributes.pirepId}}">
                                                <i class="fas fa-exchange-alt"></i> Review
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="collapse" id="rpDetailCollapse-{{rp.id}}">
                                    <div class="card-body">
                                        <app-rejectedpirepdetails [rp]="rp" [isNew]="false" (onRPUpdateComplete)="onRPUpdateComplete($rp)"></app-rejectedpirepdetails>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <span *ngIf="loading">
        <div class="d-flex justify-content-center " style="margin-top: 10em;margin-bottom: 10em;">
            <div class="spinner-border text-primary" style="width: 6rem; height: 6rem;" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </span>