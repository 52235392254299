<div class="card shadow rounded-top rounded-lg mt-2" *ngIf="!loading">
    <div class="card-header panel-american-blue-grey-red">
        <div class="row p-0">
            <div class="col-xl p-0">
                Airports
            </div>
        </div>
    </div>
    <div class="card-body">
        <div class="row p-0">
            <div class="col-xl p-0">
                <div class="card mt-2 rounded-top">
                    <div class="card-header">
                        <b>{{airportStats.monthlyDepartures.title}}</b>
                    </div>
                    <div class="card-body">
                        <div class="flex-item">
                            <div>
                                <canvas baseChart width="400" height="150px" id="monthlyDeparturesReport" 
                                    [datasets]="monthlyDepartureChartData" 
                                    [labels]="airportStats.monthlyDepartures.labels"
                                    [options]="airportRadarOptions"
                                    [legend]="lineChartLegend"
                                    [colors]="airportRadarColors"  
                                    [chartType]="radarChartType">
                                </canvas>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row p-0">
            <div class="col-xl p-0">
                <div class="card mt-2 rounded-top">
                    <div class="card-header">
                        <b>{{airportStats.monthlyDestinations.title}}</b>
                    </div>
                    <div class="card-body">
                        <div class="flex-item">
                            <div>
                                <canvas baseChart width="400" height="150px" id="monthlyDestinationsReport" 
                                    [datasets]="monthlyDestinationChartData" 
                                    [labels]="airportStats.monthlyDestinations.labels" 
                                    [options]="airportRadarOptions"
                                    [legend]="lineChartLegend"
                                    [colors]="airportRadarColors"  
                                    [chartType]="radarChartType">
                                </canvas>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="goToTop" (click)="tabTop();" [ngClass]="{ 'show-scrollTop': windowScrolled }" *ngIf="windowScrolled">
            <a  title="Scroll up"><i class="fas fa-arrow-circle-up"></i></a>
        </div>
    </div>
</div>

<div class="container text-center" *ngIf="loading">
    <div class="lds-hourglass"></div>
    <p>Loading...</p>
</div>