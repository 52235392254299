<nav aria-label="Page navigation example">
  <ul class="pagination justify-content-end">
    <li class="page-item">
      <!-- <a class="page-link" href="#" tabindex="-1" aria-disabled="true">Previous</a> -->
      <button class="page-link" *ngIf="prevPageId>0 || nextPageId>1" (click)="onPreviousButtonClick()">Previous</button>
    </li>
    <li class="page-item" *ngIf="nextPageId>-1">
      <span class="ava-page-number">
        {{nextPageId}} of {{totalPages}}
      </span>
    </li>
    <li class="page-item" *ngIf="nextPageId==-1">
      <span class="ava-page-number">
        {{totalPages}}
      </span>
    </li>
    <li class="page-item">
      <!-- <a class="page-link" href="#">Next</a> -->
      <button class="page-link" *ngIf="nextPageId>0" (click)="onNextButtonClick()">Next</button>
    </li>
  </ul>
</nav>