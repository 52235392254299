<div *ngIf="loading==false">
    <div class="card shadow rounded-top rounded-lg " style="margin: 5px 10px 30px 10px">
        <div class="card-header bg-ava-info">
            <div class="row">
                <div class="col-md-12 col-xs-12">
                    Logbook
                    </div>
            </div>
        </div>
        <div class="card-body" *ngIf="pireps.length<=0 && !loading">
            It appears you have not filed a PIREP yet. If this is in error, please report it via Discord.
        </div>
        <div class="card-body " *ngIf="pireps.length>0 && !loading">
            <div class="row d-flex justify-content-end">
                <app-logbookpaginator [nextPageId]="nextPageId" [prevPageId]="prevPageId" [totalPireps]="pirepCount"
                    (onPageChange)="onPageChange($event)"></app-logbookpaginator>
            </div>
            <div class="row">
                <div class="col-sm-12">
                <div class="table-responsive" style="font-size: .75em">
                    <table class="table table-condensed table-bordered table-hover table-striped text-center">
                        <thead>
                            <th class="text-center">View Pirep</th>
                            <th class="text-center">#</th>
                            <th class="text-center">Id</th>
                            <th class="text-center">Date</th>
                            <th class="text-center">Aircraft</th>
                            <th class="text-center" *ngIf="perms.canReject">In CAT?</th>
                            <th class="text-center">Departure</th>
                            <th class="text-center">Arrival</th>
                            <th class="text-center">Dep Time</th>
                            <th class="text-center">Arr Time</th>
                            <th class="text-center">Landing</th>
                            <th class="text-center">Hours</th>
                            <th class="text-center" *ngIf="perms.canReject">Online Hrs</th>
                            <th class="text-center" *ngIf="perms.canReject">Online?</th>
                            <th class="text-center" *ngIf="perms.canReject">In Alt?</th>
                            <th class="text-center">Flown For</th>
                            <th class="text-center">ACARS</th>
                            <th class="text-center" *ngIf="perms.canReject">Approved?</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let pirep of pireps; let i=index"
                                [ngClass]="{'pirep-warning' : pirep.attributes.reviewState==1, 'panel-american-blue' : pirep.attributes.reviewState==2 }">
                                <td>
                                    <a [routerLink]="['/managehrprofile/',profilePilotId,'hrpireps',pirep.attributes.pirepId]"
                                        routerLinkActive="active" >
                                        <i class="far fa-eye"
                                            title="Review PIREP: {{pirep.attributes.pirepId}}" alt="Review"></i>
                                    </a>
                                </td>

                                <td>{{i+1}}</td>
                                <td>{{pirep.attributes.pirepId}}</td>


                                <td>{{pirep.attributes.flightDate}}  </td>
                                <td>{{pirep.attributes.ac}}</td>

                                <td *ngIf="perms.canReject" [ngClass]="{'cat-green' : pirep.attributes.acCat<=perms.catRating, 'cat-red' : pirep.attributes.acCat>perms.catRating}">
                                    <i class="fas fa-check" title="{{pirep.attributes.acCat}}/{{perms.catRating}}" *ngIf="pirep.attributes.acCat<=perms.catRating"></i>
                                    <i class="fas fa-exclamation" title="{{pirep.attributes.acCat}}/{{perms.catRating}}" *ngIf="pirep.attributes.acCat>perms.catRating"></i>
                                </td>

                                <td>{{pirep.attributes.depAirport}}</td>
                                <td>{{pirep.attributes.arrAirport}}</td>
                                <td>{{pirep.attributes.depTime}}</td>
                                <td>{{pirep.attributes.arrTime}}</td>
                                <td>
                                  <span *ngIf="pirep.attributes.landingRate<0 && pirep.attributes.landingRate>=-100"
                                      title="{{pirep.attributes.landingRate}}">
                                      <i class="fas fa-plane-departure" style="color:purple"></i>
                                  </span>
                                  <span
                                      *ngIf="pirep.attributes.landingRate<-100 && pirep.attributes.landingRate>=-400"
                                      title="{{pirep.attributes.landingRate}}">
                                      <i class="fas fa-plane" style="color:seagreen"></i>
                                  </span>
                                  <span *ngIf="pirep.attributes.landingRate<-400"
                                      title="{{pirep.attributes.landingRate}}">
                                      <i class="fas fa-plane-arrival" style="color:red"></i>
                                  </span>
                                  <span *ngIf="pirep.attributes.landingRate==null" title="No landing data">
                                      <i class="fas fa-plane-slash"></i>
                                  </span>
                                  <span *ngIf="pirep.attributes.landingRate>0" title="{{pirep.attributes.landingRate}}">
                                      <i class="fas fa-plus"></i>
                                  </span>
                                  <span *ngIf="pirep.attributes.landingRate==0" title="Perfect 0 landing rate - you are a star!">
                                      <i class="fas fa-star" style="color: #FFD43B;"></i>
                                  </span>
                                </td>

                                <td>{{pirep.attributes.hours}}</td>

                                <td *ngIf="perms.canReject" [ngClass]="{'cat-red' : pirep.attributes.onlineHours<pirep.attributes.hours}">
                                    <span class="text-center" >{{pirep.attributes.onlineHours}}</span>
                                </td>
                                <td *ngIf="perms.canReject && pirep.attributes.wasFlownOnline>-1" [ngClass]="{'cat-green' : pirep.attributes.onlineHours>=pirep.attributes.hours, 'cat-red' : pirep.attributes.onlineHours<pirep.attributes.hours}">
                                    <i class="fas fa-flag" title="FH: {{pirep.attributes.hours}} OH: {{pirep.attributes.onlineHours}}" *ngIf="pirep.attributes.onlineHours<pirep.attributes.hours"></i>
                                    <i class="fas fa-check"  title="FH: {{pirep.attributes.hours}} OH: {{pirep.attributes.onlineHours}}" *ngIf="pirep.attributes.onlineHours>=pirep.attributes.hours"></i>
                                </td>
                                <td *ngIf="perms.canReject && pirep.attributes.wasFlownOnline==-1">
                                    <span class="text-center" title="Before we started recording" *ngIf="pirep.attributes.wasFlownOnline==-1">N/A</span>
                                </td>
                                <td *ngIf="perms.canReject" [ngClass]="{'cat-green' : pirep.attributes.inAltitude==1, 'cat-red' : pirep.attributes.inAltitude==0}">
                                    <i class="fas fa-flag" title="pirep.attributes started/ended above 10,000agl" *ngIf="pirep.attributes.inAltitude==0"></i>
                                    <i class="fas fa-check"  title="pirep.attributes started & ended below 10,000agl" *ngIf="pirep.attributes.inAltitude==1"></i>
                                    <span class="text-center" title="Before we started recording" *ngIf="pirep.attributes.inAltitude==-1">N/A</span>
                                </td>

                                <td>{{pirep.attributes.flownFor}}</td>
                                <td>
                                    <span title="{{pirep.attributes.acarsId}}" *ngIf="pirep.attributes.acarsId!=''">
                                        <i class="fas fa-check"></i>
                                    </span>
                                </td>
                                <td *ngIf="perms.canReject && pirep.attributes.reviewState>0">
                                    <span *ngIf="pirep.attributes.reviewState==1">
                                        <a routerLink="/managepireps" title="Needs Review..."><i class="fas fa-stamp"></i></a>
                                    </span>
                                    <span *ngIf="pirep.attributes.reviewState==2">UNDER REVIEW</span>
                                </td>
                                <td *ngIf="perms.canReject && pirep.attributes.reviewState==0">
                                    <i class="fas fa-check cat-green" title="Approved"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                </div>
            </div>
            <div class="row d-flex justify-content-end">
                <app-logbookpaginator [nextPageId]="nextPageId" [prevPageId]="prevPageId"
                    [totalPireps]="pirepCount" (onPageChange)="onPageChange($event)"></app-logbookpaginator>
            </div>
        </div>

    </div>
</div>

<span *ngIf="loading">
    <div class="d-flex justify-content-center " style="margin-top: 10em;margin-bottom: 10em;">
        <div class="spinner-border text-primary" style="width: 6rem; height: 6rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</span>
