import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { BaseChartDirective, Color, Label } from 'ng2-charts';
import { FlightModel } from 'src/app/models/flight.model';
import { OfrModel } from 'src/app/models/ofr.model';

@Component({
  selector: 'app-flightchart',
  templateUrl: './flightchart.component.html',
  styleUrls: ['./flightchart.component.css']
})
export class FlightchartComponent implements OnInit {
  @Input() flightDetails: FlightModel;

  public lineChartOptions: (ChartOptions) = {
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [{}],
      yAxes: [
        {
          id: 'y-axis-1',
          type: 'linear',
          display: true,
          position: 'left',
          scaleLabel: {
            display: true,
            labelString: 'Altitude'
          }
        },
        {
          id: 'y-axis-2',
          type: 'linear',
          display: true,
          position: 'right',
          scaleLabel: {
            display: true,
            labelString: 'Speed'
          },
        },
      ],
    },
  };
  public lineChartColors: Color[] = [
    { // blue/altitude
      backgroundColor: 'rgba(41,137,216,0.3)',
      borderColor: '#2989d8',
      pointBackgroundColor: '#2989d8',
      pointBorderColor: '#2989d8',
      pointHoverBackgroundColor: '#2989d8',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // yellow/speed
      backgroundColor: 'rgba(247,203,72,.2)',
      borderColor: 'rgba(247,203,72,1)',
      pointBackgroundColor: 'rgba(247,203,72,1)',
      pointBorderColor: 'rgba(247,203,72,1)',
      pointHoverBackgroundColor: 'rgba(247,203,72,1)',
      pointHoverBorderColor: 'rgba(77,83,96,1)'
    },
  ];

  public lineChartLegend = true;
  public lineChartType: ChartType = 'line';

  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

  constructor() { }

  ngOnInit(): void {
    // this.lineChartData = this.loadChartData();
  }

  // events
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    // console.log(event, active);
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    // console.log(event, active);
  }

  public hideOne(): void {
    const isHidden = this.chart.isDatasetHidden(1);
    this.chart.hideDataset(1, !isHidden);
  }

}
