<div class="container-fluid no-gutters" *ngIf="!loading">
    <section id="pilotname" *ngIf="isValidPilot">
        <div class="row no-gutters">
            <div class="col-md-5">
                <div class="mt-3">
                    <img src="{{pilotDetails.rankImage}}">
                    <span class="pilotname p-2" id="pilotTitle">{{pilotDetails.rank}}&nbsp;
                        {{pilotDetails.firstName}}&nbsp;{{pilotDetails.lastName}}&nbsp;({{pilotDetails.callSign}})</span>
                    <p style="font-size:.7em;margin-left:7em">
                        [<a href="https://stats.vatsim.net/stats/{{pilotDetails.vatsimID}}" target="_blank"
                            title="VATSIM Stats Lookup">{{pilotDetails.vatsimID}}</a>]
                        - CAT{{pilotDetails.catRating}} assigned to ({{pilotDetails.hub}}) hub.

                        <br/>Status:

                        <span *ngIf="pilotDetails.status=='Leave of Absence'" style="color:orange">
                            {{pilotDetails.status}} until {{pilotDetails.endDate}}
                        </span>

                        <span *ngIf="pilotDetails.status!='ACTIVE' && pilotDetails.status!='Leave of Absence' && pilotDetails.status!='Probation'" style="color:red">
                            {{pilotDetails.status}} requested on {{pilotDetails.endDate}}
                        </span>

                        <span *ngIf="pilotDetails.status=='ACTIVE' || pilotDetails.status=='Leave of Absence' || pilotDetails.status=='Probation'" style="color:green">
                            {{pilotDetails.status}}
                        </span>
                    </p>
                </div>
            </div>
            <div class="col-md-7">
                <div class="row no-gutters justify-content-center">
                    <div class="col-md-3 no-gutters" style="margin-top: 1.2em;">
                        <span class="badge">
                            Monthly Flights<br />
                            <span class="badge badge-pill" style="margin-top:5px" [ngClass]="{'badge-warning' : pilotDetails.pirepsThisMonth<2,
                                'badge-danger' : pilotDetails.pirepsThisMonth===0,
                                'badge-info' : pilotDetails.pirepsThisMonth>=2}">
                                {{pilotDetails.pirepsThisMonth}}
                            </span>
                        </span>
                    </div>
                    <div class="col-md-3 no-gutters" style="margin-top: 1.2em;">
                        <span class="badge">
                            Monthly Hours <br />
                            <span class="badge badge-pill " style="margin-top:5px" [ngClass]="{'badge-warning' : pilotDetails.hoursThisMonth<1,
                                            'badge-danger' : pilotDetails.hoursThisMonth===0,
                                            'badge-info' : pilotDetails.hoursThisMonth>=1}">
                                {{pilotDetails.hoursThisMonth}}
                            </span>
                        </span>
                    </div>
                    <div class="col-md-3" style="margin-top: 1.2em;">
                        <span class="badge ">
                            Total Flights <br />
                            <span class="badge badge-pill badge-info" style="margin-top:5px">
                                {{pilotDetails.totalPireps}}
                            </span>
                        </span>
                    </div>
                    <div class="col-md-3" style="margin-top: 1.2em;">
                        <span class="badge">
                            Total Hours <br />
                            <span class="badge badge-pill badge-info" style="margin-top:5px">
                                {{pilotDetails.totalHours}}
                            </span>
                        </span>
                    </div>
                </div>
            </div>
            <!-- <div class="col-md-2 mt-4 text-right">
                <button class="btn btn-danger" title="Manually File a PIREP" (click)="openManualPirep()">
                    File Manual PIREP
                </button>
            </div> -->
        </div>
        <hr class="aa-line" />

    </section>

    <section id="pilotname" *ngIf="!isValidPilot">
        <div class="container pilotname">
            Pilot not found!
        </div>
    </section>

    <!-- <hr class="aa-line" /> -->
    <section id="lastflightdetails" *ngIf="hasLastFlight">
        <h4 class="mt-3 ">Last Flight Details:</h4>
        <div class="card rounded-0">
            <div class="card-body">
                <div class="row  no-gutters text-center justify-content-center">
                    <div class="col-md-2">
                        <p class="card-title">Date:</p>
                        <p class="card-text">{{pilotDetails.lastFlightDate}}</p>
                        <p class="card-title">Duration:</p>
                        <p class="card-text">{{pilotDetails.lastFlightHours}}</p>
                    </div>
                    <div class="col-md-2">
                        <p class="card-title">Aircraft</p>
                        <p class="card-text">{{pilotDetails.lastFlightAC}}</p>
                    </div>
                    <div class="col-md-2">
                        <p class="card-title">Departed</p>
                        <p class="card-text">
                            <a *ngIf="pilotDetails.lastFlightDepAirport.substring(0,1)==='K'"
                                href="http://airnav.com/airport/{{pilotDetails.lastFlightDepAirport}}"
                                target="_blank">{{pilotDetails.lastFlightDepAirport}}</a>
                            <span
                                *ngIf="pilotDetails.lastFlightDepAirport.substring(0,1)!='K'">{{pilotDetails.lastFlightDepAirport}}</span>
                            <span> &#64; {{pilotDetails.lastFlightDepTime}}</span>
                        </p>
                        <p>{{pilotDetails.lastFlightDepAirportInfo.name}}</p>
                        <p class="mt-n3">{{pilotDetails.lastFlightDepAirportInfo.country}}</p>
                    </div>
                    <div class="col-md-2">
                        <p class="card-title">Arrived</p>
                        <p class="card-text">
                            <a *ngIf="pilotDetails.lastFlightArrAirport.substring(0,1)==='K'"
                                href="http://airnav.com/airport/{{pilotDetails.lastFlightArrAirport}}"
                                target="_blank">{{pilotDetails.lastFlightArrAirport}}</a>
                            <span
                                *ngIf="pilotDetails.lastFlightArrAirport.substring(0,1)!='K'">{{pilotDetails.lastFlightArrAirport}}</span>
                            &#64; {{pilotDetails.lastFlightArrTime}}
                        </p>
                        <p>{{pilotDetails.lastFlightArrAirportInfo.name}}</p>
                        <p class="mt-n3">{{pilotDetails.lastFlightArrAirportInfo.country}}</p>
                    </div>
                    <div class="col-md-2">
                        <p class="card-title">Landing</p>
                        <p class="card-text" *ngIf="pilotDetails.landingRate<0">
                          <i class="fas fa-plane-departure" style="color:purple; font-size:2em;"   title="{{pilotDetails.landingRate}}" *ngIf="pilotDetails.landingRate<0 && pilotDetails.landingRate>=-100"></i>
                          <i class="fas fa-plane"           style="color:seagreen; font-size:2em;" title="{{pilotDetails.landingRate}}" *ngIf="pilotDetails.landingRate<-100 && pilotDetails.landingRate>=-400"></i>
                          <i class="fas fa-plane-arrival"   style="color:red; font-size:2em;"      title="{{pilotDetails.landingRate}}" *ngIf="pilotDetails.landingRate<-400"></i>
                        </p>
                        <p class="card-text" *ngIf="pilotDetails.landingRate==null" title="No landing data">
                          <i class="fas fa-plane-slash" style="font-size: 2em;"></i>
                        </p>
                        <p class="card-text" *ngIf="pilotDetails.landingRate>0" title="{{pilotDetails.landingRate}}">
                          <i class="fas fa-plus" style="font-size: 2em;"></i>
                        </p>
                        <p class="card-text" *ngIf="pilotDetails.landingRate==0" title="Perfect 0 landing rate - you are a star!">
                            <i class="fas fa-star" style="color: #FFD43B; font-size: 2em;"></i>
                        </p>
                    </div>
                    <div class="col-md-2">
                        <p class="card-title">PIREP State</p>
                        <p class="card-text">
                            <i [ngClass]="{'fas fa-check' : pilotDetails.reviewState===0,
                                            'far fa-eye' : pilotDetails.reviewState>0}"
                                [ngStyle]="{'color' : pilotDetails.reviewState>0 ? 'orange' : 'green'}"
                                style="font-size: 2em" title="{{pilotDetails.reviewState>0 ? 'Under Review' : 'Approved'}}">
                            </i>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section id="lastflightdetails" *ngIf="!hasLastFlight">
        <div>
            No information available for your last flight.
        </div>
    </section>
    <section id="last-flight-map" *ngIf="hasLastFlight">
        <h4 class="mt-3 ">Last Flight Map:</h4>
        <div class="card rounded-0">
            <div class="card-body">
                <app-last-flight-map [pilotDetails]='pilotDetails'></app-last-flight-map>
            </div>
        </div>
    </section>
    <section id="last-flight-chart" *ngIf="hasLastFlight">
        <div class="card rounded-0">
            <div class="card-body">
                <app-last-flight-chart [pilotDetails]='pilotDetails'></app-last-flight-chart>
            </div>
        </div>
    </section>
</div>


<span *ngIf="loading">
    <div class="d-flex justify-content-center " style="margin-top: 10em;margin-bottom: 10em;">
        <div class="spinner-border text-primary" style="width: 6rem; height: 6rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</span>
