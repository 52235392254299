import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageModel } from 'src/app/models/message.model';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: 'app-welcomemessage',
  templateUrl: './welcomemessage.component.html',
  styleUrls: ['./welcomemessage.component.css']
})
export class WelcomemessageComponent implements OnInit {
  loading: boolean = false;
  welcomeMessage: MessageModel;
  isPreview: boolean = false;
  showCounter: boolean = false;

  constructor(private router: Router, private msgService: MessageService) { }


  ngOnInit(): void {
    this.loading = true;
    this.welcomeMessage = {
      created_at: '',
      enddate: '',
      id: 0,
      message: 'na',
      msgtype: 0,
      startdate: '',
      title: '',
      updated_at: ''
    };

    console.log(this.welcomeMessage);
    this.loadWelcomeMessage();
  }

  loadWelcomeMessage() {
    this.msgService.getMessages().subscribe(
      (responseData) => {
        var messages: MessageModel[] = responseData;

        console.log(responseData);
        var welcomeMessage = messages.filter(message => {
          if (message.msgtype == 1) {
            this.welcomeMessage = message;
          }
          else return null;
        });
        console.log(this.welcomeMessage);
        this.showCounter = true;
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        if (error.status = 404) {
        } else {
          this.router.navigate(['/techerror']);
        }
      }
    );
  }

  onSubmit(wdDetailForm) {
    this.loading=true;
    console.log(wdDetailForm);
    this.welcomeMessage.title=wdDetailForm.txtWDTitle;
    this.welcomeMessage.message=wdDetailForm.txtWDMessage;

    this.msgService.postMessage(this.welcomeMessage.id,this.welcomeMessage).subscribe(
      (responseData) => {
        console.log(responseData);
        this.loading = false;
        this.ngOnInit();
      },
      (error) => {
        this.loading = false;
        if (error.status = 404) {
        } else {
          this.router.navigate(['/techerror']);
        }
      }
    );
  }
}
