<div class="card">
    <div class="card-header panel-american-blue-grey-red text-light">Book a Charter Flight</div>
    <div class="card-body">
        <form name="bookingform" class="css-form" (ngSubmit)="onSubmit(bookingform.value)" #bookingform="ngForm"
            *ngIf="!loading">
            <div class="row ">
                <div class="col">
                    <div class="card ">
                        <div class="card-header bg-ava-info">Departure Airport ICAO</div>
                        <div class="card-body">
                            <div class="form-group">
                                <input type="text" id="departureIcao" name="departureIcao" class="form-control" ngModel
                                    #departureIcao="ngModel" minlength="4" maxlength="4" pattern="^[a-zA-Z0-9]*$"
                                    required oninput="this.value = this.value.toUpperCase()" />
                                <div class="alert alert-danger"
                                    *ngIf="departureIcao.invalid && (departureIcao.dirty || departureIcao.touched)">
                                    <div *ngIf="departureIcao.errors?.required">
                                        You must enter a departure airport.
                                    </div>
                                    <div
                                        *ngIf="departureIcao.errors?.minlength || departureIcao.errors?.maxlength || departureIcao.errors?.pattern">
                                        Airport is invalid: length must be 4 and only alphanumerics.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card ">
                        <div class="card-header  bg-ava-info">Arrival Airport ICAO</div>
                        <div class="card-body">
                            <div class="form-group">
                                <input type="text" id="arrivalIcao" name="arrivalIcao" class="form-control" ngModel
                                    #arrivalIcao="ngModel" minlength="4" maxlength="4" pattern="^[a-zA-Z0-9]*$" required
                                    oninput="this.value = this.value.toUpperCase()" />
                                <div class="alert alert-danger"
                                    *ngIf="arrivalIcao.invalid && (arrivalIcao.dirty || arrivalIcao.touched)">
                                    <div *ngIf="arrivalIcao.errors?.required">
                                        You must enter an arrival airport.
                                    </div>
                                    <div
                                        *ngIf="arrivalIcao.errors?.minlength || arrivalIcao.errors?.maxlength || arrivalIcao.errors?.pattern">
                                        Airport is invalid: length must be 4 and only alphanumerics.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card ">
                        <div class="card-header  bg-ava-info">Equipment</div>
                        <div class="card-body">
                            <div class="form-group">
                                <select name="acIcaoCode" id="acIcaoCode" class="form-control" ngModel
                                    #acIcaoCode="ngModel" required>
                                    <option *ngFor="let equipment of equipmentList;let i = index"
                                        [value]="equipment.icao">
                                        {{equipment.description}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col">
                    <div class="card ">
                        <div class="card-header bg-ava-info">Estimated Cruise Altitude</div>
                        <div class="card-body">
                            <div class="form-group">
                                <input type="number" id="assignedAltitude" name="assignedAltitude" class="form-control"
                                    ngModel #assignedAltitude="ngModel" min="500" max="100000" required />
                                <div class="alert alert-danger"
                                    *ngIf="assignedAltitude.invalid && (assignedAltitude.dirty || assignedAltitude.touched)">
                                    <div *ngIf="assignedAltitude.errors?.required">
                                        You must enter an estimated cruise assignedAltitude
                                    </div>
                                    <div *ngIf="assignedAltitude.errors?.min || assignedAltitude.errors?.max">
                                        Cruise is invalid, must be between 500 and 100,000 feet.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card">
                        <div class="card-header  bg-ava-info">Estimated Duration</div>
                        <div class="card-body">
                            <div class="form-group">
                                <ngb-timepicker name="duration" #duration ngModel required [spinners]="false">
                                </ngb-timepicker>
                                <div class="alert alert-danger"
                                    *ngIf="duration.invalid && (duration.dirty || duration.touched)">
                                    <div *ngIf="duration.errors?.required">
                                        You must enter an arrival airport.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card">
                        <div class="card-header  bg-ava-info">ACARs to book with</div>
                        <div class="card-body">
                            <div class="form-group">
                                <select name="acars" id="acars" class="form-control" [(ngModel)]="acars">
                                    <option *ngFor="let acarsItem of selectedAcars;let i = index" [value]="acarsItem">
                                        {{acarsItem}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <div class="card  mt-2">
                        <div class="card-header  bg-ava-info">
                            Route
                        </div>
                        <div class="card-body">
                            <div class="float-right">
                                <a href="https://flightaware.com/analysis/route.rvt?origin={{departureIcao.value}}&destination={{arrivalIcao.value}}"
                                    target="_blank" *ngIf="departureIcao.value!='' && arrivalIcao.value!=''">
                                    <img class="float-right" title="Click to see routes on FlightAware"
                                        src="assets/img/flightaware_blue.png" style="height: 2em" alt="FlightAware" />
                                </a>
                                <a href="http://simbrief.com/system/dispatch.php?orig={{departureIcao.value}}&dest={{arrivalIcao.value}}&manualrmk=joinava&units=LBS"
                                    *ngIf="departureIcao.value!='' && arrivalIcao.value!=''" target="_blank">
                                    <img class="float-right mt-1" title="Click to see routes on Simbrief"
                                        src="assets/img/simbrief_logo.png" style="height: 2em;margin-right:5px"
                                        alt="simBrief" />
                                </a>
                            </div>
                            <div class="form-group">
                                <textarea class="form-control" id="route" name="route" ngModel rows="4" #route="ngModel"
                                    pattern="^[\w\-#()\s]+$" required minlength="3" maxlength="750">
                                            </textarea>
                                <div class="alert alert-danger" *ngIf="route.invalid && (route.dirty || route.touched)">
                                    <div *ngIf="route.errors?.required">
                                        You must enter a valid route.
                                    </div>
                                    <div *ngIf="route.errors?.minlength || route.errors?.maxlength">
                                        Minimum of 3 chars | Maximum 750
                                    </div>
                                    <div *ngIf="route.errors?.pattern">
                                        You have entered an invalid character
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">

            </div>
            <div class="row">
                <div class="col-md-8 mt-5">
                    <div class="button-group ">
                        <button type="submit" class="btn btn-primary" [disabled]="!bookingform.valid"
                            id="btnSubmit">Book It!</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<span *ngIf="loading">
    <div class="d-flex justify-content-center " style="margin-top: 10em;margin-bottom: 10em;">
        <div class="spinner-border text-primary" style="width: 6rem; height: 6rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</span>

<app-avamodal  [modalName]="modalName" [modalMessage]="modalMessage" [modalTitle]="modalTitle" [showMiniClose]="showMiniClose"
    [showCancelButton]="showCancelButton" [modalStatus]="modalStatus" [buttonText]="'Ok'" [modalPurpose]="modalPurpose"
    (modalClosed)="onModalClosed($event)"></app-avamodal>