<div *ngIf="hasGoals">
    <span class="leftnavTitle">Monthly Goals</span>
    <div class="progress border border-info panel-american-coral " >
        <div class="progress-bar  " 
            role="progressbar" 
            attr.aria-valuenow="{{totalHours}}" 
            aria-valuemin="0" 
            attr.aria-valuemax="{{goalHours}}" 
            [ngClass]="{'bg-grey'  : totalHours<goalHours, 'bg-success' : totalHours>=goalHours }"
            [ngStyle]="{width : goalHoursPercentage + '%'}">
            <span class="text-info">{{goalHoursPercentage | number:'1.0-0'}}%</span>
        </div>
    </div> 
    <span class="text-info pl-3 float-right" style="font-size: .9rem;">{{totalHours}} of {{goalHours}} hours with {{remainingHours}} left.</span>
    
</div>
