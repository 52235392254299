import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hrsearchmember',
  templateUrl: './hrsearchmember.component.html',
  styleUrls: ['./hrsearchmember.component.css']
})
export class HrsearchmemberComponent implements OnInit {
  loading : boolean = false;
  searchSuccessful : boolean = false;
  searchResults : any[] = [];
  hasMemberSearchResults : boolean = false;
  searchComplete : boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  handleSearchResults(searchResults) {
    this.searchResults = [];
    this.searchComplete=true;
    if (searchResults.length>0) {
      this.hasMemberSearchResults=true;
      this.searchResults = searchResults;
    } else {
      this.hasMemberSearchResults=false;
      this.searchResults=[];
    }
    console.log(searchResults);
  }
}
