import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { AvAStatModel } from 'src/app/models/avastat.model';
import { StatsService } from 'src/app/services/stats.service';

@Component({
  selector: 'app-hoursstats',
  templateUrl: './hoursstats.component.html',
  styleUrls: ['./hoursstats.component.css']
})
export class HoursstatsComponent implements OnInit {
  loading: boolean = false;
  windowScrolled = false;

  hoursStats: AvAStatModel[] = [];

  monthlyByPilotChartData: ChartDataSets[];
  yearlyByPilotChartData: ChartDataSets[];
  monthlyByHubChartData: ChartDataSets[];
  yearlyByHubChartData: ChartDataSets[];
  monthlyTotalsChartData: ChartDataSets[];
  yearlyTotalsChartData:  ChartDataSets[];

  COLOR_GRID = ['#2989d8', '#FDB45C', '#00ADF9', '#ed042b', '#46BFBD', '#949FB1', '#4D5360', '#bc5090', '#ffa600', '#003f5c', '#4D4567','#ed1980', '#FDB45C', '#00ADF9', '#ed042b', '#46BFBD', '#949FB1', '#4D5360', '#bc5090', '#ffa600', '#003f5c', '#4D4567','#ed1980'];

  lineChartLegend = false;
  horizonaltalBarChartType: ChartType = 'horizontalBar';
  lineChartType: ChartType = 'line';
  barChartType: ChartType = 'bar';

  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

  constructor(private router: Router, private statsService: StatsService) { }

  ngOnInit(): void {
    //add listener for scroll up button
    window.addEventListener('scroll', () => {
      this.windowScrolled = window.pageYOffset > 200;
    });
    this.loading = true;
    this.loadFlightStats();
  }

  tabTop() {
    window.scrollTo(0, 0);
  }

  public lineChartOptions: (ChartOptions) = {
    scales: {
      xAxes: [{}],
      yAxes: [
        {
          id: 'y-axis-1',
          type: 'linear',
          display: true,
          position: 'left',
          scaleLabel: {
            display: true,
            labelString: ''
          }
        },
      ],
    },
  };

  loadFlightStats() {
    this.statsService.getHourStats().subscribe(
      (responseData: AvAStatModel[]) => {
        console.log(responseData)
        this.hoursStats = responseData;
        this.loadUpDataSets();
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        if (error.status = 404) {
        } else {
          this.router.navigate(['/techerror']);
        }
      }
    );
  }

  loadUpDataSets() {

    this.loadMonthlyByPilot();
    this.loadYearlyByPilot();
    this.loadMonthlyByHubData();
    this.loadYearlyByHubData();
    this.loadMonthlyTotalsChartData();
    this.loadYearlyTotalsData();


  }

  loadMonthlyByPilot() {
    this.monthlyByPilotChartData = [
      {
        data: this.hoursStats['monthlyHoursByPilot'].data,
        label: '',
        backgroundColor: this.COLOR_GRID
      }
    ];
  }

  loadYearlyByPilot() {
    this.yearlyByPilotChartData = [
      {
        data: this.hoursStats['yearlyHoursByPilot'].data,
        label: '',
        backgroundColor: this.COLOR_GRID
      }
    ];
  }

  loadMonthlyByHubData() {
    this.monthlyByHubChartData = [
      {
        data: this.hoursStats['monthlyHoursByHub'].data,
        label: '',
        backgroundColor: this.COLOR_GRID
      }
    ];
  }

  loadYearlyByHubData() {
    this.yearlyByHubChartData = [
      {
        data: this.hoursStats['yearlyHoursByHub'].data,
        label: '',
        backgroundColor: this.COLOR_GRID
      }
    ];
  }

  loadMonthlyTotalsChartData() {
    this.monthlyTotalsChartData =[];
    this.monthlyTotalsChartData = [
      {
        data: this.hoursStats['monthlyHourTotals'].data,
        label: '',
        backgroundColor: this.COLOR_GRID
      }
    ];
  }

  loadYearlyTotalsData() {
    this.yearlyTotalsChartData = [
      {
        data: this.hoursStats['yearlyHoursTotals'].data,
        label: '',
        backgroundColor: this.COLOR_GRID
      }
    ];
  }
}
