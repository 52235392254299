import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompetitionService {
  BOH_RESULTS = '/bohresults';
  LC_RESULTS = '/lcresults';

  constructor(private httpClient: HttpClient) { }

  getLcResults() {
    return this.httpClient.get(environment.API_URL + this.LC_RESULTS).pipe(map(
      (data) => {
        // for (const key in data) {
        //   if (Object.prototype.hasOwnProperty.call(data, key)) {
        //     for (const index in data[key]) {
        //       if (Object.prototype.hasOwnProperty.call(data[key], index)) {
        //         const element = data[key][index];
        //         this.goals.push(element);
        //       }
        //     }
        //   }
        // }
        return data;
      }))
  }
}
