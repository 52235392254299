<div class="card border ">
    <div class="card-header bg-light ava-mini-card-header">Manager's Actions</div>
    <div class="card-body">
        <form name="loaForm" class="css-form" #loaForm="ngForm">

            <div class="row">
                <div class="col-md-12">
                    Comments:
                    <input type="text" id="comments" name="comments" class="form-control" ngModel
                        #comments="ngModel" required minlength="3" maxlength="100"
                        pattern="^[\w\-,.?#!()\s]+$" />
                    <div class="alert alert-danger"
                        *ngIf="comments.invalid && (comments.dirty || comments.touched)">
                        <div *ngIf="comments.errors?.required">
                            You must enter a Manager's Comment for your LOA.
                        </div>
                        <div
                            *ngIf="comments.errors?.minlength || comments.errors?.maxlength || comments.errors?.pattern">
                            Manager's Comment is invalid: must be 10 to 100 alphanumeric characters plus -,.?#!()
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-md-12 ">
                    <button class="btn btn-danger" id="btnAppReject" *ngIf="!loading" (click)="reject(loaForm.value)" [disabled]="!loaForm.valid || (!loaForm.touched && !loaForm.dirty)">
                        <i class="fas fa-thumbs-down"></i> Reject
                    </button>
                    <button type="button" class="btn btn-success float-right" *ngIf="!loading" (click)="approve(loaForm.value)" [disabled]="!loaForm.valid || (!loaForm.touched && !loaForm.dirty)">
                        <i class="fas fa-thumbs-up"></i> Accept
                    </button>
                    <button type="button" class="btn btn-success float-right disabled" *ngIf="loading">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Loading...
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>

<app-avamodal [modalName]="modalName" [modalTitle]="modalTitle" [modalMessage]="modalMessage" [modalTitle]="modalTitle" [showMiniClose]="showMiniClose"
[showCancelButton]="showCancelButton" [modalStatus]="modalStatus" [buttonText]="'Ok'"
[modalPurpose]="modalPurpose" (modalClosed)="onModalClosed($event)" ></app-avamodal>