<div class="container-fluid no-gutters mt-2" *ngIf="!loading">
    <div class="card shadow rounded-top rounded-lg">
        <div class="card-header panel-american-blue-grey-red">
            <div class="row">
                <div class="col-12">
                    Organization Positions
                </div>
            </div>
        </div>
        <div class="card-body ">
            <h3>Officers:</h3>
            <div class="row justify-content-center">
                <div class="col-md-3 justify-content-center btn-light pl-2 pr-2 pt-2 pb-2 rounded-top rounded-sm round-bottom border text-center" >
                    <p>
                        <b><u>{{ceoOrgPosition.attributes.longTitle}}</u></b>
                    </p>
                    <p>
                        {{ceoOrgPosition.attributes.callsign}} - {{ceoOrgPosition.attributes.firstName}} {{ceoOrgPosition.attributes.lastName}}
                    </p>
                    <p>
                        <a href="mailto:{{ceoOrgPosition.attributes.email}} at joinava.org">{{ceoOrgPosition.attributes.shortTitle}}</a>
                    </p>
                </div>
            </div>            
            <div class="row justify-content-center">
                <div class="col-md-4 justify-content-center btn-light pl-2 pr-2 pt-2 pb-2 rounded-top rounded-sm round-bottom border text-center" *ngFor="let position of execVPPositions">
                    <p>
                        <b><u>{{position.attributes.longTitle}}</u></b>
                    </p>
                    <p>
                        {{position.attributes.callsign}} - {{position.attributes.firstName}} {{position.attributes.lastName}}
                    </p>
                    <p>
                        <a href="mailto:{{position.attributes.email}} at joinava.org">{{position.attributes.shortTitle}}</a>
                    </p>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-md-3 justify-content-center btn-light pl-2 pr-2 pt-2 pb-2 rounded-top rounded-sm round-bottom border text-center" *ngFor="let position of vpPositions">
                    <p>
                        <b><u>{{position.attributes.longTitle}}</u></b>
                    </p>
                    <p>
                        {{position.attributes.callsign}} - {{position.attributes.firstName}} {{position.attributes.lastName}}
                    </p>
                    <p>
                        <a href="mailto:{{position.attributes.email}} at joinava.org">{{position.attributes.shortTitle}}</a>
                    </p>
                </div>
            </div> 
            <hr class="aa-line"/>
            <h3>Hub Managers:</h3>
            <div class="row justify-content-center">
                <div class="col-md-3 justify-content-center btn-light pl-2 pr-2 pt-2 pb-2 rounded-top rounded-sm round-bottom border text-center" *ngFor="let position of hmPositions">
                    <p>
                        <b><u>{{position.attributes.longTitle}}</u></b>
                    </p>
                    <p>
                        {{position.attributes.callsign}} - {{position.attributes.firstName}} {{position.attributes.lastName}}
                    </p>
                    <p>
                        <a href="mailto:{{position.attributes.email}} at joinava.org">{{position.attributes.shortTitle}}</a>
                    </p>
                </div>
            </div>
            <hr class="aa-line">
            <h3>Asst. Hub Managers:</h3>
            <div class="row justify-content-center">
                <div class="col-md-3 justify-content-center btn-light pl-2 pr-2 pt-2 pb-2 rounded-top rounded-sm round-bottom border text-center" *ngFor="let position of ahmPositions">
                    <p>
                        <b><u>{{position.attributes.longTitle}}</u></b>
                    </p>
                    <p>
                        {{position.attributes.callsign}} - {{position.attributes.firstName}} {{position.attributes.lastName}}
                    </p>
                    <p>
                        <a href="mailto:{{position.attributes.email}} at joinava.org">{{position.attributes.shortTitle}}</a>
                    </p>
                </div>
            </div>             
        </div>
    </div>
</div>

<div class="container text-center" *ngIf="loading">
    <div class="lds-hourglass"></div>
    <p>Loading...</p>
</div>