import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-avastats',
  templateUrl: './avastats.component.html',
  styleUrls: ['./avastats.component.css']
})
export class AvastatsComponent implements OnInit {
  loading : boolean = false;
  activeMenu : string = 'flights';

  constructor() { }

  ngOnInit(): void {
  }

  statsMenuClick(menuChoice) {
    this.activeMenu=menuChoice;
  }

}
