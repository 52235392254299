import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-logbookpaginator',
  templateUrl: './logbookpaginator.component.html',
  styleUrls: ['./logbookpaginator.component.css']
})
export class LogbookpaginatorComponent implements OnInit {
  @Input() nextPageId : number;
  @Input() prevPageId : number;
  @Input() totalPireps : number;
  totalPages : number;
  isDisabled : number = 0;

  @Output() onPageChange = new EventEmitter<{pageToLoad: number}>();

  constructor() { }

  ngOnInit(): void {
    this.totalPages = Math.ceil(this.totalPireps/50);
    console.log(this.nextPageId, this.prevPageId, this.totalPireps, this.totalPages);
  }

  onNextButtonClick() {
    let pageToLoad : number = this.nextPageId;
    console.log("NEXT Hit : " + pageToLoad);
    this.onPageChange.emit({pageToLoad:pageToLoad}) ;
  }

  onPreviousButtonClick() {
    let pageToLoad : number = this.prevPageId;
    console.log("PREV Hit : " + pageToLoad);
    this.onPageChange.emit({pageToLoad:pageToLoad}) ;
  }

}
