import { HttpClient } from '@angular/common/http';
import { Injectable, Input } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MembersearchService {
  MEMBER_SEARCH_SVC = '/membersearch';

  memberSearchResults : any[] = [];

  constructor(private httpClient: HttpClient) { }

  search(scFromData, searchSource) {
    if (searchSource=='') {
      searchSource='PILOTS';
    }
    
    let url = environment.API_URL + this.MEMBER_SEARCH_SVC;
    console.log(url);
    let searchData = {
      searchCriteria : scFromData,
      searchSource : searchSource
    }
    console.log(searchData);
    return this.httpClient.post(url, searchData, searchSource).pipe(map( 
      (data) => {
        this.memberSearchResults = [];
        for (const key in data) {
          if (Object.prototype.hasOwnProperty.call(data, key)) {
            for ( const index in data[key]) {
              if (Object.prototype.hasOwnProperty.call(data[key], index)) {
                const element = data[key][index];
                element.attributes.toggledOpen=false;
                this.memberSearchResults.push(element.attributes);
              }
            }
          }
        }
        return this.memberSearchResults;
    }))      
  }
}
