import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-techerror',
  templateUrl: './techerror.component.html',
  styleUrls: ['./techerror.component.css']
})
export class TecherrorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
