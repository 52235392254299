<div class="avacontent">
    <div *ngIf="notams.length>0">
        <app-notam *ngFor="let notam of notams" [notam]="notam"></app-notam>
    </div>
</div>
<div class="container-fluid no-gutters mt-2" *ngIf="!loading">
    <div class="card shadow rounded-top rounded-lg">
        <div class="card-header panel-american-blue">
            <div class="row">
                <div class="col-12">
                    AvA Management Console
                </div>
            </div>
        </div>
        <div class="card-body ">
            <div class="card shadowed rounded-top rounded-lg">
                <div class="card-header panel-american-blue-grey-red">
                    <div class="row">
                        <div class="col-12">
                            Hub Manager
                        </div>
                    </div>
                </div>
                <div class="card-body ">
                    <div class="row mt-2">
                        <div class="col d-flex justify-content-center btn-light mt-3 pl-2 pr-2 pt-2 pb-2 rounded-top rounded-sm round-bottom border"
                            *ngFor="let link of hmLinks">
                            <a class="hm mt-3" href="{{link.href}}">
                                <i class="{{link.meta.icon}} mg-menu-icon"></i><br />
                                <span>{{link.meta.menuText}}</span><br />
                                <span *ngIf="link.meta.appCount>0" class="badge badge-danger">
                                    {{link.meta.appCount}}
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="card-body" *ngIf="staffLinks.length>0">
            <div class="card shadowed rounded-top rounded-lg">
                <div class="card-header panel-american-blue">
                    <div class="row">
                        <div class="col-12">
                            Staff Member
                        </div>
                    </div>
                </div>
                <div class="card-body ">
                    <div class="row mt-2">
                        <div class="col d-flex justify-content-center btn-light mt-3 pl-2 pr-2 pt-2 pb-2 rounded-top rounded-sm round-bottom border" *ngFor="let link of staffLinks">
                            <a href="{{link.href}}">
                                <i class="{{link.meta.icon}} mg-menu-icon"></i><br />
                                <span>{{link.meta.menuText}}</span><br />
                                <span *ngIf="link.meta.appCount>0" class="badge badge-danger">
                                    {{link.meta.appCount}}
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="card-body" *ngIf="vpLinks.length>0">
            <div class="card shadowed rounded-top rounded-lg">
                <div class="card-header panel-american-coral">
                    <div class="row">
                        <div class="col-12">
                            VP
                        </div>
                    </div>
                </div>
                <div class="card-body ">
                    <div class="row">
                        <div class="col d-flex justify-content-center btn-light mt-3 pl-2 pr-2 pt-2 pb-2 rounded-top rounded-sm round-bottom border"
                            *ngFor="let link of vpLinks">
                            <a class="vp mt-3" href="{{link.href}}" >
                                <i class="{{link.meta.icon}} mg-menu-icon"></i><br />
                                <span>{{link.meta.menuText}}</span><br />
                                <span *ngIf="link.meta.appCount>0" class="badge badge-danger">
                                    {{link.meta.appCount}}
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body" *ngIf="ceoLinks.length>0">
            <div class="card shadowed rounded-top rounded-lg">
                <div class="card-header panel-american-red">
                    <div class="row">
                        <div class="col-12">
                            CEO
                        </div>
                    </div>
                </div>
                <div class="card-body ">
                    <div class="row mt-2">
                        <div class="col d-flex justify-content-center btn-light mt-3 pl-2 pr-2 pt-2 pb-2 rounded-top rounded-sm round-bottom border"
                            *ngFor="let link of ceoLinks">
                            <a class="ceo mt-3" [routerLink]="[link.href]" routerLinkeActive="active">
                                <i class="{{link.meta.icon}} mg-menu-icon"></i><br />
                                <span>{{link.meta.menuText}}</span><br />
                                <span *ngIf="link.meta.appCount>0" class="badge badge-danger">
                                    {{link.meta.appCount}}
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<span *ngIf="loading">
    <div class="d-flex justify-content-center " style="margin-top: 10em;margin-bottom: 10em;">
        <div class="spinner-border text-primary" style="width: 6rem; height: 6rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</span>