<div class="container mt-2 mb-3">
    <div class="row">
        <div class="col-md-12">
            <div class="card shadow rounded-top rounded-lg">
                <div class="card-header panel-american-coral">
                    <div class="row">
                        <div class="col-10 mt-2">
                            <span>Manage Resignations</span>
                        </div>
                    </div>
                </div>
                <div class="card-body" *ngIf="!loading ">
                    <div class="row" *ngIf="!hasResignations ">
                        <div class="col-sm-12">
                            <p>I'm sorry but there are no Resignations at this time.</p>
                        </div>
                    </div>
                    <div *ngIf="hasResignations && !loading">
                        <div class="card mt-2  border border-info" *ngFor="let resignation of resignations">
                            <div class="card-header bg-ava-info">
                                <div class="row">
                                    <div class="col-sm-8">
                                       {{resignation.attributes.firstName}}
                                        {{resignation.attributes.lastName}}
                                    </div>
                                    <div class="col-sm-4">
                                        <button class="btn btn-info btn-sm float-right" type="button"
                                            data-toggle="collapse"
                                            [attr.data-target]="'#appDetailCollapse-' + resignation.attributes.resignationId"
                                            aria-expanded="true"
                                            aria-controls="appDetailCollapse-{{resignation.attributes.resignationId}}">
                                            <i class="fas fa-binoculars"></i> Review
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="collapse" id="appDetailCollapse-{{resignation.attributes.resignationId}}">
                                <div class="card-body">
                                    <app-resignationdetail [resignation]="resignation" (onResignationUpdate)="onResignationUpdate($event)"></app-resignationdetail>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container text-center" *ngIf="loading">
        <div class="lds-hourglass"></div>
        <p>Loading...</p>
    </div>