<app-membersearch (onMemberSearchComplete)="handleSearchResults($event)" [searchSource]=""></app-membersearch>

<div class="container mt-2 mb-3" *ngIf="searchComplete">
    <div class="row">
        <div class="col-md-12">
            <div class="card shadow rounded-top rounded-lg">
                <div class="card-header bg-warning">
                    <div class="row">
                        <div class="col-md-12">
                            Search Results
                        </div>
                    </div>
                </div>
                <div class="card-body ">
                    <div class="row mt-2 " >
                        <div class="col-md-12" *ngIf="hasMemberSearchResults && searchComplete">
                            <div class="table-responsive">
                                <table class="table table-condensed table-bordered table-hover table-striped text-center">
                                    <thead >
                                        <th class="text-center">#</th>
                                        <th class="text-center">Callsign</th>
                                        <th class="text-center">Pilot</th>
                                        <th class="text-center">VATSIM Id</th>
                                        <th class="text-center">Hired On</th>
                                        <th class="text-center">Status</th>
                                        <th class="text-center">Hub</th>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let pilot of searchResults; let i = index;">
                                            <td>{{i+1}}</td>
                                            <td>
                                                <a [routerLink]="['/managehrprofile/',pilot.pilotId]"
                                                        routerLinkActive="active" >
                                                        {{pilot.callsign}}
                                                </a>                                                
                                            </td>
                                            <td>
                                                <a href="mailto:{{pilot.email}}">
                                                    {{pilot.firstName}}&nbsp;{{pilot.lastName}}
                                                </a>
                                            </td>
                                            <td>{{pilot.vatsimId}}</td>
                                            <td>{{pilot.hireDate}}</td>
                                            <td>{{pilot.status}}</td>
                                            <td>{{pilot.hubShortName}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12" *ngIf="!hasMemberSearchResults && searchComplete">
                        No pilots found.
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>