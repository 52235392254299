import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ProfileModel } from 'src/app/models/profile.model';
import { AuthService } from 'src/app/services/auth.service';
import { DataSharingService } from 'src/app/services/datasharing.service';
import { LoaService } from 'src/app/services/loa.service';
import { PilotService } from 'src/app/services/pilot.service';

@Component({
  selector: 'app-loaform',
  templateUrl: './loaform.component.html',
  styleUrls: ['./loaform.component.css']
})
export class LoaformComponent implements OnInit {
  @Output() onLOARequestFormComplete = new EventEmitter<string>();
  @Input() profile : ProfileModel;
  @Input() pilotId : number;
  loading : boolean = false;

  @Output() modalMessage: string = "";
  @Output() modalTitle: string = "";
  @Output() modalStatus: string = "";
  @Output() modalPurpose: string = "";
  @Output() showCancelButton: boolean = false;
  @Output() showMiniClose: boolean = false;
  @Output() modalName: string = 'loaFormModal';

  constructor(private loaService : LoaService,private authService : AuthService, private router : Router, private dss : DataSharingService) { }

  ngOnInit(): void {
  }

  onSubmit(loaForm) {
    this.loading=true;
    console.log(loaForm, this.profile);

    this.loaService.postLOA(loaForm).subscribe(
      (responseData) => {
        console.log(responseData);
        this.showSuccessModal(responseData);
        this.loading=false;
      },
      (error) => {
        if (error.status === 500 ) {
          this.router.navigate(['/techerror']);
        } else {
          this.showFailureModal(error);
        }
        this.loading=false;
      }
    )
  }

  redirectTo(uri:string){
    this.router.navigateByUrl('', {skipLocationChange: true}).then(()=>
    this.router.navigate([uri]));
 }

  onModalClosed(returnMessage: {status: string, purpose: string}) {
    if (returnMessage.purpose == 'loasuccess' && returnMessage.status == 'ok') {
      this.dss.showModal.next({modalName : this.modalName, showModal :false});
      this.onLOARequestFormComplete.emit('loaRequestComplete');
      // this.redirectTo('/pilot/' + this.pilotId + '/profile');
      // window.location.reload();
      // this.router.navigate(['/pilot', this.pilotId, 'profile']);
    }
  }

  showSuccessModal(data) {
    this.modalStatus='success';
    this.showMiniClose=false;
    this.showCancelButton=false;
    this.modalTitle = 'LOA Request Submitted';
    this.modalMessage = 'Your LOA has been successfully submitted as LOA #:' + data['loaId'];
    this.modalMessage += '<br/>Please allow a few days for our staff to accept/deny your request.';
    this.modalPurpose = "loasuccess";
    this.dss.showModal.next({modalName : this.modalName, showModal :true});
  }

  showFailureModal(error) {
    this.modalStatus='danger';
    this.showMiniClose=false;
    this.showCancelButton=false;
    this.modalTitle = 'LOA Request Failed';
    this.modalMessage = 'Your LOA request failed to be processed with the following error:<br/>';
    if (error.error.hasOwnProperty("messages")) {
      for (const key in error.error.messages) {
        this.modalMessage += '<br/>' + error.error.messages;
      }
    }
    console.log(error.message + ' == ' + error.status);
    this.modalPurpose = "loasuccess";
    this.dss.showModal.next({modalName : this.modalName, showModal :true});
  }
}
