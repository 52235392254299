import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/pages/home/home.component';
import { LoginformComponent } from './components/auth/loginform/loginform.component';
import { PilotComponent } from './components/pages/pilot/pilot.component';
import { ManageComponent } from './components/pages/manage/manage.component';
import { AboutusComponent } from './components/pages/aboutus/aboutus.component';
import { SignupComponent } from './components/pages/signup/signup.component';
import { LogbookComponent } from './components/pages/pilot/logbook/logbook.component';
import { DispatcherComponent } from './components/pages/pilot/dispatcher/dispatcher.component';
import { StatsComponent } from './components/pages/pilot/stats/stats.component';
import { ProfileComponent } from './components/pages/pilot/profile/profile.component';
import { RosterComponent } from './components/pages/pilot/roster/roster.component';
import { TrainingComponent } from './components/pages/pilot/training/training.component';
import { CommsComponent } from './components/pages/pilot/comms/comms.component';
import { AuthGuard } from './components/auth/auth.guard';
import { ManagerGuard } from './components/auth/manager.guard';
import { LastFlightComponent } from './components/pages/pilot/lastflight/lastflight.component';
import { PirepComponent } from './components/pages/pilot/pirep/pirep.component';
import { TecherrorComponent } from './components/static/errors/techerror/techerror.component';
import { LoaComponent } from './components/pages/manage/loa/loa.component';
import { TransferComponent } from './components/pages/manage/transfer/transfer.component';
import { HrtransferComponent } from './components/pages/manage/hrtransfer/hrtransfer.component';
import { HrrosterComponent } from './components/pages/manage/hrroster/hrroster.component';
import { HrprofileComponent } from './components/pages/manage/hrprofile/hrprofile.component';
import { HrpirepComponent } from './components/pages/manage/hrprofile/hrpirep/hrpirep.component';
import { HrsearchmemberComponent } from './components/pages/manage/hrsearchmember/hrsearchmember.component';
import { MassMailComponent } from './components/pages/manage/mass-mail/mass-mail.component';
import { PirepreviewComponent } from './components/pages/manage/pirepreview/pirepreview.component';
import { EventsComponent } from './components/pages/manage/events/events.component';
import { ApplicationsComponent } from './components/pages/manage/applications/applications.component';
import { ResignationsComponent } from './components/pages/manage/resignations/resignations.component';
import { TerminationsComponent } from './components/pages/manage/terminations/terminations.component';
import { PersonnelrecordsComponent } from './components/pages/manage/personnelrecords/personnelrecords.component';
import { DnrsComponent } from './components/pages/manage/dnrs/dnrs.component';
import { DismissedsComponent } from './components/pages/manage/dismisseds/dismisseds.component';
import { ChangecallsignComponent } from './components/pages/manage/changecallsign/changecallsign.component';
import { FeatureddestinationComponent } from './components/pages/manage/featureddestination/featureddestination.component';
import { NotamsComponent } from './components/pages/manage/notams/notams.component';
import { HubsComponent } from './components/pages/manage/hubs/hubs.component';
import { WelcomemessageComponent } from './components/pages/manage/welcomemessage/welcomemessage.component';
import { GoalsComponent } from './components/pages/manage/goals/goals.component';
import { RejectedpirepsComponent } from './components/pages/manage/rejectedpireps/rejectedpireps.component';
import { StaffpositionsComponent } from './components/pages/manage/staffpositions/staffpositions.component';
import { MissionComponent } from './components/pages/aboutus/mission/mission.component';
import { HistoryComponent } from './components/pages/aboutus/history/history.component';
import { PolicymanualComponent } from './components/pages/aboutus/policymanual/policymanual.component';
import { OrgchartComponent } from './components/pages/aboutus/orgchart/orgchart.component';
import { EventcalendarComponent } from './components/pages/aboutus/eventcalendar/eventcalendar.component';
import { AvastatsComponent } from './components/pages/aboutus/avastats/avastats.component';
import { FaqComponent } from './components/pages/aboutus/faq/faq.component';
import { GenericrosterComponent } from './components/pages/aboutus/genericroster/genericroster.component';
import { PwdresetComponent } from './components/auth/header/pwdreset/pwdreset.component';
import { ResetpwdComponent } from './components/auth/resetpwd/resetpwd.component';
import { SoftwareComponent } from './components/pages/pilot/software/software.component';
import { TrainingpageComponent } from './components/pages/manage/trainingpage/trainingpage.component';

const appRoutes: Routes = [
  { path: '', component: HomeComponent},
  { path: 'aboutus', component: AboutusComponent },
  // { path: 'login', component: LoginformComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'pwdreset', component: PwdresetComponent },
  { path: 'resetpassword', component: ResetpwdComponent },
  { path: 'techerror', component: TecherrorComponent },
  { path: 'mission', component: MissionComponent },
  { path: 'history', component: HistoryComponent },
  { path: 'orgchart', component: OrgchartComponent },
  { path: 'policymanual', component: PolicymanualComponent },
  { path: 'eventcalendar', component: EventcalendarComponent },
  { path: 'stats', component: AvastatsComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'genericroster', component: GenericrosterComponent },
  { path: 'pilot', component: PilotComponent, canActivate : [AuthGuard],
    children: [
      { path: ':pilotId/lastflight', component: LastFlightComponent, canActivate : [AuthGuard] },      
      { path: ':pilotId/logbook', component: LogbookComponent, canActivate : [AuthGuard] },
      { path: ':pilotId/dispatcher', component: DispatcherComponent, canActivate : [AuthGuard] },
      { path: ':pilotId/stats', component:  StatsComponent, canActivate : [AuthGuard]},
      { path: ':pilotId/profile', component: ProfileComponent, canActivate : [AuthGuard] },
      { path: ':pilotId/roster', component: RosterComponent, canActivate : [AuthGuard] },
      { path: ':pilotId/training', component: TrainingComponent, canActivate : [AuthGuard] },
      { path: ':pilotId/comms', component: CommsComponent, canActivate : [AuthGuard] },
      { path: ':pilotId/software', component: SoftwareComponent, canActivate : [AuthGuard] },
      { path: 'pireps/:pirepId', component: PirepComponent, canActivate : [AuthGuard] },
      { path: 'pireps', component: PirepComponent, canActivate : [AuthGuard] },      
    ]
  },
  // now all the independent manage routes - on the pilot it all goes through the pilot component - manager pages are individual
  { path: 'manage', component: ManageComponent, canActivate : [AuthGuard, ManagerGuard] },
  { path: 'manageloas', component: LoaComponent, canActivate : [AuthGuard, ManagerGuard] },
  { path: 'managehrtransfer', component: HrtransferComponent, canActivate : [AuthGuard, ManagerGuard] },  
  { path: 'managetransfers', component: TransferComponent, canActivate : [AuthGuard, ManagerGuard] },  
  { path: 'manageroster', component: HrrosterComponent, canActivate : [AuthGuard, ManagerGuard] },  
  { path: 'managehrprofile/:pilotId', component: HrprofileComponent, canActivate : [AuthGuard, ManagerGuard] },  
  { path: 'managehrprofile/:pilotId/hrpireps/:pirepId', component: HrpirepComponent, canActivate : [AuthGuard, ManagerGuard] },  
  { path: 'membersearch', component: HrsearchmemberComponent, canActivate : [AuthGuard, ManagerGuard] },  
  { path: 'massemail', component: MassMailComponent, canActivate : [AuthGuard, ManagerGuard] },  
  { path: 'managepireps', component: PirepreviewComponent, canActivate : [AuthGuard, ManagerGuard] },  
  { path: 'manageevents', component: EventsComponent, canActivate : [AuthGuard, ManagerGuard] }, 
  { path: 'manageapplications', component: ApplicationsComponent, canActivate : [AuthGuard, ManagerGuard] },  
  { path: 'manageresignations', component: ResignationsComponent, canActivate : [AuthGuard, ManagerGuard] },  
  { path: 'manageterminations', component: TerminationsComponent, canActivate : [AuthGuard, ManagerGuard] },  
  { path: 'managepersonnelrecords', component: PersonnelrecordsComponent, canActivate : [AuthGuard, ManagerGuard] },  
  { path: 'managednrlist', component: DnrsComponent, canActivate : [AuthGuard, ManagerGuard] },  
  { path: 'managedismissedlist', component: DismissedsComponent, canActivate : [AuthGuard, ManagerGuard] },  
  { path: 'changecallsign', component: ChangecallsignComponent, canActivate : [AuthGuard, ManagerGuard] },  
  { path: 'managefeatureddestination', component: FeatureddestinationComponent, canActivate : [AuthGuard, ManagerGuard] },  
  { path: 'managenotams', component: NotamsComponent, canActivate : [AuthGuard, ManagerGuard] },  
  { path: 'managehubs', component: HubsComponent, canActivate : [AuthGuard, ManagerGuard] },  
  { path: 'welcomemessage', component: WelcomemessageComponent, canActivate : [AuthGuard, ManagerGuard] },  
  { path: 'managegoals', component: GoalsComponent, canActivate : [AuthGuard, ManagerGuard] },  
  { path: 'managerejectedpireps', component: RejectedpirepsComponent, canActivate : [AuthGuard, ManagerGuard] },  
  { path: 'managestaff', component: StaffpositionsComponent, canActivate : [AuthGuard, ManagerGuard] }, 
  { path: 'managetrainingpage', component: TrainingpageComponent, canActivate : [AuthGuard, ManagerGuard] },  
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes,{scrollPositionRestoration: 'enabled'})
  ],
  exports: [RouterModule]
})
export class AppRoutingModuleModule { }
