import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { NotamModel } from '../models/notam.model';

@Injectable({
  providedIn: 'root'
})
export class NotamService {
  MANAGED_NOTAMS_SVC : string = '/managednotams';
  PUBLIC_NOTAMS_SVC : string = '/notams';
  SECURED_NOTAMS_SVC : string = '/securednotams';
  notams : NotamModel[] = [];

  constructor(private httpClient : HttpClient ) {}

  getPublicNotams() {
    this.notams=[];
    return this.httpClient.get(environment.API_URL + this.PUBLIC_NOTAMS_SVC).pipe(map(
      (data) => {
      for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
          for ( const index in data[key]) {
            if (Object.prototype.hasOwnProperty.call(data[key], index)) {
              const element = data[key][index];
              this.notams.push(element.attributes);
            }
          }
        }
      }
      return this.notams;
    }))
  }
  getSecuredNotams() {
    this.notams=[];
    return this.httpClient.get(environment.API_URL + this.SECURED_NOTAMS_SVC).pipe(map(
      (data) => {
      for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
          for ( const index in data[key]) {
            if (Object.prototype.hasOwnProperty.call(data[key], index)) {
              const element = data[key][index];
              this.notams.push(element.attributes);
            }
          }
        }
      }
      return this.notams;
    }))
  }

  getManagedNotams() {
    this.notams=[];
    return this.httpClient.get(environment.API_URL + this.MANAGED_NOTAMS_SVC).pipe(map(
      (data) => {
      for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
          for ( const index in data[key]) {
            if (Object.prototype.hasOwnProperty.call(data[key], index)) {
              const element = data[key][index];
              element.attributes.startDate = new Date(element.attributes.startDate*1000);
              element.attributes.endDate = new Date(element.attributes.endDate*1000);
              this.notams.push(element.attributes);
            }
          }
        }
      }
      return this.notams;
    }))
  }

  postNotam(newNotam) {
    let url = environment.API_URL + this.MANAGED_NOTAMS_SVC;

    return this.httpClient.post(url, newNotam).pipe(map(
      (data) => {
        return data;
      }
    ));
  }

  putNotam(updatedNotam, notamId) {
    let url = environment.API_URL + this.MANAGED_NOTAMS_SVC + '/' + notamId;
    console.log(url);
    console.log(updatedNotam);
    return this.httpClient.put(url, updatedNotam);
  }

  deleteEvent(notamId) {
    let url = environment.API_URL + this.MANAGED_NOTAMS_SVC + '/' + notamId;

    return this.httpClient.delete(url).pipe(map(
      (data) => {
        return data;
      }
    ));
  }
}
