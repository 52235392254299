<div class="card shadow rounded-top rounded-lg " style="margin: 5px 10px 30px 10px" *ngIf="!loading">
    <div class="card-header panel-american-blue">
        <div class="row">
            <div class="col-md-12 col-xs-12">
                <b>{{pilotsHubDetails.longName}} ({{pilotsHubDetails.shortName}}) </b>
                <span class="float-right">
                    Manager: <b>({{pilotsHubDetails.hubManager.callsign}}) {{pilotsHubDetails.hubManager.firstName}} {{pilotsHubDetails.hubManager.lastName}}</b>
                </span>
                <br/>
                <span class="float-right" style="text-decoration: underline;cursor: pointer;"
                    (click)="showCertHelp=!showCertHelp">Wings and Stars</span>
                <p class="col-md-12 col-xs-12" *ngIf="pilotsHubDetails.length==0">
                    I'm sorry but there are no pilots assigned to {{pilotsHubDetails.shortName}} as of yet.
                </p>
            </div>
        </div>
    </div>
    <div class="card-body " *ngIf="hubRoster.length>0 && !loading">
        <div class="col-12 align-self-end float-right">
            <div class="row" *ngIf="showCertHelp">
                <div class="col-md-12 float-right">
                    <div class="alert alert-primary" role="alert">
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="showCertHelp=false;">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <br>
                        <p>
                            This table is your Hub Roster.  The Wings & Stars column represent the hours in each aircraft type the pilot has accumulated.
                            Hover your mouse over each wing/star combo to see a pop-up of the a/c type and hours in type.
                        </p>
                        <p>
                            <img src="assets/img/certtable.png" />
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 align-self-end float-right">
                <div class="btn-group btn-group-sm float-right mb-2" role="group" aria-label="Other hub Rosters to chose">
                    <ng-container *ngFor="let hub of hubLinks; let i=index">
                        <button type="button" class="btn btn-light" style="border-color: silver;" *ngIf="hub.hubId != hubToLoad" (click)="onChangeHub(hub.hubId)">
                            {{hub.shortName}}
                        </button>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 align-self-end float-right">
                <div *ngIf="!hasPilots">
                    There does not appear to be anyone assigned to {{pilotsHubDetails.shortName}} at this time.
                </div>
                <div class="table-responsive" *ngIf="hasPilots">
                    <table class="table table-condensed table-bordered table-hover table-striped text-center">
                        <thead>
                            <th class="text-center">#</th>
                            <th class="text-center">Callsign</th>
                            <th class="text-center">Pilot</th>
                            <th class="text-center">Total Hours</th>
                            <th class="text-center">CAT</th>
                            <th class="text-center">Status</th>
                            <th class="text-center">Last Flight</th>
                            <th class="text-center" width="30%">Achievements</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let pilot of hubRoster; let i=index">
                                <td>{{i+1}}</td>
                                <td>{{pilot.attributes.callsign}}</td>
                                <td>{{pilot.attributes.pilot}}</td>
                                <td>{{pilot.attributes.totalHours}}</td>
                                <td>{{pilot.attributes.catRating}}</td>
                                <td>
                                    <span [ngStyle]="{'color' : pilot.attributes.status=='ACTIVE' ? 'seagreen' : 'red'}">
                                        {{pilot.attributes.status}}
                                    </span>
                                </td>
                                <td>{{pilot.attributes.lastFlight}}</td>
                                <td class="text-center">
                                    <span *ngFor="let cert of pilot.attributes.pilotCerts" style="display: inline-block;" class="mr-1">
                                        <div *ngIf="cert.attributes.starCount>0">
                                            <img src="assets/img/wings/star.svg" height="16px" color="silver" *ngFor="let in of getStarCount(cert);let i = index" />
                                        </div>
                                        <div *ngIf="cert.attributes.certType==1" style="display: inline-block;">
                                            <img src="assets/img/wings/silverwings.svg" title="{{cert.attributes.description}} - {{cert.attributes.loggedHours}} hours" height="32px" color="silver" />
                                        </div>
                                        <div *ngIf="cert.attributes.certType==2" style="display: inline-block;">
                                            <img src="assets/img/wings/goldwings.svg" title="{{cert.attributes.description}} - {{cert.attributes.loggedHours}} hours" height="32px" color="silver" />
                                        </div>
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<span *ngIf="loading">
    <div class="d-flex justify-content-center " style="margin-top: 10em;margin-bottom: 10em;">
        <div class="spinner-border text-primary" style="width: 6rem; height: 6rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</span>