import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { BaseChartDirective, Color, Label } from 'ng2-charts';
import { OfrModel } from 'src/app/models/ofr.model';
import { PilotLandingModel } from 'src/app/models/pilot-landing.model';

@Component({
  selector: 'app-last-flight-chart',
  templateUrl: './last-flight-chart.component.html',
  styleUrls: ['./last-flight-chart.component.css']
})
export class LastFlightChartComponent implements OnInit {
  @Input() pilotDetails: PilotLandingModel;

  // public lineChartData: ChartDataSets[];
  //  = [
  //   { data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A' },
  //   { data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B' },
  //   { data: [180, 480, 770, 90, 1000, 270, 400], label: 'Series C', yAxisID: 'y-axis-1' }
  // ];


  // public lineChartLabels: Label[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
  // public lineChartOptions: (ChartOptions & { annotation: any }) = {

  public lineChartOptions: (ChartOptions) = {
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [{}],
      yAxes: [
        {
          id: 'y-axis-1',
          type: 'linear',
          display: true,
          position: 'left',
          scaleLabel: {
            display: true,
            labelString: 'Altitude'
          }
        },
        {
          id: 'y-axis-2',
          type: 'linear',
          display: true,
          position: 'right',
          scaleLabel: {
            display: true,
            labelString: 'Speed'
          },
        },
      ],
    },
    // annotation: {
    //   annotations: [
    //     {
    //       type: 'line',
    //       mode: 'vertical',
    //       scaleID: 'x-axis-0',
    //       value: 'March',
    //       borderColor: 'orange',
    //       borderWidth: 2,
    //       label: {
    //         enabled: true,
    //         fontColor: 'orange',
    //         content: 'LineAnno'
    //       }
    //     },
    //   ],
    // },
  };
  public lineChartColors: Color[] = [
    { // blue/altitude
      backgroundColor: 'rgba(41,137,216,0.3)',
      borderColor: '#2989d8',
      pointBackgroundColor: '#2989d8',
      pointBorderColor: '#2989d8',
      pointHoverBackgroundColor: '#2989d8',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // yellow/speed
      backgroundColor: 'rgba(247,203,72,.2)',
      borderColor: 'rgba(247,203,72,1)',
      pointBackgroundColor: 'rgba(247,203,72,1)',
      pointBorderColor: 'rgba(247,203,72,1)',
      pointHoverBackgroundColor: 'rgba(247,203,72,1)',
      pointHoverBorderColor: 'rgba(77,83,96,1)'
    },
  ];

  public lineChartLegend = true;
  public lineChartType: ChartType = 'line';

  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

  constructor() { }

  ngOnInit(): void {
    // this.lineChartData = this.loadChartData();
  }

  // loadChartData() {
  //   this.pilotDetails.lastOfrs.forEach((ofr: OfrModel) => {
  //     console.log(ofr.chartData);
  //     ofr.chartData = this.formatChartData(ofr.chartData);

  //   });

  //     // return [
  //     //   { data: this.pilotDetails.lastOfrs[0]['chartdata'], label: 'Series Altitude' },
  //     //   { data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B' },
  //     //   { data: [180, 480, 770, 90, 1000, 270, 400], label: 'Series C', yAxisID: 'y-axis-1' }
  //     // ];
  //   }

  // events
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    // console.log(event, active);
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    // console.log(event, active);
  }

  public hideOne(): void {
    const isHidden = this.chart.isDatasetHidden(1);
    this.chart.hideDataset(1, !isHidden);
  }
}
