import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-comms',
  templateUrl: './comms.component.html',
  styleUrls: ['./comms.component.css']
})
export class CommsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
