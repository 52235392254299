<nav class="navbar navbar-expand-md navbar-light " style="background-color: #d0dae0">
    <a class="navbar-brand" [routerLink]="['/']">
        <div>
            <img src="assets/img/logo_130208.png" height="32px"><span class="logo-font"></span>
        </div>
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavDropdown">
        <ul class="navbar-nav ml-auto">
            <li *ngIf="!isAuthenticated" class="nav-item">
                <a class="nav-link" [routerLink]="['/signup']" routerLinkActive="active">Join AvA</a>
            </li>
            <li *ngIf="isAuthenticated" class="nav-item">
                <a class="nav-link" [routerLink]="['/pilot']" routerLinkActive="active">Pilot</a>
            </li>
            <li *ngIf="isAuthenticated===true && isManager===true" class="nav-item">
                <a class="nav-link" [routerLink]="['/manage']" routerLinkActive="active">Manage</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [routerLink]="['/aboutus']" routerLinkActive="active">About Us</a>
            </li>
            <li class="nav-item" *ngIf="!isAuthenticated">

                <a class="nav-link pointer" (click)="onLoginClick()" title="Start Login" data-toggle="modal" data-target="#loginModal">
                    Login
                </a>

                <div class="modal fade " id="loginModal" tabindex="-1" aria-labelledby="loginModalLabel" [attr.aria-hidden]="showLoginForm">
                    <div class="modal-dialog modal-dialog-centered ">
                        <div class="modal-content">
                            <div class="modal-header">
                                <span style="font-size: 1.3em"><b>AvA Login - Welcome!</b></span>
                                <span class="float-right">
                                    <img src="assets/img/logo2.png" height="32em">
                                </span>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <app-loginform (onLoginFailure)="onLoginFailure($event)"></app-loginform>
                            <div class="modal-footer justify-content-center">
                                <div style="text-align:center">
                                    <a href="" id="resetPasswordLink" (click)="onPwdResetStart()" [hidden]="loading" data-dismiss="modal">Help! I forgot my password!</a>
                                </div>
                                <button type="button" class="btn btn-success" style="width: 100%;margin-bottom:2em;" [disabled]="loading" (click)="joinAva()" data-dismiss="modal" >How do I join?</button>
                            </div>                            
                        </div>
                    </div>
                </div>
            </li>
            <li *ngIf="isAuthenticated" class="nav-item">
                <a class="nav-link ava-nav-logout-link" href="#" (click)="onLogoutClick()">Logout </a>
            </li>
        </ul>
    </div>
</nav>