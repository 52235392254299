import { Component, DebugEventListener, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDateStruct, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { FlightModel } from 'src/app/models/flight.model';
import { PirepModel } from 'src/app/models/pirep.model';
import { PirepGetModel } from 'src/app/models/pirepget.model';
import { PirepPostModel } from 'src/app/models/pireppost.model';
import { AuthService } from 'src/app/services/auth.service';
import { DataSharingService } from 'src/app/services/datasharing.service';
import { EquipmentService } from 'src/app/services/equipment.service';
import { PirepService } from 'src/app/services/pirep.service';

@Component({
  selector: 'app-pirep',
  templateUrl: './pirep.component.html',
  styleUrls: ['./pirep.component.css']
})
export class PirepComponent implements OnInit {
  loading: boolean = false;
  isValidDuration: boolean = true;
  isEditing: boolean = true;
  canUpdate: boolean = false;
  isSuccessful: boolean = false;
  isInReview: boolean = false;


  pilotId: number;
  equipmentList = [];
  pirepId: number | undefined = 0;
  pirep: PirepPostModel;
  submitButtonText = 'Submit';

  flightDate: NgbDateStruct;
  depTime: NgbTimeStruct;
  arrTime: NgbTimeStruct;
  flightDuration: NgbTimeStruct = { hour: 0, minute: 0, second: 0 }

  flightDetails: FlightModel;

  @Output() modalMessage: string = "";
  @Output() modalTitle: string = "";
  @Output() modalStatus: string = "";
  @Output() modalPurpose: string = "";
  @Output() showCancelButton: boolean = false;
  @Output() modalName : string = 'pirepModal';
  showMiniClose: boolean = false;

  constructor(private authService: AuthService, private route: ActivatedRoute, private pirepService: PirepService,
    private equipmentService: EquipmentService, private router: Router, private dss: DataSharingService) { }

  initializePirep() {
    this.preLoadDateTimeComponents();
    this.pirep = {
      arrAirport: "",
      arrTime: "",
      depAirport: "",
      depTime: "",
      equipment: 0,
      flightDate: "",
      fuelConsumed: 100,
      passengers: 1,
      route: "",
      cargoWeight: 0,
      comments: "",
      acars: '',
      acarsId: ''
    }
  }

  preLoadDateTimeComponents() {
    //we don't bind to the model times due to the ngDate/Time pickers we use so we must convert back and forth accordingly
    let today = new Date();
    this.flightDate = { year: today.getFullYear(), month: today.getMonth() + 1, day: today.getDate() }
    this.depTime = { hour: 0, minute: 0, second: 0 }
    this.arrTime = { hour: 0, minute: 0, second: 0 }
  }

  ngOnInit(): void {
    //get equipment
    this.loading = true;
    this.pilotId = this.authService.getPilotId();

    this.initializePirep();

    //TODO build out the edit pirep logic, load it up and into the form accordingly.
    this.pirepId = this.route.snapshot.params['pirepId'];

    if (this.pirepId === undefined) {
      this.pirepId = 0;
      this.isEditing = false;
      this.canUpdate = true;
      console.log('new pirep detected');
    } else {
      console.log('editing pirep ' + this.pirepId);
      this.loadExistingPirep();
    }

    //fill in the aircraft dropdown.
    this.equipmentService.get().subscribe(
      (responseData) => {
        this.equipmentList = responseData['data'];
        if (this.pirepId==0) {
          this.loading=false;
        }
      },
      (error) => {
        this.loading = false;
      }
    )
  }

  ngOnDestroy(): void {
    this.dss.isEditingPirep.next(false);
  }
  handlePirepDeleteSuccess(responseData) {
    console.log(responseData);
    this.modalStatus='success';
    this.showMiniClose=true;
    this.modalTitle = 'Delete Pirep Confirmation';
    this.modalMessage = this.pirepId + ' was successfully deleted from your logbook.';
    this.modalPurpose = "confirmation";
    this.dss.showModal.next({modalName : this.modalName, showModal :true});
  }

  handlePirepDeleteFailure(error) {
    console.log(error);
  }

  deletePirep() {
    console.log('delete pirep fired');
    this.pirepService.deletePirep(this.pirepId,this.pilotId).subscribe(
      (responseData) => {
        this.handlePirepDeleteSuccess(responseData);
      },
      (error) => {
        this.handlePirepDeleteFailure(error);
      }
    );

  }
  onModalClosed(returnMessage: {status: string, purpose: string}) {
    this.showCancelButton=false;
    if (returnMessage.purpose == 'delete' && returnMessage.status == 'ok') {
      this.deletePirep();
    } else if (returnMessage.status == 'cancel'){
      console.log('canceled out of the modal');
    } else {
      this.router.navigate(['/pilot', this.pilotId, 'logbook']);
    }
  }

  onDeleteClick() {
    this.modalStatus='info';
    this.showMiniClose=true;
    this.showCancelButton=true;
    this.modalTitle = 'Delete Pirep Confirmation';
    this.modalMessage = 'Are you sure you would like to delete PIREP # ' + this.pirepId + '?';
    this.modalPurpose = "delete";
    this.dss.showModal.next({modalName : this.modalName, showModal :true});
  }

  onCancelClick() {
    this.dss.showModal.next({modalName : this.modalName, showModal :false});
    this.router.navigate(['/pilot', this.pilotId, 'logbook']);
  }

  convertDate(dateToConvert: NgbDateStruct) {
    let convertedDateString = dateToConvert.year + '-' + dateToConvert.month + '-' + dateToConvert.day;
    return convertedDateString;
  }

  convertTime(timeToConvert: NgbTimeStruct) {
    var hourPad = "";
    var minutePad = "";
    if (timeToConvert.hour < 10) {
      hourPad = "0";
    }
    if (timeToConvert.minute < 10) {
      minutePad = "0";
    }
    let convertedTime = hourPad + timeToConvert.hour + ':' + minutePad + timeToConvert.minute;
    return convertedTime;
  }

  prepPirep(pirep: PirepPostModel) {
    //fix dates
    let fdToConvert: NgbDateStruct = { year: pirep.flightDate['year'], month: pirep.flightDate['month'], day: pirep.flightDate['day'] }
    pirep.flightDate = this.convertDate(fdToConvert);

    let timeToConvert: NgbTimeStruct = { hour: pirep.depTime['hour'], minute: pirep.depTime['minute'], second: 0 }
    pirep.depTime = this.convertTime(timeToConvert);
    timeToConvert = { hour: pirep.arrTime['hour'], minute: pirep.arrTime['minute'], second: 0 }
    pirep.arrTime = this.convertTime(timeToConvert);

    console.log(pirep.flightDate, pirep.depTime, pirep.arrTime);

    return pirep;
  }

  postPirep(pirep: PirepPostModel) {
    //open the spinner/loading screen
    this.loading = true;
    this.pirepService.postPirep(pirep, this.pilotId).subscribe(
      (responseData) => {
        //display results dialog
        //TODO make these handlers outside the calling pattern to make this method smaller
        this.modalTitle = 'Pirep submitted successfully!';
        this.modalMessage = 'A new pirep has been added to your logbook: ' + responseData['attributes']['newId'];
        this.modalStatus = "success";
        this.modalPurpose = "confirmation";
        if (responseData.hasOwnProperty("reviewReason")) {
          let modalReason = responseData['reviewReason'];
          if (modalReason.length > 0) {
            this.isInReview = true;
          }
        }
        this.isSuccessful = true;
        this.loading = false;
        this.dss.showModal.next({modalName : this.modalName, showModal :true});
      },
      (err) => {
        this.modalTitle = 'Pirep Submission Failure';
        this.modalMessage = err.message;
        this.modalMessage += '<br/><br/>Please contact our technical channel for assistance.<br/>Error details: ';
        if (err.status != 500) {
          this.modalMessage += '<ul>'
          if (err.error !== null) {
            if (Array.isArray(err.error)) {
              // If array, display each message in bullets
              err.error.forEach(element => {
                this.modalMessage += '<li>' + element + '</li>';
              });
            } else {
              // Just print the data whatever it is
              this.modalMessage += `<li>${err.error}</li>`
            }
          }
          this.modalMessage += '</ul>'
        } else {
          // Parse unhandled exception
          this.modalMessage += `<ul><li>${err.error.exception} occurred on line ${err.error.line} in file ${err.error.file}: ${err.error.message}</li></ul>`;
        }

		    this.modalStatus = 'danger';
        this.modalPurpose = "error";
        this.loading = false;
        this.isSuccessful = false;
        this.dss.showModal.next({modalName : this.modalName, showModal :true});
      }
    );
  }

  handlePirepUpdateSuccess(responseData) {
    //display results dialog
    this.modalTitle = 'Pirep updated successfully!';
    this.modalMessage = 'Pirep #' + this.pirepId + ' successfully updated!';
    this.modalStatus = "success";

    this.isSuccessful = true;
    this.loading = false;
    this.dss.showModal.next({modalName : this.modalName, showModal :true});
  }

  handlePirepUpdateFailure(error) {
    console.log(error.message);

    //auto reject
    if (error.status == 424) {
      this.modalTitle = 'Pirep Submission Failure';
      this.modalMessage = 'We are sorry but your pirep submission failed.<br/> Errors: <ul>';
      console.log(error.error);
      this.modalMessage += '<li>' + error.error.message + '</li>';
      this.modalMessage += '<li>Please contact our technical support via our Discord channel.  We apologize for the inconvenience.</li>';
      this.modalMessage += '</ul>';
    } else if (error.status == 500) {
      this.modalTitle = 'Pirep Submission Issue';
      this.modalMessage = 'We are sorry but your pirep had an issue during submission.  Your pirep may have been successfully added.  <br/> Issue: <ul>';
      console.log(error.message);
      this.modalMessage += '<li>' + error.message + '</li>';
      this.modalMessage += '<li>' + error.error.exception + '</li>';
      this.modalMessage += '<li>' + error.error.line + '</li>';
      this.modalMessage += '<li>' + error.error.file + '</li>';
      this.modalMessage += '<li>Please contact our technical support via Discord.  We apologize for the inconvenience.</li>';
      this.modalMessage += '</ul>';
    } else {
      this.modalMessage += '<li>' + error.message + '</li>';
      this.modalMessage += '</ul>';
    }

    console.log(this.modalMessage);
    this.modalStatus = 'danger';
    this.loading = false;
    this.isSuccessful = false;
    this.dss.showModal.next({modalName : this.modalName, showModal :true});
  }
  putPirep(pirep) {
    //open the spinner/loading screen
    this.loading = true;
    this.pirepService.updatePirep(pirep, this.pilotId,this.pirepId).subscribe(
      (responseData) => {
        this.handlePirepUpdateSuccess(responseData);
      },
      (error) => {
        this.handlePirepUpdateFailure(error);
      }
    );
  }

  onSubmit(pirep: PirepPostModel) {
    this.modalPurpose = "update";
    //check that the duration is valid before moving on
    if (this.flightDuration.hour > 0 || this.flightDuration.minute > 0) {
      this.isValidDuration = true;
    } else {
      this.isValidDuration = false;
    }

    pirep = this.prepPirep(pirep);
    if (!this.isEditing) {
      this.postPirep(pirep);
    } else {
      this.putPirep(pirep);
    }
    console.log(pirep);
  }

  calculateDuration() {
    var depTime = this.depTime;
    var tempArrTime = this.arrTime.hour;

    if (tempArrTime < depTime.hour) {
      console.log("converting arrival time");
      var hours = tempArrTime;
      tempArrTime = hours + 24;
    }
    let arrivalTime = new Date(0, 1, 1, tempArrTime, this.arrTime.minute);
    let departureTime = new Date(0, 1, 1, depTime.hour, depTime.minute);

    var timeDiff = Math.abs(arrivalTime.valueOf() - departureTime.valueOf());
    var midNight = new Date().setHours(0, 0, 0, 0);
    var duration = new Date(midNight + timeDiff);
    this.flightDuration = { hour: duration.getHours(), minute: duration.getMinutes(), second: 0 }
    if (this.flightDuration.hour > 0 || this.flightDuration.minute > 0) {
      this.isValidDuration = true;
    } else {
      this.isValidDuration = false;
    }
    console.log(this.flightDuration);
  }

  onDateTimeChange() {
    this.calculateDuration();
  }

  convertGetTimes() {
    let flightDateArray = this.pirep.flightDate.split('-');
    let depTimeArray = this.pirep.depTime.split(':');
    let arrTimeArray = this.pirep.arrTime.split(':');
    this.flightDate = { year: parseInt(flightDateArray[0]), month: parseInt(flightDateArray[1]), day: parseInt(flightDateArray[2]) }
    this.depTime = { hour: parseInt(depTimeArray[0]), minute: parseInt(depTimeArray[1]), second: 0 }
    this.arrTime = { hour: parseInt(arrTimeArray[0]), minute: parseInt(arrTimeArray[1]), second: 0 }
    console.log(this.flightDate, this.depTime, this.arrTime);
  }

  loadFlightDetails(flightData: PirepModel) {
    this.flightDetails = {
      arrivalAirportInfo : flightData.arrivalAirportInfo,
      departureAirportInfo : flightData.departureAirportInfo,
      arrivalTime : flightData.arrTime,
      departureTime : flightData.depTime,
      ofrs : flightData.ofrs,
    }
    console.log(this.flightDetails);
  }
  loadExistingPirep() {
    //open the spinner/loading screen
    this.loading = true;
    this.pirepService.getPirep(this.pirepId).subscribe(
      (responseData: PirepGetModel) => {
        //load the pirep ngModel for display
        this.pirep = responseData.pirep;
        this.loadFlightDetails(responseData.pirep);
        this.convertGetTimes();
        this.calculateDuration();

        //set the isEditable flag depending on if it has an ACARS associated with it.
        if (this.pirep.acars.length == 0) {
          this.isEditing = true;
          this.submitButtonText = 'Update';
          this.canUpdate=true;
        } else {
          this.canUpdate=false;
        }
        this.isSuccessful = true;
        this.loading = false;
        this.dss.isEditingPirep.next(true);
      },
      (error) => {
        console.log(error.message);
        this.loading = false;
        this.isSuccessful = false;
      }
    )
  }
}
