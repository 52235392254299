import { HttpErrorResponse, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { tap } from "rxjs/operators";
import { AuthService } from "src/app/services/auth.service";

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
    accessDeniedEvent = new EventEmitter<string>();

    constructor(private authService: AuthService, private router: Router) { };

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        let token = this.authService.getToken();
        const modifiedRequest = req.clone({ headers: req.headers.append('authorization', 'Bearer ' + token) });
        //return next.handle(modifiedRequest);
        return next.handle(modifiedRequest).pipe(tap(() => { },
            (err: any) => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status !== 401 && err.status !== 498) {
                        return;
                    }
                    this.authService.invalidateToken();
                    // this.authService.logOut();
                    this.router.navigate(['/']);
                }
            })
        );
    }

}