import { Component, OnInit, Output } from '@angular/core';
import { EmailGroup } from 'src/app/models/emailgroup.model';
import { PilotEmailAddress } from 'src/app/models/pilotemailaddress.model';
import { EmailStaffDetailModel } from 'src/app/models/staffdetail.model';
import { AuthService } from 'src/app/services/auth.service';
import { CommService } from 'src/app/services/comm.service';
import { DataSharingService } from 'src/app/services/datasharing.service';

@Component({
  selector: 'app-mass-mail',
  templateUrl: './mass-mail.component.html',
  styleUrls: ['./mass-mail.component.css']
})
export class MassMailComponent implements OnInit {
  staffDetail : EmailStaffDetailModel;
  loading : boolean = false;
  pilotId : number = 0;
  hasPickedToOption : boolean = false;
  toOption;
  isSingleMember: boolean;
  pilots : PilotEmailAddress[] = [];
  toPilots : PilotEmailAddress[] = [];
  isAddressesLoaded: boolean;
  pilotToAdd : PilotEmailAddress;

  emailGroups : EmailGroup[] = [];
  isGroupsLoaded: boolean;
  isGroups: boolean;
  groupToAdd : EmailGroup;
  toGroups: EmailGroup[] = [];

  @Output() modalMessage: string = "";
  @Output() modalTitle: string = "";
  @Output() modalStatus: string = "";
  @Output() modalPurpose: string = "emailSentStatus";
  @Output() showCancelButton: boolean = false;
  @Output() showMiniClose: boolean = false;
  @Output() modalName : string = 'emailModal';

  constructor(private commService : CommService, private authService : AuthService, private dss : DataSharingService) { }

  ngOnInit(): void {
    this.staffDetail;
    this.loading=true;
    this.pilots = [];
    this.toPilots = [];
    this.hasPickedToOption=false;
    this.toOption='';
    this.isSingleMember=false;
    this.isAddressesLoaded=false;
    this.pilotToAdd;
    this.isGroups = false;
    this.isGroupsLoaded = false;
    this.emailGroups = [];
    this.toGroups = [];

    if (this.authService.isAuthenticated()) {
      this.pilotId = this.authService.getPilotId();
      this.commService.getPilotEmailAddress().subscribe(
        (responseData) => {
          console.log(responseData);
          this.staffDetail=responseData;
          this.loading=false;
        },
        (error) => {
          this.loading=false;
        }
      );
    }
  }

  onSubmit(emailForm) {
    this.loading=true;
    console.log(emailForm);
    var groups=[],emailData={from:this.pilotId,to:'',subject:emailForm.txtSubject,body:emailForm.txtBody};
    console.log(emailData);

    switch(this.toOption.name) {
      case 'Selected Members' :
        for(var i=0;i<this.toPilots.length;i++) {
          emailData.to = emailData.to + this.toPilots[i].id;
          if (i+1<this.toPilots.length) {emailData.to = emailData.to + ',';}
        }
        this.commService.postSingleMemberEmail(emailData).subscribe(
          (responseData) => {
            console.log(responseData);
            this.showSuccessModal(responseData);
            this.loading=false;
          },
          (error) => {
            this.showFailureModal(error);
            this.loading=false;
          }
        );
        break;
      case 'Selected Groups' :
        for(var i=0;i<this.toGroups.length;i++) {
          emailData.to = emailData.to + this.toGroups[i].attributes.groupId;
          if (i+1<this.toGroups.length) {emailData.to = emailData.to + ',';}
        }
        this.commService.postGroupEmail(emailData).subscribe(
          (responseData) => {
            console.log(responseData);
            this.showSuccessModal(responseData);
            this.loading=false;
          },
          (error) => {
            this.showFailureModal(error);
            this.loading=false;
          }
        );
        break;
    }
  }

  onModalClosed(returnMessage: {status: string, purpose: string}) {
    if (returnMessage.purpose == 'emailSentStatus' && returnMessage.status == 'ok') {
      this.ngOnInit();
    }
  }

  showSuccessModal(data) {
    this.modalStatus='success';
    this.showMiniClose=false;
    this.showCancelButton=false;
    this.modalTitle = 'Email Sent';
    this.modalMessage = 'Your email has been queued and will process during the next 5 minute queue processing.  Email #:' + data['newId'];
    this.modalPurpose = "emailSentStatus";
    this.dss.showModal.next({modalName : this.modalName, showModal :true});
  }

  showFailureModal(error) {
    this.modalStatus='danger';
    this.showMiniClose=false;
    this.showCancelButton=false;
    this.modalTitle = 'Email Failed';
    this.modalMessage = 'Your Email request failed to be processed with the following error:<br/>';
    if (error.error.hasOwnProperty("messages")) {
      for (const key in error.error.messages) {
        this.modalMessage += '<br/>' + error.error.messages;
      }
    } else {
      this.modalMessage += '<br/>' + error.message + ' == ' + error.status;
    }
    console.log(error.message + ' == ' + error.status);
    this.modalPurpose = "emailSentStatus";
    this.dss.showModal.next({modalName : this.modalName, showModal :true});
  }

  pickToOption(toOption) {
    console.log(toOption);
    this.toOption=toOption;
    this.hasPickedToOption=true;
    switch (toOption.name) {
      case 'Selected Members' :
        this.isSingleMember=true;
        this.commService.getActivePilotEmails().subscribe(
          (responseData) => {
            this.pilots = responseData;
            this.isAddressesLoaded=true;
            this.loading=false;
          },
          (error) => {
            this.loading=false;
          }
        );
        break;
      case 'Selected Groups' :
        this.isGroups=true;
        this.commService.getGroupEmails().subscribe(
          (responseData) => {
            this.emailGroups = responseData;
            console.log(this.emailGroups);
            this.isGroupsLoaded=true;
            this.loading=false;
          },
          (error) => {
            this.loading=false;
          }
        );
        break;
    }
  }

  addMemberToList(memberToAdd) {
    this.pilotToAdd = this.pilots[memberToAdd];
    if (this.toPilots.filter( toPilot => {
      return toPilot.id === this.pilotToAdd.id;
    }).length<1) {
      console.log('adding...');
      this.toPilots.push(this.pilots[memberToAdd]);
    } else {
      console.log('already in the list');
    }

    console.log(this.toPilots);
  }

  removeToPilot(pilotToRemove) {
    console.log(pilotToRemove);
    this.toPilots.splice(pilotToRemove,1);
    console.log(this.toPilots);
    return false;
  }

  addGroupToList(groupToAdd) {
    this.groupToAdd = this.emailGroups[groupToAdd];
    if (this.toGroups.filter( toGroup => {
      return toGroup.id === this.groupToAdd.id;
    }).length<1) {
      console.log('adding...');
      this.toGroups.push(this.emailGroups[groupToAdd]);
    } else {
      console.log('already in the list');
    }

    console.log(this.toGroups);
  }

  removeToGroup(groupToRemove) {
    console.log(groupToRemove);
    this.toGroups.splice(groupToRemove,1);
    return false;
  }
}
