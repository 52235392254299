<div class="container mt-2 mb-3">
    <div class="row">
        <div class="col-md-12">
            <div class="card shadow rounded-top rounded-lg">
                <div class="card-header panel-american-coral">
                    <div class="row">
                        <div class="col-6 mt-2">
                            Manage Transfers
                        </div>
                        <div class="col-6 justify-content-right">
                            <a [routerLink]="['/managehrtransfer']" class="nav-link float-right btn btn-primary"
                                style="font-size: .75em;" *ngIf="isVPorHigher">
                                Initiate Transfer
                            </a>
                        </div>
                    </div>
                </div>
                <div class="card-body" *ngIf="!loading && hasInboundRequests">
                    <div class="col-md-12">
                        <div class="card shadow rounded-top rounded-lg">
                            <div class="card-header bg-success">
                                <div class="row">
                                    <div class="col-md-12">
                                        Inbound Transfer Requests
                                    </div>
                                </div>
                            </div>
                            <div class="card-body ">
                                <div class="row mt-2 " *ngFor="let transferRequest of inboundRequests; let i = index;">
                                    <div class="col-md-12">
                                        <app-transferrequestrow [transferRequest]="transferRequest"
                                            (onTransferRequestComplete)="onTransferRequestComplete($event)">
                                        </app-transferrequestrow>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body" *ngIf="!loading && hasOutboundRequests">
                    <div class="col-md-12">
                        <div class="card shadow rounded-top rounded-lg">
                            <div class="card-header bg-warning">
                                <div class="row">
                                    <div class="col-md-12">
                                        Outbound Transfer Requests
                                    </div>
                                </div>
                            </div>
                            <div class="card-body ">
                                <div class="row mt-2 " *ngFor="let transferRequest of outboundRequests; let i = index;">
                                    <div class="col-md-12">
                                        <app-transferrequestrow [transferRequest]="transferRequest"
                                            (onTransferRequestComplete)="onTransferRequestComplete($event)">
                                        </app-transferrequestrow>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body" *ngIf="!loading && hasTransferRequests">
                    <div class="col-md-12">
                        <div class="card shadow rounded-top rounded-lg">
                            <div class="card-header bg-warning">
                                <div class="row">
                                    <div class="col-md-12">
                                        Transfer Requests
                                    </div>
                                </div>
                            </div>
                            <div class="card-body ">
                                <div class="row mt-2 " *ngFor="let transferRequest of transferRequests; let i = index;">
                                    <div class="col-md-12">
                                        <app-transferrequestrow [transferRequest]="transferRequest"
                                            (onTransferRequestComplete)="onTransferRequestComplete($event)">
                                        </app-transferrequestrow>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
                <div class="card-body " *ngIf="!loading && !hasTransferRequests && !hasInboundRequests && !hasOutboundRequests">
                    <div class="row mt-2" >
                        <div class="col-md-12">
                            <span>There are no active Transfer requests at this time.</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<span *ngIf="loading">
    <div class="d-flex justify-content-center " style="margin-top: 10em;margin-bottom: 10em;">
        <div class="spinner-border text-primary" style="width: 6rem; height: 6rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</span>