<div class="container mt-2 mb-3">
    <div class="row">
        <div class="col-md-12">
            <div class="card shadow rounded-top rounded-lg">
                <div class="card-header panel-american-coral">
                    <div class="row">
                        <div class="col-12 mt-2">
                            Reviewable Pireps
                        </div>
                    </div>
                </div>
                <div class="card-body" *ngIf="!loading ">
                    <div class="row" *ngIf="!hasPireps">
                        <div class="col-sm-12">
                            <p>I'm sorry but there are no PIREPs to review at this time.</p>
                        </div>
                    </div>
                    <div *ngIf="hasPireps">
                        <div class="row" *ngFor="let pirep of reviewablePireps; let i=index">
                            <app-pireptoreview [pirep]="pirep" (onAcceptReject)="onAcceptReject($event)"></app-pireptoreview>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<span *ngIf="loading">
    <div class="d-flex justify-content-center " style="margin-top: 10em;margin-bottom: 10em;">
        <div class="spinner-border text-primary" style="width: 6rem; height: 6rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</span>

<!-- Reject Modal with Reason Modal -->
<div class="modal fade " id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="{{displayStatus}}" *ngIf="hasPirepToReject" >
    <div class="modal-dialog  modal-dialog-centered ">
        <div class="modal-content bg-danger">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Are you sure?</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <form name="rejectPirepForm" class="css-form" #rejectPirepForm="ngForm">
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <p>Are you sure you would like to REJECT this pirep? If so, please provide a reason.</p>
                                <label for="txtSpecificReason">Specific Reason:</label>
                                <input type="text" class="form-control" id="txtSpecificReason" name="txtSpecificReason" ngModel #txtSpecificReason="ngModel" minlength="5" maxlength="50" pattern="^[\w\-,.?#!()\s]+$" required />
                                <div class="alert alert-danger" *ngIf="txtSpecificReason.invalid && (txtSpecificReason.dirty || txtSpecificReason.touched)">
                                    <div *ngIf="txtSpecificReason.errors?.required">
                                        You must enter a reason.
                                    </div>
                                    <div *ngIf="txtSpecificReason.errors?.minlength || txtSpecificReason.errors?.maxlength || txtSpecificReason.errors?.pattern">
                                        Reason is invalid: must be 5 to 50 alphanumeric characters plus -,.?#!()
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-light" data-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-warning" data-dismiss="modal" (click)="executePirepReject(rejectPirepForm.value)" [disabled]="rejectPirepForm.invalid">Reject Pirep</button>
                </div>
            </form>
        </div>
    </div>
</div>