import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EquipmentService {

  EQUIPMENT_SVC = '/equipment';

  constructor(private httpClient: HttpClient) { }

  get() {
    let url = environment.API_URL + this.EQUIPMENT_SVC;
    return this.httpClient.get(url).pipe(map(
      (data) => {
        return data;
      }
    ))
  }
}
