export class EventModel {
    attributes : {
        bannerUrl : string;
        description : string;
        duration : number;
        eventId : number;
        name : string;
        startDate : string;
        startTime : string;
        trackingTag : string;
        url : string;
    }
}