import { not } from '@angular/compiler/src/output/output_ast';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbDateStruct, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { NotamModel } from 'src/app/models/notam.model';
import { DataSharingService } from 'src/app/services/datasharing.service';
import { NotamService } from 'src/app/services/notam.service';

@Component({
  selector: 'app-notamdetail',
  templateUrl: './notamdetail.component.html',
  styleUrls: ['./notamdetail.component.css']
})
export class NotamdetailComponent implements OnInit {
  showCancel: boolean = false;
  showDelete: boolean = false;
  loading: boolean = false;
  isVisible : boolean = false;
  hasDateError : boolean = false;

  @Output() onNotamUpdateComplete = new EventEmitter<string>();
  @Input() notam: NotamModel;
  @Input() isNew: boolean;

  startDate: NgbDateStruct;
  endDate: NgbDateStruct;
  notamIdToDelete : number;

  @Output() modalMessage: string = "";
  @Output() modalTitle: string = "";
  @Output() modalStatus: string = "";
  @Output() modalPurpose: string = "";
  @Output() showCancelButton: boolean = false;
  @Output() modalName : string;
  showMiniClose: boolean = false;


  constructor(private notamService: NotamService, private router: Router, private dss : DataSharingService) { }

  ngOnInit(): void {
    this.modalName = 'notamDeleteConfirmationModal' + this.notam.notamId;
    console.log(this.notam);
    console.log(this.isNew);

    this.showCancel = false;
    this.showDelete = true;
    this.hasDateError = false;

    if (this.isNew) {
      this.showCancel = true;
      this.showDelete = false;
    }

    let startDateNumber = new Date(this.notam.startDate );
    let endDateNumber = new Date(this.notam.endDate );

    console.log('End Date: ' + endDateNumber.valueOf());
    console.log('Now: ' + Date.now());

    this.isVisible = this.notam.isActive;

    this.startDate = { year: startDateNumber.getFullYear(), month: startDateNumber.getMonth() + 1, day: startDateNumber.getDate() }
    this.endDate = { year: endDateNumber.getFullYear(), month: endDateNumber.getMonth() + 1, day: endDateNumber.getDate() }
  }

  recalcVis(newStartDate,newEndDate) {
    console.log(newStartDate, newEndDate);
    let convertedStartDate = new Date(this.convertDate(newStartDate));
    let convertedEndDate = new Date(this.convertDate(newEndDate));
    let rightNow = Date.now().valueOf();

    console.log(convertedStartDate.valueOf(), convertedEndDate.valueOf(), rightNow);

    // this.isVisible = ((convertedStartDate.valueOf()) < (rightNow) < (convertedEndDate.valueOf())) ? true : false;

    this.isVisible = ((convertedStartDate.valueOf() < rightNow) && (rightNow < convertedEndDate.valueOf())) ? true : false;
    this.hasDateError = (convertedEndDate.valueOf() < convertedStartDate.valueOf()) ? true : false;

    console.log(this.isVisible);

  }

  convertDate(dateToConvert: NgbDateStruct) {
    let convertedDateString = dateToConvert.year + '-' + dateToConvert.month + '-' + dateToConvert.day;
    return convertedDateString;
  }

  onSubmit(notamForm) {
    console.log(notamForm);

    let updatedNotam = {
      title : notamForm.txtTitle,
      message : notamForm.txtMessage,
      startDate : this.convertDate(notamForm.startDate),
      endDate : this.convertDate(notamForm.endDate),
      visibility : notamForm.rdoVis
    }

    this.hasDateError=false;
    if (updatedNotam.startDate>updatedNotam.endDate) {
      console.warn('start after end or end before start!');
      this.hasDateError = true;
    } else {
      console.log(updatedNotam);

      //open the spinner/loading screen
      this.loading = true;

      if (this.isNew) {
        this.postEvent(updatedNotam);
      } else {
        this.putEvent(updatedNotam);
      }
    }
  }

  postEvent(newNotam) {
    this.notamService.postNotam(newNotam).subscribe(
      (responseData) => {
        this.loading = false;
        //emit event
        this.onNotamUpdateComplete.emit("notamUpdated");
      },
      (error) => {
        console.log(error.message);
        this.router.navigate(['techerror']);
      }
    );
  }

  putEvent(updatedNotam) {
    this.notamService.putNotam(updatedNotam, this.notam.notamId).subscribe(
      (responseData) => {
        this.loading = false;
        //emit event
        this.onNotamUpdateComplete.emit("notamUpdated");
      },
      (error) => {
        console.log(error.message);
        this.router.navigate(['techerror']);
      }
    );
  }
  onDeleteClick() {
    this.notamIdToDelete=this.notam.notamId;
    this.modalStatus='danger';
    this.showMiniClose=true;
    this.showCancelButton=true;
    this.modalTitle = 'Delete NOTAM Confirmation';
    this.modalMessage = 'Are you sure you would like to delete NOTAM # ' + this.notam.notamId + ' : <br/> ' + this.notam.title + '?';
    this.modalPurpose = "deletenotam";
    this.dss.showModal.next({modalName : this.modalName, showModal :true});
  }

  onModalClosed(returnMessage: {status: string, purpose: string}) {
    this.showCancelButton=false;
    if (returnMessage.purpose == 'deletenotam' && returnMessage.status == 'ok') {
      this.executeDeleteNotam();
    } else if (returnMessage.status == 'cancel'){
      console.log('canceled out of the modal');
    }
    this.dss.showModal.next({modalName : this.modalName, showModal :false});
  }

  cancelNotamAdd() {
    this.onNotamUpdateComplete.emit("notamAddCancelled");
  }

  executeDeleteNotam() {
    this.notamService.deleteEvent(this.notamIdToDelete).subscribe(
      (responseData) => {
        this.loading = false;
        //emit event
        this.onNotamUpdateComplete.emit("notamDeleteSuccess");
      },
      (error) => {
        console.log(error.message);
        this.router.navigate(['techerror']);
      }
    );
  }
}
