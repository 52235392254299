import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AvAStatModel } from 'src/app/models/avastat.model';
import { StatsService } from 'src/app/services/stats.service';

@Component({
  selector: 'app-goalsummary',
  templateUrl: './goalsummary.component.html',
  styleUrls: ['./goalsummary.component.css']
})
export class GoalsummaryComponent implements OnInit {
  hoursStats: AvAStatModel[] = [];
  goalHours : number = 0;
  totalHours : number = 0;
  remainingHours : number = 0;
  goalHoursPercentage : number = 0;
  hasGoals : boolean = false;


  constructor(private router: Router, private statsService: StatsService) { }

  ngOnInit(): void {
    this.hasGoals = false;
    this.loadHoursStats();
  }

  loadHoursStats() {
    this.statsService.getHourStats().subscribe(
      (responseData: AvAStatModel[]) => {
        console.log(responseData)
        this.hoursStats = responseData;
        this.calcGoalNumbers();
      },
      (error) => {
        if (error.status = 404) {
        } else {
          this.router.navigate(['/techerror']);
        }
      }
    );
  }

  calcGoalNumbers() {
    this.totalHours = this.hoursStats['monthlyHoursByHub'].totalHours;
    this.goalHours = this.hoursStats['monthlyHoursByHub'].goal;
    this.remainingHours = this.goalHours-this.totalHours;
    // this.goalHoursPercentage = 100*(parseInt(this.totalHours)/parseInt(this.goalHours));
    this.goalHoursPercentage = Math.round(100*(this.totalHours/this.goalHours));
    console.log(this.goalHours, this.totalHours, this.remainingHours, this.goalHoursPercentage);


    if (this.goalHours!=0) {this.hasGoals=true;}
  }
}

