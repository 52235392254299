<div class="row">
    <div class="col-12 align-self-end float-right">
        <div class="btn-group btn-group-sm float-right mb-2" role="group" aria-label="Other hub Rosters to chose">
            <ng-container *ngFor="let hub of hubLinks; let i=index">
                <button type="button" class="btn btn-light" style="border-color: silver;" [ngClass]="{'active' : hubId==i+1}"
                    *ngIf="hub.hubId != hubToLoad" (click)="onChangeHub(hub.hubId)">
                    {{hub.shortName}}
                </button>
            </ng-container>
        </div>
    </div>
</div>
