<div class="card shadow rounded-top rounded-lg " style="margin: 5px 10px 30px 10px">
    <div class="card-header panel-american-blue">
        <div class="row">
            <div class="col-md-12 col-xs-12">
                Communications
            </div>
        </div>
    </div>
    <div class="card-body ">
        <div class="row">
            <div class="col-sm-12">
                <h3>Discord Server</h3>
                <div class="well">
                    <div class="row">
                        <div class="col-sm-12">
                            We have an active Discord server located <a href="https://discord.gg/aXb54GW3Zu"
                                target="_blank">HERE.</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>