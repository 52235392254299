<div class="container mt-2 mb-3">
    <div class="row">
        <div class="col-md-12">
            <div class="card shadow rounded-top rounded-lg">
                <div class="card-header panel-american-coral">
                    <div class="row">
                        <div class="col-6">
                            Member Search
                        </div>
                    </div>
                </div>
                <div class="card-body" *ngIf="!loading ">
                    <div class="col-md-12">
                        <form name="memberSearchForm" (ngSubmit)="onSubmit(memberSearchForm.value)" #memberSearchForm="ngForm" *ngIf="!loading">
                            <div class="row">
                                <div class="col-md-12">
                                    <label for="searchCriteria">Enter callsign, firstname, lastname, e-mail address or Vatsim ID:</label>
                                    <input type="text" class="form-control" id="searchCriteria" name="searchCriteria" #searchCriteria="ngModel"  ngModel
                                        minlength="2" maxlength="50" pattern="^[ A-Za-z0-9_@./#&+-]*$"
                                        required
                                    />
                                    <div class="alert alert-danger" *ngIf="searchCriteria.invalid && (searchCriteria.dirty || searchCriteria.touched)">
                                        <div *ngIf="searchCriteria.errors?.required">
                                            You must enter a valid search criteria.
                                        </div>
                                        <div *ngIf="searchCriteria.errors?.minlength || searchCriteria.errors?.maxlength || searchCriteria.errors?.pattern">
                                            Criteria is invalid: must be 2 to 50 alphanumeric characters
                                        </div>
                                    </div>
                                    {{searchCriteria.errors?.minlength}}
                                </div>
                            </div>
                            <br/>
                            <div class="row" style="padding-top:10px" >
                                <div class="col-md-12 d-flex justify-content-end">
                                    <button type="submit" class="btn btn-warning" [disabled]="!memberSearchForm.valid || (!memberSearchForm.touched && !memberSearchForm.dirty)" >
                                        <i class="fas fa-search"></i> Search
                                    </button>
                                </div>                                
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<span *ngIf="loading">
    <div class="d-flex justify-content-center " style="margin-top: 10em;margin-bottom: 10em;">
        <div class="spinner-border text-primary" style="width: 6rem; height: 6rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</span>