import { ThrowStmt } from '@angular/compiler';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { BookedFlightModel } from 'src/app/models/bookedflight.model';
import { AuthService } from 'src/app/services/auth.service';
import { DataSharingService } from 'src/app/services/datasharing.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { PilotService } from 'src/app/services/pilot.service';
import { textChangeRangeIsUnchanged } from 'typescript';

@Component({
  selector: 'app-bookedflights',
  templateUrl: './bookedflights.component.html',
  styleUrls: ['./bookedflights.component.css']
})
export class BookedflightsComponent implements OnInit {
  @Output() onDeleteComplete = new EventEmitter<string>();
  loading: boolean = false;
  hasBookings : boolean = false;
  hasSmartCarsBookings : boolean = false;
  hasXacarsBookings : boolean = false;

  smartCarsFlights: BookedFlightModel[] = [];
  xacarsFlights: any[] = [];

  scheduleIdToDelete : number = 0;
  acarsToDeleteFrom : string = 'smartCars';

  pilotId : number = 0;
  pilotDetails : any;


  constructor(private dispatcherService: DispatcherService, private dss : DataSharingService, private router : Router, private authService : AuthService,
              private pilotService : PilotService) { }

  @Output() modalMessage: string = "";
  @Output() modalTitle: string = "";
  @Output() modalStatus: string = "";
  @Output() modalPurpose: string = "";
  @Output() showCancelButton: boolean = true;
  @Output() modalName : string = 'bookedFlightsModal';

  showMiniClose: boolean = false;

  ngOnInit(): void {
    this.smartCarsFlights=[];
    this.xacarsFlights=[];
    this.hasBookings=false;
    this.hasXacarsBookings=false;
    this.hasSmartCarsBookings=false;

    this.loadPilot();
    this.loadBookings();
  }
  loadBookings() {
    this.dispatcherService.getBookings().subscribe(
      (responseData) => {
        for (const key in responseData['smartCarsFlights']) {
          if (responseData['smartCarsFlights'][key].hasOwnProperty('attributes')) {
            //TODO move to the service or external methods
            let booking : BookedFlightModel = {
              id : responseData['smartCarsFlights'][key]['id'],
              aircraft : responseData['smartCarsFlights'][key]['attributes']['aircraft'],
              arrTime : responseData['smartCarsFlights'][key]['attributes']['arrTime'],
              cruise : responseData['smartCarsFlights'][key]['attributes']['cruise'],
              depAirportDetails : responseData['smartCarsFlights'][key]['attributes']['depAirportDetails'],
              depTime : responseData['smartCarsFlights'][key]['attributes']['depTime'],
              destAirportDetails : responseData['smartCarsFlights'][key]['attributes']['destAirportDetails'],
              duration : responseData['smartCarsFlights'][key]['attributes']['duration'],
              route : responseData['smartCarsFlights'][key]['attributes']['route']
            }

            this.smartCarsFlights.push(booking);
          }
        }
        if (this.smartCarsFlights.length>0) {
          this.hasSmartCarsBookings = true;
        }
        for (const key in responseData['xacarsFlights']) {
          if (responseData['xacarsFlights'][key].hasOwnProperty('attributes')) {
            let booking : BookedFlightModel = {
              id : responseData['xacarsFlights'][key]['id'],
              aircraft : responseData['xacarsFlights'][key]['attributes']['aircraft'],
              arrTime : responseData['xacarsFlights'][key]['attributes']['arrTime'],
              cruise : responseData['xacarsFlights'][key]['attributes']['cruise'],
              depAirportDetails : responseData['xacarsFlights'][key]['attributes']['depAirportDetails'],
              depTime : responseData['xacarsFlights'][key]['attributes']['depTime'],
              destAirportDetails : responseData['xacarsFlights'][key]['attributes']['destAirportDetails'],
              duration : responseData['xacarsFlights'][key]['attributes']['duration'],
              route : responseData['xacarsFlights'][key]['attributes']['route']
            }

            this.xacarsFlights.push(booking);
          }
        }
        if (this.xacarsFlights.length>0) {
          this.hasXacarsBookings=true;
        }

        console.log(this.smartCarsFlights);
        console.log(this.xacarsFlights);
        if (this.hasSmartCarsBookings || this.hasXacarsBookings) {
          this.hasBookings = true;
        }
        this.loading = false;
      },
      (error) => {
        //degugger;
        this.loading = false;
      }
    )
  }
  showDeleteConfirmation(acars,id) {
    console.log(acars, id);

    this.scheduleIdToDelete = id;
    this.acarsToDeleteFrom = acars;
    this.modalStatus='warning';
    this.showMiniClose=true;
    this.modalTitle = 'Are you sure??';
    this.modalMessage = 'You would like me to DELETE Booking: ' + id + ' from the ' + acars + ' ACARS system.';
    this.modalPurpose = "confirmation";
    this.dss.showModal.next({modalName : this.modalName, showModal :true});
  }

  private deleteBooking() {
    this.dispatcherService.deleteBooking(this.acarsToDeleteFrom, this.scheduleIdToDelete).subscribe(
      (responseData) => {
        this.loading = false;
        this.ngOnInit();
      },
      (error) => {
        //degugger;
        console.log(error.message);
        this.router.navigate(['/techerror']);
        this.loading = false;
      }
    )
  }

  loadPilot() {
    this.pilotId = this.authService.getPilotId();

    this.pilotService.get(this.pilotId).subscribe(
      (responseData) => {
        this.pilotDetails = responseData;
        this.pilotDetails.callSign = responseData.callSign.substr(3);
        console.log(this.pilotDetails);
      },
      (error) => {
        this.loading=false;
      }
    )
  }
  onModalClosed(returnMessage: {status: string, purpose: string}) {
    if (returnMessage.purpose == 'confirmation' && returnMessage.status == 'ok') {
      this.deleteBooking();
    } else if (returnMessage.status == 'cancel'){
      console.log('canceled out of the modal');
    }

    this.dss.showModal.next({modalName : this.modalName, showModal :false});
  }
}
