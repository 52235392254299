import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ChartOptions, ChartType } from 'chart.js';
import { Color, Label, SingleDataSet } from 'ng2-charts';
import { HubHealthModel } from 'src/app/models/hubhealth.model';
import { HubRosterDetailsModel } from 'src/app/models/hubrosterdetails.model';
import { PilotDetailsModel } from 'src/app/models/pilotdetails.model';
import { PilotRosterModel } from 'src/app/models/pilotroster.model';
import { AuthService } from 'src/app/services/auth.service';
import { HubService } from 'src/app/services/hub.service';
import { HubRosterService } from 'src/app/services/hubroster.service';
import { PilotService } from 'src/app/services/pilot.service';

@Component({
  selector: 'app-hrroster',
  templateUrl: './hrroster.component.html',
  styleUrls: ['./hrroster.component.css']
})
export class HrrosterComponent implements OnInit {
  loading: boolean = false;
  hubIdToLoad: number = 1;
  pilot: PilotDetailsModel;
  hasPilotLoaded: boolean = false;
  firstTime: boolean = true;

  hubHealthInfo: HubHealthModel;
  hasHubHealthInfo: boolean = false;

  hasHubRoster: boolean = true;
  hubRoster : PilotRosterModel[] = [];
  hubDetails : HubRosterDetailsModel;

  isHM: boolean = true;

  constructor(private pilotService: PilotService, private authService: AuthService, private router: Router, private hubService: HubService, private rosterService : HubRosterService) { }

  ngOnInit(): void {
    console.log();
    this.hasHubHealthInfo = false;
    this.hasPilotLoaded = false;
    this.pilot = null;
    this.hubHealthInfo = null;
    this.hubRoster = [];
    this.hasHubRoster = false;
    this.loadPilot();
  }

  loadPilot() {
    this.loading = true;
    if (this.authService.isAuthenticated()) {
      let pilotId = this.authService.getPilotId();
      this.pilotService.getDetails(pilotId).subscribe(
        (pilot: PilotDetailsModel) => {
          console.log(pilot);
          this.pilot = pilot;
          this.hubIdToLoad = this.pilot.domicile;
          this.hasPilotLoaded = true;
          this.loadHubHealth(this.pilot.domicile);
          this.loadRoster(this.pilot.domicile);
        },
        (error) => {
          console.log('WARNING: ' + error.message);
          if (error.status === 500) {
            this.router.navigate(['/techerror']);
          }
        }
      );
    }
  }

  loadHubHealth(hubId) {
    this.loading=true;
    this.hasHubHealthInfo = false;
    this.hubHealthInfo = null;

    this.hubService.getHubHealth(hubId).subscribe(
      (responseData: HubHealthModel) => {
        this.hubHealthInfo = responseData;
        this.hasHubHealthInfo = true;
        this.loading = false;
        // this.loadRoster(this.hubIdToLoad);
      },
      (error) => {
        console.log('WARNING: ' + error.message);
        this.loading = false;
        this.router.navigate(['/techerror']);
      }
    )
  }

  onHubSelected($event) {
    console.log($event);
    // if (this.firstTime) {
    //   this.firstTime = false;
    // } else {
      this.loading=true;
      this.loadHubHealth($event);
      this.hubIdToLoad=$event;
      this.loadRoster($event);
    // }
  }

  loadRoster(hubId) {
    this.hubRoster = [];
    this.hasHubRoster = false;
    this.loading = true;
    this.rosterService.getHRRoster(hubId).subscribe(
      (responseData) => {
        this.loading=true;
        console.log(responseData);

        if (responseData.hasOwnProperty('data')) {
          let pilots = responseData['data'];
          for (const key in pilots) {
            if (pilots[key].hasOwnProperty('attributes')) {
              this.hubRoster.push(pilots[key]['attributes']);
            }
          }
          console.log(this.hubRoster);
        }

        if (responseData.hasOwnProperty('included')) {
          this.hubDetails = responseData['included'][0]['attributes'];
          this.hubDetails.hubId = responseData['included'][0]['id'];
          console.log(this.hubDetails);
        }

        this.loading = false;
        this.hasHubRoster = true;
      },
      (error) => {
        console.log('WARNING: ' + error.message);
        this.loading = false;
        this.router.navigate(['/techerror']);
      }
    )
  }

  onHRHubRosterUpdated($event) {
    console.log($event);
    this.loading=true;
    if ($event=='reload_roster') {
      this.loadRoster(this.hubIdToLoad);
    }
  }
}
