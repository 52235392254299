<div class="row">
    <div class="col-md-12">
        <div class="table-responsive">
            <table class="table table-sm table-bordered table-hover text-center">
                <thead>
                    <tr>
                        <th colspan="6">&nbsp;</th>
                        <th id="lastMonth" colspan="2" class="text-center">{{hubDetails.lastMonthsName}}</th>
                        <th id="thisMonth" colspan="2" class="text-center">{{hubDetails.thisMonthsName}}</th>
                        <th>&nbsp;</th>
                    </tr>
                    <tr>
                        <th class="text-center">Callsign</th>
                        <th class="text-center">Pilot</th>
                        <th class="text-center">Total Hours</th>
                        <th class="text-center">CAT</th>
                        <th class="text-center">Status</th>
                        <th class="text-center">Last Flight</th>
                        <th headers="lastMonth" class="text-center">Hours</th>
                        <th headers="lastMonth" class="text-center">Pireps</th>
                        <th headers="thisMonth" class="text-center">Hours</th>
                        <th headers="thisMonth" class="text-center">Pireps</th>
                        <th class="text-center">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let pilot of hubRoster"
                        [ngClass]="{'table-success': pilot.isLoa, 'table-warning': pilot.hasWarnings, 'table-danger': pilot.isTerminating}">
                        <td>
                            <a class="nav-link" [routerLink]="['/managehrprofile',pilot.pilotId]" >
                                {{pilot.callsign}}
                            </a>
                        </td>
                        <td>{{pilot.pilot}}</td>
                        <td>{{pilot.totalHours}}</td>
                        <td>
                            <!-- <a ng-href="/#!/ranks" target="_blank"> -->
                            {{pilot.catRating}}
                            <!-- </a> -->
                        </td>
                        <td>{{pilot.status}} <span *ngIf="pilot.statusDate"><br />({{pilot.statusDate}})</span>
                        </td>
                        <td>{{pilot.lastFlight}}</td>
                        <td>{{pilot.lastMonthsHours}}</td>
                        <td [ngClass]="{'table-danger': pilot.lastMonthsPireps<2}">
                            {{pilot.lastMonthsPireps}}</td>
                        <td>{{pilot.thisMonthsHours}}</td>
                        <td [ngClass]="{'table-info': pilot.thisMonthsPireps<2}">{{pilot.thisMonthsPireps}}
                        </td>
                        <td>
                            <div *ngIf="!pilot.isTerminating">
                                <div *ngIf="pilot.isLoa || pilot.hasWarnings">
                                    <a (click)="clearWarning(pilot)"
                                        title="Clear Warning and set to ACTIVE for {{pilot.callsign}}"
                                        style="cursor:pointer">
                                        <i class="fas fa-check" style="color:green"></i>
                                    </a>
                                </div>
                                <div *ngIf="!pilot.isLoa && !pilot.hasWarnings && pilot.thisMonthsPireps<2">
                                    <a (click)="issueWarning(pilot)"
                                        title="Issue Termination Warning to {{pilot.callsign}}" style="cursor:pointer">
                                        <i class="fas fa-exclamation-triangle" style="color:orange"></i>
                                    </a>
                                </div>
                                <div *ngIf="pilot.isLoa || pilot.hasWarnings || staffMember.level>2">
                                    <a (click)="startTermination(pilot)"
                                        title="Start Termination for {{pilot.callsign}}" style="cursor:pointer"
                                        data-toggle="modal" data-target="#exampleModal">
                                        <i class="fas fa-ban" style="color:red"></i>
                                    </a>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<app-avamodal [modalName]="modalName" [modalTitle]="modalTitle" [modalMessage]="modalMessage" [modalTitle]="modalTitle"
    [showMiniClose]="showMiniClose" [showCancelButton]="showCancelButton" [modalStatus]="modalStatus"
    [buttonText]="'Ok'" [modalPurpose]="modalPurpose" (modalClosed)="onModalClosed($event)"></app-avamodal>

<!-- Termination In Progress with Reason Modal -->
<div class="modal fade " id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog  modal-dialog-centered ">
        <div class="modal-content bg-danger">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Are you sure?</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <form name="terminationForm" class="css-form" #terminationForm="ngForm">
                <div class="modal-body" *ngIf="hasPilotToTerminate">
                    <div class="row">
                        <div class="col-md-12">
                            <p>This will begin termination proceedings for ({{pilotToTerminate.callsign}})
                                {{pilotToTerminate.firstName}} {{pilotToTerminate.lastName}}. Before we begin, you will
                                need
                                to add
                                a Termination Reason.</p>

                            <label for="termReasonSelect">Termination Reason:</label>
                            <select name="termReasonSelect" id="termReasonSelect" class="form-control" ngModel
                                #termReasonSelect="ngModel" required>
                                <option *ngFor="let reason of TERMINATION_REASONS;let i = index"
                                    [value]="TERMINATION_REASONS[i].name">
                                    {{reason.name}}
                                </option>
                            </select>
                            <div class="alert alert-danger"
                                *ngIf="termReasonSelect.invalid && (termReasonSelect.dirty || termReasonSelect.touched)">
                                <div *ngIf="termReasonSelect.errors?.required">
                                    You must choose a reason.
                                </div>
                            </div>
                        </div>
                    </div><br />
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group" *ngIf="termReasonSelect.value=='Other'">
                                <label for="txtSpecificReason">Specific Reason:</label>
                                <input type="text" class="form-control" id="txtSpecificReason" name="txtSpecificReason"
                                    ngModel #txtSpecificReason="ngModel" minlength="5" maxlength="50"
                                    pattern="^[\w\-,.?#!()\s]+$" required />
                                <div class="alert alert-danger"
                                    *ngIf="txtSpecificReason.invalid && (txtSpecificReason.dirty || txtSpecificReason.touched)">
                                    <div *ngIf="txtSpecificReason.errors?.required">
                                        You must enter a reason.
                                    </div>
                                    <div
                                        *ngIf="txtSpecificReason.errors?.minlength || txtSpecificReason.errors?.maxlength || txtSpecificReason.errors?.pattern">
                                        Reason is invalid: must be 5 to 50 alphanumeric characters plus -,.?#!()
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-light" data-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-warning" (click)="executeTermination(terminationForm.value)">Yes,
                        begin
                        Termination Proceedings</button>
                </div>
            </form>
        </div>
    </div>
</div>