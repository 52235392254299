import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-utcclock',
  templateUrl: './utcclock.component.html',
  styleUrls: ['./utcclock.component.css']
})
export class UtcclockComponent implements OnInit {
  interval;
  utcClock : string = "";
  constructor() { }

  ngOnInit(): void {
    this.startClock();
  }

  startClock() {
    this.interval = setInterval(() => {
      let myDate = new Date();

      //current Time
      let Hours = myDate.getUTCHours();
      let Minutes = myDate.getUTCMinutes();
      let Seconds = myDate.getUTCSeconds();
      let myString = ((Hours < 10) ? "0" : "") + Hours;
      myString += ((Minutes < 10) ? ":0" : ":") + Minutes;
      myString += ((Seconds < 10) ? ":0" : ":") + Seconds;

      this.utcClock = myString + " UTC";
    })
  }
}
