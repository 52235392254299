import { Component, OnInit } from '@angular/core';
import { ApplicationModel } from 'src/app/models/application.model';
import { ApplicationService } from 'src/app/services/application.service';

@Component({
  selector: 'app-applications',
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.css']
})
export class ApplicationsComponent implements OnInit {
  loading : boolean =false;
  hasApps : boolean = false;
  isAdding : boolean = false;
  showAddEventForm: boolean = false;

  // events : EventModel[] = [];
  applications : ApplicationModel[] = [];
  // blankEvent : EventModel;

  constructor(private appService : ApplicationService) { }

  ngOnInit(): void {
    this.hasApps=false;
    this.isAdding=false;
    this.loading=true;
    this.applications=[];
    this.loadApps();
  }

  loadApps() {
    this.appService.getApplications().subscribe(
      (responseData) => {
        this.applications = responseData;
        console.log(responseData);
        this.loading = false;
        this.hasApps = true;
      },
      (error) => {
        if (error.status = 404) {
          this.hasApps=false;
        }
        this.loading = false;
      }
    );
  }
  onApplicationUpdate(event) {
    this.ngOnInit();
  }

}
