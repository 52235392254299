<div class="container mt-2 mb-3">
    <div class="row">
        <div class="col-md-12">
            <div class="card shadow rounded-top rounded-lg">
                <div class="card-header panel-american-coral">
                    <div class="row">
                        <div class="col-6 mt-2">
                            Manage Staff Positions
                        </div>
                    </div>
                </div>
                <div class="card-body" *ngIf="!loading">
                    <div class="col-md-12">
                        <div class="card mt-2 " *ngFor="let position of staffPositions">
                            <div class="card-header bg-ava-info">
                                <div class="row">
                                    <div class="col-sm-8" *ngIf="position.attributes.vacant==0">
                                        <b>{{position.attributes.shortTitle}}</b> - ({{position.attributes.callsign}}) {{position.attributes.lastName}}, {{position.attributes.firstName}}
                                    </div>
                                    <div class="col-sm-8" *ngIf="position.attributes.vacant==1">
                                        <b>{{position.attributes.shortTitle}}</b> - VACANT <i class="fa fa-exclamation-circle" style="color:red;font-size:18px"></i>
                                    </div>

                                    <div class="col-sm-4">
                                        <button class="btn btn-warning btn-sm float-right " type="button" data-toggle="collapse" [attr.data-target]="'#posDetailCollapse-' + position.id" aria-expanded="true" aria-controls="posDetailCollapse" title="Review Staff Position - {{position.attributes.shortTitle}}">
                                            <i class="far fa-paper-plane"></i> Modify
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="collapse" id="posDetailCollapse-{{position.id}}">
                                <div class="card-body">
                                    <app-staffpositiondetails [position]="position" [levels]="levels" (onPositionUpdateComplete)="onPositionUpdateComplete($position)"></app-staffpositiondetails>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <span *ngIf="loading">
        <div class="d-flex justify-content-center " style="margin-top: 10em;margin-bottom: 10em;">
            <div class="spinner-border text-primary" style="width: 6rem; height: 6rem;" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </span>
</div>