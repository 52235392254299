<ngb-carousel *ngIf="images" [showNavigationArrows]="false" [showNavigationIndicators]="false" style="box-shadow: 8px 8px 10px #aaa">
    <ng-template ngbSlide *ngFor="let image of images;let i = index" style="width:100%;border:1px silver solid">
        <div class="aspect-ratio-box">
            <div class="aspect-ratio-box-inside" >
                <div class="flexbox " >
                    <div class="picsum-img-wrapper" >
                        <img class="aspect-ratio-box-inside" [src]="image" alt="..." *ngIf="image!='welcomemessage'">
                    </div>
                    <div class="picsum-img-wrapper" *ngIf="image==='welcomemessage'" style="padding:20px 20px 20px 20px">
                        <h2>{{welcomeMessage.title}}</h2>
                        <hr class="aa-line">
                        <p [innerHtml]="welcomeMessage.message"></p>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</ngb-carousel>