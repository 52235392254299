import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { HubDetailModel } from 'src/app/models/hubdetail.model';
import { HubService } from 'src/app/services/hub.service';

@Component({
  selector: 'app-hubinfodetail',
  templateUrl: './hubinfodetail.component.html',
  styleUrls: ['./hubinfodetail.component.css']
})
export class HubinfodetailComponent implements OnInit {
  loading : boolean = false;
  @Input() hub : HubDetailModel;
  @Output() onHubUpdateComplete = new EventEmitter<string>();

  constructor(private router : Router, private hubService : HubService) { }

  ngOnInit(): void {
    console.log(this.hub);
  }

  onSubmit(hubDetailForm, hubId) {
    this.loading=true;
    let payload = {
      shortName : hubDetailForm.txtShortName,
      longName : hubDetailForm.txtLongName,
      location : hubDetailForm.txtLocation,
      isHiring : hubDetailForm.rdoIsHiring,
      hasCatLimit : 0
    }

    console.log(payload);

    this.hubService.postHubDetails(payload, hubId).subscribe(
      (responseData) => {
        this.loading = false;
        //emit event
        this.onHubUpdateComplete.emit("hubUpdated");
      },
      (error) => {
        console.log(error.message);
        this.router.navigate(['techerror']);
      }
    );
  }
}
