import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotamModel } from 'src/app/models/notam.model';
import { NotamService } from 'src/app/services/notam.service';

@Component({
  selector: 'app-notams',
  templateUrl: './notams.component.html',
  styleUrls: ['./notams.component.css']
})
export class NotamsComponent implements OnInit {
  loading : boolean = false;
  hasNotams : boolean = false;
  isAdding : boolean = false;
  notams : NotamModel[] = [];
  showAddNotamForm : boolean = false;
  blankNotam : NotamModel;

  constructor(private router : Router, private notamService : NotamService) { }

  ngOnInit(): void {
    this.loading = true;
    this.hasNotams = false;
    this.isAdding = false;
    this.notams = [];
    this.showAddNotamForm = false;

    this.loadNotams();
  }

  loadNotams() {
    this.notams=[];
    this.hasNotams=false;
    this.notamService.getManagedNotams().subscribe(
      (responseData: NotamModel[]) => {
        this.notams = responseData;
        console.log(this.notams);
        this.loading = false;
        if (this.notams.length>0) {
          this.hasNotams=true;
        }
      },
      (error) => {
          console.log('WARNING: ' + error.message);
          if (error.status === 500) {
            this.router.navigate(['/techerror']);
          }
      }
    );
  }

  addNotam() {
    this.isAdding=true;
    this.showAddNotamForm=true;

    let todaysDate = new Date();
    let todaysDateString = todaysDate.getFullYear() + '-' + (todaysDate.getMonth()+1) + '-' + todaysDate.getDate();
    let todaysTimeString = todaysDate.getHours() + ':' + todaysDate.getMinutes();

    this.blankNotam= new NotamModel;

    this.blankNotam['attributes'] = {
      bannerUrl : "",
      description : "",
      duration : 1,
      NotamId : 0,
      name : "",
      startDate : todaysDateString,
      startTime : todaysTimeString,
      trackingTag : "",
      url : ""
    }
    console.log(this.blankNotam);
  }

  onNotamUpdateComplete($event) {
    console.log($event);
    this.ngOnInit();
  }
}
