
            <form name="loginForm" class="loginForm" (ngSubmit)="onSubmit(loginForm.value)" #loginForm="ngForm">
                <div class="modal-body" >
                    <div class="form-group">
                        <label for="callsign">AAL:</label> 
                        <input type="number" class="form-control" required minlength="1" maxlength="4" max="9999" min="1"
                             ngModel name="callsign" id="callsign" #callsign="ngModel" [disabled]="loading"
                             autofocus>
                    </div>
                    <div class="alert alert-info" *ngIf="callsign.invalid && (callsign.dirty || callsign.touched)">
                        <div *ngIf="callsign.errors?.required">
                            Callsign is required.
                        </div>
                        <div *ngIf="callsign.errors?.minLength || callsign.errors?.maxLength">
                            Callsign is invalid length.
                        </div>
                        <div *ngIf="callsign.errors?.max || callsign.errors?.min">
                            Callsign is out of range.
                        </div>
                        <div *ngIf="callsign.errors?.number">
                            Callsign must be a number only.
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="password">Password:</label>
                        <input type="password" id="password" name="password" class="form-control" 
                            ngModel #password="ngModel"
                            pattern="^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*~()-=+_]{8,16}$" required
                            [disabled] = "loading" />
                    </div>
                    <div class="alert alert-info" *ngIf="password.invalid && (password.dirty || password.touched)">
                        <div *ngIf="password.errors?.required">
                            Password is required.
                        </div>
                        <div *ngIf="password.errors?.pattern">
                            Passwords must contain at least 1 number, 1 capital, and 1 symbol.  It must be between 8 and 16 characters.
                        </div>
                    </div>

                    <div class="button-group">
                        <button type="submit" class="btn btn-primary" style="clear: left; width: 100%" id="loginFormBtn"
                            [disabled]="!loginForm.valid">
                            <span *ngIf="!loading">Login</span>

                            <span *ngIf="loading">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Loading...
                            </span>                                
                        </button>
                    </div>
                    <div class="alert alert-danger" *ngIf="loginErrorMessage.length>0">
                        {{loginErrorMessage}}
                    </div>                    
                </div>
            </form>