<div class="container mt-2 mb-3">
    <div class="row">
        <div class="col-md-12">
            <div class="card shadow rounded-top rounded-lg">
                <div class="card-header panel-american-coral">
                    <div class="row">
                        <div class="col-10">
                            <span>Featured Destination</span>
                        </div>
                    </div>
                </div>
                <div class="card-body" *ngIf="!loading ">
                    <form name="fdDetailForm" class="css-form" #fdDetailForm="ngForm" (ngSubmit)="onSubmit(fdDetailForm.value)">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <label for="txtFDTitle">Title:</label>
                                    <input type="text" id="txtFDTitle" name="txtFDTitle" class="form-control" #txtFDTitle="ngModel" ngModel [(ngModel)]="featuredDestination.title" minlength="3" maxlength="100" pattern="[\w\s\-,.?#!\/'\(\)]+" required />
                                    <div class="alert alert-danger" *ngIf="txtFDTitle.invalid && (txtFDTitle.dirty || txtFDTitle.touched)">
                                        <div *ngIf="txtFDTitle.errors?.required">
                                            You must enter a Featured Destination Title.
                                        </div>
                                        <div *ngIf="txtFDTitle.errors?.minlength || txtFDTitle.errors?.maxlength || txtFDTitle.errors?.pattern">
                                            Title is invalid: must be 10 to 100 alphanumeric characters plus -,.?#!/'()
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <label for="txtFDMessage">Message:</label>
                                    <textarea id="txtFDMessage" name="txtFDMessage" class="form-control" #txtFDMessage="ngModel" ngModel [(ngModel)]="featuredDestination.message" rows=7 minlength="4" maxlength="3000" required>
                                        </textarea>
                                    <span *ngIf="txtFDMessage.dirty || showCounter">{{featuredDestination.message.length}} of 3000</span>
                                    <div class="alert alert-danger" *ngIf="txtFDMessage.invalid && (txtFDMessage.dirty || txtFDMessage.touched)">
                                        <div *ngIf="txtFDMessage.errors?.required">
                                            You must enter a message of the Featured Destination.
                                        </div>
                                        <div *ngIf="txtFDMessage.errors?.minlength || txtFDMessage.errors?.maxlength ">
                                            Featured Destination message is invalid: must be 4 to 3000 alphanumeric characters
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div [ngClass]="{'col-sm-1 || offset-sm-11 ' : !isPreview, 'col-sm-12' : isPreview}">
                                <button class="btn btn-success btn-sm float-right" id="btnSaveFDMessage" [disabled]="(txtFDMessage.invalid || !txtFDMessage.dirty) && (txtFDTitle.invalid || !txtFDTitle.dirty)">
                                    Save
                                </button>
                            </div>
                        </div>
                    </form>
                    <button class="btn btn-secondary btn-sm" (click)="isPreview=!isPreview"><i class="fas fa-search"></i> Preview</button>
                    <div class="row border border-warning well mt-3" *ngIf="isPreview">
                        <div class="col-sm-12" >
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <label for="txtFDTitlePreview">Title:</label>
                                        <div [innerHTML]="txtFDTitle.value"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <label for="txtFDMessagePreview">Message:</label>
                                        <div [innerHTML]="txtFDMessage.value"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container text-center" *ngIf="loading">
        <div class="lds-hourglass"></div>
        <p>Loading...</p>
    </div>
