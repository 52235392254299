import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StaffService {
  STAFF_SVC = '/staffPositions';
  ORGCHART_SVC = '/orgchart'

  constructor(private httpClient: HttpClient) { }

  getStaffPositions() {
    return this.httpClient.get(environment.API_URL + this.STAFF_SVC ).pipe(map(
      (data) => {
        console.log(data);
        return data;
      }));
  }

  unassign(positionId) {
    return this.httpClient.delete(environment.API_URL + this.STAFF_SVC + '/' + positionId).pipe(map(
      (data) => {
        console.log(data);
        return data;
      }
    ));
  }

  updatePosition(positionId, payload) {
    let url = environment.API_URL + this.STAFF_SVC + '/' + positionId;
    console.log(payload);

    return this.httpClient.post(url, payload).pipe(map(
      (data) => {
        return data;
      }
    ));
  }

  getOrgChart() {
    return this.httpClient.get(environment.API_URL + this.ORGCHART_SVC ).pipe(map(
      (data) => {
        console.log(data);
        return data;
      }));
  }
}
