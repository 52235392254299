<div class="card shadow rounded-top rounded-lg mt-2" *ngIf="!loading">
    <div class="card-header panel-american-blue-grey-red">
        <div class="row p-0">
            <div class="col-xl p-0">
                Fight Hours
            </div>
        </div>
    </div>
    <div class="card-body">
        <div class="row p-0">
            <div class="col-xl p-0">
                <div class="card mt-2 rounded-top">
                    <div class="card-header">
                        <b>{{hoursStats.monthlyHoursByPilot.title}}</b>
                    </div>
                    <div class="card-body">
                        <div class="flex-item">
                            <div>
                                <canvas baseChart width="400" height="150px" id="monthlyByPilotReport" 
                                    [datasets]="monthlyByPilotChartData" 
                                    [labels]="hoursStats.monthlyHoursByPilot.labels" 
                                    [colors]="COLOR_GRID" [legend]="lineChartLegend" 
                                    [chartType]="horizonaltalBarChartType">
                                </canvas>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row p-0">
            <div class="col-xl p-0">
                <div class="card mt-2 rounded-top">
                    <div class="card-header">
                        <b>{{hoursStats.yearlyHoursByPilot.title}}</b>
                    </div>
                    <div class="card-body">
                        <div class="flex-item">
                            <div>
                                <canvas baseChart width="400" height="150px" id="yearlyHoursByPilotReport" 
                                    [datasets]="yearlyByPilotChartData" 
                                    [labels]="hoursStats.yearlyHoursByPilot.labels" 
                                    [colors]="COLOR_GRID" 
                                    [legend]="lineChartLegend" 
                                    [chartType]="horizonaltalBarChartType">
                                </canvas>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row p-0">
            <div class="col-xl p-0">
                <div class="card mt-2 rounded-top">
                    <div class="card-header">
                        <b>{{hoursStats.monthlyHoursByHub.title}}</b>
                        <span class="badge badge-info float-right ">Total: {{hoursStats.monthlyHoursByHub.totalHours | number}}</span>
                    </div>
                    <div class="card-body">
                        <div >
                            <div>
                                <canvas baseChart width="400" height="150px" id="monthlyHoursByHubReport" 
                                    [datasets]="monthlyByHubChartData" 
                                    [labels]="hoursStats.monthlyHoursByHub.labels" 
                                    [colors]="COLOR_GRID" 
                                    [legend]="lineChartLegend" 
                                    [chartType]="barChartType">
                                </canvas>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
         <div class="row p-0">
            <div class="col-xl p-0">
                <div class="card mt-2 rounded-top">
                    <div class="card-header">
                        <b>{{hoursStats.yearlyHoursByHub.title}}</b>
                        <span class="badge badge-info float-right ">Total: {{hoursStats.yearlyHoursByHub.totalHours | number}}</span>
                    </div>
                    <div class="card-body">
                        <div >
                            <div>
                                <canvas baseChart width="400" height="150px" id="yearlyByHubReport" 
                                    [datasets]="yearlyByHubChartData" 
                                    [labels]="hoursStats.yearlyHoursByHub.labels" 
                                    [colors]="COLOR_GRID" 
                                    [legend]="lineChartLegend" 
                                    [chartType]="barChartType">
                                </canvas>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
        <div class="row p-0">
            <div class="col-xl p-0">
                <div class="card mt-2 rounded-top">
                    <div class="card-header">
                        <b>{{hoursStats.monthlyHourTotals.title}}</b>
                        <span class="badge badge-info float-right ">Total: {{hoursStats.monthlyHourTotals.totalHours| number}}</span>
                    </div>
                    <div class="card-body">
                        <div >
                            <div>
                                <canvas baseChart width="400" height="150px" id="monthlyHourTotalsReport" 
                                    [datasets]="monthlyTotalsChartData" 
                                    [labels]="hoursStats.monthlyHourTotals.labels" 
                                    [colors]="COLOR_GRID" 
                                    [legend]="lineChartLegend" 
                                    [chartType]="barChartType">
                                </canvas>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row p-0">
            <div class="col-xl p-0">
                <div class="card mt-2 rounded-top">
                    <div class="card-header">
                        <b>{{hoursStats.yearlyHoursTotals.title}}</b>
                        <span class="badge badge-info float-right ">Total: {{hoursStats.yearlyHoursTotals.totalHours | number}}</span>
                    </div>
                    <div class="card-body">
                        <div >
                            <div>
                                <canvas baseChart width="400" height="150px" id="yearlyHourTotalssReport" 
                                    [datasets]="yearlyTotalsChartData" 
                                    [labels]="hoursStats.yearlyHoursTotals.labels" 
                                    [colors]="COLOR_GRID" 
                                    [legend]="lineChartLegend" 
                                    [chartType]="barChartType">
                                </canvas>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>                            
        <div class="goToTop" (click)="tabTop();" [ngClass]="{ 'show-scrollTop': windowScrolled }" *ngIf="windowScrolled">
            <a  title="Scroll up"><i class="fas fa-arrow-circle-up"></i></a>
        </div>
    </div>
</div>

<div class="container text-center" *ngIf="loading">
    <div class="lds-hourglass"></div>
    <p>Loading...</p>
</div>