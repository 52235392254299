import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GoalModel } from 'src/app/models/goal.model';
import { GoalsService } from 'src/app/services/goals.service';

@Component({
  selector: 'app-goals',
  templateUrl: './goals.component.html',
  styleUrls: ['./goals.component.css']
})
export class GoalsComponent implements OnInit {
  loading: boolean = false;
  goals : GoalModel[] = [];
  monthNames = ["January", "February", "March", "April", "May", "June","July", "August", "September", "October", "November", "December"];
  monthlyHourGoals=[100,100,100,100,100,100,100,100,100,100,100,100];

  totalHours : number = 0;

  constructor(private router: Router, private goalService: GoalsService) { }

  ngOnInit(): void {
    this.loading = true;
    this.goals = [];
    this.totalHours=0;
    this.loadGoals();
  }
  sumTotals() {
    this.goals.forEach( goal => {
      this.totalHours += goal.value;
    });
  }
  loadGoals() {
    this.goalService.getGoals().subscribe(
      (responseData) => {
        console.log(responseData);
        this.goals=responseData;
        this.sumTotals();
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        if (error.status = 404) {
        } else {
          this.router.navigate(['/techerror']);
        }
      }
    );
  }

  onSubmit(goalsForm) {
    console.log(goalsForm);
    let monthlyHourGoals = {'monthlyHourGoals' : this.goals};
    console.log(monthlyHourGoals);

    this.goalService.putGoals(monthlyHourGoals).subscribe(
      (responseData) => {
        console.log(responseData);
        this.ngOnInit();
      },
      (error) => {
        this.router.navigate(['/techerror']);
      }
    )
  }

  onChange(mnthIndex) {
    this.totalHours=0;
    this.sumTotals();
  }
}
