<div class="container-fluid no-gutters mt-2" *ngIf="!loading">
    <div class="card shadow rounded-top rounded-lg">
        <div class="card-header panel-american-blue">
            <div class="row">
                <div class="col-12">
                    American Virtual Airlines Dispatcher
                </div>
            </div>
        </div>
        <div class="card-body ">
            <div class="row mt-2">
                <div class="col-md-12">
                    <ul class="nav nav-pills">
                        <li class="nav-item">
                            <a [routerLink]="[]" class="nav-link " (click)="hrMenuClick('ff')"
                                [ngClass]="{'active' : activeMenu=='ff'}">
                                Flight Finder
                            </a>
                        </li>
                        <li class="nav-item">
                            <a [routerLink]="[]" class="nav-link " (click)="hrMenuClick('bf')"
                                [ngClass]="{'active' : activeMenu=='bf'}">
                                Booked Flights
                            </a>
                        </li>
                        <li class="nav-item">
                            <a [routerLink]="[]" class="nav-link " (click)="hrMenuClick('bc')"
                                [ngClass]="{'active' : activeMenu=='bc'}">
                                Book Charter
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row" *ngIf="activeMenu=='bf'" >
                <div class="col-md-12 mt-2">
                    <app-bookedflights (onDeleteComplete)="OnDispatcherTabChange($event)"></app-bookedflights>
                </div>
            </div>
            <div class="row" *ngIf="activeMenu=='ff'">
                <div class="col-md-12 mt-2">
                    <app-findflights (onDispatcherTabChange)="OnDispatcherTabChange($event)"></app-findflights>
                </div>
            </div>
            <div class="row" *ngIf="activeMenu=='bc'">
                <div class="col-md-12 mt-2">
                    <app-charterflight (onDispatcherTabChange)="OnDispatcherTabChange($event)"></app-charterflight>
                </div>
            </div>
        </div>
    </div>
</div>
<span *ngIf="loading">
    <div class="d-flex justify-content-center " style="margin-top: 10em;margin-bottom: 10em;">
        <div class="spinner-border text-primary" style="width: 6rem; height: 6rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</span>