<form name="resignationForm" class="css-form" (ngSubmit)="onSubmit(resignationForm.value)" #resignationForm="ngForm" *ngIf="!loading">
    <div class="row">
        <div class="col-md-6">
            <div class="form-group">
                <span style="color:red">By submitting this resignation, you understand that you will be removed from the active
                pilot roster at AvA and all future flights and data will not be stored in our systems.  You will no longer be able 
                to utilize our website and ACARS systems as well.  This resignation takes effect immediately.  A member of our leadership
                will review your performance at AvA and determine if you will be placed on our Do Not Hire list and you may possibly not 
                be accepted back as a member should you desire to return in the future.</span>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label for="reason">Reason:</label>
                <input type="text" id="reason" name="reason" class="form-control" ngModel
                    #reason="ngModel"  required minlength="10" maxlength="100" pattern="^[\w\-,.?#!()\s]+$"
                    />
                <div class="alert alert-danger" *ngIf="reason.invalid && (reason.dirty || reason.touched)">
                    <div *ngIf="reason.errors?.required">
                        You must enter a reason for your Resignation.
                    </div>
                    <div *ngIf="reason.errors?.minlength || reason.errors?.maxlength || reason.errors?.pattern">
                        Reason is invalid: must be 10 to 100 alphanumeric characters plus -,.?#!()
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 d-flex justify-content-end">
            <button type="submit" class="btn btn-danger" [disabled]="!resignationForm.valid || (!resignationForm.touched && !resignationForm.dirty)" >
                Request Resignation
            </button>
        </div>
    </div>
</form>

<app-avamodal [modalName]="modalName" [modalMessage]="modalMessage" [modalTitle]="modalTitle" [showMiniClose]="showMiniClose"
[showCancelButton]="showCancelButton" [modalStatus]="modalStatus" [buttonText]="'Ok'"
[modalPurpose]="modalPurpose" (modalClosed)="onModalClosed($event)"></app-avamodal>

<span *ngIf="loading">
    <div class="d-flex justify-content-center " style="margin-top: 10em;margin-bottom: 10em;">
        <div class="spinner-border text-primary" style="width: 6rem; height: 6rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</span>
