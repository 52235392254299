<div class="container mt-2 mb-3">
    <div class="row">
        <div class="col-md-12">
            <div class="card shadow rounded-top rounded-lg">
                <div class="card-header panel-american-coral">
                    <div class="row">
                        <div class="col-10">
                            <span>Training Page</span>
                        </div>
                    </div>
                </div>
                <div class="card-body" *ngIf="!loading && isFound ">
                    <form name="tpDetailForm" class="css-form" #tpDetailForm="ngForm" (ngSubmit)="onSubmit(tpDetailForm.value)">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <label for="txtTPTitle">Title:</label>
                                    <input type="text" id="txtTPTitle" name="txtTPTitle" class="form-control" #txtTPTitle="ngModel" ngModel [(ngModel)]="trainingPage.title" minlength="3" maxlength="100" pattern="[\w\s\-,.?#!\/'\(\)]+" required />
                                    <div class="alert alert-danger" *ngIf="txtTPTitle.invalid && (txtTPTitle.dirty || txtTPTitle.touched)">
                                        <div *ngIf="txtTPTitle.errors?.required">
                                            You must enter a Training Page Title.
                                        </div>
                                        <div *ngIf="txtTPTitle.errors?.minlength || txtTPTitle.errors?.maxlength || txtTPTitle.errors?.pattern">
                                            Title is invalid: must be 10 to 100 alphanumeric characters plus -,.?#!()
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <label for="txtTPMessage">Message:</label>
                                    <textarea id="txtTPMessage" name="txtTPMessage" class="form-control" #txtTPMessage="ngModel" ngModel [(ngModel)]="trainingPage.message" rows=7 minlength="4" maxlength="1500" required>
                                        </textarea>
                                    <span *ngIf="txtTPMessage.dirty || showCounter">{{trainingPage.message.length}} of 1500</span>
                                    <div class="alert alert-danger" *ngIf="txtTPMessage.invalid && (txtTPMessage.dirty || txtTPMessage.touched)">
                                        <div *ngIf="txtTPMessage.errors?.required">
                                            You must enter a message of the Training Page.
                                        </div>
                                        <div *ngIf="txtTPMessage.errors?.minlength || txtTPMessage.errors?.maxlength ">
                                            Training Page message is invalid: must be 4 to 1500 alphanumeric characters plus -,.?#!/'()
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div [ngClass]="{'col-sm-1 || offset-sm-11 ' : !isPreview, 'col-sm-12' : isPreview}">
                                <button class="btn btn-success btn-sm float-right" id="btnSaveTPMessage" [disabled]="(txtTPMessage.invalid || !txtTPMessage.dirty) && (txtTPTitle.invalid || !txtTPTitle.dirty)">
                                    Save
                                </button>
                            </div>
                        </div>
                    </form>
                    <button class="btn btn-secondary btn-sm" (click)="isPreview=!isPreview"><i class="fas fa-search"></i> Preview </button>
                    <div class="row border border-warning well mt-3" *ngIf="isPreview">
                        <div class="col-sm-12">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <label for="txtTPTitlePreview">Title:</label>
                                        <div [innerHTML]="txtTPTitle.value"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <label for="txtTPMessagePreview">Message:</label>
                                        <div [innerHTML]="txtTPMessage.value"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container text-center" *ngIf="loading">
    <div class="lds-hourglass"></div>
    <p>Loading...</p>
</div>
