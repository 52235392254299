import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HiringHubModel } from '../models/hiringhub.model';
import { HubDetailModel } from '../models/hubdetail.model';
import { HubHealthModel } from '../models/hubhealth.model';
import { VAHealthModel } from '../models/vahealth.model';

@Injectable({
  providedIn: 'root'
})
export class HubService {
  HUBS_SVC = '/hubs';
  HIRING_HUBS_SVC = "/hiringHubs";
  TRANSFERABLE_HUBS_SVC = '/transferableHubs';
  AVAILABLE_CALLSIGNS_SVC = '/availableCallsigns';
  VA_HEALTH_SVC = '/vahealth';
  HUB_HEALTH_SVC = '/hubhealth/';
  HUBDETAILS_SVC = '/hubdetails';

  hiringHubs : HiringHubModel[] = [];
  transferableHubs : HiringHubModel[] = [];
  availableCallsigns : string[] = [];

  constructor(private httpClient: HttpClient) { }

  getHiringHubs() {
    this.hiringHubs = [];
    return this.httpClient.get(environment.API_URL + this.HIRING_HUBS_SVC).pipe(map(
      (data) => {
      for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
          for ( const index in data[key]) {
            if (Object.prototype.hasOwnProperty.call(data[key], index)) {
              const element = data[key][index];
              this.hiringHubs.push(element);
            }
          }
        }
      }
      return this.hiringHubs;
    }))
  }

  getTransferableHubs() {
    this.transferableHubs = [];
    return this.httpClient.get(environment.API_URL + this.TRANSFERABLE_HUBS_SVC).pipe(map(
      (data) => {
      for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
          for ( const index in data[key]) {
            if (Object.prototype.hasOwnProperty.call(data[key], index)) {
              const element = data[key][index];
              this.transferableHubs.push(element);
            }
          }
        }
      }
      return this.transferableHubs;
    }))
  }

  getTransferableHubsByPilotId(pilotId) {
    this.transferableHubs = [];
    return this.httpClient.get(environment.API_URL + this.TRANSFERABLE_HUBS_SVC + '/' + pilotId).pipe(map(
      (data) => {
        for (const key in data) {
          if (Object.prototype.hasOwnProperty.call(data, key)) {
            for ( const index in data[key]) {
              if (Object.prototype.hasOwnProperty.call(data[key], index)) {
                const element = data[key][index];
                this.transferableHubs.push(element);
              }
            }
          }
        }
        return this.transferableHubs;
    }))
  }

  getAvailableCallsignsForAHub(hubId : number) {
    this.availableCallsigns = [];
    let url = environment.API_URL + this.HUBS_SVC + '/' + hubId + this.AVAILABLE_CALLSIGNS_SVC;
    console.log(url);
    return this.httpClient.get(url).pipe(map(
      (data) => {
          if (data.hasOwnProperty('data')) {
            for (const key in data['data']) {
              this.availableCallsigns.push(data['data'][key]);
            }
          }
          // console.log(this.availableCallsigns);
          return this.availableCallsigns;
    }))
  }

  getHubHealth(hubId)   {
    let url = environment.API_URL + this.HUB_HEALTH_SVC + hubId;
    console.log(url);

    return this.httpClient.get(url).pipe(map(
      (data) => {
        let hubHealth : HubHealthModel = data['data']['attributes'];
        console.log(hubHealth);
        return hubHealth;
      }
    ))
  }

  getVAHealth()   {
    let url = environment.API_URL + this.VA_HEALTH_SVC;
    console.log(url);

    return this.httpClient.get(url).pipe(map(
      (data) => {
        let vaHealth : VAHealthModel = data['data'][0]['attributes'];
        console.log(vaHealth);
        return vaHealth;
      }
    ))
  }

  getHubDetails() {
    let url = environment.API_URL + this.HUBDETAILS_SVC;
    console.log(url);

    return this.httpClient.get(url).pipe(map(
      (data : HubDetailModel) => {
        let details : HubDetailModel = data['data'];
        console.log(details);
        return details;
      }
    ))
  }

  postHubDetails(payload,hubId) {
    let url = environment.API_URL + this.HUBDETAILS_SVC + '/' + hubId;
    console.log(payload);

    return this.httpClient.post(url, payload).pipe(map(
      (data) => {
        return data;
      }
    ));
  }
}
