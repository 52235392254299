import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-policymanual',
  templateUrl: './policymanual.component.html',
  styleUrls: ['./policymanual.component.css']
})
export class PolicymanualComponent implements OnInit {
  windowScrolled = false;

  constructor() { }

  ngOnInit(): void {
    //add listener for scroll up button
    window.addEventListener('scroll', () => {
      this.windowScrolled = window.pageYOffset > 200;
    });
  }
  tabTop() {
    window.scrollTo(0, 0);
  }
}
