<form name="passwordForm" class="css-form" (ngSubmit)="onSubmit(passwordForm.value)" #passwordForm="ngForm" *ngIf="!loading">
    <div class="row">
        <div class="col-md-4">
            <div class="form-group">
                <label for="oldPassword">Current Password:</label>
                <input type="password" id="oldPassword" name="oldPassword" class="form-control" [(ngModel)]="passwords.oldPassword"
                    #oldPassword="ngModel" pattern="^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$" required
                    />
                <div class="alert alert-danger" *ngIf="oldPassword.invalid && (oldPassword.dirty || oldPassword.touched)">
                    <div *ngIf="oldPassword.errors?.required">
                        You must enter a current password.
                    </div>
                    <div *ngIf="oldPassword.errors?.minlength || oldPassword.errors?.maxlength || oldPassword.errors?.pattern">
                        Password is invalid: must be 2 to 40 alpha only characters.
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label for="newPassword">New Password:</label>
                <input type="password" id="newPassword" name="newPassword" class="form-control" [(ngModel)]="passwords.newPassword"
                    #newPassword="ngModel" pattern="^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$" required
                    />
                <div class="alert alert-danger" *ngIf="newPassword.invalid && (newPassword.dirty || newPassword.touched)">
                    <div *ngIf="newPassword.errors?.required">
                        You must enter a new password.
                    </div>
                    <div *ngIf="newPassword.errors?.minlength || newPassword.errors?.maxlength || newPassword.errors?.pattern">
                        New password is invalid: must be 2 to 40 alpha only characters.
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label for="confirmPassword">Confirm Password:</label>
                <input type="password" id="confirmPassword" name="confirmPassword" class="form-control" [(ngModel)]="passwords.confirmPassword"
                    #confirmPassword="ngModel" pattern="^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$" required 
                    />
                <div class="alert alert-danger" *ngIf="confirmPassword.invalid && (confirmPassword.dirty || confirmPassword.touched)">
                    <div *ngIf="confirmPassword.errors?.required">
                        You must confirm your new password.
                    </div>
                    <div *ngIf="confirmPassword.errors?.minlength || confirmPassword.errors?.maxlength || confirmPassword.errors?.pattern">
                        Confirmation Password is invalid: must be 2 to 40 alpha only characters.
                    </div>
                </div>
                <div class="alert alert-danger" *ngIf="confirmPassword.value!=newPassword.value && (confirmPassword.dirty || confirmPassword.touched)">
                    <div >
                        New and Confirmation Passwords must match. 
                    </div>
                </div>                
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 d-flex justify-content-end">
            <button type="submit" class="btn btn-warning" [disabled]="!passwordForm.valid || (!passwordForm.touched && !passwordForm.dirty)" >
                Change Password
            </button>
        </div>
    </div>
</form>

<app-avamodal [modalName]="modalName" [modalMessage]="modalMessage" [modalTitle]="modalTitle" [showMiniClose]="showMiniClose"
[showCancelButton]="showCancelButton" [modalStatus]="modalStatus" [buttonText]="'Ok'"
[modalPurpose]="modalPurpose" (modalClosed)="onModalClosed($event)"></app-avamodal>



<span *ngIf="loading">
    <div class="d-flex justify-content-center " style="margin-top: 10em;margin-bottom: 10em;">
        <div class="spinner-border text-primary" style="width: 6rem; height: 6rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</span>