import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { HubService } from 'src/app/services/hub.service';
import { PilotService } from 'src/app/services/pilot.service';

@Component({
  selector: 'app-ccformdetail',
  templateUrl: './ccformdetail.component.html',
  styleUrls: ['./ccformdetail.component.css']
})
export class CcformdetailComponent implements OnInit {
  loading: boolean = false;
  @Input() member;
  @Output() onCCComplete = new EventEmitter<string>();

  availableCallsigns: string[];
  showCCFormRow=false;

  constructor(private router: Router, private hubService: HubService, private pilotService : PilotService) { }

  ngOnInit(): void {
    this.loading = false;
  }

  loadHubCallsigns() {
    this.hubService.getAvailableCallsignsForAHub(this.member.hubId).subscribe(
      (responseData) => {
        this.availableCallsigns = responseData;
        console.log(this.availableCallsigns);
        this.loading=false;
      },
      (error) => {
        console.log(error);
        this.router.navigate(['/techerror']);
        this.loading=false;
        //tod
      }
    );
  }

  onChangeCallsignClick() {
    this.showCCFormRow=!this.showCCFormRow;
    console.log(this.showCCFormRow);

    this.loadHubCallsigns();
  }

  executeCC(ccForm) {
    console.log(ccForm);
    var payload = {"newCallsign" : ccForm.newCallsign, "comment":ccForm.comments};
    console.log(payload);

    this.loading = true;
    this.pilotService.changeCallsign(this.member.pilotId, payload).subscribe(
      (responseData) => {
        console.log(responseData);
        this.loading=false;
        this.onCCComplete.emit('success');
      },
      (error) => {
        console.log(error);
        this.router.navigate(['/techerror']);
        this.loading=false;
      }
    )

  }
}
