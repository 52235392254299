<div class="div row no-gutters justify-content-center mt-n2">
    <div class="col-md-12 no-gutters" >
        <google-map [options]="gOptions" width="100%" [center]="center">
            <map-marker #markerElem *ngFor="let marker of markers"
                [position]="marker.position"
                [label]="marker.label"
                [title]="marker.title"
                [options]="marker.options"
                >
            </map-marker>
            <map-polyline #lineElem *ngFor="let line of lines" [options]="line"></map-polyline>
        </google-map>
    </div>
</div>