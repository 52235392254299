<div class="border border-rounded pl-2 pt-1 mb-1">
    <div class="row">
        <div class="col-sm-12 font-weight-bold text-info">
            <u>Resignation Details</u>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4">
            <label class="mr-2">Callsign:</label>
            <span>{{resignation.attributes.callsign}}</span>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4">
            <label class="mr-2">VATSIM ID:</label>
            <span>{{resignation.attributes.vatsimId}}</span>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <label class="mr-2">Comment:</label>
            <mark class="text-danger">{{resignation.attributes.reason}}</mark>
        </div>
    </div>
</div>
<div class="border border-rounded pl-2 pt-1 mb-1 pr-2" *ngIf="resignation.attributes.personnelRecords.length>0">
    <div class="row">
        <div class="col-sm-12 font-weight-bold text-info">
            <u>Personnel Records</u>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <table class="table table-bordered table-hover responsive">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Date</th>
                        <th scope="col">Action</th>
                        <th scope="col">Comments</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let prec of resignation.attributes.personnelRecords;let i = index">
                        <th scope="row">{{i+1}}</th>
                        <td>{{prec.Date_Action}}</td>
                        <td>{{prec.Action}}</td>
                        <td>{{prec.Comments}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<form name="resignationForm" class="css-form" (ngSubmit)="onSubmit(resignationForm.value)" #resignationForm="ngForm"
    *ngIf="!loading">
    <div class="border border-rounded pl-2 pt-1 mb-1 pr-2">
        <div class="row">
            <div class="col-sm-12 font-weight-bold text-info">
                <u>Manager's Response</u>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <label for="comment">Comment:</label>
                    <input type="text" id="comment" name="comment" class="form-control" ngModel #comment="ngModel" required
                        minlength="10" maxlength="100" pattern="^[\w\-,.?#!()\s]+$" />
                    <div class="alert alert-danger" *ngIf="comment.invalid && (comment.dirty || comment.touched)">
                        <div *ngIf="comment.errors?.required">
                            You must enter a comment for your decision.
                        </div>
                        <div *ngIf="comment.errors?.minlength || comment.errors?.maxlength || comment.errors?.pattern">
                            Comment is invalid: must be 10 to 100 alphanumeric characters plus -,.?#!()
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-md-12">
                <label class="checkbox-inline">
                    <input type="checkbox" id="isDNR" name="isDNR" #isDNR="ngModel" ngModel>
                    - Add to Do Not Rehire List?
                </label>
            </div>
        </div>
    </div>

    <div class="row pl-2 pt-1 mb-1 mt-5 pr-2" *ngIf="isShowingDefaultButtons">
        <div class="col-md-6 button-group">
            <button type="submit" class="btn btn-danger" id="btnResReject" (click)="resRejectStart()" [disabled]="comment.invalid || !comment.dirty">
                <i class="fa fa-thumbs-down"></i> Reject
            </button>
        </div>
        <div class="col-md-6 ">
            <div class="btn-group float-right" id="btnAccpt" (click)="resAcceptStart()">
                <button type="submit" class="btn btn-success" [disabled]="comment.invalid || !comment.dirty">
                    <i class="fa fa-thumbs-up"></i>Accept
                </button>
            </div>
        </div>
    </div>
</form>

<div class="row float-right text-light" style="font-size:.25em">
    {{resignation.attributes.resignationId}}
</div>