import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TransferService {
  TRANSFER_SVC = '/transfers';
  HR_TRANSFERS_SVC = '/hrtransfers';

  constructor(private httpClient: HttpClient) { }

  postTransfer(tx) {
    let url = environment.API_URL + this.TRANSFER_SVC;
    return this.httpClient.post(url, tx);
  }

  getTransferRequests() {
    return this.httpClient.get(environment.API_URL + this.TRANSFER_SVC).pipe(map( 
      (data) => {
        return data;
      //   this.loaRequests = [];
      //   for (const key in data) {
      //     if (Object.prototype.hasOwnProperty.call(data, key)) {
      //       for ( const index in data[key]) {
      //         if (Object.prototype.hasOwnProperty.call(data[key], index)) {
      //           const element = data[key][index];
      //           element.attributes.toggledOpen=false;
      //           this.loaRequests.push(element.attributes);
      //         }
      //       }
      //     }
      //   }
      // return this.loaRequests;
    }))   
  }

  updateTransferStatus(transferRequestForm,transferRequest,status) {
    let url = environment.API_URL + this.TRANSFER_SVC + '/' + transferRequest.transferId + '/' + status;
    console.log(url);
    let transferData = {
      newCallsign : transferRequestForm.newCallsign,
      comment : transferRequestForm.comments
    }
    return this.httpClient.post(url, transferData);
  }  
  
  postHRTransfer(transferData) {
    let url = environment.API_URL + this.HR_TRANSFERS_SVC;
    console.log(url);
    return this.httpClient.post(url, transferData);
  }  
}
