import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TerminationRequestModel } from 'src/app/models/termination.model.';
import { TerminationService } from 'src/app/services/termination.service';

@Component({
  selector: 'app-terminations',
  templateUrl: './terminations.component.html',
  styleUrls: ['./terminations.component.css']
})
export class TerminationsComponent implements OnInit {
  loading : boolean = false;
  hasTerminations : boolean = false;
  terminations : TerminationRequestModel[] = [];

  constructor(private router : Router, private termService : TerminationService) { }

  ngOnInit(): void {
    this.loading =true;
    this.hasTerminations=false;
    this.loadTerminations();
  }

  loadTerminations() {
    this.termService.getTerminations().subscribe(
      (responseData) => {
        this.terminations = responseData;
        console.log(this.terminations);
        this.loading = false;
        this.hasTerminations = true;
      },
      (error) => {
        this.loading = false;
        this.hasTerminations=false;
        this.terminations=[];
        if (error.status = 404) {
          this.hasTerminations=false;
        } else {
          this.router.navigate(['/techerror']);
        }
      }
    );
  }

  onTerminationUpdate(event) {
    console.log(event);
    this.ngOnInit();
  }
}
