import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommService {
  COMM_STAFF_EMAIL_ADDRESS = '/staffEmailAddress';
  COMM_ACTIVE_PILOT_EMAILS = '/activePilotEmailAddresses';
  COMM_MEMBER_EMAILS = '/memberEmails';
  COMM_GROUP_EMAIL = '/groupemail';
  COMM_EMAIL_GROUPS = '/emailgroups';

  constructor(private httpClient: HttpClient) { }

  getPilotEmailAddress() {
    let url = environment.API_URL + this.COMM_STAFF_EMAIL_ADDRESS;
    return this.httpClient.get(url).pipe(map(
      (data) => {
        return data['attributes'];
      }
    ));
  }
  getActivePilotEmails() {
    let url = environment.API_URL + this.COMM_ACTIVE_PILOT_EMAILS;
    return this.httpClient.get(url).pipe(map(
      (data) => {
        return data['data'];
      }
    ));
  }

  getGroupEmails() {
    let url = environment.API_URL + this.COMM_EMAIL_GROUPS;
    return this.httpClient.get(url).pipe(map(
      (data) => {
        return data['data'];
      }
    ));
  }

  postSingleMemberEmail(emailData) {
    let url = environment.API_URL + this.COMM_MEMBER_EMAILS;
    console.log('URL: ' + url);

    return this.httpClient.post(url, emailData).pipe(map(
      (data) => {
        return data["attributes"];
      }
    ));
  }

  postGroupEmail(emailData) {
    let url = environment.API_URL + this.COMM_GROUP_EMAIL;
    console.log('URL: ' + url);

    return this.httpClient.post(url, emailData).pipe(map(
      (data) => {
        return data["attributes"];
      }
    ));
  }
}
