<div class="container-fluid no-gutters mt-2" *ngIf="!loading">
    <div class="card shadow rounded-top rounded-lg">
        <div class="card-header panel-american-blue-grey-red">
            <div class="row">
                <div class="col-12">
                    American Virtual Airlines 
                </div>
            </div>
        </div>
        <div class="card-body ">
            <div class="card shadowed rounded-top rounded-lg">
                <div class="card-body ">
                    <div class="row">
                        <div class="col d-flex justify-content-center btn-light mt-3 pl-2 pr-2 pt-2 pb-2 rounded-top rounded-sm round-bottom border" *ngFor="let link of auLinks">
                            <a class="hm mt-3" href="{{link.href}}">
                                <i class="{{link.meta.icon}} mg-menu-icon"></i><br />
                                <span>{{link.meta.menuText}}</span><br />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>