import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DispatcherService {
  PILOT_DESTINATIONS_SVC = '/pilotdestinations';
  PILOT_BOOKINGS_SVC = '/bookings';
  SCHEDULE_AIRPORTS_SVC = '/scheduleairports';
  SCHEDULE_AIRCRAFT_SVC = '/scheduleaircraft';
  SCHEDULE_ROUTES_SVC = '/routes';
  SCHEDULES_SVC = '/schedules/'
  IATA_FLIGHTS_SVC = '/iataflights';
  CHARTER_SCHEDULES_SVC = '/charterschedules';

  constructor(private httpClient: HttpClient) { }

  getPilotDestinations() {
    let url = environment.API_URL + this.PILOT_DESTINATIONS_SVC;
    return this.httpClient.get(url).pipe(map(
      (data) => {
        // //degugger;
        return data['data'];
      }
    ));
  }

  getBookings() {
    let url = environment.API_URL + this.PILOT_BOOKINGS_SVC;
    return this.httpClient.get(url).pipe(map(
      (data) => {
        return data;
      }
    ));
  }

  getScheduleAirports() {
    let url = environment.API_URL + this.SCHEDULE_AIRPORTS_SVC;
    return this.httpClient.get(url).pipe(map(
      (data) => {
        return data['data'];
      }
    ));
  }

  getScheduleAircraft() {
    let url = environment.API_URL + this.SCHEDULE_AIRCRAFT_SVC;
    return this.httpClient.get(url).pipe(map(
      (data) => {
        return data['data'];
      }
    ));
  }

  getRoutes(searchForm) {
    let url = environment.API_URL + this.SCHEDULE_ROUTES_SVC;

    if (searchForm.departureIcao == null) {
      searchForm.departureIcao = '';
    }
    if (searchForm.arrivalIcao == null) {
      searchForm.arrivalIcao = '';
    }
    if (searchForm.acIcao == null) {
      searchForm.acIcao = '';
    }
    if (searchForm.duration == null) {
      searchForm.duration = '';
    }
    url += '?departureIcao=' + searchForm.departureIcao +
      '&arrivalIcao=' + searchForm.arrivalIcao +
      '&acIcao=' + searchForm.acIcao +
      '&duration=' + searchForm.duration;
    return this.httpClient.get(url).pipe(map(
      (data) => {
        return data['data'];
      }
    ));
  }

  getFlightsByIata(departureAirportIata, destinationAirportIata) {
    let url = environment.API_URL + this.IATA_FLIGHTS_SVC + '?deptAirport=' + departureAirportIata + '&destAirport=' + destinationAirportIata;
    console.log('URL: ' + url);
    return this.httpClient.get(url).pipe(map(
      (data) => {
        //degugger;
        return data['data'];
      }
    ));
  }

  getSchedule(scheduleId) {
    let url = environment.API_URL + this.SCHEDULES_SVC + scheduleId;
    console.log('URL: ' + url);
    return this.httpClient.get(url).pipe(map(
      (data) => {
        //degugger;
        return data['data'];
      }
    ));
  }

  scheduleFlight(pilotId, scheduleId, route, acars) {
    let url = environment.API_URL + this.SCHEDULES_SVC + scheduleId;
    console.log('URL: ' + url);
    let payload = { "route": route, "acars": acars };
    console.log(payload);

    return this.httpClient.post(url, payload).pipe(map(
      (data) => {
        return data;
      }
    ));
  }

  deleteBooking(acarsType, bookingId) {
    let url = environment.API_URL + this.PILOT_BOOKINGS_SVC + '/' + acarsType + '/' + bookingId;

    return this.httpClient.delete(url).pipe(map(
      (data) => {
        return data;
      }
    ));
  }

  scheduleCharterFlight(flight) {
    console.log('scheduling charter flight:  ' + flight);
    let url = environment.API_URL + this.CHARTER_SCHEDULES_SVC;

    return this.httpClient.post(url,flight).pipe(map(
      (data) => {
        return data;
      }
    ));
  }
}
