import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { EventModel } from '../models/event.model';

@Injectable({
  providedIn: 'root'
})
export class EventService {
  EVENTS_SVC = '/events';
  MANAGED_EVENTS_SVC = '/managedevents';
  SUMMARY_EVENTS_SVC = '/summaryevents';

  events : EventModel[] = [];

  constructor(private httpClient: HttpClient) { }

  getManaged() {
    // this.hiringHubs = [];
    return this.httpClient.get(environment.API_URL + this.MANAGED_EVENTS_SVC).pipe(map(
      (data) => {
        this.events = data['data'];
      return this.events;
    }))
  }

  get() {
    return this.httpClient.get(environment.API_URL + this.EVENTS_SVC).pipe(map(
      (data) => {
        this.events = data['data'];
      return this.events;
    }))
  }

  getEventSummary() {
    return this.httpClient.get(environment.API_URL + this.SUMMARY_EVENTS_SVC).pipe(map(
      (data) => {
        this.events = data['data'];
      return this.events;
    }))
  }

  putEvent(updatedEvent, eventId) {
    let url = environment.API_URL + this.EVENTS_SVC + '/' + eventId;
    console.log(url);
    return this.httpClient.put(url, updatedEvent);
  }

  postEvent(event) {
    let url = environment.API_URL + this.EVENTS_SVC;
    console.log(event);

    return this.httpClient.post(url, event).pipe(map(
      (data) => {
        return data;
      }
    ));
  }

  deleteEvent(eventId) {
    let url = environment.API_URL + this.EVENTS_SVC + '/' + eventId;

    return this.httpClient.delete(url).pipe(map(
      (data) => {
        return data;
      }
    ));
  }
}
