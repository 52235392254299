import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PilotDetailsModel } from 'src/app/models/pilotdetails.model';
import { ProfileModel } from 'src/app/models/profile.model';
import { AuthService } from 'src/app/services/auth.service';
import { PilotService } from 'src/app/services/pilot.service';

@Component({
  selector: 'app-hrprofile',
  templateUrl: './hrprofile.component.html',
  styleUrls: ['./hrprofile.component.css']
})
export class HrprofileComponent implements OnInit {
  loading : boolean = false;
  staffPilot : PilotDetailsModel;
  profilePilot : ProfileModel;
  statsLinks : any[] = [];
  profilePilotId : number =0;
  perms = { "isStaff" : false, "canReject" : false, "catRating" : 0 };

  collapseUp : boolean = true;

  constructor(private route : ActivatedRoute, private authService : AuthService, private pilotService : PilotService, private router : Router) { }

  ngOnInit(): void {
    this.loadStaffPilot();
  }

  toggleCollapse() {
    this.collapseUp=!this.collapseUp;
  }

  loadStaffPilot() {
    this.loading = true;
    if (this.authService.isAuthenticated()) {
      let pilotId = this.authService.getPilotId();
      this.pilotService.getDetails(pilotId).subscribe(
        (pilot: PilotDetailsModel) => {
          console.log(pilot);
          this.staffPilot = pilot;
          this.loadPilotProfile();
        },
        (error) => {
          console.log('WARNING: ' + error.message);
          if (error.status === 500) {
            this.router.navigate(['/techerror']);
          }
        }
      );
    }
  }

  loadPilotProfile() {
    this.loading = true;
    if (this.authService.isAuthenticated()) {
      this.profilePilotId = this.route.snapshot.params.pilotId;
      this.pilotService.getProfile(this.profilePilotId).subscribe(
        (responseData) => {
          console.log(responseData);
          this.profilePilot = responseData['data']['attributes'];
          this.statsLinks = responseData['data']['statsLinks'];
          this.perms = { "isStaff" : responseData['isStaff'], "canReject" : responseData['canReject'], "catRating" : this.profilePilot.catRating};
          this.loading=false;
        },
        (error) => {
          this.loading=false;
        }
      )
    }
  }
}
