<form name="transferRequestForm" class="css-form" #transferRequestForm="ngForm">
    <div class="card border-info">
        <div class="card-header ">
            <div class="row">
                <div class="col-sm-8 mt-1">
                    ({{pilot.callsign}}) {{pilot.lastName}}, {{pilot.firstName}} of {{pilot.hubShortName}}  <span class="text-muted">[{{pilot.vatsimId}}]</span>
                </div>
                <div class="col-sm-4">
                    <button type="button" class="btn btn-sm btn-warning float-right" id="btnStartTransfer"
                        (click)="onTransferButtonClick()" style="font-size:.75em">
                        <i class="fab fa-telegram-plane"></i>&nbsp;Transfer
                    </button>
                </div>
            </div>
        </div>
        <div class="card-body" *ngIf="showTransferRow">
            <div class="row">
                <div class="col-md-3">
                    <label>Select a new hub:</label>
                    <select name="newHub" id="newHub" class="form-control" ngModel
                        #newHub="ngModel" required (change)="newHubSelected($event.target.value)">
                        <option *ngFor="let hiringHub of transferableHubs;let i = index" [value]="transferableHubs[i].hubId" >
                            {{transferableHubs[i].longName}}
                            ({{hiringHub.shortName}})
                        </option>

                    </select>
                    <div class="alert alert-danger"
                        *ngIf="newHub.invalid && (newHub.dirty || newHub.touched)">
                        <div ng-show="newHub.errors?.required">
                            You must chose a hub
                        </div>
                    </div>
                </div>
                <div class="col-md-3" >
                    <label>New Callsign:</label>
                    <select name="newCallsign" id="newCallsign" class="form-control" ngModel #newCallsign="ngModel"
                        required [disabled]="!newHub.dirty || !newHub.valid">
                        <option *ngFor="let callsign of availableCallsigns;let i = index"
                            [value]="callsign">
                            {{callsign}}
                        </option>
                    </select>
                    <div class="alert alert-danger"
                        *ngIf="newCallsign.invalid && (newCallsign.dirty || newCallsign.touched)">
                        <div *ngIf="newCallsign.errors?.required">
                            You must enter a Manager's Comment for your Transfer.
                        </div>
                    </div>
                </div>
                <div class="col-md-6" >
                    <label>Comments:</label>
                    <input type="text" id="comments" name="comments" class="form-control" ngModel
                        #comments="ngModel" required minlength="3" maxlength="100"
                        pattern="^[\w\-,.?#!()\s]+$" [disabled]="!newCallsign.valid && !newCallsign.dirty"/>
                    <div class="alert alert-danger"
                        *ngIf="comments.invalid && (comments.dirty || comments.touched)">
                        <div *ngIf="comments.errors?.required">
                            You must enter a Manager's Comment for your Transfer.
                        </div>
                        <div
                            *ngIf="comments.errors?.minlength || comments.errors?.maxlength || comments.errors?.pattern">
                            Manager's Comment is invalid: must be 10 to 100 alphanumeric characters plus -,.?#!()
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-md-12 ">
                    <button type="button" class="btn btn-sm btn-success float-right" *ngIf="!loading && comments.dirty && comments.valid" (click)="executeTransfer(transferRequestForm.value)">
                        <span class="spinner-border spinner-border-sm" *ngIf="loading" role="status"
                            aria-hidden="true"></span>
                        <i class="fas fa-thumbs-up"></i> Excecute Transfer
                    </button>
                    <button type="button" class="btn btn-success float-right disabled" *ngIf="loading ">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Transfering...
                    </button>
                </div>
            </div>
        </div>
    </div>
</form>

<app-avamodal [modalName]="modalName" [modalTitle]="modalTitle" [modalMessage]="modalMessage" [modalTitle]="modalTitle"
    [showMiniClose]="showMiniClose" [showCancelButton]="showCancelButton" [modalStatus]="modalStatus"
    [buttonText]="modalButtonText" [modalPurpose]="modalPurpose" (modalClosed)="onModalClosed($event)"></app-avamodal>