import { Component, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { DataSharingService } from 'src/app/services/datasharing.service';
import { DnrService } from 'src/app/services/dnr.service';

@Component({
  selector: 'app-dnrs',
  templateUrl: './dnrs.component.html',
  styleUrls: ['./dnrs.component.css']
})
export class DnrsComponent implements OnInit {
  loading : boolean = false;
  searchSuccessful : boolean = false;
  searchResults : any[] = [];
  hasMemberSearchResults : boolean = false;
  searchComplete : boolean = false;
  dnrToRemove : number = 0;
  dnrIndexToRemoveFromResults : number = 0;

  @Output() modalMessage: string = "";
  @Output() modalTitle: string = "";
  @Output() modalStatus: string = "";
  @Output() modalPurpose: string = "";
  @Output() showCancelButton: boolean = false;
  @Output() modalName : string;
  showMiniClose: boolean = false;

  constructor(private dss : DataSharingService, private dnrService : DnrService, private router : Router) { }

  ngOnInit(): void {
    this.dnrToRemove=0;
    this.searchResults = [];
    this.hasMemberSearchResults= false;
    this.dnrToRemove=0;
    this.modalName = 'dnrRemoveConfirmationModal';
  }

  handleSearchResults(searchResults) {
    this.searchResults = [];
    this.searchComplete=true;
    if (searchResults.length>0) {
      this.hasMemberSearchResults=true;
      this.searchResults = searchResults;
    } else {
      this.hasMemberSearchResults=false;
    }
    console.log(searchResults);
  }

  onRemoveFromDNR(member, index) {
    this.loading=true;
    console.log(member.dnrId);
    this.dnrToRemove = member.dnrId;
    this.dnrIndexToRemoveFromResults = index;

    console.log(this.dnrToRemove);

    this.modalStatus='danger';
    this.showMiniClose=false;
    this.showCancelButton=true;
    this.modalTitle = 'Remove from DNR';
    this.modalMessage = 'Are you sure you would like to remove ' + member.firstName + ' ' + member.lastName + ' from the DNR list?';
    this.modalPurpose = "removednr";
    this.dss.showModal.next({modalName : this.modalName, showModal :true});
  }

  onModalClosed(returnMessage: {status: string, purpose: string}) {
    this.showCancelButton=true;
    if (returnMessage.purpose == 'removednr' && returnMessage.status == 'ok') {
      this.removeMemberFromDNRList();
    } else if (returnMessage.status == 'cancel'){
      console.log('canceled out of the modal');
    }
    this.dss.showModal.next({modalName : this.modalName, showModal :false});
  }

  removeMemberFromDNRList() {
    console.log(this.dnrToRemove);

    this.dnrService.removeMemberFromDNRList(this.dnrToRemove).subscribe(
      (responseData) => {
        this.loading=false;
        this.dnrToRemove = 0;
        this.searchResults.splice(this.dnrIndexToRemoveFromResults,1);
        if (this.searchResults.length<1) {
          this.ngOnInit();
        }
      },
      (error) => {
        console.log(error.message);
        this.router.navigate(['techerror']);
      }
    );
  }
}
