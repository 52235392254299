<div class="container-fluid no-gutters" *ngIf="!loading">
    <div class="card shadow rounded-top rounded-lg">
        <div class="card-header panel-american-blue">
            <div class="row">
                <div class="col-6">
                    Pilot Profile - {{profile.callSign}}
                </div>
                <div class="col-6 d-flex justify-content-end">
                    <span>Hired on {{profile.hireDate}}</span>
                </div>
            </div>
        </div>
        <div class="card-body ">
            <div class="row ">
                <div class="col-md-12">
                    <div class="card ">
                        <div class="card-header bg-ava-info">
                            <div class="row">
                                <div class="col-6">
                                    Pilot Details
                                </div>
                                <div class="col-6 d-flex justify-content-end">
                                    Status:
                                    <span class="ml-1" *ngIf="profile.status=='Leave of Absence'" style="color:orange">
                                        {{profile.status}} until {{profile.statusDate}}
                                    </span>

                                    <span class="ml-1"
                                        *ngIf="profile.status!='ACTIVE' && profile.status!='Leave of Absence' && profile.status!='Probation'"
                                        style="color:red">
                                        {{profile.status}} requested on {{profile.statusDate}}
                                    </span>

                                    <span class="ml-1"
                                        *ngIf="profile.status=='ACTIVE' || profile.status=='Leave of Absence' || profile.status=='Probation'"
                                        style="color:green">
                                        {{profile.status}}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <app-profileform [profile]="profile" [pilotId]="pilotId"></app-profileform>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-md-12">
                    <div class="card ">
                        <div class="card-header bg-ava-info">
                            External Stats Resources <span style="font-size: .75em;color:orangered"> - (opens in new window)</span>
                        </div>
                        <div class="card-body">
                            <div class="row mb-2">
                                <div class="col-md-12">
                                    <span class="ml-3" *ngFor="let statLink of statsLinks">
                                        <a href="{{statLink.href}}" target="_blank">
                                            <img src="{{statLink.meta.icon}}"  alt="{{statLink.meta.text}}" height="32em"/>
                                        </a>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-md-12">
                    <div class="card ">
                        <div class="card-header bg-ava-info">
                            HR Info & Actions
                        </div>
                        <div class="card-body">
                            <div class="row mb-2">
                                <div class="col-md-12">
                                    <ul class="nav nav-pills">
                                        <li class="nav-item">
                                            <a [routerLink]="[]" class="nav-link " (click)="hrMenuClick('pr')"
                                                [ngClass]="{'active' : activeHRMenu=='pr'}">
                                                Personnel Records
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a [routerLink]="[]" class="nav-link " (click)="hrMenuClick('cp')"
                                                [ngClass]="{'active' : activeHRMenu=='cp'}">
                                                Change Password
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a [routerLink]="[]" class="nav-link " (click)="hrMenuClick('rl')"
                                                [ngClass]="{'active' : activeHRMenu=='rl'}" *ngIf="!profile.isLoa">
                                                Request LOA
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a [routerLink]="[]" class="nav-link " (click)="hrMenuClick('rt')"
                                                [ngClass]="{'active' : activeHRMenu=='rt'}">
                                                Request Transfer
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a [routerLink]="[]" class="nav-link " (click)="hrMenuClick('ra')"
                                                [ngClass]="{'active' : activeHRMenu=='ra'}"
                                                *ngIf="!profile.isTerminating">
                                                Resign from AvA
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="row" *ngIf="activeHRMenu=='pr'">
                                <div class="col-md-12">
                                    <table class="table table-hover responsive">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Date</th>
                                                <th scope="col">Action</th>
                                                <th scope="col">Comments</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let pr of profile.personnelRecords;let i = index">
                                                <th scope="row">{{i+1}}</th>
                                                <td>{{pr.Date_Action}}</td>
                                                <td>{{pr.Action}}</td>
                                                <td>{{pr.comments}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="row" *ngIf="activeHRMenu=='cp'">
                                <div class="col-md-12">
                                    <div class="card ">
                                        <div class="card-header bg-warning text-dark">Change Password</div>
                                        <div class="card-body">
                                            <app-changepasswordform [profile]="profile" [pilotId]="pilotId">
                                            </app-changepasswordform>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="activeHRMenu=='rl'">
                                <div class="col-md-12">
                                    <div class="card ">
                                        <div class="card-header bg-warning text-dark"> Request LOA</div>
                                        <div class="card-body" *ngIf="!profile.isLoa">
                                            <app-loaform [profile]="profile" [pilotId]="pilotId" (onLOARequestFormComplete)="onSubScreenComplete($event)"></app-loaform>
                                        </div>
                                        <div class="card-body" *ngIf="profile.isLoa">
                                            You are currently on LOA.
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="row" *ngIf="activeHRMenu=='rt'">
                                <div class="col-md-12">
                                    <div class="card ">
                                        <div class="card-header bg-warning text-dark"> Request Transfer</div>
                                        <div class="card-body">
                                            <app-transferform [profile]="profile" [pilotId]="pilotId" (onTransferRequestFormComplete)="onSubScreenComplete($event)">
                                            </app-transferform>
                                        </div>
                                        <div class="card-body" *ngIf="profile.isLoa">
                                            You are currently on transfer.
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row" *ngIf="activeHRMenu=='ra'">
                                <div class="col-md-12">
                                    <div class="card ">
                                        <div class="card-header bg-danger text-white "> Request Transfer</div>
                                        <div class="card-body">
                                            <app-resignationform [profile]="profile" [pilotId]="pilotId">
                                            </app-resignationform>
                                        </div>
                                        <div class="card-body" *ngIf="profile.isLoa">
                                            You are currently on transfer.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<span *ngIf="loading">
    <div class="d-flex justify-content-center " style="margin-top: 10em;margin-bottom: 10em;">
        <div class="spinner-border text-primary" style="width: 6rem; height: 6rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</span>