import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { DataSharingService } from 'src/app/services/datasharing.service';
import { HubRosterService } from 'src/app/services/hubroster.service';

@Component({
  selector: 'app-hubselector',
  templateUrl: './hubselector.component.html',
  styleUrls: ['./hubselector.component.css']
})
export class HubselectorComponent implements OnInit {
  @Output() onHubSelected = new EventEmitter<number>();
  @Input() hubId : number = 0;
  hubLinks : {hublink:number,shortName:string}[] = [];
  isActive : boolean = false;

  constructor(private router : Router, private dss : DataSharingService, private rosterService : HubRosterService) { }

  ngOnInit(): void {
    console.log('HUB LOADING....' + this.hubId);
    this.hubLinks = [];
    this.loadHubLinks();
  }

  loadHubLinks() {
    this.rosterService.getHRHubLinks().subscribe(
      (responseData : [{hublink:number,shortName:string}]) => {
        this.hubLinks = responseData;
        console.log(this.hubLinks);
      },
      (error) => {
        console.log('WARNING: ' + error.message);
        if (error.status === 500) {
          this.router.navigate(['/techerror']);
        }
      }
    )
  }

  onChangeHub(hubToLoad : number) {
    console.log(hubToLoad);
    this.hubId = hubToLoad;
    this.loadHubLinks();
    this.onHubSelected.emit(this.hubId);
  }
}
