<div class="row" *ngIf="hasVAHealthInfo">
    <div class="col-md-12">
        <div class="card shadow rounded-top rounded-lg" *ngIf="!loading"> 
            <div class="card-header text-white bg-ava-info">
                <div class="row">
                    <div class="col-md-12 text-dark">
                       VA Health Details:
                    </div>
                </div>
            </div>
            <div class="card-body ">
                <div class="row">
                    <div class="col-sm-4">
                        <canvas baseChart [data]="pieChartData" [labels]="pieChartLabels" [chartType]="pieChartType" [options]="pieChartOptions" [plugins]="pieChartPlugins" [colors]="pieChartColors" [legend]="pieChartLegend">
                        </canvas>
                    </div>
                    <div class="col-sm-4">
                        <div class="hhbox active-pilots"></div>
                        <div>&nbsp;&nbsp;{{vaHealthInfo.activePilots}} - Active Pilots</div><br />
                        <div class="hhbox inactive-pilots"></div>
                        <div>&nbsp;&nbsp;{{vaHealthInfo.inactivePilots}} - Inactive Pilots</div><br />
                        <div class="hhbox warned-pilots"></div>
                        <div>&nbsp;&nbsp;{{vaHealthInfo.warnedPilots}} - Warned Pilots</div><br />
                        <div class="hhbox loa-pilots"></div>
                        <div>&nbsp;&nbsp;{{vaHealthInfo.loaPilots}} - LOA Pilots</div><br />
                        <!-- <div class="hhbox probie-pilots"></div>
                        <div>&nbsp;&nbsp;{{vaHealthInfo.probiePilots}} - Probie Pilots</div> -->
                    </div>
                    <div class="col-sm-4">
                        <div class="hhbox bg-success"></div>
                        <div>&nbsp;&nbsp;{{vaHealthInfo.totalPilots}} - Total Pilots</div><br />
                        <div class="hhbox bg-warning"></div>
                        <div>&nbsp;&nbsp;{{vaHealthInfo.totalFlights}} - Flights Last 31 Days</div><br />
                    </div>                    
                </div>
                <!-- <hr class="aa-line"/> -->
                <!-- <app-hrhubroster [hubRoster]="hubRoster" [hubDetails]="hubDetails" [staffMember]="pilot" *ngIf="hasHubRoster" (onHRHubRosterUpdated)="onHRHubRosterUpdated($event)"></app-hrhubroster> -->
            </div>
        </div>   
    </div>
</div>

<div class="container text-center" *ngIf="loading">
    <div class="lds-hourglass"></div>
    <p>Loading...</p>
</div>