<div class="container-fluid no-gutters mt-2">
    <div class="card shadow rounded-top rounded-lg">
        <div class="card-header panel-american-blue-grey-red">
            <div class="row">
                <div class="col-12 sticky-top">
                    Policy Manual <span style="font-size: .75em" class="text-warning float-right">as of 01/22</span>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col border border-light border-rounded ml-3 ava-policy-manual" style="position: relative;">
                    <ul style="list-style-type:decimal;font-size:.9em;padding-left:0;position: fixed;">
                        <li><a href="policymanual/#Purpose">Purpose</a></li>
                        <li><a href="policymanual/#Mission">Mission</a></li>
                        <li><a href="policymanual/#Membership">Membership</a></li>
                        <li><a href="policymanual/#PilotDuties">Pilot Duties</a></li>
                        <li><a href="policymanual/#PilotReports">Pilot Reports</a></li>
                        <ul style="list-style-type:circle;font-size:.8em;padding-left:15px">
                            <li><a href="policymanual/#PIREPs">PIREPs</a></li>
                            <li><a href="policymanual/#SimulationRate">Simulation Rate</a></li>
                            <li><a href="policymanual/#ReportedTime">Reported Time</a></li>
                            <li><a href="policymanual/#TimeFormat">Time Format</a></li>
                            <li><a href="policymanual/#BonusTime">Bonus Time</a></li>
                        </ul>
                        <li><a href="policymanual/#RankStructureAircraftRatings">Ranks & Ratings</a></li>
                        <ul style="list-style-type:circle;font-size:.8em;padding-left:15px">
                            <li><a href="policymanual/#CurrentFleet">Current Fleet</a></li>
                            <li><a href="policymanual/#RetroFleet">Retro Fleet</a></li>
                            <li><a href="policymanual/#OneWorld">One World/Codeshare Aircraft</a></li>
                            <li><a href="policymanual/#CATRestrictions">CAT Restriction Wavier</a></li>
                            <li><a href="policymanual/#RetirementHub">Retirement Hub</a></li>
                            <li><a href="policymanual/#TrainingHub">Training Hub</a></li>
                        </ul>
                        <li><a href="policymanual/#OnlineBehavior">Online Behavior</a></li>
                        <!-- <ul style="list-style-type:circle;font-size:.8em;padding-left:15px">
                            <li><a href="policymanual/#Discord">Discord</a></li>
                            <li><a href="policymanual/#Facebook">Facebook</a></li>
                        </ul> -->
                    </ul>
                </div>
                <div class="col-sm-10" style="overflow-y: auto;height:auto">
                    <section id="Purpose">
                        <h2 id="PurposeHeader"><span class="bd-content-title">Purpose<a class="anchorjs-link " aria-label="Anchor" data-anchorjs-icon="#" href="#purpose" style="padding-left: 0.375em;"></a></span></h2>
                        <p>
                            This document is intended to provide the pilots and management of American Virtual Airlines (hereafter referred
                            to as AvA) with the information needed to successfully participate in this virtual airline.
                            The contents of this document are developed and maintained by AvA's Senior Staff. Any questions about this
                            document should be directed to the Chief Executive Officer at ceo&#64;joinava.org.
                            All AvA members must read, understand and abide by this General Operating Policies & Procedures document.
                        </p>
                        Failure to abide by the Policies and Procedures of this Document will result in:
                        <ul>
                            <li>Minor infraction - Oral Warning </li>
                            <li>Serious infraction - Written Warning</li>
                            <li>Repeated Serious Infraction - Termination</li>
                            <li>Severe infraction – Termination</li>
                        </ul>
                    </section>
                    <hr class="aa-line">
                    <section id="Mission">
                        <h2 id="MissionHeader"><span class="bd-content-title">Mission<a class="anchorjs-link " aria-label="Anchor" data-anchorjs-icon="#" href="#purpose" style="padding-left: 0.375em;"></a></span></h2>
                        <p>
                            The mission of American Virtual Airlines is to provide, for the simulation pilot, an environment in which:
                        <ul>
                            <li>
                                <p>
                                    The individual can learn and practice basic and advanced piloting skills using commercial flight simulation software.
                                </p>
                            </li>
                            <li>
                                <p>
                                    The individual can access information and tools to make their flight simulation experience more realistic and rewarding.
                                </p>
                            </li>
                            <li>
                                <p>
                                    The individual can have their progress tracked with respect to flight hours and rank.
                                </p>
                            </li>
                            <li>
                                <p>
                                    The individual can feel as part of a family of like-minded enthusiasts.
                                </p>
                            </li>
                            <li>
                                <p>
                                    The individual can have an enjoyable experience flying for AvA using the VATSIM network.
                                </p>
                            </li>
                        </ul>
                    </section>
                    <hr class="aa-line">
                    <section id="Membership">
                        <h2 id="MembershipHeader"><span class="bd-content-title">Membership<a class="anchorjs-link " aria-label="Anchor" data-anchorjs-icon="#" href="#purpose" style="padding-left: 0.375em;"></a></span></h2>
                        <p>
                            Candidate must be a minimum of Fifteen (15) years of age, possess and maintain valid email address and apply for membership via AvA Application.
                        </p>
                        <ul>
                            <li>New hires will be awarded 70% of their total VATSIM hours.</li>
                            <li>Rehires will be awarded the flight hours attained at the time they left AvA.</li>
                        </ul>
                        <p>
                            Candidate must possess, at the time of application (and throughout employment with AvA), a valid Pilot
                            Identification Number (PIN) with <a href="http://www.vatsim.net" target="_blank">VATSIM </a> and must agree to abide by all VATSIM Policies.
                        </p>
                        <p>
                            Candidate must not have a negative disciplinary record with VATSIM or with any other Virtual Airline (VA). <br />
                            <span class="text-danger ml-5 bg-light">
                                AvA reserves the right to decline applications of persons who are known to have disciplinary problems with VATSIM or another Virtual Airline (VA).
                            </span>
                        </p>
                        <p>
                            No member shall hold a membership in another American Airlines based VA nor log PIREPs for American Airlines
                            flights for NON-American Airlines based VA’s.
                        </p>
                        <p>
                            Members may hold membership in NON-American Airlines based VA’s but shall not file PIREPs with AvA for Flights
                            flown with any other VA.
                        </p>
                        <p>
                            Staff members may hold membership in NON-American Airlines based VA’s as a pilot only. They may not hold
                            any staff position in that VA.
                        </p>
                        <p>
                            The CEO and COO may NOT hold membership in any other VA
                        </p>
                    </section>
                    <hr class="aa-line">
                    <section id="PilotDuties">
                        <h2 id="PilotDutiesHeader"><span class="bd-content-title">Pilot Duties & Responsibilities<a class="anchorjs-link " aria-label="Anchor" data-anchorjs-icon="#" href="#purpose" style="padding-left: 0.375em;"></a></span></h2>
                        <p>
                            All Pilots of AvA must adhere to the following rules regardless of rating or Staff Position.
                        </p>
                        <p>
                            Pilots shall fly at least one flight of not less than Thirty (30) minutes in duration within the first Fourteen (14) days
                            of Membership acceptance.
                        </p>
                        <p>
                            Pilots shall subsequently fly no less than Two (2) qualifying flights per calendar month. A qualifying flight is:
                        </p>
                        <ul style="list-style-type:disc">
                            <li>A flight flown from start to finish online with <a href="http://www.vatsim.net" target="_blank">VATSIM </a>.</li>
                            <li>A flight of not less than Thirty (30) minutes in duration.</li>
                            <li>A flight in an aircraft for which the member is rated (See <a href="rankstructure">Rank Structure</a>).</li>
                            <li>A flight in an aircraft for which the member is rated (See Rank Structure).</li>
                            <li>A flight flown in a scheduled One World or Codeshare airframe using the call sign “AAL” and one’s assigned AvA Pilot ID (AALXXX) or the One World or Codeshare call sign and one’s assigned AvA Pilot ID (i.e
                                BAWXXX, QFAXX, HALXXX) for which the member is rated.</li>
                            <li>Flights may be conducted using AA/One World/Codeshare timetables using the aircraft assigned on that route providing it is within the Pilot’s CAT Rating.</li>
                            <li>A Charter Flight in the current AAL or Retro fleet, for which the pilot is rated, may be flown to/from any
                                departure/destination regardless of timetable.</li>
                            <li>A Code Share Flight <b>MUST</b> be a scheduled flight published in an appropriate time table. A Charter in a
                                Code Share aircraft or callsign is <b>NOT</b> authorized.</li>
                        </ul>
                        <p>
                            Pilots should carry the tag “joinava.org” in the Remarks section of each flight plan.
                        </p>
                        <p>
                            Pilots may use aircraft outside of what they are rated for during selected Group Flights/Events (such as Cross the
                            Pond), and Monthly Featured Destinations. CAT Waivers will be identified in the Event Details.
                        </p>
                        <p>
                            Pilots shall log each qualifying flight using either the Manual PIREP form or one of the Automated ACARS programs
                            used by AvA. See Pilot Reports for more info.
                        </p>
                        <p>
                            Pilots shall request a Leave of Absence (LOA) via the Pilot Menu, of not more than Ninety (90) days when
                            circumstance arise that prevent the completion of Two (2) qualifying flights per month.
                        </p>
                        <ul>
                            <li>Request must be made prior to being “Warned” of failing to meet the monthly qualifying flight rule.</li>
                            <li>LOA’s more than Ninety (90) days may be approved for members of the Armed Forces who will be
                                deployed. This approval must come from the VP-HR, COO, or CEO</li>
                            <li>Members may take a maximum of Ninety (90) days in any calendar year.</li>
                        </ul>
                        <p>
                            Pilots shall check for any AvA Correspondence via email at least once weekly and respond when requested to do
                            so in a timely manner and ensure contact email is current and correct
                        </p>
                        <ul>
                            <li>Email address can be changed using the Pilot Tab under the Profile tab</li>
                            <li>Email address may be “blocked” from public access by checking the appropriate radio button in the same
                                “Edit Profile” link. Staff will still always be able to see members’ email address</li>
                            <li>Invalid emails (those that “bounce”) will be brought to the attention of HM’s and VP-HR who will make
                                reasonable attempts to contact the member. If unsuccessful, member may be terminated.</li>
                        </ul>
                        <p>
                            Members are assigned to a Hub for oversight only. Members are not restricted to flying into or out of assigned
                            hub. Members may request Hub Transfers after being in one’s Hub for at least 90 days.
                        </p>
                    </section>
                    <hr class="aa-line">
                    <section id="PilotReports">
                        <h2 id="PilotReportsHeader"><span class="bd-content-title">Pilot Reports<a class="anchorjs-link " aria-label="Anchor" style="padding-left: 0.375em;"></a></span></h2>
                    </section>
                    <section id="PIREPs">
                        <h3 id="PIREPSHeader"><span class="bd-content-subtitle">PIREPS<a class="anchorjs-link " aria-label="Anchor" style="padding-left: 0.375em;"></a></span></h3>
                        <p>A Pilot Reports (PIREP) is a log of each flight from start to finish and contains the following info:</p>
                        <ul>
                            <li><b>Departure Airport</b>: Airport ICAO code (e.g. KLAX, EDDM, YSSY)</li>
                            <li><b>Departure Time</b>: The time the aircraft “pushed” back from the Gate in 24 hr. Zulu or UTC time (23:30z)</li>
                            <li><b>Destination Airport</b>: Airport ICAO code (e.g. KLAX, EDDM, YSSY)</li>
                            <li><b>Arrival Time</b>: The time the aircraft arrived at the Gate in 24 hr. Zulu or UTC time (23:30z)</li>
                            <li><b>Date of Flight</b>: The “Zulu” date that the flight was started. </li>
                            <li><b>Equipment Flown</b>: Aircraft type used.</li>
                            <li><b>Passengers</b>: Number of Passengers flown.</li>
                            <li><b>Fuel Used</b>: Total weight in lbs. of fuel burned for flight.</li>
                            <li><b>Cargo Weight</b>: Weight of cargo flown on flight</li>
                            <li><b>Route</b>: The route that the aircraft flew from departure to destination. The same route that was listed in the VATSIM Flight Plan</li>
                            <li><b>Remarks</b>: If you are using either of ACARS, for now, you <b>MUST</b> enter <i>joinava.org</i> in the remarks in order for the automated flight tracker to pickup your flight</li>
                        </ul>
                        <p>
                            PIREPs shall be submitted within <b>Three (3) days</b> of when the flight was flown.
                        </p>
                        <p>
                            PIREPs shall be checked for accuracy. PIREPs which may have been in error will be deleted and refiled.
                        </p>
                        <p>
                            Members shall make all flights for AvA while online and while using the VATSIM system. Offline flights are not
                            valid for purposes of submitting a PIREP. A flight must be flown online in its entirety from start to finish.
                        </p>
                        <p>
                            Only VATSIM flights are valid. Flights flown on other Internet systems are not valid for purposes of submitting an
                            AvA PIREP.
                        </p>
                        <p>
                            Long Haul flights by members required to fly online may be paused and saved, then later resumed online if there
                            is a need to be away from the computer for more than 30 minutes.
                        </p>
                        <p>
                            Time logged offline shall not be counted in any PIREP, except for those short periods of time where automatic
                            disconnects occur.
                        </p>
                        <p>
                            Flights found to be in violation of the above policies shall be deleted by Hub Staff (HM/AHM) or Senior Staff.
                        </p>
                    </section>
                    <section id="SimulationRate">
                        <h3 id="SimRateHeader"><span class="bd-content-subtitle">Simulation Rate<a class="anchorjs-link " aria-label="Anchor" style="padding-left: 0.375em;"></a></span></h3>
                        <p>All PIREP flights shall be flown at 1x simulation speed, from beginning to end. Accelerated flights and flights where
                            any portion of the flight is spent in accelerated flight are not valid for PIREPs. </p>
                    </section>
                    <section id="ReportedTime">
                        <h3 id="RTHeader"><span class="bd-content-subtitle">Reported Time<a class="anchorjs-link " aria-label="Anchor" style="padding-left: 0.375em;"></a></span></h3>
                        <p>Flight times reported on the PIREP shall be reported as the time the parking brake is released at the departure
                            gate and set at the arrival gate. On-the-ground gate hold times shall not be included in PIREP hours. </p>
                    </section>
                    <section id="TimeFormat">
                        <h3 id="TFHeader"><span class="bd-content-subtitle">Time Format<a class="anchorjs-link " aria-label="Anchor" style="padding-left: 0.375em;"></a></span></h3>
                        <p>PIREPs submitted shall reference Zulu or (UTC) time and Zulu date, unless otherwise noted in the notes/comments
                            section of the PIREP. The date of flight shall reflect the Zulu date at the time of engine start. </p>
                    </section>
                    <section id="BonusTime">
                        <h3 id="BTHeader"><span class="bd-content-subtitle">Bonus Time<a class="anchorjs-link " aria-label="Anchor" style="padding-left: 0.375em;"></a></span></h3>
                        <p>All flights are credited with real-time hours flown. There are no double-hours or triple-hours programs</p>
                    </section>
                    <hr class="aa-line">
                    <section id="RankStructureAircraftRatings">
                        <h2 id="RSHeader"><span class="bd-content-subtitle">Rank Structure & Aircraft Ratings<a class="anchorjs-link " aria-label="Anchor" style="padding-left: 0.375em;"></a></span></h2>
                    </section>
                    <section id="CurrentFleet">
                        <h3 id="CFHeader"><span class="bd-content-subtitle">Current Fleet<a class="anchorjs-link " aria-label="Anchor" style="padding-left: 0.375em;"></a></span></h3>
                        <div class="table-responsive">
                            <table class="table table-bordered">
                                <thead class="text-center bg-info">
                                    <tr>
                                        <th scope="col">Pilot Hours</th>
                                        <th scope="col">Title</th>
                                        <th scope="col">Category</th>
                                        <th scope="col">Equipment You Can Fly</th>
                                    </tr>
                                </thead>
                                <tbody class="text-center">
                                    <tr>
                                        <th scope="row">0 - 149.4</th>
                                        <td>Captain</td>
                                        <td>Category III</td>
                                        <td>
                                            <ul style="list-style-type: none;">
                                                <li>ERJ-140/145/175 </li>
                                                <li>CRJ-200/700/900 </li>
                                                <li>E190</li>
                                                <li>Boeing 737-800/MAX</li>
                                                <li>Boeing MD-80</li>
                                                <li>Airbus A319/320/321</li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">150 - 399.9</th>
                                        <td>Senior Captain</td>
                                        <td>Category IV</td>
                                        <td>
                                            <p>Category III craft plus…</p>
                                            <ul style="list-style-type: none;">
                                                <li>Boeing 757-200</li>
                                                <li>Boeing 767-300</li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">400 - 899.9</th>
                                        <td>Command Captain</td>
                                        <td>Category V</td>
                                        <td>
                                            <p>Category IV craft plus…</p>
                                            <ul style="list-style-type: none;">
                                                <li>Airbus A330-200/30</li>
                                                <li>Boeing 787-8/9</li>
                                                <li>Boeing 777-200ER/300ER</li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">900+</th>
                                        <td>Senior Command Captain</td>
                                        <td>Category VI</td>
                                        <td>
                                            <p>Category V craft plus…</p>
                                            <ul style="list-style-type: none;">
                                                <li>Concorde</li>
                                            </ul>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </section>
                    <section id="RetroFleet">
                        <h3 id="RFHeader"><span class="bd-content-subtitle">ReTro Fleet<a class="anchorjs-link " aria-label="Anchor" style="padding-left: 0.375em;"></a></span></h3>
                        <ul>
                            <li>CAT III – Turbo Props or Light Jet aircraft, and Twin Engine Jet aircraft: MToW of Less than 250,000Lbs.</li>
                            <li>CAT IV – Jet aircraft: MToW of more than 250,000 Lbs. but Less than 450,000 Lbs. </li>
                            <li>CAT V – Heavy Jet aircraft: (MToW greater than 450,000Lbs).</li>
                        </ul>
                    </section>
                    <section id="OneWorld">
                        <h3 id="OWHeader"><span class="bd-content-subtitle">One World/Codeshare Aircraft<a class="anchorjs-link " aria-label="Anchor" style="padding-left: 0.375em;"></a></span></h3>
                        <p>Pilots must adhere to the normal AvA CAT Restrictions for all code share Flights. </p>
                        <p>Aircraft operated by code share Partners of the same type used by AvA are subject the CAT Restrictions outlined
                            in Table above.
                        </p>
                        Aircraft operated by code share Partners that are not used by AvA will adhere to the following guidelines:
                        <ul>
                            <li>CAT III – Turbo Props or Light Jet aircraft, and Twin-Engine Jet aircraft: MToW of Less than 250,000Lbs.</li>
                            <li>CAT IV – Jet aircraft: MToW of more than 250,000 Lbs. but Less than 450,000 Lbs.</li>
                            <li>CAT V – Heavy Jet aircraft: (MToW greater than 450,000Lbs)</li>
                        </ul>
                    </section>
                    <section id="CATRestrictions">
                        <h3 id="CRHeader"><span class="bd-content-subtitle">CAT Restriction Waiver<a class="anchorjs-link " aria-label="Anchor" style="padding-left: 0.375em;"></a></span></h3>
                        Category restrictions are waived (pilot may operate any equipment he/she chooses) for the following activities:
                        <ul>
                            <li>
                                Sanctioned AvA regional events; these will be posted on the AvA Events Page and specifically mention waiver of category restrictions such as the MNGF.
                            </li>
                            <li>
                                Sanctioned regional VATSIM fly-in events; for example, Cross the Pond®, Washington Squared®, etc., where long-range aircraft would be required.
                            </li>
                            <li>
                                Flying into or out of the monthly “Featured Destination”.
                            </li>
                            <li>
                                CAT VI pilots may fly the Concorde aircraft into and out of the featured destination with British Airways call signs.
                            </li>
                            <li>
                                All pilots may fly the 744 aircraft into and out of the featured destination.
                            </li>
                        </ul>
                        <b>Note</b>
                        <ul>
                            <li>
                                ARTCC events and events where the pilot has a choice of departure or destination where they would be able to remain within their CAT restrictions are subject to category restrictions.
                            </li>
                            <li>
                                AvA shall post, on its website, significant events that are eligible for release of category restrictions. Only these posted events are eligible for waiver of category restrictions.
                            </li>
                        </ul>
                    </section>
                    <section id="RetirementHub">
                        <h3 id="RHHeader"><span class="bd-content-subtitle">Retirement Hub<a class="anchorjs-link " aria-label="Anchor" style="padding-left: 0.375em;"></a></span></h3>
                        <p>
                            A retirement hub has been established for long time members who may not have the time they once had and
                            don’t want to resign or be terminated for inactivity. This hub is based in Tucson and uses the 900’s for callsigns.
                            There shall be no Hub Manager in the retirement hub. All administrative duties fall upon the CEO and VP-HR.
                        </p>
                        <b>Retirement Hub Eligibility</b>
                        <ul>
                            <li>
                                Former CEOs
                            </li>
                            <li>Admirals</li>
                            <li>
                                Members in good standing who have at least 5,000 hours with American Virtual Airlines
                            </li>
                            <li>
                                Member must make a written request to and be approved by Senior Leadership
                            </li>
                        </ul>
                        <b>Retirement Hub Rules</b>
                        <ul>
                            <li>
                                There are no minimum flights/hours per month.
                            </li>
                            <li>
                                Stats will not be made public for the Retirement Hub but will count toward VA totals.
                            </li>
                            <li>
                                Retired members are not eligible for any monthly award/recognition.
                            </li>
                            <li>
                                Members of the retirement hub Shall not hold any AvA Leadership role at any level.
                            </li>
                            <li>
                                Retirees may fly as much or as little as they want.
                            </li>
                            <li>
                                Retirees shall not fly for any other American Airlines based VA.
                            </li>
                            <li>
                                Retired member may request transfer back to active roster and will then be required to meet all requirements of AvA Pilots.
                            </li>
                            <li>
                                Retired Hub Members will not be removed from the roster for Inactivity.
                            </li>
                        </ul>
                    </section>
                    <section id="TrainingHub">
                        <h3 id="THHeader"><span class="bd-content-subtitle">Training Hub<a class="anchorjs-link " aria-label="Anchor" style="padding-left: 0.375em;"></a></span></h3>
                        <p>
                            When a candidate pilot applies to join American Virtual Airlines, they will be automatically assigned to the training hub and given a temporary
                            callsign in the 1000's range if they meet the following conditions:
                        </p>
                        <ul>
                            <li>
                                The candidate member has over 75 hours flying on VATSIM
                            </li>
                            <li>
                                They are NOT on our Do Not ReHire (DNR) list
                            </li>
                            <li>
                                They are NOT already a member
                            </li>
                            <li>
                                They do not have a current application pending
                            </li>
                            <li>
                                Their VATSIM ID is not suspended
                            </li>
                        </ul>
                        <p>
                            <b>NOTE:</b> If they have under 75 hours and/or they are on our DNR list, the application
                            is put into a REVIEW state and a member of the staff will need to review the application before accepting it. If the application is accepted, then the pilot
                            will be added automatically to the Training Hub.
                            All other issues will result in an automatic DENIAL of the application.
                        </p>
                    </section>
                    <hr class="aa-line" />
                    <section id="OnlineBehavior">
                        <h2 id="OBHeader"><span class="bd-content-subtitle">Online Behavior<a class="anchorjs-link " aria-label="Anchor" style="padding-left: 0.375em;"></a></span></h2>
                        <p>
                            Members are welcome and encouraged to join all our Social Media outlets and shall conduct one’s self in a
                            respectful manner (<a href="https://en.wikipedia.org/wiki/Golden_Rule" target="_blank">The Golden Rule</a>) in all communications. Disrespectful, profane, or abusive language shall not
                            be tolerated in any of AvA’s Social Media outlets and may lead to disciplinary up to and including Termination.
                        </p>
                    </section>
                </div>
                <div class="goToTop" (click)="tabTop();" [ngClass]="{ 'show-scrollTop': windowScrolled }" *ngIf="windowScrolled">
                    <a  title="Scroll up"><i class="fas fa-arrow-circle-up"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>
