import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationModel } from 'src/app/models/application.model';
import { HiringHubModel } from 'src/app/models/hiringhub.model';
import { ApplicationService } from 'src/app/services/application.service';
import { HubService } from 'src/app/services/hub.service';

@Component({
  selector: 'app-acceptbox',
  templateUrl: './acceptbox.component.html',
  styleUrls: ['./acceptbox.component.css']
})
export class AcceptboxComponent implements OnInit {
  isLoading = false;
  hiringHubs : HiringHubModel[] = [];
  hubChosen : boolean = false;
  callsignPicked : boolean = false;
  availableCallsigns: string[];
  pickedCallsign : string = '';
  isNewHire : boolean = false;
  initialHours : number = 0;
  appId : number = 0;

  @Input() application : ApplicationModel;
  @Output() onAppAcceptUpdate = new EventEmitter<string>();

  constructor(private appService : ApplicationService, private hubService : HubService, private router : Router) { }

  ngOnInit(): void {
    this.appId=this.application.attributes.appId;
    this.hubChosen=false;
    this.calcHours();
    this.loadHiringHubs();
  }

  calcHours() {
    if (this.application.attributes.dismissalRecords.length>0) {
      this.isNewHire=false;
    } else {
      this.isNewHire=true;
    }
    this.initialHours = this.application.attributes.vatsimData.hours.pilot;
  }
  loadHiringHubs() {
    this.hubService.getHiringHubs().subscribe(
      (responseData: HiringHubModel[]) => {
        this.hiringHubs = responseData;
        //add trining hub??  not sure i like this here....
        let trainingHub = new HiringHubModel;
        trainingHub.hubId=10;
        trainingHub.longName="Tulsa, OK - Training";
        trainingHub.shortName="TUL";
        if (this.initialHours*.7<50) {
          this.hiringHubs = [];
        }
        this.hiringHubs.push(trainingHub);
      },
      (error) => {
        this.router.navigate(['/techerror']);
      }
    );
  }

  acceptSubmit(acceptForm) {
    console.log(acceptForm);
    // 'callsign' => 'AAL699',
    // 'initialHours' => '999',
    // 'hubId' => '6'

    let payload = {
      callsign : acceptForm.callsignChoice,
      initialHours : this.initialHours,
      hubId : acceptForm.hubChoice
    }

    console.log(payload);

    this.appService.acceptApplication(this.appId,payload).subscribe(
      (responseData) => {
        console.log(responseData);
        this.onAppAcceptUpdate.emit('accepted');
      },
      (error) => {
        this.router.navigate(['/techerror']);
      }
    )
  }

  acceptCancel() {
    this.onAppAcceptUpdate.emit("cancel");
  }

  onHubChoice(hubChoice) {
    console.log(hubChoice);
    this.hubChosen=true;
    this.loadAvailableCallsignsForHub(hubChoice);

  }

  onCallsignChoice(pickedCallsign) {
    this.pickedCallsign=pickedCallsign;
    this.callsignPicked=true;
  }
  loadAvailableCallsignsForHub(hubId) {
    this.hubService.getAvailableCallsignsForAHub(hubId).subscribe(
      (responseData) => {
        this.availableCallsigns = responseData;
        console.log(this.availableCallsigns);
        this.isLoading=false;
      },
      (error) => {
        console.log(error);
        this.router.navigate(['/techerror']);
        this.isLoading=false;
        //tod
      }
    );
  }
}
