import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  constructor(private authService : AuthService, private router : Router) {}
  title = 'American Virtual Airlines 2001-2022';
  googleMapUrl = environment.GOOGLE_MAP_URL + environment.GOOGLE_MAP_KEY;

  ngOnInit(): void {
    this.loadMapURL();
    if (this.authService.isAuthenticated() ) {
      let pilotId = this.authService.getPilotId();
      console.log('Loading data for pilot: ' + pilotId, this.router.url);
      // if (this.router.url==='/') {
      //   this.router.navigate(['/pilot',pilotId,'lastflight']);
      // }
    } else {
      // this.router.navigate(['/']);
    }
  }

  //dynamically add google map API script link to the page so we don't hardcode it in the code.
  loadMapURL() {
    const script = document.createElement('script');
    script.src=this.googleMapUrl;
    document.body.appendChild(script);
  }

}
