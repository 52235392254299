import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.css']
})
export class AboutusComponent implements OnInit {
  loading : boolean = false;
  auLinks = [
    {
      href: "/policymanual#mission",
      meta: {
        menuText: "Mission",
        icon: "fas fa-compass"
      }
    },
    {
      href: "/history",
      meta: {
        menuText: "History",
        icon: "fas fa-history"
      }
    },
    {
      href: "/orgchart",
      meta: {
        menuText: "Org Chart ",
        icon: "fas fa-sitemap"
      }
    },
    {
      href: "/genericroster",
      meta: {
        menuText: "Active Roster",
        icon: "fas fa-clipboard-list"
      }
    },    
    {
      href: "/policymanual",
      meta: {
        menuText: "Policy manual",
        icon: "fas fa-book"
      }
    },
    {
      href: "/policymanual#RankStructureAircraftRatings",
      meta: {
        menuText: "Rank Structure",
        icon: "fas fa-atom"
      }
    },
    {
      href: "/eventcalendar",
      meta: {
        menuText: "Event Calendar",
        icon: "fas fa-calendar-alt"
      }
    },  
    {
      href: "/stats",
      meta: {
        menuText: "AvA Statistics",
        icon: "fas fa-chart-pie"
      }
    },    
    {
      href: "/faq",
      meta: {
        menuText: "FAQ",
        icon: "fas fa-question"
      }
    },    
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
