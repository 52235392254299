import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BrowserDynamicTestingModule } from '@angular/platform-browser-dynamic/testing';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { DataSharingService } from 'src/app/services/datasharing.service';

@Component({
  selector: 'app-loginform',
  templateUrl: './loginform.component.html',
  styleUrls: ['./loginform.component.css']
})
export class LoginformComponent implements OnInit {
  @Output() onLoginFailure = new EventEmitter<string>();
  loginErrorMessage='';
  pilotId=0;
  loading=false;
  loginFailureCounter : number = 0;

  @ViewChild('loginForm') loginForm : NgForm;

  constructor(private authService: AuthService, private router : Router, private dss : DataSharingService) { }

  ngOnInit(): void {
    this.loginFailureCounter=0;
  }

  isAuthenticated() {
    return this.authService.isAuthenticated();
  }

  private callService(creds) {
    this.authService.login(creds).subscribe(
      (data) => {
        let isManager = false;
        this.authService.setToken(data['token']);
        if (data['lvl']>1) {
          this.authService.setIsManager(true);
          isManager=true;
        } else {
          this.authService.setIsManager(false);
        }

        if (this.authService.isAuthenticated()) {
          this.pilotId = this.authService.getPilotId();
          this.dss.isManager.next(isManager);
          this.dss.isAuthenticated.next(true);
          //TODO move this modal display logic into an event response type of thing vs coding it
          document.body.classList.remove("modal-open");
          var modalele = document.body.getElementsByClassName('modal-backdrop fade show');
          modalele[0].remove();
          this.router.navigate(['/pilot',this.pilotId,'lastflight']);
        }
      },
      (error) => {
        switch (error.status) {
          case 401:
            this.loginErrorMessage = "Callsign or password incorrect!  Please try again.";
            this.loginFailureCounter++;

            if (this.loginFailureCounter > 2) {
              this.onLoginFailure.emit('failure');
            }
            break;

          default:
            this.loginErrorMessage= "An expected error occurred.  Please contact your system administrator.";
            break;
        };
        this.loginForm.reset();
        this.loading=false;
      }
    );
  }

  onSubmit(creds : { callsign : string; pwd : string}) {
    this.loading=true;

    this.callService(creds);

  }
}
