<div class="card shadow rounded-top rounded-lg mt-2" *ngIf="!loading">
    <div class="card-header panel-american-blue-grey-red">
        <div class="row p-0">
            <div class="col-xl p-0">
                Fight Hours
            </div>
        </div>
    </div>
    <div class="card-body">
        <div class="row p-0">
            <div class="col-xl p-0">
                <div class="card mt-2 rounded-top">
                    <div class="card-header">
                        <b>{{landingStats.monthlyRateByPilot.title}}</b>
                    </div>
                    <div class="card-body">
                        <div class="flex-item">
                            <div>
                                <canvas baseChart width="400" height="150px" id="monthlyByPilotReport" 
                                    [datasets]="monthlyByPilotChartData" 
                                    [labels]="landingStats.monthlyRateByPilot.labels" 
                                    [colors]="COLOR_GRID" 
                                    [legend]="lineChartLegend" 
                                    [chartType]="horizonaltalBarChartType">
                                </canvas>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row p-0">
            <div class="col-xl p-0">
                <div class="card mt-2 rounded-top">
                    <div class="card-header">
                        <b>{{landingStats.yearlyRateByPilot.title}}</b>
                    </div>
                    <div class="card-body">
                        <div class="flex-item">
                            <div>
                                <canvas baseChart width="400" height="150px" id="yearlyRateByPilotReport" 
                                    [datasets]="yearlyByPilotChartData" 
                                    [labels]="landingStats.yearlyRateByPilot.labels" 
                                    [colors]="COLOR_GRID" 
                                    [legend]="lineChartLegend" 
                                    [chartType]="horizonaltalBarChartType">
                                </canvas>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row p-0">
            <div class="col-xl p-0">
                <div class="card mt-2 rounded-top">
                    <div class="card-header">
                        <b>{{landingStats.monthlyRateByHub.title}}</b>
                        <span class="badge badge-info float-right ">Total: {{landingStats.monthlyRateByHub.totalAvgRate | number:'1.0-0'}}</span>
                    </div>
                    <div class="card-body">
                        <div >
                            <div>
                                <canvas baseChart width="400" height="150px" id="monthlyRateByHubReport" 
                                    [datasets]="monthlyByHubChartData" 
                                    [labels]="landingStats.monthlyRateByHub.labels" 
                                    [colors]="COLOR_GRID" 
                                    [legend]="lineChartLegend" 
                                    [chartType]="barChartType">
                                </canvas>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
        <div class="row p-0">
            <div class="col-xl p-0">
                <div class="card mt-2 rounded-top">
                    <div class="card-header">
                        <b>{{landingStats.yearlyRateByHub.title}}</b>
                        <span class="badge badge-info float-right ">Total: {{landingStats.yearlyRateByHub.totalAvgRate | number:'1.0-0'}}</span>
                    </div>
                    <div class="card-body">
                        <div >
                            <div>
                                <canvas baseChart width="400" height="150px" id="yearlyByHubReport" 
                                    [datasets]="yearlyByHubChartData" 
                                    [labels]="landingStats.yearlyRateByHub.labels" 
                                    [colors]="COLOR_GRID" 
                                    [legend]="lineChartLegend" 
                                    [chartType]="barChartType">
                                </canvas>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
        <div class="row p-0">
            <div class="col-xl p-0">
                <div class="card mt-2 rounded-top">
                    <div class="card-header">
                        <b>{{landingStats.yearlyRateTotals.title}}</b>
                        <span class="badge badge-info float-right ">Total: {{landingStats.yearlyRateTotals.totalAvgRate | number:'1.0-0'}}</span>
                    </div>
                    <div class="card-body">
                        <div >
                            <div>
                                <canvas baseChart width="400" height="150px" id="yearlyRateTotalsReport" 
                                    [datasets]="yearlyTotalsChartData" 
                                    [labels]="landingStats.yearlyRateTotals.labels" 
                                    [colors]="COLOR_GRID" 
                                    [legend]="lineChartLegend" 
                                    [chartType]="barChartType">
                                </canvas>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>                            
        <div class="goToTop" (click)="tabTop();" [ngClass]="{ 'show-scrollTop': windowScrolled }" *ngIf="windowScrolled">
            <a  title="Scroll up"><i class="fas fa-arrow-circle-up"></i></a>
        </div>
    </div>
</div>

<div class="container text-center" *ngIf="loading">
    <div class="lds-hourglass"></div>
    <p>Loading...</p>
</div>
