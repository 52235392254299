<form name="notamDetailForm" class="css-form" #notamDetailForm="ngForm" (ngSubmit)="onSubmit(notamDetailForm.value)">
    <div class="row">
        <div class="col-md-3 ">
            <div class="form-group">
                <label for="startDate">Start Date:</label>
                <div class="input-group ngb-dp-today ">
                    <input class="form-control" placeholder="yyyy-mm-dd" name="startDate" [(ngModel)]="startDate" ngbDatepicker #d="ngbDatepicker" required (dateSelect)="recalcVis(startDate,endDate)">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"><i class="far fa-calendar-alt"></i></button>
                    </div>
                    <div class="alert alert-danger" *ngIf="hasDateError">
                        <div *ngIf="hasDateError">
                            Please check your dates!
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3 ">
            <div class="form-group">
                <label for="endDate">End Date:</label>
                <div class="input-group ngb-dp-today ">
                    <input class="form-control" placeholder="yyyy-mm-dd" name="endDate" [(ngModel)]="endDate" ngbDatepicker #d1="ngbDatepicker" required (dateSelect)="recalcVis(startDate,endDate)">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()" type="button"><i class="far fa-calendar-alt"></i></button>
                    </div>
                </div>
                <div class="alert alert-danger" *ngIf="hasDateError">
                    <div *ngIf="hasDateError">
                        Please check your dates!
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6 justify-text-right">
            <span class="float-right" [ngClass]="{'showmetheeye': isVisible, 'hidetheeye' : !isVisible}">
                <i class="fas fa-eye" title="Green - notam visible.  Red - notam not visible."></i>
            </span>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="form-group">
                <label for="txtTitle">Title:</label>
                <input type="text" id="txtTitle" name="txtTitle" class="form-control" #txtTitle="ngModel" ngModel [(ngModel)]="notam.title" minlength="10" maxlength="50" pattern="[\w\s\-,.?#!\/'\(\)]+" required />
                <div class="alert alert-danger" *ngIf="txtTitle.invalid && (txtTitle.dirty || txtTitle.touched)">
                    <div *ngIf="txtTitle.errors?.required">
                        You must enter an Notam Title.
                    </div>
                    <div *ngIf="txtTitle.errors?.minlength || txtTitle.errors?.maxlength || txtTitle.errors?.pattern">
                        Notam Title is invalid: must be 10 to 50 alphanumeric characters plus -,.?#!/'()
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12">
            <div class="form-group">
                <label for="txtMessage">Message:</label>
                <input type="text" id="txtMessage" name="txtMessage" class="form-control" #txtMessage="ngModel" ngModel [(ngModel)]="notam.message" minlength="10" maxlength="100" pattern="[\w\s\-,.?#!\/'\(\)]+" required />
                <div class="alert alert-danger" *ngIf="txtMessage.invalid && (txtMessage.dirty || txtMessage.touched)">
                    <div *ngIf="txtMessage.errors?.required">
                        You must enter an Notam Description.
                    </div>
                    <div *ngIf="txtMessage.errors?.minlength || txtMessage.errors?.maxlength || txtTitle.errors?.pattern">
                        Notam Description is invalid: must be 10 to 150 alphanumeric characters plus -,.?#!/'()
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row ">
        <div class="container">
            <label>Visibility:</label>
            <div class="col-sm-3 ">
                <input type="radio" id="rdoVis" name="rdoVis" [(ngModel)]='notam.visibility' required value='public' #rdoVis /> Public?
            </div>
            <div class="col-sm-3">
                <input type="radio" id="rdoVis" name="rdoVis" [(ngModel)]='notam.visibility' value='pilots' #rdoVis /> Pilots Only?
            </div>
            <div class="col-sm-3">
                <input type="radio" id="rdoVis" name="rdoVis" [(ngModel)]='notam.visibility' value='staff' #rdoVis /> Staff Only?
            </div>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-sm-12 ">
            <button type="button" class="btn btn-secondary" *ngIf="showCancel" (click)="cancelNotamAdd()">
                Cancel
            </button>
            <button type="button" class="btn btn-danger" *ngIf="showDelete" (click)="onDeleteClick()">
                <i class="fas fa-times mr-2"></i>Delete
            </button>
            <button type="submit" class="btn btn-success float-right" [disabled]="notamDetailForm.invalid || !notamDetailForm.dirty">
                <i class="fas fa-check mr-2"></i>
                <span *ngIf="!isNew">Update</span>
                <span *ngIf="isNew">Add</span>
            </button>
        </div>
    </div>
</form>

<app-avamodal [modalName]="modalName" [modalMessage]="modalMessage" [modalTitle]="modalTitle" [showMiniClose]="showMiniClose" [showCancelButton]="showCancelButton" [modalStatus]="modalStatus" [buttonText]="'Ok'" [modalPurpose]="modalPurpose" (modalClosed)="onModalClosed($event)"></app-avamodal>
