import { Component, Input, OnInit } from '@angular/core';
import { PilotDetailsModel } from 'src/app/models/pilotdetails.model';
import { ProfileModel } from 'src/app/models/profile.model';
import { PilotService } from 'src/app/services/pilot.service';

@Component({
  selector: 'app-hrpilotprofile',
  templateUrl: './hrpilotprofile.component.html',
  styleUrls: ['./hrpilotprofile.component.css']
})
export class HrpilotprofileComponent implements OnInit {
  @Input() profilePilot : ProfileModel;
  @Input() profilePilotId : number;
  @Input() staffMember : PilotDetailsModel;
  loading : boolean = false;

  constructor(private pilotService : PilotService) { }

  ngOnInit(): void {
    console.log(`Staff Member ${this.staffMember.callsign}`);
  }

  onSubmit(profileForm) {
    this.loading=true;
    console.log(profileForm);

    this.pilotService.putProfile(this.profilePilotId,profileForm).subscribe(
      (responseData) => {
        console.log(responseData);
        this.loading=false;
      },
      (error) => {
        this.loading=false;
      }
    )
  }

}
