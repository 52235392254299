<div class="container text-center" *ngIf="isLoading">
    <div class="lds-hourglass" ></div>
    <p>Signing up...please wait...</p>
</div>
<div class="container-fluid" [hidden]="isLoading">
    <h1>Pilot's Application Form</h1>
    <form name="signupForm" class="css-form" (ngSubmit)="onSubmit(signupForm)" #signupForm="ngForm" style="margin: 5px 10px 30px 10px">
        <div class="card shadow rounded-top rounded-lg">
            <div class="card-header bg-ava-danger ">
                All fields in this section are required
            </div>
            <div class="card-body ">
                <div class="row">
                    <div class="col-md-12">
                        <h4><u>Pilot Profile Details:</u></h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="firstName">First Name:</label>
                            <input type="text" id="firstName" name="firstName" class="form-control" ngModel
                                #firstName="ngModel" minlength="1" maxlength="40" pattern="^[a-zA-Z]*$" required
                                autofocus />
                            <div class="alert alert-danger"
                                *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)">
                                <div *ngIf="firstName.errors?.required">
                                    You must enter your first name
                                </div>
                                <div *ngIf="firstName.errors?.minLength || firstName.errors?.maxLength">
                                    Name is invalid length.
                                </div>
                                <div *ngIf="firstName.errors?.pattern">
                                    Only alpha allowed
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 ">
                        <div class="form-group">
                            <label for="lastName">Last Name:</label>
                            <input type="text" id="lastName" name="lastName" class="form-control" ngModel
                                #lastName="ngModel" minlength="1" maxlength="40" pattern="^[a-zA-Z]*$" required
                                autofocus />
                            <div class="alert alert-danger"
                                *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)">
                                <div *ngIf="lastName.errors?.required">
                                    You must enter your last name
                                </div>
                                <div *ngIf="lastName.errors?.minLength || lastName.errors?.maxLength">
                                    Name is invalid length.
                                </div>
                                <div *ngIf="lastName.errors?.pattern">
                                    Only alpha allowed
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form">
                            <label for="vatsimId">VATSIM ID:</label>
                            <input type="number" id="vatsimId" name="vatsimId" class="form-control" ngModel
                                #vatsimId="ngModel" minlength="1" maxlength="7" max="5000000" min="800000" required />
                            <div class="alert alert-danger"
                                *ngIf="vatsimId.invalid && (vatsimId.dirty || vatsimId.touched)">
                                <div *ngIf="vatsimId.errors?.required">
                                    You must enter your VATSIM ID. You can obtain one at <a
                                        href="https://www.vatsim.net" target="_blank">VATSIM.net</a>
                                </div>
                                <div *ngIf="vatsimId.errors?.minLength || vatsimId.errors?.maxLength">
                                    VATSIM Id must be between 800000 and 5000000.
                                </div>
                                <div *ngIf="vatsimId.errors?.number">
                                    Only numeric is allowed
                                </div>
                                <div *ngIf="vatsimId.errors?.max || vatsimId.errors?.min">
                                    VATSIM Id must be between 800000 and 5000000.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row ">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="email">Email:</label>
                            <input type="email" id="email" name="email" class="form-control" ngModel #email="ngModel"
                                email required />
                            <div class="alert alert-danger" *ngIf="email.invalid && (email.dirty || email.touched)">
                                <div *ngIf="email.errors?.required">
                                    You must enter your email
                                </div>
                                <div *ngIf="email.errors?.email">
                                    Enter a valid email
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="confirmEmail">Confirm Email:</label>
                            <input type="text" id="confirmEmail" name="confirmEmail" class="form-control" ngModel
                                #confirmEmail="ngModel" required appCheckEquals="email" email />
                            <div class="alert alert-danger"
                                *ngIf="confirmEmail.invalid && (confirmEmail.dirty || confirmEmail.touched)">
                                <div *ngIf="confirmEmail.errors?.required">
                                    You must enter your email
                                </div>
                                <div *ngIf="confirmEmail.errors?.email">
                                    Enter a valid email
                                </div>
                                <span
                                    *ngIf="confirmEmail.errors.appCheckEquals==false && confirmEmail.invalid && (confirmEmail.dirty || confirmEmail.touched)">
                                    Email does not match.
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form">
                            <label for="age">Age:</label>
                            <input type="number" id="age" name="age" class="form-control" ngModel #age="ngModel"
                                minlength="2" maxlength="3" max="110" min="13" maxlength="3" required />
                            <div class="alert alert-danger" *ngIf="age.invalid && (age.dirty || age.touched)">
                                <div *ngIf="age.errors?.required">
                                    You must enter a valid age
                                </div>
                                <div
                                    *ngIf="age.errors?.min || age.errors?.max || age.errors?.maxLength || age.errors?.minLength">
                                    You must enter a valid age
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row ">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="password">Password:</label>
                            <input type="password" id="password" name="password" class="form-control" ngModel
                                #password="ngModel" required
                                pattern="^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$" />
                            <div class="alert alert-danger" *ngIf="password.invalid && (password.dirty || password.touched)">
                                <div *ngIf="password.errors?.required">
                                    You must enter a password
                                </div>
                                <div *ngIf="password.errors?.pattern">
                                    It must be between 8 and 16 characters long, and it must contain a special
                                    character and a number
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="confirmPassword">Confirm Password:</label>
                            <input type="password" id="confirmPassword" name="confirmPassword"
                                class="form-control" #confirmPassword="ngModel" ngModel required
                                appCheckEquals="password" />
                            <div class="alert alert-danger"
                                *ngIf="confirmPassword.invalid && (confirmPassword.dirty || confirmPassword.touched)">
                                <div *ngIf="confirmPassword.errors?.required">
                                    You must enter your password again in order to confirm it
                                </div>
                                <span
                                    *ngIf="confirmPassword.errors.appCheckEquals==false && confirmPassword.invalid && (confirmPassword.dirty || passwordConfirmation.touched)">
                                    Password does not match.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <!-- <hr class="aa-line"/> -->
                        <h4><u>Terms and Conditions of Membership:</u></h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="confirmVatsim" class="control-label" style="padding-right:10px">
                                I confirm and understand I will fly ALL flights for AvA online via the VATSIM network?
                            </label>
                            <label class="radio-inline">
                                <input type="radio" name="confirmVatsim" ngModel #confirmVatsim="ngModel" value="1"
                                    required /> Yes
                            </label>
                            <label class="radio-inline">
                                <input type="radio" name="confirmVatsim" ngModel #confirmVatsim="ngModel" value="0"
                                    required /> No
                            </label>
                            <div class="alert alert-danger"
                                *ngIf="confirmVatsim.invalid && (confirmVatsim.dirty || confirmVatsim.touched)">
                                <div *ngIf="confirmVatsim.errors?.required">
                                    You must answer the question
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="confirmSpeed" class="control-label" style="padding-right:10px">
                                I confirm and understand that all flights filed for AvA must be flown at 1x simulation
                                speed?
                            </label>
                            <label class="radio-inline">
                                <input type="radio" name="confirmSpeed" ngModel #confirmSpeed="ngModel" value="1"
                                    required /> Yes
                            </label>
                            <label class="radio-inline">
                                <input type="radio" name="confirmSpeed" ngModel #confirmSpeed="ngModel" value="0"
                                    required /> No
                            </label>
                            <div class="alert alert-danger"
                                *ngIf="confirmSpeed.invalid && (confirmSpeed.dirty || confirmSpeed.touched)">
                                <div ng-show="confirmSpeed.errors?.required">
                                    You must answer the question
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="confirmPireps" class="control-label" style="padding-right:10px">
                                I confirm and understand that I must fly/file two(2) Pilot Reports Monthly to remain on
                                the roster?
                            </label>
                            <label class="radio-inline">
                                <input type="radio" name="confirmPireps" ngModel #confirmPireps="ngModel" value="1"
                                    required /> Yes
                            </label>
                            <label class="radio-inline">
                                <input type="radio" name="confirmPireps" ngModel #confirmPireps="ngModel" value="0"
                                    required /> No
                            </label>
                            <div class="alert alert-danger"
                                *ngIf="confirmPireps.invalid && (confirmPireps.dirty || confirmPireps.touched)">
                                <div ng-show="confirmPireps.errors?.required">
                                    You must answer the question
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="confirmCallsign" class="control-label" style="padding-right:10px">
                                I confirm and understand that I must use my AvA Callsign in accordance with our manual on VATSIM whenever flying and
                                <span class="bg-ava-danger">we do not accept the use of real world Callsigns</span>?
                            </label>
                            <label class="radio-inline">
                                <input type="radio" name="confirmCallsign" ngModel #confirmCallsign="ngModel" value="1"
                                    required /> Yes
                            </label>
                            <label class="radio-inline">
                                <input type="radio" name="confirmCallsign" ngModel #confirmCallsign="ngModel" value="0"
                                    required /> No
                            </label>
                            <div class="alert alert-danger"
                                *ngIf="confirmCallsign.invalid && (confirmCallsign.dirty || confirmCallsign.touched)">
                                <div ng-show="confirmCallsign.errors?.required">
                                    You must answer the question
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <h4><u>Hub Choice:</u></h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="firstHubChoice">First Hub Choice:</label>
                            <select name="firstHubChoice" id="firstHubChoice" class="form-control" ngModel
                                #firstHubChoice="ngModel" required>
                                <option *ngFor="let hiringHub of hiringHubs;let i = index"
                                    [value]="hiringHubs[i].hubId">
                                    {{hiringHubs[i].longName}}
                                    ({{hiringHub.shortName}})
                                </option>

                            </select>
                            <div class="alert alert-danger"
                                *ngIf="firstHubChoice.invalid && (firstHubChoice.dirty || firstHubChoice.touched)">
                                <div ng-show="firstHubChoice.errors?.required">
                                    You must answer the question
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="secondHubChoice">Second Hub Choice:</label>
                            <select name="secondHubChoice" id="secondHubChoice" class="form-control" ngModel
                                #secondHubChoice="ngModel" required>
                                <option *ngFor="let hiringHub of hiringHubs;let i = index"
                                    [value]="hiringHubs[i].hubId">
                                    {{hiringHubs[i].longName}}
                                    ({{hiringHub.shortName}})
                                </option>
                            </select>
                            <div class="alert alert-danger"
                                *ngIf="secondHubChoice.invalid && (secondHubChoice.dirty || secondHubChoice.touched)">
                                <div ng-show="secondHubChoice.errors?.required">
                                    You must answer the question
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="reasonWhy">Briefly tell us why you would like to join AvA:</label>
                            <input type="textarea" rows="3" class="form-control"
                                id="reasonWhy" name="reasonWhy" ngModel #reasonWhy="ngModel"
                                minlength="10" maxlength="250" pattern="^[\w\-,.?#!'()\s]+$" required/>

                                {{ 250-reasonWhy.value?.length}}
                            <div class="alert alert-danger" *ngIf="reasonWhy.invalid && (reasonWhy.dirty || reasonWhy.touched)">
                                <span *ngIf="reasonWhy.errors?.minlength || reasonWhy.errors?.maxlength">
                                    Must be 10 to 250 characters
                                </span>
                                <span *ngIf="reasonWhy.errors?.required">
                                    You must provide an answer to this question
                                </span>
                                <span *ngIf="reasonWhy.errors?.pattern">
                                    Only letters, numbers, and a few special chars allowed
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card shadow rounded-top rounded-lg">
            <div class="card-header bg-ava-info ">
                The following information is Optional. Personal Information for your new Hub Manager.
            </div>
            <div class="card-body ">
                <div class="row">
                    <div class="col-md-12">
                        <h4><u>Pilot Profile Optional Details:</u></h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="city">City:</label>
                            <input type="text" class="form-control" id="city" name="city" ngModel #city="ngModel"
                                minlength="2" maxlength="40" pattern="^[\w\-,.?#!'()\s]+$" />
                            <div class="alert alert-danger" *ngIf="city.invalid && (city.dirty || city.touched)">
                                <span *ngIf="city.errors?.minlength || city.errors?.maxlength">
                                    Must be 2 to 40 characters
                                </span>
                                <span *ngIf="city.errors?.pattern">
                                    Only letters allowed
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="state">State/Province:</label>
                            <input type="text" class="form-control" id="state" name="state" ngModel #state="ngModel"
                                minlength="2" maxlength="40" pattern="^[\w\-,.?#!'()\s]+$" />
                            <div class="alert alert-danger" *ngIf="state.invalid && (state.dirty || state.touched)">
                                <span *ngIf="state.errors?.minlength || state.errors?.maxlength">
                                    Must be 2 to 40 characters
                                </span>
                                <span *ngIf="state.errors?.pattern">
                                    Only letters allowed
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="country">Country:</label>
                            <input type="text" class="form-control" id="country" name="country" ngModel #country="ngModel"
                                minlength="2" maxlength="40" pattern="^[\w\-,.?#!'()\s]+$" />
                            <div class="alert alert-danger" *ngIf="country.invalid && (country.dirty || country.touched)">
                                <span *ngIf="country.errors?.minlength || country.errors?.maxlength">
                                    Must be 2 to 40 characters
                                </span>
                                <span *ngIf="country.errors?.pattern">
                                    Only letters allowed
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="simUsed">Which Sim do you use?</label>
                            <select name="simUsed" id="simUsed" class="form-control" ngModel
                                #simUsed="ngModel">
                                <option *ngFor="let sim of sims;let i = index"
                                    [value]="sim.simId">
                                    {{sim.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="yearsSim">How long have you been flying simulators?</label>
                            <input type="number" class="form-control" id="yearsSim" name="yearsSim" ngModel="0" #yearsSim="ngModel"
                                minlength="1" maxlength="2" max="99" min="0" number />
                            <div class="alert alert-danger" *ngIf="yearsSim.invalid && (yearsSim.dirty || yearsSim.touched)">
                                <span *ngIf="yearsSim.errors?.minlength || yearsSim.errors?.maxlength || yearsSim.errors?.min || yearsSim.errors?.max">
                                    Must be between 0 and 99 years
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="confirmRealWorld" class="control-label" style="padding-right:10px">
                                Do you have any Real World flying experience?
                            </label>
                            <label class="radio-inline">
                                <input type="radio" name="confirmRealWorld" ngModel confirmRealWorld="ngModel" value="1" /> Yes
                            </label>
                            <label class="radio-inline">
                                <input type="radio" name="confirmRealWorld" ngModel="0" confirmRealWorld="ngModel" value="0" /> No
                            </label>

                            <div class="col-lg-12 pull-right">
                                <div class="form-group">
                                    <label for="rwExperience">Explain:</label>
                                        <input type="text" class="form-control" id="rwExperience" name="rwExperience" ngModel #rwExperience
                                           minlength="2" maxlength="150" pattern="^[\w\-,.?#!()\s]+$"
                                           />
                                        <div class="alert alert-danger" *ngIf="rwExperience.invalid && (rwExperience.dirty || rwExperience.touched)">
                                            <span *ngIf="rwExperience.errors?.minlength || rwExperience.errors?.maxlength">
                                                Must be 2 to 150 characters
                                            </span>
                                        <span *ngIf="rwExperience.errors?.invalid">
                                            Only alphanumeric plus -,.?#!() allowed
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="emailPublic" class="control-label" style="padding-right:10px">
                                Do you wish to have your email address visible on your hub's web pages?
                            </label>
                            <label class="radio-inline">
                                <input type="radio" name="emailPublic" ngModel #emailPublic="ngModel" value="1"
                                    required /> Yes
                            </label>
                            <label class="radio-inline">
                                <input type="radio" name="emailPublic" ngModel="1" #emailPublic="ngModel" value="0"
                                    required /> No
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="avaInterestSource">
                                How did you become interested in American Virtual Airlines?
                            </label>
                            <select name="avaInterestSource" id="avaInterestSource" class="form-control" ngModel
                                #simUsed="ngModel">
                                <option *ngFor="let source of avaInterest;let i = index"
                                    [value]="source.id">
                                    {{source.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- Form Footer-->
        <div class="row" style="padding: 10px 10px 10px 10px">

          <!-- Left Column: Captcha -->
          <div class="col-md-6">

            <!-- Checkbox to allow hiding captcha when in test mode -->
            <div class="row" *ngIf="isCaptchaSkippable">
              <div class="col">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" value="" id="app_form_skip_captcha" name="skipCaptcha" [(ngModel)]="skipCaptcha">
                  <label class="form-check-label" for="app_form_skip_captcha">Skip Captcha</label>
                </div>
              </div>
            </div>

            <!-- Form captcha -->
            <div class="row" *ngIf="!skipCaptcha">
              <div class="col">
                <re-captcha (resolved)="resolved($event)" siteKey="{{siteKey}}" ></re-captcha>
              </div>
            </div>
          </div>

          <!-- Right Column: Submit/Reset button group -->
          <div class="col-md-6">
              <div class="button-group float-right">
                  <button class="btn btn-light" id="btnReset" type="reset">Reset</button>
                  <button type="submit" class="btn btn-primary" id="btnAppSubmit" [disabled]="(isCaptchaResolved==false && !skipCaptcha) || signupForm.invalid">
                      Submit
                  </button>
              </div>
          </div>
        </div>
    </form>
</div>

<app-avamodal  [modalName]="modalName" [modalMessage]="modalMessage" [modalTitle]="modalTitle" [showMiniClose]="false"
                [buttonText]="'Ok'" [modalStatus]="modalStatus"
                (modalClosed)="onModalClosed($event)"></app-avamodal>
