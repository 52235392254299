import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { HiringHubModel } from 'src/app/models/hiringhub.model';
import { PilotAppModel } from 'src/app/models/pilotapp.model';
import { DataSharingService } from 'src/app/services/datasharing.service';
import { HubService } from 'src/app/services/hub.service';
import { PilotappService } from 'src/app/services/pilotapp.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-signupform',
  templateUrl: './signupform.component.html',
  styleUrls: ['./signupform.component.css']
})
export class SignupformComponent implements OnInit {
  errorMessage: string;
  hiringHubs: HiringHubModel[] = [];
  siteKey=environment.RECAPTCHA_KEY;

  isCaptchaResolved=false;
  isCaptchaSkippable=false;
  skipCaptcha=false;

  @Output() modalMessage : string = "";
  @Output() modalTitle: string = "";
  @Output() modalStatus: string = "";
  @Output() modalName : string = '';
  isLoading=false;
  catpchaResponse : string = "";
  defaultValue : string ="blah blah";
  pilotId: any;
  isSuccessful : boolean = false;
  isInReview: boolean = false;

  sims = [
    {
      "simId": "MSFS",
      "name": "Microsoft Flight Simulator (2021)",
      "shortName": "FS2021"
    },
    {
      "simId": "MSFS X",
      "name": "MSFS X",
      "shortName": "FSX"
    },
    {
      "simId": "MSFS 2004",
      "name": "MSFS 2004",
      "shortName": "FS2004"
    },
    {
      "simId": "X-Plane",
      "name": "X-Plane",
      "shortName": "XP"
    },
    {
      "simId": "P3D",
      "name": "Prepare 3D",
      "shortName": "P3D"
    }
  ];

  avaInterest = [
    {
      "id": "VATSIM Link",
      "name": "VATSIM Link"
    },
    {
      "id": "VA Link",
      "name": "VA Link"
    },
    {
      "id": "Friend",
      "name": "Friend"
    },
    {
      "id": "ServInfo",
      "name": "ServInfo"
    },
    {
      "id": "VATSIM Presence",
      "name": "VATSIM Presence"
    },
    {
      "id": "Other",
      "name": "Other"
    }
  ]


  constructor(private hubService: HubService,private dss : DataSharingService, private pas : PilotappService, private router : Router) { }

  ngOnInit(): void {
    this.isCaptchaResolved=false;

    if (!environment.production) {
      this.isCaptchaSkippable=true;
    } else {
      this.isCaptchaSkippable=false;
    }

    this.hubService.getHiringHubs().subscribe(
      (responseData: HiringHubModel[]) => {
        this.hiringHubs = responseData;
      },
      (error) => {
        switch (error.status) {
          case 401:
            this.errorMessage = "I'm sorry but there is an authorization issue.";
            break;

          default:
            this.errorMessage = "An expected error occurred.  Please contact your system administrator.";
            break;
        };
      }
    );
  }

  fixCityStateCountry(myForm : PilotAppModel) {
    if (myForm.country.length==0)
      {myForm.country="na";
    }
    if (myForm.city.length==0)
      {myForm.city="na";
    }
    if (myForm.state.length==0)
      {myForm.state="na";
    }
  }
  onSubmit(form : NgForm) {
    let myForm: PilotAppModel = form.value;

    //open the spinner/loading screen
    this.isLoading=true;
    this.fixCityStateCountry(myForm);
    //call the pilotapp service and submit the form
    myForm.gresponse=this.catpchaResponse;
    this.pas.postApplication(myForm).subscribe(
      (responseData) => {
        this.pilotId = responseData['pilotId'];
        //display results dialog
        this.modalTitle = 'Application submitted successfully!';
        this.modalMessage = responseData['messages'];
        this.modalStatus="success";
        if (responseData.hasOwnProperty("reviewReason")) {
          let modalReason = responseData['reviewReason'];
          if (modalReason.length>0) {
            this.isInReview=true;
          }
        }

        // Reset form fields so background looks cleaner while success modal is floating
        form.reset();

        this.isSuccessful=true;
        this.isLoading=false;
        this.dss.showModal.next({modalName : this.modalName, showModal :true});
      },
      (error) => {
        console.log(error.message);
        this.modalTitle='Application Submission failed.';
        this.modalMessage='We are sorry but your application did not process correctly.<br/> Errors: <ul>';

        if (error.status==500) {
          this.modalMessage += '<li>' + error.message + '</li>';
          this.modalMessage += '<li>' + error.error.message + '</li>';
          this.modalMessage += '<li>Please contact our technical support by emailing vp-it at joinava dot org.  We apologize for the inconvenience.</li>';
        } else {
          for (let index = 0; index < error.error.messages.length; index++) {
            const errorMsg = error.error.messages[index];
            this.modalMessage += '<li>' + errorMsg + '</li>';
          }
        }
        this.modalMessage += '</ul>';
        this.modalStatus='danger';
        this.isLoading=false;
        this.isSuccessful=false;
        this.dss.showModal.next({modalName : this.modalName, showModal :true});
      }
    )
  }

  onModalClosed(returnMessage: {purpose: string, status: string}) {
    // Regardless of how the modal was closed (captured by "status" property being 'ok' or 'cancel'):
    // 1) if we are successful, redirect to homepage
    // 2) if we failed, don't redirect anywhere so the user can correct their form and resubmit
    if (this.isSuccessful) {
        this.router.navigate(['/']);
    }
  }

  resolved(captchaResponse: string) {
    this.isCaptchaResolved=true;
    this.catpchaResponse=captchaResponse;
  }
}
