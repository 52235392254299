import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { DataSharingService } from 'src/app/services/datasharing.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {
  private activatedDSS: Subscription;
  private activatedDSS_isManager : Subscription;
  isAuthenticated=false;
  isManager=false;
  showLoginForm : boolean = false;
  showPwdResetForm : boolean = false;

  @ViewChild('loginButton') loginButton;
  @ViewChild('loginModal') loginModal;
  
  constructor(private authService : AuthService, private router : Router, private dss : DataSharingService) {
    this.activatedDSS = this.dss.isAuthenticated.subscribe( value => {
      this.isAuthenticated = value;
    })
    this.activatedDSS_isManager = this.dss.isManager.subscribe( value => {
      this.isManager = value;
    })    
   }
  ngOnDestroy(): void {
    this.activatedDSS.unsubscribe();
    this.activatedDSS_isManager.unsubscribe();
  }

  ngOnInit(): void {
    this.isAuthenticated = this.authService.isAuthenticated();
    this.isManager = this.authService.isManager();
  }

  onLogoutClick() {
    this.authService.invalidateToken();
    this.dss.isAuthenticated.next(false);
    this.dss.isManager.next(false);        
    this.router.navigate(['/']);
  }
  onLoginClick() {
    this.showLoginForm=!this.showLoginForm;
  }
  onPwdResetStart() {
    this.dss.isResetPasswordActive.next({route : 'home', id : Math.random()*100});
    this.router.navigate(['/pwdreset']);
  }
  async joinAva(loginModal) {
    this.router.navigate(['/signup']);
  }

  onLoginFailure($event) {
    //clear off the login modal -- probably a lot better way to do this but this does work :)
    var modalele = document.body.getElementsByClassName('modal-backdrop fade show');
    modalele[0].remove();
    var modalele = document.body.getElementsByClassName('modal fade show');
    modalele[0].remove();
    this.onLogoutClick();
  }
}
