import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RejectedPirepModel } from 'src/app/models/rejectedpirep.model';
import { PirepService } from 'src/app/services/pirep.service';

@Component({
  selector: 'app-rejectedpireps',
  templateUrl: './rejectedpireps.component.html',
  styleUrls: ['./rejectedpireps.component.css']
})
export class RejectedpirepsComponent implements OnInit {
  loading: boolean = false;
  hasRejectedPireps: boolean = false;

  rejectedPireps: RejectedPirepModel[] = [];

  constructor(private router: Router, private pirepService : PirepService) { }

  ngOnInit(): void {
    this.loading=true;
    this.hasRejectedPireps=false;
    this.loadRejectedPireps();
  }

  loadRejectedPireps() {
    this.pirepService.getRejected().subscribe(
      (responseData) => {
        this.rejectedPireps = responseData;
        console.log(this.rejectedPireps);
        this.loading = false;
        this.hasRejectedPireps = true;
      },
      (error) => {
        this.loading = false;
        if (error.status = 404) {
          this.hasRejectedPireps=false;
        } else {
          this.router.navigate(['/techerror']);
        }
      }
    );
  }
  onRPUpdateComplete($rp) {
    this.ngOnInit();
  }
}
