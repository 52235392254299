import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TransferRequestModel } from 'src/app/models/transferrequest.model';
import { AuthService } from 'src/app/services/auth.service';
import { DataSharingService } from 'src/app/services/datasharing.service';
import { PilotService } from 'src/app/services/pilot.service';
import { TransferService } from 'src/app/services/transfer.service';

@Component({
  selector: 'app-transferrequestrow',
  templateUrl: './transferrequestrow.component.html',
  styleUrls: ['./transferrequestrow.component.css']
})
export class TransferrequestrowComponent implements OnInit {
  @Output() onTransferRequestComplete = new EventEmitter<string>();
  loading : boolean = false;
  isAccepting : boolean = false;
  isRejecting : boolean = false;
  pilotId : number;

  @Input() transferRequest : TransferRequestModel;

  TRANSFER_ACCEPTED_STATUS = '1';
  TRANSFER_REJECTED_STATUS = '0';

  transferRequestStatus : string;
  pilotIdtoTransfer : number;

  //modal vars
  @Output() modalMessage: string = "";
  @Output() modalTitle: string = "";
  @Output() modalStatus: string = "";
  @Output() modalPurpose: string = "";
  @Output() showCancelButton: boolean = false;
  @Output() showMiniClose: boolean = false;
  @Output() modalName : string = 'transferRequestApprovalFormModal';


  constructor(private router : Router, private pilotService : PilotService, private authService : AuthService, private dss : DataSharingService,
    private transferService : TransferService) { }


  ngOnInit(): void {
    this.loading=false;
    this.isRejecting=false;
    this.isAccepting=false;
    this.modalName = 'transferRequestApprovalFormModal' + this.transferRequest.lastName;
  }

  approve(transferRequestForm) {
    console.log('accept');
    this.loading=true;
    this.transferRequestStatus=this.TRANSFER_ACCEPTED_STATUS;
    this.setStatus(transferRequestForm);
  }

  reject(transferRequestForm) {
    console.log('reject');
    this.loading=true;
    this.transferRequestStatus=this.TRANSFER_REJECTED_STATUS;
    this.setStatus(transferRequestForm);
  }

  setStatus(transferRequestForm) {
    this.transferService.updateTransferStatus(transferRequestForm, this.transferRequest, this.transferRequestStatus).subscribe(
      (responseData) => {
        console.log(responseData);
        this.showSuccessModal(responseData);
        this.loading=false;
      },
      (error) => {
        if (error.status === 500 ) {
          this.router.navigate(['/techerror']);
        } else {
          // this.showFailureModal(error);
        }
        this.loading=false;
      }
    )
  }

  showSuccessModal(data) {
    this.dss.showModal.next({modalName : this.modalName, showModal :false});
    this.modalStatus='success';
    this.showMiniClose=false;
    this.showCancelButton=false;
    this.modalTitle = 'Transfer Status Update Success';
    this.modalMessage = 'Transfer ' + this.transferRequest.transferId + ' has been successfully ' + (this.transferRequestStatus==this.TRANSFER_ACCEPTED_STATUS ? 'accepted.' : 'rejected.');
    this.modalPurpose = "transferstatussuccess";
    this.dss.showModal.next({modalName : this.modalName, showModal :true});
  }

  showFailureModal(error) {
    this.dss.showModal.next({modalName : this.modalName, showModal :false});
    this.modalStatus='danger';
    this.showMiniClose=false;
    this.showCancelButton=false;
    this.modalTitle = 'Transfer Request Failed';
    this.modalMessage = 'Your Transfer request failed to be processed with the following error:<br/>';
    if (error.error.hasOwnProperty("messages")) {
      for (const key in error.error.messages) {
        this.modalMessage += '<br/>' + error.error.messages;
      }
    }
    console.log(error.message + ' == ' + error.status);
    this.modalPurpose = "transferstatussuccess";
    this.dss.showModal.next({modalName : this.modalName, showModal :true});
  }

  onModalClosed(returnMessage: {status: string, purpose: string}) {
    if (returnMessage.purpose == 'transferstatussuccess' && returnMessage.status == 'ok') {
      this.onTransferRequestComplete.emit('success');
    }

  }
}
