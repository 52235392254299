<app-membersearch (onMemberSearchComplete)="handleSearchResults($event)"></app-membersearch>

<div class="container mt-2 mb-3" *ngIf="searchComplete">
    <div class="row">
        <div class="col-md-12">
            <div class="card shadow rounded-top rounded-lg">
                <div class="card-header bg-warning">
                    <div class="row">
                        <div class="col-md-12">
                            Found Pilots to Transfer
                        </div>
                    </div>
                </div>
                <div class="card-body ">
                    <div class="row mt-2 " *ngFor="let pilot of searchResults; let i = index;">
                        <div class="col-md-12" *ngIf="hasMemberSearchResults && searchComplete">
                            <app-hrtransferrow [pilot]="pilot" (onHRTransferComplete)="onHRTransferComplete($event)">
                            </app-hrtransferrow>
                        </div>
                    </div>
                    <div class="col-md-12" *ngIf="!hasMemberSearchResults && searchComplete">
                        No pilots found.
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>