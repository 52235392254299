import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PwdresetService {
  PASSWORD_RESET_URL = '/pwdReset';
  RESET_PASSWORD_SVC = '/resetpwd';

  constructor(private httpClient: HttpClient) { }


  resetPassword(resetInfo) {
    return this.httpClient.post(environment.API_URL + this.PASSWORD_RESET_URL, resetInfo);
  }

  changePassword(pwdInfo) {
    return this.httpClient.post(environment.API_URL + this.RESET_PASSWORD_SVC, pwdInfo);
  }
}
