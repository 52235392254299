import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './app.component';
import { FooterComponent } from './components/static/footer/footer.component';
import { HeaderComponent } from './components/auth/header/header.component';
import { DropdownDirective } from './common/directives/dropdown/dropdown.directive';
import { LoginformComponent } from './components/auth/loginform/loginform.component';
import { HomeComponent } from './components/pages/home/home.component';
import { AppRoutingModuleModule } from './app-routing-module.module';
import { AboutusComponent } from './components/pages/aboutus/aboutus.component';
import { PilotComponent } from './components/pages/pilot/pilot.component';
import { ManageComponent } from './components/pages/manage/manage.component';
import { SignupComponent } from './components/pages/signup/signup.component';
import { DataSharingService } from './services/datasharing.service';
import { HomeContentComponent } from './components/pages/signup/home-content/home-content.component';
import { SignupexamComponent } from './components/pages/signup/signupexam/signupexam.component';
import { SignupformComponent } from './components/pages/signup/signupform/signupform.component';
import { AppCheckEqualsDirective } from './components/pages/signup/signupform/directives/appcheckequals.directive';
import { RecaptchaModule } from 'ng-recaptcha';
import { AvamodalComponent } from './components/util/avamodal/avamodal.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LogbookComponent } from './components/pages/pilot/logbook/logbook.component';
import { RosterComponent } from './components/pages/pilot/roster/roster.component';
import { TrainingComponent } from './components/pages/pilot/training/training.component';
import { CommsComponent } from './components/pages/pilot/comms/comms.component';
import { StatsComponent } from './components/pages/pilot/stats/stats.component';
import { DispatcherComponent } from './components/pages/pilot/dispatcher/dispatcher.component';
import { ProfileComponent } from './components/pages/pilot/profile/profile.component';
import { PirepComponent } from './components/pages/pilot/pirep/pirep.component';
import { PilotmenuComponent } from './components/pages/pilot/pilotmenu/pilotmenu.component';
import { UtcclockComponent } from './components/util/utcclock/utcclock.component';
import { AuthInterceptorService } from './components/auth/auth-interceptor.service';
import { LastFlightMapComponent } from './components/pages/pilot/lastflight/last-flight-map/last-flight-map.component';
import { environment } from 'src/environments/environment';
import { GoogleMapsModule } from '@angular/google-maps';
import { LastFlightChartComponent } from './components/pages/pilot/lastflight/last-flight-chart/last-flight-chart.component';
import { ChartsModule } from 'ng2-charts';
import { NotamComponent } from './components/util/notam/notam.component';
import { LastFlightComponent } from './components/pages/pilot/lastflight/lastflight.component';
import { LogbookpaginatorComponent } from './components/util/logbookpaginator/logbookpaginator.component';
import { FlightmapComponent } from './components/util/flightmap/flightmap.component';
import { FlightchartComponent } from './components/util/flightchart/flightchart.component';
import { ProfileformComponent } from './components/pages/pilot/profile/profileform/profileform.component';
import { ChangepasswordformComponent } from './components/pages/pilot/profile/changepasswordform/changepasswordform.component';
import { TecherrorComponent } from './components/static/errors/techerror/techerror.component';
import { LoaformComponent } from './components/pages/pilot/profile/loaform/loaform.component';
import { TransferformComponent } from './components/pages/pilot/profile/transferform/transferform.component';
import { ResignationformComponent } from './components/pages/pilot/profile/resignationform/resignationform.component';
import { RoutemapComponent } from './components/pages/pilot/dispatcher/routemap/routemap.component';
import { ScheduleflightComponent } from './components/pages/pilot/dispatcher/scheduleflight/scheduleflight.component';
import { FindflightsComponent } from './components/pages/pilot/dispatcher/findflights/findflights.component';
import { BookedflightsComponent } from './components/pages/pilot/dispatcher/bookedflights/bookedflights.component';
import { CharterflightComponent } from './components/pages/pilot/dispatcher/charterflight/charterflight.component';
import { LoaComponent } from './components/pages/manage/loa/loa.component';
import { LoaresponseformComponent } from './components/pages/manage/loa/loaresponseform/loaresponseform.component';
import { ActiveloatableComponent } from './components/pages/manage/loa/activeloatable/activeloatable.component';
import { TransferComponent } from './components/pages/manage/transfer/transfer.component';
import { TransferrequestrowComponent } from './components/pages/manage/transfer/transferrequestrow/transferrequestrow.component';
import { HrtransferComponent } from './components/pages/manage/hrtransfer/hrtransfer.component';
import { MembersearchComponent } from './components/util/membersearch/membersearch.component';
import { HrtransferrowComponent } from './components/pages/manage/hrtransfer/hrtransferrow/hrtransferrow.component';
import { HrrosterComponent } from './components/pages/manage/hrroster/hrroster.component';
import { HubselectorComponent } from './components/util/hubselector/hubselector.component';
import { HubhealthComponent } from './components/pages/manage/hrroster/hubhealth/hubhealth.component';
import { HrhubrosterComponent } from './components/pages/manage/hrroster/hrhubroster/hrhubroster.component';
import { HrprofileComponent } from './components/pages/manage/hrprofile/hrprofile.component';
import { HrpilotprofileComponent } from './components/pages/manage/hrprofile/hrpilotprofile/hrpilotprofile.component';
import { HrlogbookprofileComponent } from './components/pages/manage/hrprofile/hrlogbookprofile/hrlogbookprofile.component';
import { HrpirepComponent } from './components/pages/manage/hrprofile/hrpirep/hrpirep.component';
import { HrsearchmemberComponent } from './components/pages/manage/hrsearchmember/hrsearchmember.component';
import { MassMailComponent } from './components/pages/manage/mass-mail/mass-mail.component';
import { PirepreviewComponent } from './components/pages/manage/pirepreview/pirepreview.component';
import { EventsComponent } from './components/pages/manage/events/events.component';
import { EventdetailComponent } from './components/pages/manage/events/eventdetail/eventdetail.component';
import { ApplicationsComponent } from './components/pages/manage/applications/applications.component';
import { ApplicationdetailComponent } from './components/pages/manage/applications/applicationdetail/applicationdetail.component';
import { RejectionboxComponent } from './components/pages/manage/applications/applicationdetail/rejectionbox/rejectionbox.component';
import { AcceptboxComponent } from './components/pages/manage/applications/applicationdetail/acceptbox/acceptbox.component';
import { ResignationsComponent } from './components/pages/manage/resignations/resignations.component';
import { ResignationdetailComponent } from './components/pages/manage/resignations/resignationdetail/resignationdetail.component';
import { TerminationsComponent } from './components/pages/manage/terminations/terminations.component';
import { TerminationdetailComponent } from './components/pages/manage/terminations/terminationdetail/terminationdetail.component';
import { PersonnelrecordsComponent } from './components/pages/manage/personnelrecords/personnelrecords.component';
import { PersonnelrecordetailComponent } from './components/pages/manage/personnelrecords/personnelrecordetail/personnelrecordetail.component';
import { DnrsComponent } from './components/pages/manage/dnrs/dnrs.component';
import { DismissedsComponent } from './components/pages/manage/dismisseds/dismisseds.component';
import { ChangecallsignComponent } from './components/pages/manage/changecallsign/changecallsign.component';
import { CcformdetailComponent } from './components/pages/manage/changecallsign/ccformdetail/ccformdetail.component';
import { FeatureddestinationComponent } from './components/pages/manage/featureddestination/featureddestination.component';
import { NotamsComponent } from './components/pages/manage/notams/notams.component';
import { NotamdetailComponent } from './components/pages/manage/notams/notamdetail/notamdetail.component';
import { HubsComponent } from './components/pages/manage/hubs/hubs.component';
import { VahealthpanelComponent } from './components/pages/manage/hubs/vahealthpanel/vahealthpanel.component';
import { HubinfodetailComponent } from './components/pages/manage/hubs/hubinfodetail/hubinfodetail.component';
import { WelcomemessageComponent } from './components/pages/manage/welcomemessage/welcomemessage.component';
import { GoalsComponent } from './components/pages/manage/goals/goals.component';
import { RejectedpirepsComponent } from './components/pages/manage/rejectedpireps/rejectedpireps.component';
import { RejectedpirepdetailsComponent } from './components/pages/manage/rejectedpireps/rejectedpirepdetails/rejectedpirepdetails.component';
import { StaffpositionsComponent } from './components/pages/manage/staffpositions/staffpositions.component';
import { StaffpositiondetailsComponent } from './components/pages/manage/staffpositions/staffpositiondetails/staffpositiondetails.component';
import { MissionComponent } from './components/pages/aboutus/mission/mission.component';
import { HistoryComponent } from './components/pages/aboutus/history/history.component';
import { OrgchartComponent } from './components/pages/aboutus/orgchart/orgchart.component';
import { PolicymanualComponent } from './components/pages/aboutus/policymanual/policymanual.component';
import { EventcalendarComponent } from './components/pages/aboutus/eventcalendar/eventcalendar.component';
import { AvastatsComponent } from './components/pages/aboutus/avastats/avastats.component';
import { FlightstatsComponent } from './components/pages/aboutus/avastats/flightstats/flightstats.component';
import { HoursstatsComponent } from './components/pages/aboutus/avastats/hoursstats/hoursstats.component';
import { LandingstatsComponent } from './components/pages/aboutus/avastats/landingstats/landingstats.component';
import { AirportstatsComponent } from './components/pages/aboutus/avastats/airportstats/airportstats.component';
import { FaqComponent } from './components/pages/aboutus/faq/faq.component';
import { GenericrosterComponent } from './components/pages/aboutus/genericroster/genericroster.component';
import { CarouselComponent } from './components/pages/home/carousel/carousel.component';
import { GoalsummaryComponent } from './components/pages/home/goalsummary/goalsummary.component';
import { LeadingedgeComponent } from './components/pages/home/leadingedge/leadingedge.component';
import { EventsummaryComponent } from './components/pages/home/eventsummary/eventsummary.component';
import { HmflightmapComponent } from './components/pages/home/hmflightmap/hmflightmap.component';
import { PwdresetComponent } from './components/auth/header/pwdreset/pwdreset.component';
import { ResetpwdComponent } from './components/auth/resetpwd/resetpwd.component';
import { PireptoreviewComponent } from './components/pages/manage/pirepreview/pireptoreview/pireptoreview.component';
import { SoftwareComponent } from './components/pages/pilot/software/software.component';
import { TrainingpageComponent } from './components/pages/manage/trainingpage/trainingpage.component';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    DropdownDirective,
    LoginformComponent,
    HomeComponent,
    MissionComponent,
    AboutusComponent,
    PilotComponent,
    ManageComponent,
    SignupComponent,
    HomeContentComponent,
    SignupexamComponent,
    SignupformComponent,
    AppCheckEqualsDirective,
    AvamodalComponent,
    LogbookComponent,
    RosterComponent,
    TrainingComponent,
    CommsComponent,
    StatsComponent,
    DispatcherComponent,
    ProfileComponent,
    PirepComponent,
    PilotmenuComponent,
    UtcclockComponent,
    LastFlightMapComponent,
    LastFlightChartComponent,
    NotamComponent,
    LastFlightComponent,
    LogbookpaginatorComponent,
    FlightmapComponent,
    FlightchartComponent,
    ProfileformComponent,
    ChangepasswordformComponent,
    TecherrorComponent,
    LoaformComponent,
    TransferformComponent,
    ResignationformComponent,
    FindflightsComponent,
    RoutemapComponent,
    ScheduleflightComponent,
    BookedflightsComponent,
    CharterflightComponent,
    LoaComponent,
    LoaresponseformComponent,
    ActiveloatableComponent,
    TransferComponent,
    TransferrequestrowComponent,
    HrtransferComponent,
    MembersearchComponent,
    HrtransferrowComponent,
    HrrosterComponent,
    HubselectorComponent,
    HubhealthComponent,
    HrhubrosterComponent,
    HrprofileComponent,
    HrpilotprofileComponent,
    HrlogbookprofileComponent,
    HrpirepComponent,
    HrsearchmemberComponent,
    MassMailComponent,
    PirepreviewComponent,
    EventsComponent,
    EventdetailComponent,
    ApplicationsComponent,
    ApplicationdetailComponent,
    RejectionboxComponent,
    AcceptboxComponent,
    ResignationsComponent,
    ResignationdetailComponent,
    TerminationsComponent,
    TerminationdetailComponent,
    PersonnelrecordsComponent,
    PersonnelrecordetailComponent,
    DnrsComponent,
    DismissedsComponent,
    ChangecallsignComponent,
    CcformdetailComponent,
    FeatureddestinationComponent,
    NotamsComponent,
    NotamdetailComponent,
    HubsComponent,
    VahealthpanelComponent,
    HubinfodetailComponent,
    WelcomemessageComponent,
    GoalsComponent,
    RejectedpirepsComponent,
    RejectedpirepdetailsComponent,
    StaffpositionsComponent,
    StaffpositiondetailsComponent,
    HistoryComponent,
    OrgchartComponent,
    PolicymanualComponent,
    EventcalendarComponent,
    AvastatsComponent,
    FlightstatsComponent,
    HoursstatsComponent,
    LandingstatsComponent,
    AirportstatsComponent,
    FaqComponent,
    GenericrosterComponent,
    CarouselComponent,
    GoalsummaryComponent,
    LeadingedgeComponent,
    EventsummaryComponent,
    HmflightmapComponent,
    PwdresetComponent,
    ResetpwdComponent,
    PireptoreviewComponent,
    SoftwareComponent,
    TrainingpageComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModuleModule,
    HttpClientModule,
    RecaptchaModule,
    GoogleMapsModule,
    ChartsModule,
    NgbModule
  ],
  providers: [
    DataSharingService,
    [{provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true}]
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
