<div *ngIf="hasLandings">
    <span class="leftnavTitle">
        Leading Edge 
        <ng-template #leRules> Average Landing Rate per CAT for pilots with 5 or more flights in the CAT
        </ng-template>
        <i class="fas fa-info-circle" [ngbPopover]="leRules" popoverTitle="Leading Edge Competition Results" style="cursor: pointer;font-size: .85rem;"></i>
        
    </span>
    <div class="leftnavText">
        <table border="0" class="table table-sm table-borderless table-responsive" [cellPadding]="3" [cellSpacing]="3" style="font-size: .90rem">
            <tr>
                <th width="60px"><u>CAT</u></th>
                <th width="100px"><u>Pilot</u></th>
                <th width="50px"><u>Landing</u></th>
            </tr>
            <tr *ngFor="let lc of lcResults">
                <td width="20px">{{lc.id}}</td>
                <td width="40px" >
                    {{lc.attributes.callsign}}
                </td>
                <td width="20px" placement="right" ngbPopover="Out of {{lc.attributes.totalFlights}} flights this month" style="cursor: pointer;">{{lc.attributes.landingRate}}</td>
            </tr>
        </table>
    </div>
</div>