import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PilotLandingModel } from 'src/app/models/pilot-landing.model';
import { AuthService } from 'src/app/services/auth.service';
import { HubRosterService } from 'src/app/services/hubroster.service';
import { PilotService } from 'src/app/services/pilot.service';

@Component({
  selector: 'app-roster',
  templateUrl: './roster.component.html',
  styleUrls: ['./roster.component.css']
})
export class RosterComponent implements OnInit {
  loading : boolean = false;
  pilotId : number;
  hubRoster = [];
  isValidPilot : boolean = false;
  pilotDetails : PilotLandingModel;
  hubLinks;
  hasPilots : boolean = false;

  hubToLoad : number;
  pilotsHubDetails;

  constructor(private rosterService : HubRosterService, private authService : AuthService, private route : ActivatedRoute, private pilotService : PilotService) { }

  ngOnInit(): void {
    this.loading=true;
    this.pilotId = this.authService.getPilotId();
    this.hasPilots=false;

    this.pilotService.get(this.pilotId).subscribe(
      (responseData) => {
        this.pilotDetails = responseData;
        this.hubToLoad = this.pilotDetails['hubId'];
        console.log(this.hubToLoad);
        console.log(this.pilotDetails);
        this.isValidPilot=true;
        this.loadHubRoster(this.hubToLoad);
      },
      (error) => {
        this.loading=false;
      }
    )
  }

  loadHubRoster(hubToLoad : number) {
    this.loading=true;
    this.rosterService.get(hubToLoad).subscribe(
      (responseData) => {
        let hubDetails = responseData;
        if (hubDetails.hasOwnProperty("included")) {
          this.hubLinks = hubDetails['included']['hubLinks'];
          this.pilotsHubDetails = hubDetails['included'][0]['attributes'];
          this.hubRoster = hubDetails['data'];
        }

        console.log(this.hubLinks);
        this.isValidPilot=true;
        this.loading=false;
        this.hasPilots=true;
      },
      (error) => {
        this.pilotsHubDetails.longName='';
        this.pilotsHubDetails.shortName=this.hubLinks.find(e=> e.hubId === this.hubToLoad).shortName;
        this.hasPilots=false;
        this.loading=false;
      }
    )
  }

  onChangeHub(hubToLoad : number) {
    console.log(hubToLoad);
    this.hubToLoad=hubToLoad;
    this.loading=true;
    this.loadHubRoster(hubToLoad);
  }

  getStarCount(cert) {
    return new Array(cert.attributes.starCount);
  }

}
