import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ChartOptions, ChartType } from 'chart.js';
import { Color, Label, SingleDataSet } from 'ng2-charts';
import { VAHealthModel } from 'src/app/models/vahealth.model';
import { HubService } from 'src/app/services/hub.service';
import { HubRosterService } from 'src/app/services/hubroster.service';

@Component({
  selector: 'app-vahealthpanel',
  templateUrl: './vahealthpanel.component.html',
  styleUrls: ['./vahealthpanel.component.css']
})
export class VahealthpanelComponent implements OnInit {
  loading: boolean = false;
  hasVAHealthInfo: boolean = false;
  vaHealthInfo : VAHealthModel;

  // Pie
  public pieChartOptions: ChartOptions = {
    responsive: true,
    legend: { display: false }
  };
  public pieChartLabels: Label[] = ["Active", "Inactive", "Warned", "LOA", "Probation"];;
  public pieChartData: SingleDataSet;
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [];
  public pieChartColors: Color[] = [{ backgroundColor: ['#21f900', '#f9a600', '#f90000', '#00a5f9', '#848380'] }];


  constructor(private router: Router, private hubService: HubService) { }

  ngOnInit(): void {
    this.loading = true;
    this.loadVAHealth();
    this.loadVAHubDetails();

  }

  loadVAHealth() {
    this.hasVAHealthInfo = false;
    this.vaHealthInfo = null;

    this.hubService.getVAHealth().subscribe(
      (responseData: VAHealthModel) => {
        this.vaHealthInfo = responseData;
        this.hasVAHealthInfo = true;
        this.loading = false;
        this.pieChartData=[this.vaHealthInfo.activePilots, this.vaHealthInfo.inactivePilots, this.vaHealthInfo.warnedPilots, this.vaHealthInfo.loaPilots, this.vaHealthInfo.probiePilots];
      },
      (error) => {
        console.log('WARNING: ' + error.message);
        this.loading = false;
        this.router.navigate(['/techerror']);
      }
    )
  }

  loadVAHubDetails() {

  }
}
