import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageModel } from 'src/app/models/message.model';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: 'app-trainingpage',
  templateUrl: './trainingpage.component.html',
  styleUrls: ['./trainingpage.component.css']
})
export class TrainingpageComponent implements OnInit {
  loading: boolean = false;
  trainingPage: MessageModel = new MessageModel;
  isPreview : boolean = true;
  showCounter : boolean = false;
  isFound : boolean = false;

  constructor(private router : Router, private messageService : MessageService) { }

  ngOnInit(): void {
    this.loading=true;
    this.trainingPage.title='';
    this.loadTrainingPage();
  }

  loadTrainingPage() {
    this.messageService.getTrainingPage().subscribe(
      (responseData: MessageModel) => {
        this.trainingPage = responseData['messages'][0];
        this.loading = false;
        this.isFound = true;
      },
      (error) => {
          console.log('WARNING: ' + error.message);
          if (error.status === 500) {
            this.router.navigate(['/techerror']);
          } else if (error.status=404) {
            this.trainingPage.title='';
            this.trainingPage.message='';
            this.loading=false;
            this.isFound=false;
          }
      }
    );
  }


  onSubmit(tpDetailForm) {
    this.loading=true;
    this.trainingPage.title=tpDetailForm.txtTPTitle;
    this.trainingPage.message=tpDetailForm.txtTPMessage;

    this.messageService.postMessage(this.trainingPage.id,this.trainingPage).subscribe(
      (responseData) => {
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        if (error.status = 404) {
        } else {
          this.router.navigate(['/techerror']);
        }
      }
    );
  }
}
