<div class="card shadow rounded-top rounded-lg mt-2" *ngIf="!loading">
    <div class="card-header panel-american-blue-grey-red">
        <div class="row p-0">
            <div class="col-xl p-0">
                Flights
            </div>
        </div>
    </div>
    <div class="card-body">
        <div class="row p-0">
            <div class="col-xl p-0">
                <div class="card mt-2 rounded-top">
                    <div class="card-header">
                        <b>{{flightStats.monthlyByPilot.title}}</b>
                    </div>
                    <div class="card-body">
                        <div class="flex-item">
                            <div>
                                <canvas baseChart width="400" height="150px" id="monthlyByPilotReport" [datasets]="monthlyByPilotChartData" [labels]="flightStats.monthlyByPilot.labels" [colors]="COLOR_GRID" [legend]="lineChartLegend" [chartType]="horizonaltalBarChartType">
                                </canvas>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row p-0">
            <div class="col-xl p-0">
                <div class="card mt-2 rounded-top">
                    <div class="card-header">
                        <b>{{flightStats.dailyTrend.title}}</b>
                    </div>
                    <div class="card-body">
                        <div>
                            <div>
                                <canvas baseChart width="400" height="150px" id="dailyFlightTrendReport" [datasets]="dailyTrendChartData" [labels]="dailyTrendLabels" [options]="lineChartOptions" [colors]="lineChartColors" [legend]="lineChartLegend" [chartType]="lineChartType">
                                </canvas>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row p-0">
            <div class="col-xl p-0">
                <div class="card mt-2 rounded-top">
                    <div class="card-header">
                        <b>{{flightStats.yearlyByPilot.title}}</b>
                    </div>
                    <div class="card-body">
                        <div class="flex-item">
                            <div>
                                <canvas baseChart width="400" height="150px" id="yearlyByPilotReport" 
                                    [datasets]="yearlyByPilotChartData" 
                                    [labels]="flightStats.yearlyByPilot.labels" 
                                    [colors]="COLOR_GRID" 
                                    [legend]="lineChartLegend" 
                                    [chartType]="horizonaltalBarChartType">
                                </canvas>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row p-0">
            <div class="col-xl p-0">
                <div class="card mt-2 rounded-top">
                    <div class="card-header">
                        <b>{{flightStats.monthlyByHub.title}}</b>
                        <span class="badge badge-info float-right ">Total: {{flightStats.monthlyByHub.totalCount | number}}</span>
                    </div>
                    <div class="card-body">
                        <div >
                            <div>
                                <canvas baseChart width="400" height="150px" id="monthlyByHubReport" 
                                    [datasets]="monthlyByHubChartData" 
                                    [labels]="flightStats.monthlyByHub.labels" 
                                    [colors]="COLOR_GRID" 
                                    [legend]="lineChartLegend" 
                                    [chartType]="barChartType">
                                </canvas>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
        <div class="row p-0">
            <div class="col-xl p-0">
                <div class="card mt-2 rounded-top">
                    <div class="card-header">
                        <b>{{flightStats.yearlyByHub.title}}</b>
                        <span class="badge badge-info float-right ">Total: {{flightStats.yearlyByHub.totalCount | number}}</span>
                    </div>
                    <div class="card-body">
                        <div >
                            <div>
                                <canvas baseChart width="400" height="150px" id="yearlyByHubReport" 
                                    [datasets]="yearlyByHubChartData" 
                                    [labels]="flightStats.yearlyByHub.labels" 
                                    [colors]="COLOR_GRID" 
                                    [legend]="lineChartLegend" 
                                    [chartType]="barChartType">
                                </canvas>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
        <div class="row p-0">
            <div class="col-xl p-0">
                <div class="card mt-2 rounded-top">
                    <div class="card-header">
                        <b>{{flightStats.monthlyTotals.title}}</b>
                        <span class="badge badge-info float-right ">Total: {{flightStats.monthlyTotals.totalCount | number}}</span>
                    </div>
                    <div class="card-body">
                        <div >
                            <div>
                                <canvas baseChart width="400" height="150px" id="monthlyTotalsReport" 
                                    [datasets]="monthlyTotalsChartData" 
                                    [labels]="flightStats.monthlyTotals.labels" 
                                    [colors]="COLOR_GRID" 
                                    [legend]="lineChartLegend" 
                                    [chartType]="barChartType">
                                </canvas>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row p-0">
            <div class="col-xl p-0">
                <div class="card mt-2 rounded-top">
                    <div class="card-header">
                        <b>{{flightStats.yearlyTotals.title}}</b>
                        <span class="badge badge-info float-right ">Total: {{flightStats.yearlyTotals.totalCount | number}}</span>
                    </div>
                    <div class="card-body">
                        <div >
                            <div>
                                <canvas baseChart width="400" height="150px" id="monthlyTotalsReport" 
                                    [datasets]="yearlyTotalsChartData" 
                                    [labels]="flightStats.yearlyTotals.labels" 
                                    [colors]="COLOR_GRID" 
                                    [legend]="lineChartLegend" 
                                    [chartType]="barChartType">
                                </canvas>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>                              
        <div class="goToTop" (click)="tabTop();" [ngClass]="{ 'show-scrollTop': windowScrolled }" *ngIf="windowScrolled">
            <a  title="Scroll up"><i class="fas fa-arrow-circle-up"></i></a>
        </div>
    </div>
</div>

<div class="container text-center" *ngIf="loading">
    <div class="lds-hourglass"></div>
    <p>Loading...</p>
</div>