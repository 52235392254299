<div class="card shadow rounded-top rounded-lg " style="margin: 5px 10px 30px 10px">
    <div class="card-header panel-american-blue">
        <div class="row">
            <div class="col-md-12 col-xs-12">
                Software
            </div>
        </div>
    </div>
    <div class="card-body">
        <div class="card shadow rounded-top rounded-lg " style="margin: 5px 10px 30px 10px">
            <div class="card-header panel-american-coral">
                VATSIM Client Software
            </div>
            <div class="card-body ">
                <div class="row">
                    <div class="col-sm-12">
                        <p>
                            In order to attach to the VATSIM network, you must use one of their pilot clients to fly online.
                        </p>
                        <p>
                            You can find the proper client for your computer/simulator at the <a href="https://www.vatsim.net/pilots/pilot-clients" target="_blank">VATSIM Pilot Clients Page</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="card shadow rounded-top rounded-lg " style="margin: 5px 10px 30px 10px">
            <div class="card-header panel-american-coral">
                Aircraft Communications Addressing and Reporting System (ACARS)
            </div>
            <div class="card-body ">
                <div class="row">
                    <div class="col-sm-12">
                        <p>
                            ACARS software is the preferred way to work with AvA for scheduling, flying, and logging data about the flight.  We do support manual PIREPs as well if your system cannot
                            support the automation.
                        </p>
                        <p>
                            AvA supports two ACARS - smartCARS and XAcars. Below are the instructions for setting up each. If you have any questions, please post them in the #acars-support channel.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <h3>smartCARS</h3>
                        <div class="well">

                            <p>
                                smartCARS is our preferred ACARS for Windows based flight sims. It is integrated with our website, and offers automated PIREP submission as part of the process.
                            </p>
                            <p style="background-color:goldenrod;color:white;padding:10px 10px 10px 10px;font-size:16px">
                                You can find a helpful all-inclusive guide here: <br> <a target="_blank" href="http://joinava.org/assets/docs/AvA smartCARS Manual.pdf">AvA smartCARS manual </a>
                            </p>
                            <hr>
                            <p>
                                Follow the links below to download your respective version:
                            </p>
                            <p>
                                <a href="https://tfdidesign.com/smartcars/app.php?action=download&amp;airlineid=568&amp;language=en-US" target="_blank" alt="English">English</a>
                            </p>

                            <p>
                                <a href="https://tfdidesign.com/smartcars/app.php?action=download&amp;airlineid=568&amp;language=de-DE" target="_blank" alt="Deutsche">Deutsche</a>
                            </p>

                        </div>
                    </div>
                </div>
                <hr class="aa-line" />
                <div class="row">
                    <div class="col-sm-12">
                        <h3>XAcars</h3>
                        <div class="well">
                            <p>Right now there is no way to download the XAcars client.  You must already have it.  If you do know of a secure and proper share, please contact vp-it.</p>

                            <hr>
                            <p style="background-color:goldenrod;color:white;padding:10px 10px 10px 10px;font-size:16px">
                                You can find a helpful all-inclusive guide here: <br> <a target="_blank" href="http://joinava.org/assets/docs/XACARS_Guide_v1_1.pdf">Xacars Guide v1.1</a>
                            </p>
                            <hr>
                            <p>Like FSacars, FSPax and FSFK, XAcars requires FSUIPC to work with MSFS. FSX requires FSUIPC 4.x</p>
                            <p>Secondly, just like the other 3 add-ons, XAcars requires a booking from our Dispatch.. so again, No booking, No PIREP!</p>
                            <p>You can choose a flight from the AA or Oneworld timetable, or choose for the 'Book a flight' option. After you have filled in all necessary information, your Flight briefing is complete. You can view your briefing on your personal bookings page.</p>
                            <p>XAcars requires the Booking ID from the database; you can find the number in the pilot briefing table: <br> Image</p>
                            <p>Once you have started FS(X-Plane), you can start up XAcars.</p>
                            <p><strong>Please note XAcars requires engines shut down and Parking Brakes ON before it is able to start ACARS.</strong></p>
                            <p>First, you need to setup XAcars for AvA <br>Image <br> Fill in the fields shown i the image below:</p>
                            <ul>
                                <li>Address =
                                    <ul>
                                        <li><strong style="font-size: 1em; line-height: 1.4em; color: #333333;">http://xacars.joinava.org/liveacars.php</strong></li>
                                    </ul>
                                </li>
                                <li>PIREP =
                                    <ul>
                                        <li><strong style="font-size: 1em; line-height: 1.4em; color: #333333;">http://xacars.joinava.org/pirepv2.php</strong></li>
                                    </ul>
                                </li>
                                <li>FlightInfo =
                                    <ul>
                                        <li><strong style="font-size: 1em; line-height: 1.4em; color: #333333;">http://xacars.joinava.org/flightdata.php</strong></li>
                                    </ul>
                                </li>
                            </ul>
                            <p>Enter your PIlot ID and your AvA Website Password.</p>
                            <p>Make sure the Enable LiveACARS checkbox unchecked and Enable PIREP is checked</p>
                            <p>Don't forget to SAVE your settings!! Now the number has to be entered in the Flightnumber field.

                            </p>
                            <p>Enter the Flight number (don't mix up with the timetable flightnumber!) and press the Flightdata from website button. You will see all fields will be filled. Once ready you can click the Start ACARS button.</p>

                            <p>The radio button to choose the network have been disabled for AvA; after all, we are a VATSIM only VA! Three buttons are available then, after your flight ends with Parking brakes ON and Engine(s) OFF: LOAD PIREP: load a previously save PIREP, actually no use for AvA. SAVE PIREP: Save your PIREP for whatever you wish to do with it. SEND PIREP: The most important one; it sends your PIREP to our website. Once the PIREP is sent, you should receive a success message.</p>

                            <p>The radio button to choose the network have been disabled for AvA; after all, we are a VATSIM only VA!</p>
                            <p>Three buttons are available then, after your flight ends with Parking brakes <strong>ON</strong> and Engine(s) <strong>OFF</strong>:</p>
                            <p><strong>LOAD PIREP</strong>: load a previously save PIREP, actually no use for AvA.<br><strong>SAVE PIREP</strong>: Save your PIREP for whatever you wish to do with it.<br><strong>SEND PIREP</strong>: The most important one; it sends your PIREP to our website.</p>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>