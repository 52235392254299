import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { GoogleMap, MapMarker, MapPolyline } from '@angular/google-maps';
import { ActivatedRoute, Router } from '@angular/router';
import { AircraftModel } from 'src/app/models/aircraft.model';
import { AirportModel } from 'src/app/models/airport.model';
import { FlightModel } from 'src/app/models/flight.model';
import { FlightMapFlight } from 'src/app/models/flightmapflight.model';
import { PirepModel } from 'src/app/models/pirep.model';
import { RouteModel } from 'src/app/models/route.model';
import { AuthService } from 'src/app/services/auth.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { PilotService } from 'src/app/services/pilot.service';
import { WeatherService } from 'src/app/services/weather.service';
import { environment } from 'src/environments/environment';
import { isWhiteSpaceLike } from 'typescript';

@Component({
  selector: 'app-findflights',
  templateUrl: './findflights.component.html',
  styleUrls: ['./findflights.component.css']
})
export class FindflightsComponent implements OnInit {
  @Output() onDispatcherTabChange = new EventEmitter<string>();
  @ViewChild(GoogleMap, {static : false}) map: GoogleMap;
  loading: boolean = false;
  pilotId: number;

  destinations: AirportModel[] = [];
  lastPirep: PirepModel;
  scheduleAirports: AirportModel[] = [];
  scheduleAircraft: AircraftModel[] = [];
  routes: RouteModel[] = [];
  flights : FlightMapFlight[] = [];
  selectedFlight : FlightModel;
  metar : string = '';
  avaMarkers = [];
  polyLines = [];
  mapData;

  //TODO check all these toggles and what they do for real - think we just have junk here
  hasAirports: boolean = false;
  hasAircraft: boolean = false;
  hasRoutes: boolean = false;
  hasMetar : boolean = false;
  hasFlights : boolean = false;
  isMapLoaded : boolean = false;
  showMetar : boolean = false;
  loadingFlights : boolean = false;
  schedulingAFlight : boolean = false;

  currentAirport: AirportModel = {
    "country": "",
    "iata": "",
    "icao": "",
    "id": 0,
    "latitude": "",
    "longitude": "",
    "name": "",
    "region": "",
  };

  destinationAirport: AirportModel = {
    "country": "",
    "iata": "",
    "icao": "",
    "id": 0,
    "latitude": "",
    "longitude": "",
    "name": "",
    "region": "",
  };

  departureAirport: AirportModel = {
    "country": "",
    "iata": "",
    "icao": "",
    "id": 0,
    "latitude": "",
    "longitude": "",
    "name": "",
    "region": "",
  };

  center: google.maps.LatLngLiteral  = {
    lat: 32.8343364,
    lng: -97.0645779
  };
  gOptions: google.maps.MapOptions;
  depLat: number;
  depLng: number;
  arrLat: number;
  arrLng: number;


  constructor(private dispatcherService: DispatcherService, private authService: AuthService, private route: ActivatedRoute,
              private router: Router, private pilotService: PilotService, private weatherService : WeatherService) { }

  ngOnInit(): void {
    if (this.authService.isAuthenticated()) {
      this.loading = true;
      this.pilotId = this.authService.getPilotId();

      let styles: any = environment.GOOGLE_MAP_STYLES;

      this.gOptions = {
        zoom: 4,
        gestureHandling: 'greedy',
      }

      this.loadPilotDestinations();
      this.loadScheduleAirports();
      this.loadScheduleAircraft();
      // this.loadBookings();
    }
  }

  /**********************************************************
   * Button Handler
   *
   * @param searchForm the search form
   *
   * Called when the user hits the Route Search button
   */
  onSubmit(searchForm) {
    // this.loading = true;
    this.hasRoutes = false;
    this.hasFlights=false;
    this.loadingFlights = true;

    this.flights=[];
    this.routes = [];

    this.dispatcherService.getRoutes(searchForm).subscribe(
      (responseData) => {
        console.log(responseData);
        if (responseData.hasOwnProperty('currentAirport')) {
          this.currentAirport = responseData['currentAirport'];
        }
        if (responseData.hasOwnProperty('routes')) {
          for (const key in responseData['routes']) {
            if (responseData['routes'][key].hasOwnProperty('attributes')) {
              let newRoute: RouteModel = {
                arrivalAirport: responseData['routes'][key]['attributes']['arrivalAirport'],
                departureAirport: responseData['routes'][key]['attributes']['departureAirport'],
                id: responseData['routes'][key]['attributes']['id']
              }
              this.routes.push(newRoute);
              this.hasRoutes = true;
            }
          }
        }
        console.log('# of Routes found: ' + this.routes.length);
        this.loadingFlights = false;
        this.loading = false;
        this.loadRouteData();
      },
      (error) => {
        console.log(error.message);
        this.router.navigate(['/techerror']);
        this.loading = false;
        this.loadingFlights = false;
      }
    )
  }

  onScheduleComplete(event) {
    this.schedulingAFlight=false;
    this.onDispatcherTabChange.emit(event);
  }
  /*****************************************************************************
  *  Service Response Handler
  *   called after a successful flight lookup in response to the search button
  */
  loadRouteData() {
    this.avaMarkers = [];
    this.polyLines = [];
    this.mapData = this.routes;

    if (this.currentAirport != undefined) {
      this.center = {
        lat: parseFloat(this.currentAirport.latitude),
        lng: parseFloat(this.currentAirport.longitude)
      };
    }
    var routeId = 0;
    console.log('mapdata length: ' + this.mapData.length);
    if (this.mapData.length > 0) {
      this.hasRoutes = true;

      for (let route of this.routes) {

        //TODO move this into it's own method
        let arrivalAirport = route.arrivalAirport,
          departureAirport = route.departureAirport;

        var avaAirportMarker = this.buildAvaAirportMarker(arrivalAirport, false, routeId);
        this.avaMarkers.push(avaAirportMarker);


        //var routeLine = this.buildLine(arrivalAirport.id, arrivalAirport.latitude, arrivalAirport.longitude, departureAirport.latitude, departureAirport.longitude, '#2989d8', 1);
        let coords = this.buildCoords(departureAirport.latitude, departureAirport.longitude, arrivalAirport.latitude, arrivalAirport.longitude);
        var routeLine = this.buildLine(coords,"#367dc4",.45, routeId);
        this.polyLines.push(routeLine);

        avaAirportMarker = this.buildAvaAirportMarker(departureAirport, false, routeId);
        this.avaMarkers.push(avaAirportMarker);

        routeId++;
      };

      // //add the current airport
      // var avaAirportMarker = this.buildAvaAirportMarker(this.currentAirport, true, routeId);
      // this.avaMarkers.push(avaAirportMarker);

      console.log(this.avaMarkers);
      this.isMapLoaded = true;
      this.loadingFlights = false;
      this.loadWeatherMap();

    } else {
      this.isMapLoaded = false;
      this.hasRoutes = false;
      console.log('No routes found.');
    }
  }


  /*********************************************************
   * Helper function to load the polyline options
   */
  buildLine(coords, color, opacity, routeId) {
    return {
      path : coords,
      geodesic : true,
      strokeColor : color,
      strokeOpacity : opacity,
      strokeWeight : 2,
    }
  }

  /**********************************************************
   * Helper function to build out a proper coords pair
   */
  buildCoords(depLat, depLng, arrLat, arrLng) {
    let coords = [];
    coords.push(
      new google.maps.LatLng(parseFloat(depLat),parseFloat(depLng))
    );
    coords.push(
      new google.maps.LatLng(parseFloat(arrLat),parseFloat(arrLng))
    );
    // console.log(coords);
    return coords;
  }

  /************************************************************
   * Helper function to build out an airport marker
   */
  buildAvaAirportMarker(airportInfo, isCurrentAirport, routeId) {
    var airportIcon ;
    if (isCurrentAirport) {
      airportIcon = {
        url : '/assets/img/map/runway.png',
        scaledSize : new google.maps.Size(16,16),
        size : new google.maps.Size(16,16)
      }
    } else {
      airportIcon = '/assets/img/map/APT.png';
    }

    var bounds = new google.maps.LatLngBounds();
    var marker = {
      id: routeId,
      position: new google.maps.LatLng(parseFloat(airportInfo.latitude), parseFloat(airportInfo.longitude)),
      title: '(' + airportInfo.icao + ') ' + airportInfo.name,
      // label: {
      //   text : '('+ airportInfo.icao+')',
      //   color : 'white',
      //   fontSize : '3em'
      // },
      options: {
        draggable: false,
        icon: airportIcon,
        anchor: { x: 8, y: 4 },
        animation: google.maps.Animation.DROP,
        color: 'white'
      },
      data : {
        airport : airportInfo
      }
    }

    return marker;
  }

  /***********************************************************************
   * Service Caller
   * Calls the getflight by iata and then parses them in to the routes array
   */
  loadFlights() {
    // this.loading=true;
    this.flights=[];
    this.dispatcherService.getFlightsByIata(this.departureAirport.iata, this.destinationAirport.iata).subscribe(
      (responseData) => {
        //degugger;
        for (const key in responseData) {
          if (responseData[key].hasOwnProperty('attributes')) {
            this.hasFlights = true;
            this.flights.push(responseData[key]['attributes']);
          }
        }
        console.log(this.flights);
        this.hasFlights = true;
        this.loading = false;
      },
      (error) => {
        //degugger;
        this.router.navigate(['/techerror']);
        this.loading = false;
        this.hasFlights = false;
      }
    )
  }

  /*******************************************************************
   * Service Caller
   * Gets the metar for the given icao
   */
  loadMetar(icao) {
    this.metar='';
    this.hasMetar = false;
    this.weatherService.getMetar(icao).subscribe(
      (responseData) => {
        //degugger;
        this.metar = responseData;
        console.log(this.metar);
        this.hasMetar = true;
      },
      (error) => {
        //degugger;
        this.hasMetar = false;
      }
    )
  }

  /*******************************************************************
   * Marker Click Handler
   * Handles when the user clicks on an airport marker to retreive a
   * new set of routes for that airport in the marker and the current
   * airport.
   */
  onMarkerClick(markerElem, marker) {
    if (this.currentAirport.name != marker.data.airport.name) {
      //first let's highlight the route line
      this.polyLines[marker.id]={ ...this.polyLines[marker.id], strokeColor: 'red', strokeWeight: 1, opacity: 1 };

      //now we need to lookup the flights between this chosen airport and current airport into the grid
      let routeId = marker.id;
      console.log('RouteID: ' + routeId);

      let route = this.routes[routeId];

      this.destinationAirport = route.arrivalAirport;
      this.departureAirport = route.departureAirport;

      console.log(route);

      this.loadFlights();
      this.loadMetar(this.destinationAirport.icao);
    }
  }

  /********************************************************************
   * Marker Hover handler
   * Handles when the user hovers over a marker to highlight the line
   */
  onMarkerHover(mElem : ElementRef, mark) {
    if (this.currentAirport.name != mark.data.airport.name) {
      let index = mark.id;
      this.polyLines[index] = { ...this.polyLines[index], strokeColor: 'red', strokeWeight: 3, strokeOpacity: 1 };
    }
  }

  /********************************************************************
   * Marker Mouseout hander
   * Handles when the customer moves the mouse off a marker
   */
  onMarkerOut(mElem : ElementRef, mark) {
    let index = mark.id;
    this.polyLines[index] = { ...this.polyLines[index], strokeColor: '#367dc4', strokeWeight: 3, strokeOpacity: .45 };
  }

  /********************************************************************
   * Flight line selected Handler
   * When a user clicks a flight in the table, we take that and call
   * the scheduleflight component
   */
  onFlightSelect(flight : FlightModel) {
    console.log(flight);
    this.schedulingAFlight=true;
    this.selectedFlight = flight;
  }

  /********************************************************************
   * Service Caller
   * When the component loads we call out to see where the pilot is
   * and then load the possible destinations from that airport
   *
   */
  loadPilotDestinations() {
    this.dispatcherService.getPilotDestinations().subscribe(
      (responseData) => {

        for (const key in responseData['destinations']) {
          if (responseData['destinations'][key].hasOwnProperty('attributes')) {
            this.destinations.push(responseData['destinations'][key]['attributes']);
          }
        }

        if (responseData.hasOwnProperty('lastPirep')) {
          this.lastPirep = responseData['lastPirep'];
        }

        if (responseData.hasOwnProperty('currentAirport') && responseData['currentAirport'] !== null) {
          this.currentAirport = responseData['currentAirport'];
          // If current airport is null, it means the ICAO of the last pirep could not be found in `schedule_airports`
          // or in `airports` tables.  Because "loadInitialMapData" essentially just builds a route from $currentAirport
          // to each destination, a null current airport breaks that and we shouldn't render the map.
          //
          // Note: It is not likely that $destinations is null, because lara serves up the list of hub airports as a
          // fallback if there are no scheduled routes available with $currentAirpot as the starting point.
          this.loadInitialMapData();
        }
        this.loading = false;
      },
      (error) => {
        //degugger;
        this.router.navigate(['/techerror']);
        this.loading = false;
        this.loading = false;
      }
    )
  }

  /********************************************************************
   * Service Response Handler
   * Takes the pilotdestinations and turns them into map components
   * markers and lines
   */
  async loadInitialMapData() {
    this.avaMarkers=[];
    this.polyLines=[];
    var routeId = 0;
    this.center = {
      lat: parseFloat(this.currentAirport.latitude),
      lng: parseFloat(this.currentAirport.longitude)
    };

    var avaAirportMarker = this.buildAvaAirportMarker(this.currentAirport, true, routeId);
    this.avaMarkers.push(avaAirportMarker);

    if (this.destinations.length>0) {
      for (const key in this.destinations) {
        var destination = this.destinations[key];

        let coords = this.buildCoords(this.currentAirport.latitude,this.currentAirport.longitude,destination.latitude,destination.longitude);
        var routeLine = this.buildLine(coords,'#367dc4',.45, routeId);
        this.polyLines.push(routeLine);

        avaAirportMarker = this.buildAvaAirportMarker(destination,false, routeId);
        this.avaMarkers.push(avaAirportMarker);

        let newRoute: RouteModel = {
          arrivalAirport: destination,
          departureAirport: this.currentAirport,
          id: routeId
        }
        this.routes.push(newRoute);
        this.hasRoutes = true;
        routeId++;
      }
      this.hasRoutes=true;
      //for whatever reason this is the only way I could figure out to ensure the map was loaded prior to loading weather layer
      await new Promise(f => setTimeout(f, .5));
      this.loadWeatherMap();
    } else {
      this.hasRoutes = false;
    }
  }

  /********************************************************************
   * Service Caller
   * For the search form we need to look up the equipment list
   */
  loadScheduleAircraft() {
    this.dispatcherService.getScheduleAircraft().subscribe(
      (responseData) => {
        for (const key in responseData) {
          if (responseData[key].hasOwnProperty('attributes')) {
            this.hasAircraft = true;
            this.scheduleAircraft.push(responseData[key]['attributes']);
          }
        }
        this.loading = false;
      },
      (error) => {
        //degugger;
        this.router.navigate(['/techerror']);
        this.loading = false;
        this.hasAircraft = false;
        this.loading = false;
      }
    )
  }

  /********************************************************************
   * Service Caller
   * For the search form we need to look up the airports in our schedule
   */
  loadScheduleAirports() {
    this.dispatcherService.getScheduleAirports().subscribe(
      (responseData) => {
        for (const key in responseData) {
          if (responseData[key].hasOwnProperty('attributes')) {
            this.hasAirports = true;
            this.scheduleAirports.push(responseData[key]['attributes']);
          }
        }
        this.loading = false;
      },
      (error) => {
        //degugger;
        this.router.navigate(['/techerror']);
        this.loading = false;
        this.hasAirports = false;
        this.loading = false;
      }
    )
  }

  /********************************************************************
   * Overlay Loader
   * Loads the weather tile overlay onto the map
   */
  loadWeatherMap() {
    var tileNEX = new google.maps.ImageMapType({
        getTileUrl: function(tile, zoom) {
            return "https://mesonet.agron.iastate.edu/cache/tile.py/1.0.0/nexrad-n0q-900913/" + zoom + "/" + tile.x + "/" + tile.y +".png?"+ (new Date()).getTime();
        },
        tileSize: new google.maps.Size(256, 256),
        opacity:0.60,
        name : 'NEXRAD'
    });
    this.map.overlayMapTypes.setAt(0,tileNEX);
  }

  clearForm(searchform) {
    searchform.resetForm();
    searchform.departureIcao='';
    searchform.arrvialIcao='';
    searchform.duration='';
    searchform.acIcao='';
    this.loadingFlights = false;

  }
}
