import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GoalsService {

  GOALS_SVC = '/goals';
  goals: any[] = [];

  constructor(private httpClient: HttpClient) { }

  getGoals() {
    this.goals = [];
    return this.httpClient.get(environment.API_URL + this.GOALS_SVC).pipe(map(
      (data) => {
        for (const key in data) {
          if (Object.prototype.hasOwnProperty.call(data, key)) {
            for (const index in data[key]) {
              if (Object.prototype.hasOwnProperty.call(data[key], index)) {
                const element = data[key][index];
                this.goals.push(element);
              }
            }
          }
        }
        return this.goals;
      }))
  }

  putGoals(payload) {
    console.log(payload);
    let url = environment.API_URL + this.GOALS_SVC;
    console.log(url);
    return this.httpClient.put(url, payload);
  }
}
