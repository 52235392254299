import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PersonnelrecordService {
  PERSONNEL_RECORD_SVC = '/prs/';

  constructor(private httpClient: HttpClient) { }

  getPersonnelRecords(pilotId) {
    return this.httpClient.get(environment.API_URL + this.PERSONNEL_RECORD_SVC + pilotId).pipe(map(
      (data) => {
        // this.apps = data['data'];
        return data['personnelRecords'];
      }
    ))
  }

  deletePersonnelRecord(prId) {
    let url = environment.API_URL + this.PERSONNEL_RECORD_SVC + prId;

    return this.httpClient.delete(url).pipe(map(
      (data) => {
        return data;
      }
    ));
  }
}
