import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PilotAppModel } from '../models/pilotapp.model';

@Injectable({
  providedIn: 'root'
})
export class PilotappService {
  PILOT_APP_SVC = "/pilotapps";
  constructor(private httpClient: HttpClient) { }

  postApplication(app : PilotAppModel) {
    console.log(app);
    return this.httpClient.post(environment.API_URL + this.PILOT_APP_SVC, app);   
  }
}
