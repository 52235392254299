import { Component, ContentChild, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { ModalModel } from 'src/app/models/modal.model';
import { DataSharingService } from 'src/app/services/datasharing.service';

@Component({
  selector: 'app-avamodal',
  templateUrl: './avamodal.component.html',
  styleUrls: ['./avamodal.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AvamodalComponent {
  @Input() modalTitle: string ;
  @Input() showModal: boolean;
  @Input() modalMessage: string ;
  @Input() showMiniClose: boolean = false;
  @Input() buttonText: string = "Save";
  @Input() modalStatus: string ="alert";
  @Input() modalPurpose: string = "";
  @Input() showCancelButton: boolean = false;
  @Input() modalName : string;

  @Output() modalClosed = new EventEmitter<{status: string, purpose: string}>();

  closeResult = '';
  private activatedDSS: Subscription;

  @ViewChild('myModal',{ static: false }) avaModal;

  constructor(private modalService: NgbModal, private el : ElementRef, private dss : DataSharingService) {}


  ngAfterViewInit() {
    this.activatedDSS = this.dss.showModal.subscribe( value  => {
      if (this.modalName===value.modalName) {
        this.showModal = value.showModal;
        if (this.showModal==true) {
          this.open(this.avaModal);
        }

      }
    })
  }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',centered:true}).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
        console.log(this.closeResult);
        this.dss.showModal.next({modalName : this.modalName, showModal :false});
        this.modalClosed.emit({status:'ok', purpose: this.modalPurpose});
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        console.log(this.closeResult);
        this.modalClosed.emit({status:'cancel', purpose: this.modalPurpose});
        this.dss.showModal.next({modalName : this.modalName, showModal :false});
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnDestroy(): void {
    this.activatedDSS.unsubscribe();
  }

  getColor() {
    console.log('modal');
    return this.modalStatus.length>0 ? 'white' : 'inherit';
  }
}
