<div class="card ">
    <div class="card-header panel-american-blue-grey-red text-light">Your flight bookings</div>
    <div class="card-body">
        <div *ngIf="hasBookings && hasSmartCarsBookings">
            <span class="float-right" *ngIf="hasXacarsBookings" style="color:orangered;font-size: .75em;">XAcars bookings are further down if you have them and cannot see
                them.</span>
            <h3 *ngIf="smartCarsFlights.length>0">Your ({{smartCarsFlights.length}}) SmartCARS bookings:</h3>
            <span>You should see the same list in your smartCARS Flights tab (refresh may be needed)</span>
            <div class="table-responsive" ng-if="smartCarsFlights.length>0">
                <table class="table table-condensed table-hover ">
                    <thead>
                        <tr>
                            <th>Briefing</th>
                            <th>Origin</th>
                            <th>Destination</th>
                            <th>Aircraft</th>
                            <th>Cruise</th>
                            <th>Duration</th>
                            <th>Route</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let booking of smartCarsFlights">
                            <td>
                                <a href="http://simbrief.com/system/dispatch.php?airline=AAL&fltnum={{pilotDetails.callSign}}&type={{booking.aircraft}}&orig={{booking.depAirportDetails.icao}}&dest={{booking.destAirportDetails.icao}}&manualrmk=joinava&units=LBS"
                                    target="_blank">
                                    <img title="Click to open simBrief" src="assets/img/simbrief_logo.png"
                                        style="height: 1.3em" alt="simBrief" />
                                </a>
                            </td>
                            <td>{{booking.depAirportDetails.icao}}</td>
                            <td>{{booking.destAirportDetails.icao}}</td>
                            <td>{{booking.aircraft}}</td>
                            <td>{{booking.cruise}}</td>
                            <td>{{booking.duration}}</td>
                            <td>{{booking.route}}</td>
                            <td>
                                <button class="btn btn-danger btn-sm" id="btnAppDelete"
                                    (click)="showDeleteConfirmation('smartCARS',booking.id)"
                                    title="Delete booking {{booking.id}}...">
                                    <i class="far fa-trash-alt"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div *ngIf="hasBookings && hasXacarsBookings">
            <h3 *ngIf="xacarsFlights.length>0">Your ({{xacarsFlights.length}}) XAcars bookings:</h3>
            <span style="color:red">REMINDER: The ID in red is the one you copy into XAcars!!!</span>
            <div class="table-responsive" ng-if="xacarsFlights.length>0">
                <table class="table table-condensed table-hover ">
                    <thead>
                        <tr>
                            <th>Briefing</th>
                            <th>Id</th>
                            <th>Origin</th>
                            <th>Destination</th>
                            <th>Aircraft</th>
                            <th>Cruise</th>
                            <th>Route</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let booking of xacarsFlights">
                            <td>
                                <a href="http://simbrief.com/system/dispatch.php?airline=AAL&fltnum={{pilotDetails.callSign}}&type={{booking.aircraft}}&orig={{booking.depAirportDetails.icao}}&dest={{booking.destAirportDetails.icao}}&manualrmk=joinava&units=LBS"
                                    target="_blank">
                                    <img title="Click to open simBrief" src="assets/img/simbrief_logo.png"
                                        style="height: 1.3em" alt="simBrief" />
                                </a>
                            </td>
                            <td>
                                <font color="red"><b>{{booking.id}}</b></font>
                            </td>
                            <td>{{booking.depAirportDetails.icao}}</td>
                            <td>{{booking.destAirportDetails.icao}}</td>
                            <td>{{booking.aircraft}}</td>
                            <td>{{booking.cruise}}</td>
                            <td>{{booking.route}}</td>
                            <td>
                                <button class="btn btn-danger btn-sm" id="btnAppDelete"
                                    (click)="showDeleteConfirmation('XAcars',booking.id)"
                                    title="Delete booking {{booking.id}}...">
                                    <i class="far fa-trash-alt"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row" *ngIf="!hasBookings">
            <div class="col-md-12">
                You do not have any flights booked at this time.
            </div>
        </div>
    </div>
</div>



<span *ngIf="loading">
    <div class="d-flex justify-content-center " style="margin-top: 10em;margin-bottom: 10em;">
        <div class="spinner-border text-primary" style="width: 6rem; height: 6rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</span>

<app-avamodal [modalName]="modalName" [modalMessage]="modalMessage" [modalTitle]="modalTitle" [showMiniClose]="showMiniClose"
    [showCancelButton]="showCancelButton" [modalStatus]="modalStatus" [buttonText]="'Ok'" [modalPurpose]="modalPurpose"
    (modalClosed)="onModalClosed($event)"></app-avamodal>