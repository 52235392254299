import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageModel } from 'src/app/models/message.model';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: 'app-featureddestination',
  templateUrl: './featureddestination.component.html',
  styleUrls: ['./featureddestination.component.css']
})
export class FeatureddestinationComponent implements OnInit {
  loading: boolean = false;
  featuredDestination: MessageModel;
  isPreview : boolean = false;
  showCounter : boolean = false;

  constructor(private router: Router, private msgService: MessageService) { }

  ngOnInit(): void {
    this.loading = true;
    this.featuredDestination = {
      created_at: '',
      enddate: '',
      id: 0,
      message: 'na',
      msgtype: 0,
      startdate: '',
      title: '',
      updated_at: ''
    };

    console.log(this.featuredDestination);
    this.loadFeaturedDestionationDetails();
  }

  loadFeaturedDestionationDetails() {
    this.msgService.getMessages().subscribe(
      (responseData) => {
        var messages: MessageModel[] = responseData;

        console.log(responseData);
        var featuredDestination = messages.filter(message => {
          if (message.msgtype == 2) {
            this.featuredDestination = message;
          }
          else return null;
        });
        console.log(this.featuredDestination);
        this.showCounter=true;
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        if (error.status = 404) {
        } else {
          this.router.navigate(['/techerror']);
        }
      }
    );
  }
  onSubmit(fdDetailForm) {
    this.loading=true;
    console.log(fdDetailForm);
    this.featuredDestination.title=fdDetailForm.txtFDTitle;
    this.featuredDestination.message=fdDetailForm.txtFDMessage;

    this.msgService.postMessage(this.featuredDestination.id,this.featuredDestination).subscribe(
      (responseData) => {
        console.log(responseData);
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        if (error.status = 404) {
        } else {
          this.router.navigate(['/techerror']);
        }
      }
    );
  }
}
