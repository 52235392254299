import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TransferRequestModel } from 'src/app/models/transferrequest.model';
import { AuthService } from 'src/app/services/auth.service';
import { DataSharingService } from 'src/app/services/datasharing.service';
import { PilotService } from 'src/app/services/pilot.service';
import { TransferService } from 'src/app/services/transfer.service';
import { textChangeRangeIsUnchanged } from 'typescript';

@Component({
  selector: 'app-transfer',
  templateUrl: './transfer.component.html',
  styleUrls: ['./transfer.component.css']
})
export class TransferComponent implements OnInit {
  loading: boolean = false;
  hasTransferRequests: boolean = false;
  hasInboundRequests: boolean = false;
  hasOutboundRequests: boolean = false;

  transferRequests: TransferRequestModel[] = [];
  inboundRequests: TransferRequestModel[] = [];
  outboundRequests: TransferRequestModel[] = [];

  pilotId: number;
  isVPorHigher: boolean = false;

  constructor(private router: Router, private pilotService: PilotService, private authService: AuthService, private dss: DataSharingService,
    private transferService: TransferService) { }

  ngOnInit(): void {
    this.loading = true;
    this.transferRequests = [];
    this.hasTransferRequests = false;
    this.isVPorHigher = false;
    this.hasInboundRequests=false;
    this.hasOutboundRequests=false;
    this.inboundRequests=[];
    this.outboundRequests=[];

    this.loadTransferRequests();
  }

  onTransferRequestComplete(event) {
    console.log(event);
    this.ngOnInit();
  }

  loadTransferRequests() {
    if (this.authService.isAuthenticated()) {
      this.pilotId = this.authService.getPilotId();
      this.transferService.getTransferRequests().subscribe(
        (responseData) => {
          if (responseData.hasOwnProperty('meta')) {
            if (responseData['meta'].hasOwnProperty('level')) {
              if (responseData['meta']['level'] > 3) {
                this.isVPorHigher = true;
              }
            }
          }
          if (responseData.hasOwnProperty('data')) {
            for (let transferReq of responseData['data']) {
              if (this.isVPorHigher) {
                this.transferRequests.push(transferReq['attributes']);
              } else {
                if (transferReq['type'] == 'inboundTransfer') {
                  this.inboundRequests.push(transferReq['attributes']);
                } else if (transferReq['type'] == 'outboundTransfer') {
                  this.outboundRequests.push(transferReq['attributes']);
                }
              }
            }
            console.log(this.transferRequests);
            if (this.transferRequests.length > 0) {
              this.hasTransferRequests = true;
            }
            console.log(this.inboundRequests);
            if (this.inboundRequests.length > 0) {
              this.hasInboundRequests = true;
            }
            console.log(this.outboundRequests);
            if (this.outboundRequests.length > 0) {
              this.hasOutboundRequests = true;
            }
          }

          this.loading = false;
        },
        (error) => {
          console.log('WARNING: ' + error.message);
          if (error.status === 500) {
            this.router.navigate(['/techerror']);
          }
          this.loading = false;
        }
      );
    }
  }

}
