import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hrtransfer',
  templateUrl: './hrtransfer.component.html',
  styleUrls: ['./hrtransfer.component.css']
})
export class HrtransferComponent implements OnInit {
  loading : boolean = false;
  searchSuccessful : boolean = false;
  searchResults : any[] = [];
  hasMemberSearchResults : boolean = false;
  searchComplete : boolean = false;

  constructor() { }

  ngOnInit(): void {
    this.searchSuccessful=false;
    this.searchComplete=false;
    this.loading=false;
    this.hasMemberSearchResults=false;
  }

  handleSearchResults(searchResults) {
    this.searchResults = [];
    this.searchComplete=true;
    if (searchResults.length>0) {
      this.hasMemberSearchResults=true;
      this.searchResults = searchResults;
    } else {
    }
    console.log(searchResults);
  }

  onHRTransferComplete($event) {
    this.ngOnInit();
  }
}
