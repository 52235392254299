import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotamModel } from 'src/app/models/notam.model';
import { AuthService } from 'src/app/services/auth.service';
import { DataSharingService } from 'src/app/services/datasharing.service';
import { NotamService } from 'src/app/services/notam.service';

@Component({
  selector: 'app-pilot',
  templateUrl: './pilot.component.html',
  styleUrls: ['./pilot.component.css']
})
export class PilotComponent implements OnInit, OnDestroy {
  notams : NotamModel[] = [];
  pilotId;
  pirepButtonText : string = 'Manual Pirep';

  constructor(private router: Router, private authService : AuthService, private notamService : NotamService, private dss : DataSharingService) { }

  ngOnInit(): void {
    this.notams=[];
    if (this.authService.isAuthenticated()) {
      this.pilotId = this.authService.getPilotId();
      this.notamService.getSecuredNotams().subscribe(
        (responseData: NotamModel[]) => {
          this.notams = responseData;
        },
        (error) => {
            console.log('WARNING: ' + error.message);
            if (error.status === 500) {
              this.router.navigate(['/techerror']);
            }
        }
      );
      this.dss.isEditingPirep.subscribe(
        isEditing => {
          console.log(isEditing);
          this.pirepButtonText='Manual Pirep';
          if (isEditing) {this.pirepButtonText='Edit Pirep';}
        }
      )
      this.router.navigate(['pilot',this.pilotId,'lastflight']);
    } else {
      //TODO change this to reset the cert and what not so it works better during token timeout
      this.router.navigate(['/']);
    }
  }

  ngOnDestroy() {
    this.notams=null;
  }
}
