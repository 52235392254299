import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ModalModel } from '../models/modal.model';

@Injectable()
export class DataSharingService {
    public isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public isManager: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public showModal: BehaviorSubject<ModalModel> = new BehaviorSubject<ModalModel>({modalName : '', showModal :false});
    public isEditingPirep: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public isResetPasswordActive: BehaviorSubject<Object> = new BehaviorSubject<Object>({route : '', id : -1});
}