<ng-template #myModal let-modal>
  <div class="container" 
    [ngClass]="{'bg-danger' : modalStatus=='danger', 'bg-info' : modalStatus=='info', 'bg-success' : modalStatus=='success', 'bg-warning' : modalStatus=='warning'}"
    [ngStyle]="{color: this.modalStatus.length>0 ? 'white' : 'inherit'}"
  >
    <div class="modal-header" >
      <h3 class="modal-title" id="modal-basic-title">{{modalTitle}}</h3>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')" *ngIf="showMiniClose">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" [innerHTML]="modalMessage">
    </div>
    <div class="modal-footer">
      <button type="cancel" class="btn btn-outline-light" (click)="modal.dismiss('cancel')" *ngIf="showCancelButton" >Cancel</button>
      <button type="button" class="btn btn-outline-light" (click)="modal.close('Ok Click')">{{buttonText}}</button>
    </div>
  </div>
</ng-template>