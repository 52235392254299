import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
  pageName="suinfo";

  constructor() { }

  ngOnInit(): void {
    this.pageName = "suinfo"; //incase of page reload
  }

  onPageChange(newPage : string) {
    this.pageName = newPage;
  }
}
