<div class="container">
    <h2 class="mt-4 text-info" style="text-align: center">
        Our Mission
    </h2>

    <hr class="aa-line"/>

    <div class="row">
        <div class="col-sm-12">
            <h3 style="text-align: center">
                We value community, camaraderie, and especially fun.  <br/><br/>
                We work as a team in concert with VATSIM to make the entire online experience enjoyable for everyone who experiences this wonderful hobby.
            </h3>
        </div>
    </div>  
    <span style="font-size: .05em;color:hotpink">And of course, take over the world Pinky!</span>
        <div class="message-margins">&nbsp;</div>
</div>
