import { Component, Input, OnInit } from '@angular/core';
import { ProfileModel } from 'src/app/models/profile.model';
import { PilotService } from 'src/app/services/pilot.service';

@Component({
  selector: 'app-profileform',
  templateUrl: './profileform.component.html',
  styleUrls: ['./profileform.component.css']
})
export class ProfileformComponent implements OnInit {
  @Input() profile : ProfileModel;
  @Input() pilotId : number;
  loading : boolean = false;

  constructor(private pilotService : PilotService) { }

  ngOnInit(): void {
  }

  onSubmit(profileForm) {
    this.loading=true;
    console.log(profileForm);

    this.pilotService.putProfile(this.pilotId,profileForm).subscribe(
      (responseData) => {
        console.log(responseData);
        this.loading=false;
      },
      (error) => {
        this.loading=false;
      }
    )
  }
}
