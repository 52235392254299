import { Component, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { FlightModel } from 'src/app/models/flight.model';
import { ReviewablePirepModel } from 'src/app/models/reviewablepirep.model';
import { AuthService } from 'src/app/services/auth.service';
import { DataSharingService } from 'src/app/services/datasharing.service';
import { PirepService } from 'src/app/services/pirep.service';

@Component({
  selector: 'app-pirepreview',
  templateUrl: './pirepreview.component.html',
  styleUrls: ['./pirepreview.component.css']
})
export class PirepreviewComponent implements OnInit {
  loading: boolean = false;
  hasPireps: boolean = false;
  reviewablePireps: ReviewablePirepModel[];
  pirepToChange: ReviewablePirepModel;
  hasPirepToReject: boolean = false;

  @ViewChild('rejectPirepForm') rejectPirepForm: NgForm;
  isHidden : boolean = true;

  constructor(private authService: AuthService, private dss: DataSharingService, private pirepService: PirepService, private router : Router) { }

  ngOnInit(): void {
    this.loading = true;
    this.hasPireps = false;
    this.reviewablePireps = [];
    this.pirepToChange;
    this.hasPirepToReject = true;

    this.pirepService.getReviewablePireps().subscribe(
      (responseData) => {
        this.reviewablePireps = responseData;
        this.reviewablePireps.forEach(element => {
          console.log(element);
          element['flightDetails'] = {
            arrivalAirportInfo: null,
            departureAirportInfo: null,
            arrivalTime: element.attributes.arrTime,
            departureTime: element.attributes.depTime,
            ofrs: element.attributes.ofrs
          };
          this.loading = false;
          this.hasPireps = true;
          });
        console.log(this.reviewablePireps);
      },
      (error) => {
        if (error.status = 404) {
          this.hasPirepToReject=false;
          this.reviewablePireps=[];
        }
        this.loading = false;
      }
    );
  }

  onAcceptReject(returnMessage: {status: string, pirep: ReviewablePirepModel}) {
    console.log(returnMessage);

    if (returnMessage.status==='accept') {
      this.acceptPirep(returnMessage.pirep);
    }

    this.rejectPirep(returnMessage.pirep);

    //change the hidden modal
  }

  rejectPirep(pirepToReject) {
    this.rejectPirepForm.reset();
    this.isHidden=false;
    this.pirepToChange = pirepToReject;
    this.hasPirepToReject = true;
  }

  executePirepReject(rejectForm) {
    this.isHidden=true;;
    console.log(rejectForm);
    this.pirepService.rejectPirep(this.pirepToChange, rejectForm.txtSpecificReason).subscribe(
      (responseData) => {
        this.ngOnInit();
      },
      (error) => {
        console.log(`WARN ${error}`);
        this.router.navigate(['/techerror']);
      }
    );
  }

  acceptPirep(pirepToAccept) {
    this.pirepToChange = pirepToAccept;
    console.log(this.pirepToChange);
    this.pirepService.acceptPirep(this.pirepToChange).subscribe(
      (responseData) => {
        this.ngOnInit();
      },
      (error) => {
        console.log(`WARN ${error}`);
        this.router.navigate(['/techerror']);
      }
    );
  }
}
