<div class="card shadow rounded-top rounded-lg " style="margin: 5px 10px 30px 10px">
    <div class="card-header panel-american-blue">
        <div class="row">
            <div class="col-md-12 col-xs-12">
                Training
            </div>
        </div>
    </div>
    <div class="card-body " *ngIf="isFound">
        <h2>{{trainingPage.title}}</h2>
        <hr class="aa-line">
        <p [innerHtml]="trainingPage.message"></p>
    </div>
    <div class="card-body " *ngIf="!isFound && !loading">
        <h2><i class="fas fa-wrench"></i>  Under construction <i class="fas fa-wrench"></i></h2>
    </div>
</div>

<span *ngIf="loading">
    <div class="d-flex justify-content-center " style="margin-top: 10em;margin-bottom: 10em;">
        <div class="spinner-border text-primary" style="width: 6rem; height: 6rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</span>
