import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { HiringHubModel } from 'src/app/models/hiringhub.model';
import { ProfileModel } from 'src/app/models/profile.model';
import { AuthService } from 'src/app/services/auth.service';
import { DataSharingService } from 'src/app/services/datasharing.service';
import { HubService } from 'src/app/services/hub.service';
import { TransferService } from 'src/app/services/transfer.service';

@Component({
  selector: 'app-transferform',
  templateUrl: './transferform.component.html',
  styleUrls: ['./transferform.component.css']
})
export class TransferformComponent implements OnInit {
  @Output() onTransferRequestFormComplete = new EventEmitter<string>();
  @Input() profile : ProfileModel;
  @Input() pilotId : number;
  loading : boolean = false;
  transferableHubs: HiringHubModel[] = [];

  @Output() modalMessage: string = "";
  @Output() modalTitle: string = "";
  @Output() modalStatus: string = "";
  @Output() modalPurpose: string = "";
  @Output() showCancelButton: boolean = false;
  @Output() showMiniClose: boolean = false;
  @Output() modalName : string = 'transferFormModal';

  constructor(private transferService: TransferService, private hubService: HubService,private authService : AuthService, private router : Router, private dss : DataSharingService) { }

  ngOnInit(): void {
    this.transferableHubs = [];
    this.loading=true;
    this.hubService.getTransferableHubs().subscribe(
      (responseData: HiringHubModel[]) => {
        this.transferableHubs = responseData;
        this.loading=false;
      },
      (error) => {
        if (error.status === 500 ) {
          this.router.navigate(['/techerror']);
        } else {
          this.showFailureModal(error);
        }
        this.loading=false;
      }
    );

  }

  onSubmit(txForm) {
    this.loading=true;
    console.log(txForm, this.profile);

    this.transferService.postTransfer(txForm).subscribe(
      (responseData) => {
        console.log(responseData);
        this.showSuccessModal(responseData);
        this.loading=false;
      },
      (error) => {
        if (error.status === 500 ) {
          this.router.navigate(['/techerror']);
        } else {
          this.showFailureModal(error);
        }
        this.loading=false;
      }
    )
  }

  onModalClosed(returnMessage: {status: string, purpose: string}) {
    if (returnMessage.purpose == 'transfersuccess' && returnMessage.status == 'ok') {
      this.onTransferRequestFormComplete.emit('transferRequestFormComplete');
    }
  }

  showSuccessModal(data) {
    this.modalStatus='success';
    this.showMiniClose=false;
    this.showCancelButton=false;
    this.modalTitle = 'Transfer Submitted';
    this.modalMessage = 'Your Transfer has been successfully submitted as Transfer #:' + data['transferId'];
    this.modalMessage += '<br/>Please allow a few days for our staff to accept/deny your request.';
    this.modalPurpose = "transfersuccess";
    this.dss.showModal.next({modalName : this.modalName, showModal :true});
  }

  showFailureModal(error) {
    this.modalStatus='danger';
    this.showMiniClose=false;
    this.showCancelButton=false;
    this.modalTitle = 'Transfer Request Failed';
    this.modalMessage = 'Your Transfer request failed to be processed with the following error:<br/>';
    if (error.error.hasOwnProperty("messages")) {
      for (const key in error.error.messages) {
        this.modalMessage += '<br/>' + error.error.messages;
      }
    }
    console.log(error.message + ' == ' + error.status);
    this.modalPurpose = "transferfailure";
    this.dss.showModal.next({modalName : this.modalName, showModal :true});
  }
}
