import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DismissedService {
  DISMISSED_RECORDS_SVC = '/dismissedpilots/';

  constructor(private httpClient : HttpClient) { }

  removeDismissedListRecord(disToRemove) {
    let url = environment.API_URL + this.DISMISSED_RECORDS_SVC + disToRemove;

    return this.httpClient.delete(url).pipe(map(
      (data) => {
        return data;
      }
    ));
  }
}
