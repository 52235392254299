import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { MembersearchService } from 'src/app/services/membersearch.service';

@Component({
  selector: 'app-membersearch',
  templateUrl: './membersearch.component.html',
  styleUrls: ['./membersearch.component.css']
})
export class MembersearchComponent implements OnInit {
  @Output() onMemberSearchComplete = new EventEmitter<any[]>();  
    
  @Input() searchSource : string = '';
  DEFAULT_SRC = 'PILOTS';
  
  searchResults : any[] = [];
  loading : boolean = false;
  pilotId : number =0;

  constructor(private authService : AuthService, private memberSearchService : MembersearchService, private router : Router) { }

  ngOnInit(): void {
    console.log(this.searchSource);
    if (this.searchSource=='') {
      this.searchSource=this.DEFAULT_SRC;
    }
    this.loading = false;
    this.searchResults = [];
  }

  onSubmit(memberSearchForm) {
    this.loading = true;
    console.log(memberSearchForm);

    this.loadSearchResults(memberSearchForm);
  }

  loadSearchResults(searchCriteriaForm) {
    if (this.authService.isAuthenticated()) {
      this.pilotId = this.authService.getPilotId();
      this.memberSearchService.search(searchCriteriaForm.searchCriteria, this.searchSource).subscribe(
        (responseData) => {
          this.searchResults=responseData;
          this.loading = false;
          this.onMemberSearchComplete.emit(this.searchResults);
        },
        (error) => {
          console.log('WARNING: ' + error.message);
          if (error.status === 500) {
            this.router.navigate(['/techerror']);
          } else if (error.status === 404) {
            this.searchResults=[];
            this.onMemberSearchComplete.emit(this.searchResults);
          }
          this.loading = false;
        }
      );
    }    
  }

}
