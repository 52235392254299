import { Component, DebugEventListener, Input, OnInit, ViewChild } from '@angular/core';
import { GoogleMap, MapInfoWindow, MapMarker, MapPolyline } from '@angular/google-maps';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { FlightchartComponent } from 'src/app/components/util/flightchart/flightchart.component';
import { LiveControllerModel } from 'src/app/models/livecontroller.model';
import { LiveFlightModel } from 'src/app/models/liveflight.model';
import { MapService } from 'src/app/services/map.service';
import { WeatherService } from 'src/app/services/weather.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-hmflightmap',
  templateUrl: './hmflightmap.component.html',
  styleUrls: ['./hmflightmap.component.css']
})
export class HmflightmapComponent implements OnInit {
  isMapLoaded: boolean = false;
  isMapAPILoaded: boolean = false;
  pilotFlights: LiveFlightModel[] = [];
  liveControllers: LiveControllerModel[] = [];
  pilotCount: number = 0;
  atcCount: number = 0;
  lastUpdateTime: string;

  hasPilots: boolean = false;
  hasATC: boolean = false;
  isFlightInfoWindow: boolean = true;

  //mapping stuff
  markers = [];
  lines = [];
  center: google.maps.LatLngLiteral;
  gOptions: google.maps.MapOptions;
  apiLoaded: Observable<boolean>;
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
  @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow;
  @ViewChild(MapMarker, { static: false }) someElem: MapMarker;
  infoContent;
  isInfoContentLoaded: boolean = false;

  timerId;

  constructor(private router: Router, private mapService: MapService, private weatherService: WeatherService) { }

  ngOnInit(): void {
    this.isMapLoaded = false;
    this.hasPilots = false;
    this.hasATC = false;
    this.pilotCount = 0;
    this.atcCount = 0;
    this.initializeMap();
    this.loadMappingData();
    this.timerId = setInterval(() => {
      console.log('RELOADING MAPPING DATA...');
      this.loadMappingData()
    }, 300000);
  }

  ngOnDestroy() {
    if (this.timerId) {
      clearTimeout(this.timerId);
    }
  }

  initializeMap() {
    // let styles: any = environment.GOOGLE_MAP_STYLES;
    this.gOptions = {
      center: {
        lat: 32.8343364,
        lng: -97.0645779
      },
      zoom: 4.5,
      gestureHandling: 'greedy',
      // styles: styles,
    }

    this.infoContent = {
      id: 0,
      attributes: {
        callsign: '',
        name: '',
        departureAirport: '',
        destinationAirport: '',
        aircraftType: '',
        altitude: '',
        heading: '',
        groundSpeed: 0,
        route: '',
        remarks: ''
      }
    }

  }

  loadMappingData() {
    this.mapService.getMappingData().subscribe(
      async (responseData) => {
        this.pilotFlights = responseData['pilots'];
        this.liveControllers = responseData['controllers'];

        if (this.pilotFlights.length > 0) { this.hasPilots = true; this.pilotCount = this.pilotFlights.length; }
        if (this.liveControllers.length > 0) { this.hasATC = true; this.atcCount = this.liveControllers.length; }

        console.log(this.pilotFlights);
        console.log(this.liveControllers);
        this.hasPilots = true;
        this.isMapLoaded = true;
        this.lastUpdateTime = responseData['asOf'];
        //for whatever reason this is the only way I could figure out to ensure the map was loaded prior to loading weather layer
        await new Promise(f => setTimeout(f, .5));
        this.loadWeatherMap();
        this.loadFlightDetails();
      },
      (error) => {
        this.isMapLoaded = false;
        if (error.status = 404) {
        } else {
          console.warn(error);
        }
      }
    );
  }

  /********************************************************************
   * Overlay Loader
   * Loads the weather tile overlay onto the map
   */
  loadWeatherMap() {
    var tileNEX = new google.maps.ImageMapType({
      getTileUrl: function (tile, zoom) {
        return "https://mesonet.agron.iastate.edu/cache/tile.py/1.0.0/nexrad-n0q-900913/" + zoom + "/" + tile.x + "/" + tile.y + ".png?" + (new Date()).getTime();
      },
      tileSize: new google.maps.Size(256, 256),
      opacity: 0.60,
      name: 'NEXRAD'
    });
    this.map.overlayMapTypes.setAt(0, tileNEX);
  }

  /********************************************************************
   * map events
   */
  onMapMouseOver(marker) {
    console.log(marker);

    if (marker.type === 'airplane') {
      if (marker.pilotRecord.attributes.groundSpeed == 0) {
        marker.title = marker.pilotRecord.attributes.callsign + ' - ' + marker.pilotRecord.attributes.name;
      } else {
        marker.title = marker.pilotRecord.attributes.callsign + ' - ' + marker.pilotRecord.attributes.name + '   [H:' + marker.pilotRecord.attributes.heading + ' A:' + marker.pilotRecord.attributes.altitude + ' S:' + marker.pilotRecord.attributes.groundSpeed + ']';
      }
      var fromLine = this.buildLine(marker.pilotRecord.id, 'from', marker.pilotRecord.attributes.departureAirportLatitude, marker.pilotRecord.attributes.departureAirportLongitude, marker.pilotRecord.attributes.latitude, marker.pilotRecord.attributes.longitude, '#2989d8', 1, true);
      this.lines.push(fromLine);
      var toLine = this.buildLine(marker.pilotRecord.id, 'to', marker.pilotRecord.attributes.latitude, marker.pilotRecord.attributes.longitude, marker.pilotRecord.attributes.destinationAirportLatitude, marker.pilotRecord.attributes.destinationAirportLongitude, '#ed042b', 2, true);
      this.lines.push(toLine);
    }
  }

  onMapMouseOut(marker) {
    //we only add lines that we need during the mouseover event, so we can remove them all when we are done.
    this.lines = [];
  }


  /********************************************************************
   * Map loading functions
   */
  loadFlightDetails() {
    this.markers = [];
    this.pilotFlights.forEach(flight => {
      //airplane marker
      this.markers.push(this.buildAvaMarker(flight));

      //airports
      var avaAirportMarker = this.buildAvaAirportMarker(flight.id, flight.attributes.departureAirportLatitude, flight.attributes.departureAirportLongitude, flight.attributes.departureAirport, flight.attributes.departureAirport, flight.attributes, true);
      this.markers.push(avaAirportMarker);
      var avaAirportMarker = this.buildAvaAirportMarker(flight.id, flight.attributes.destinationAirportLatitude, flight.attributes.destinationAirportLongitude, flight.attributes.destinationAirport, flight.attributes.destinationAirport, flight.attributes, false);
      this.markers.push(avaAirportMarker);
    });
  }

  buildLine(id, direction, fromLat, fromLong, toLat, toLong, color, opacity, visibility) {
    let coords = [];
    coords.push(
      new google.maps.LatLng(fromLat, fromLong)
    );
    coords.push(
      new google.maps.LatLng(toLat, toLong)
    );
    return {
      id: id + direction,
      path: coords,
      geodesic: true,
      strokeColor: color,
      strokeOpacity: opacity,
      strokeWeight: 2,
      visible: visibility
    }
  }

  buildPlaneSymbol(pilotData) {
    var planeColor = '#0019bf';
    if (pilotData.groundSpeed == 0) {
      planeColor = 'red';
    }
    return {
      path: environment.MAPINFO.planeIcon,
      scale: 0.1,
      fillColor: planeColor,
      fillOpacity: .75,
      strokeOpacity: 1,
      color: 'black',
      strokeWeight: 1,
      rotation: parseInt(pilotData.heading) + environment.MAPINFO.iconOffset
    }
  }

  buildAvaMarker(flight: LiveFlightModel) { //id, latitude, longitude, icao, name, markerType, time) {
    let planeSymbol = this.buildPlaneSymbol(flight.attributes);
    return {
      id: flight.id,
      position: new google.maps.LatLng(flight.attributes.latitude, flight.attributes.longitude),
      title: flight.attributes.callsign,
      options: {
        draggable: false,
        icon: planeSymbol
      },
      // label: flight.attributes.callsign,
      pilotRecord: flight,
      type: 'airplane'
    }
  }

  buildAvaAirportMarker(id, latitude, longitude, icao, name, flightData, isDepartureAirport) {
    var location = flightData.destinationAirportName + ', ' + flightData.destinationAirportCity + ', ' + flightData.destinationAirportCountry;

    return {
      id: name,
      type: 'airport',
      position: new google.maps.LatLng(latitude, longitude),
      title: name + ' - ' + location,
      options: {
        draggable: false,
        icon: '/assets/img/map/APT.png',
        anchor: { x: 8, y: 4 },
        animation: google.maps.Animation.DROP
      },
      flightData: flightData,
      isDepartureAirport : isDepartureAirport
    }
  }

  /***************************************************************
   * Pilot list handlers
   */
  onMouseOver(flight) {
    var fromLine = this.buildLine(flight.id, 'from', flight.attributes.departureAirportLatitude, flight.attributes.departureAirportLongitude, flight.attributes.latitude, flight.attributes.longitude, '#2989d8', 1, true);
    this.lines.push(fromLine);
    var toLine = this.buildLine(flight.id, 'to', flight.attributes.latitude, flight.attributes.longitude, flight.attributes.destinationAirportLatitude, flight.attributes.destinationAirportLongitude, '#ed042b', 2, true);
    this.lines.push(toLine);
  }

  onMouseOut(flight) {
    this.lines = [];
  }

  onMouseClick(flight) {
    var markerToAttachTo = document.getElementById(flight.id + 'marker');
    console.log(markerToAttachTo);
    markerToAttachTo.click();
  }

  onMarkerClick(marker: MapMarker, markerInfo) {
    if (markerInfo.type === 'airplane') {
      this.infoContent = markerInfo.pilotRecord;
      this.isInfoContentLoaded = true;
      this.isFlightInfoWindow = true;
    } else {
      this.isFlightInfoWindow = false;
      this.infoContent.airportId = markerInfo.id;
      if (markerInfo.isDepartureAirport) {
        this.infoContent.airportName = markerInfo.flightData.departureAirportName;
        this.infoContent.airportCountry = markerInfo.flightData.departureAirportCountry;
        this.infoContent.airportCity = markerInfo.flightData.departureAirportCity;
      } else {
        this.infoContent.airportName = markerInfo.flightData.destinationAirportName;
        this.infoContent.airportCountry = markerInfo.flightData.destinationAirportCountry;
        this.infoContent.airportCity = markerInfo.flightData.destinationAirportCity;

      }

      let inbounds = [];
      let outbounds = [];
      this.infoContent.hasOutbounds=false;
      this.infoContent.hasInbounds=false;
      this.pilotFlights.forEach(flight => {
        if (flight.attributes.departureAirport === markerInfo.id) {
          outbounds.push(flight.attributes);
          this.infoContent.hasOutbounds = true;
        } else if (flight.attributes.destinationAirport === markerInfo.id) {
          inbounds.push(flight.attributes);
          this.infoContent.hasInbounds = true;
        }
      });
      this.infoContent.outbounds = outbounds;
      this.infoContent.inbounds = inbounds;
      console.log(this.infoContent.inbounds,this.infoContent.outbounds);

      let weatherData;

      this.weatherService.getMetar(markerInfo.id).subscribe(
        (responseData) => {
          weatherData = responseData;
          this.infoContent.weather = weatherData;
        },
        (error) => {
          console.warn('Could not gather the metar.');
        }
      )

    }
    this.infoWindow.open(marker);
  }
}
