<app-header></app-header>
<router-outlet></router-outlet>
<app-footer></app-footer>
<nav class="navbar fixed-bottom float-right" style="justify-content: right !important; background-color: #eeeeee;">
    <div class="navbar-nav float-right">
        <div class="nav-item">
            <app-utcclock class="float-right clearfix min-ava-width-for-clock"  ></app-utcclock>
        </div>
    </div>
</nav>

