<section id="newpilotinfo" [hidden]="isExamPassed==true">
    <div class="container" ng-cloak autoscroll="true" autofocus>
        <h1>New Pilot Quiz</h1>
        <p>
            Thank you for your interest in joining American Virtual Airlines (AvA)! We pride ourselves as one of the
            most
            professional Virtual Airlines on the Internet. Before embarking on your virtual career with American Virtual
            Airlines,
            we have a simple online quiz to take to ensure you have read our policy manual and understand your
            obligations as
            an AvA Pilot.
            Please take a few minutes to answer these brief questions and you will be on your way!
        </p>
    </div>
</section>
<section id="newpilotexam">
    <div class="container" name="formcontainer" [hidden]="isExamPassed==true">
        <form name="examForm" class="examForm" (ngSubmit)="onSubmit(examForm)" #examForm="ngForm" >
            <div class="row-fluid">
                <div class="card">
                    <div class="card-header">
                        How old must you be to join AvA?
                    </div>
                    <div class="card-body form-group">
                        <select class="custom-select form-control" id="agetojoin" name="agetojoin" #agetojoin ngModel autofocus >
                            <option [value]="18">Eighteen</option>
                            <option [value]="15">Fifteen</option>
                            <option [value]="13">Thirteen</option>
                        </select>
                        <div class="alert alert-danger" name="agetojoinfail" *ngIf="ageToJoinSuccess==false">
                            <div>
                                Incorrect. Information about membership can be found in the Policy Manual under the
                                Membership header.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row-fluid">
                <div class="card">
                    <div class="card-header">
                        Which online network MUST you fly ALL of your flights?
                    </div>
                    <div class="card-body form-group">
                        <select class="custom-select form-control" id="onlineNetwork" name="onlineNetwork" #onlineNetwork ngModel>
                            <option [value]="1">VATSIM</option>
                            <option [value]="2">ICAO</option>
                            <option [value]="3">No online requirement</option>
                        </select>
                        <div class="alert alert-danger" name="onlinenetworkfail" *ngIf="onlineNetworkSuccess==false">
                            <div>
                                Incorrect. Information about membership can be found in the Policy Manual under the
                                Membership header.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row-fluid">
                <div class="card">
                    <div class="card-header">
                        What are the minimum flying requirements to remain as an active pilot of AvA?
                    </div>
                    <div class="card-body form-group">
                        <select class="custom-select form-control" id="flyReqs" name="flyReqs" #flyReqs ngModel>
                            <option [value]="1">1 flight per week | Minimum 1 hour per flight</option>
                            <option [value]="2">2 flights per month | Minimum 30 minute flight</option>
                            <option [value]="3">1 flights per month | Minimum 1 hour flight</option>
                        </select>
                        <div class="alert alert-danger" name="flyReqsFail"  
                            *ngIf="flyReqsSuccess==false">
                            <div>
                                Incorrect. Information about membership can be found in the Policy Manual under the
                                Membership header.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row-fluid">
                <div class="card">
                    <div class="card-header">
                        How many flight hours with AvA must you have in order to fly the Boeing 777 or 787?
                    </div>
                    <div class="card-body form-group">
                        <select class="custom-select form-control" id="flightHours" name="flightHours" #flightHours ngModel>
                            <option [value]="150">150 hours</option>
                            <option [value]="299">299 hours</option>
                            <option [value]="400">400 hours</option>
                            <option [value]="0">AvA doesn't have any rank structure</option>
                        </select>
                        <div class="alert alert-danger" name="flightHoursFail"  
                            *ngIf="flightHoursSuccess==false">
                            <div>
                                Incorrect. Information about Ranks can be found in the Rank Structure & Aircraft Ratings
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
            <div class="row-fluid">
                <div class="card">
                    <div class="card-header">
                        If your assigned AvA callsign is AAL123, which of the following would be a valid callsign with which you may fly?
                    </div>
                    <div class="card-body form-group">
                        <select class="custom-select form-control" id="callsignReq" name="callsignReq" #callsignReq ngModel>
                            <option [value]="1">DAL123</option>
                            <option [value]="2">AAL2049</option>
                            <option [value]="3">ASA1234</option>
                            <option [value]="4">JAL123</option>
                        </select>
                        <div class="alert alert-danger" name="callsignReqFail"  
                            *ngIf="callsignReqSuccess==false">
                            <div>
                                Incorrect. Information about Ranks can be found in the Pilot Duties and Responsibilities section of the policy manual
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
            <div class="row-fluid">
                <div class="card">
                    <div class="card-header">
                        Including our retirement hub, how many hubs are currently active within AvA?
                    </div>
                    <div class="card-body form-group">
                        <select class="custom-select form-control" id="hubsReq" name="hubsReq" #hubsReq ngModel>
                            <option [value]="3">3</option>
                            <option [value]="5">5</option>
                            <option [value]="7">7</option>
                            <option [value]="9">9</option>
                        </select>
                        <div class="alert alert-danger" name="hubsReqFail"  
                            *ngIf="hubsReqSuccess==false">
                            <div>
                                Incorrect. Information about hubs can be found on the Operations tab of the website.
                            </div>
                        </div>
                    </div>
                </div>
            </div>     
            
            <button type="submit" class="btn btn-primary" style="clear: left; width: 100%" id="examFormBtn" 
                [disabled]="!examForm.dirty">
                Grade My Exam    
            </button>
        </form>
    </div>
    <div class="alert alert-success text-center" [hidden]="!isExamPassed">
        <p>Congrats and thank you for taking and passing our little entry test!</p>
        <a (click)="onNext()" class="letssignup">Now let's sign up!</a>
    </div>
</section>
<!-- <section id="newpilotquestions">
    <div class="container ">
        <div class="panel panel-default">
            <div class="panel-heading">
                <h3 class="panel-title">What is American Virtual Airlines? </h3>
            </div>
            <div class="panel-body">
                American Virtual Airlines is a group of hobbyists who enjoy flight simulator and American Airlines. We are a
                community of like-minded pilots who enjoy spending time together flying online, and sharing experiences about our
                flights and adventures. Our Virtual Airline provides our pilots with valuable resources to make their flying more
                realistic by providing a structured environment based on the real world American Airlines.
            </div>
        </div>
        <div class="panel panel-default">
            <div class="panel-heading">
                <h3 class="panel-title">What does "online-only VA" mean? </h3>
            </div>
            <div class="panel-body">
                American Virtual Airlines only accepts pilots who are registered on the
                <a href="www.vatsim.net" target="_blank">VATSIM network</a>
                and have a minumum of 10 flight hours on the VATSIM network. If you are interested in learning how to fly on VATSIM,
                please visit their Pilot Resource Center for more information, and then come back and apply with us. Our Training
                Department can also help get you up and running in no time. <br/><br/>
                American Virtual Airlines does not accept "offline" pilots, or pilots who fly on IVAO, or The Zone networks.
            </div>
        </div>
        <div class="panel panel-default">
            <div class="panel-heading">
                <h3 class="panel-title">What does the "14 day Probationary Period" mean? </h3>
            </div>
            <div class="panel-body">
                Once you have completed the application form and are assigned a pilot ID, you MUST fly your first flight at
                AvA within 14 days.
            </div>
        </div>
        <div class="panel panel-default">
            <div class="panel-heading">
                <h3 class="panel-title">What does AvA require of its pilots to remain active? </h3>
            </div>
            <div class="panel-body">
                After you complete your probationary period, you must fly 2 flights per month to remain active. If a pilot cannot
                complete 2 flights in a month, he/she must request a Leave of Absence (LOA). AvA will grant an LOA for any reason.
                It is the pilot's responsibility to communicate with their Hub Manager. Pilots who do not fly will receive a warning
                letter after 30 days. If the pilot does not communicate or fly during the next 30 days, he/she will be terminated
                after that point. In extreme circumstances, pilots will be allowed to return to AvA if they were removed from the
                roster.
            </div>
        </div>
        <div class="panel panel-default">
            <div class="panel-heading">
                <h3 class="panel-title">What is the CAT Rating System?</h3>
            </div>
            <div class="panel-body">
                American Virtual Airlines uses a CAT ratings system for all pilots. The CAT rating system is designed to allow
                pilots to fly larger aircraft as they gain more flying experience. At any airline, a pilot with no experience does
                not fly the B777 on his first day at the job. He/she must start in the smaller jets and work his/her way up to the
                heavy iron.
            </div>
        </div>
        <div class="panel panel-default">
            <div class="panel-heading">
                <h3 class="panel-title">Can I transfer hours from my previous Virtual Airline? </h3>
            </div>
            <div class="panel-body">
                American Virtual Airlines will accept a portion of your hours flown on the VATSIM network.
            </div>
        </div>
    </div>
</section> -->

<!-- <section id="wrapup" [hidden]="examPassed">
    <div class="container">
        <p>
            I hope you found this information useful in your decision to join American Virtual Airines. If you have any
            questions
            about the process, feel free to shoot me an email with your question.<br /><br />
            Sincerely,<br />
            Vice President - Human Resources<br />
            vp-hr at joinava dot org
        </p>
    </div>
    <div class="container text-center">
        <p>If you have no further questions, click the following to proceed to the application form.</p>
        <hr />
        <a href="#!/signupform" style="font-size:24px">
            YES, I HAVE READ THE AVA POLICY MANUAL;<br />
            I FULLY AGREE WITH IT <br />
            AND WANT TO COMPLETE AN APPLICATION!
        </a>
    </div>
</section> -->