import { HttpClient } from '@angular/common/http';
import { CoreEnvironment } from '@angular/compiler/src/compiler_facade_interface';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  LOGIN_SVC = '/authenticate';
  private _token = '';
  private _isManager : boolean = false;

  constructor(private httpClient: HttpClient) { }
  
  setToken(token) {
    if (token) {
      localStorage.setItem('avaToken', token);
    }  
  }
  getToken() {
    return localStorage.getItem('avaToken');
  }  

  invalidateToken() {
    //clear the local store token
    localStorage.removeItem('avaToken');
    localStorage.removeItem('lvl');
    localStorage.clear();
  }  

  setIsManager(isManager: boolean) {
    if (isManager) {
      localStorage.setItem('lvl',"1");
    } else {
      localStorage.setItem('lvl',"0");
    }
  }
  isManager() {
    let lsLevel: string = localStorage.getItem('lvl');
    this._isManager=false;
    if (lsLevel=="1") {
      this._isManager=true;
    }
    return this._isManager;
  }

  isAuthenticated() {
      this._token = this.getToken();

      if (this._token) {
          let base64Url = this._token.split('.')[1],
          base64 = base64Url.replace('-', '+').replace('_', '/'),
          token = JSON.parse(atob(base64));
          let today = Math.round(new Date().getTime() / 1000);
          console.log('Expiry: ' + token.exp + ' Today: ' + today );
          if  (today <= token.exp) { 
            return true; 
          }
      }

      this.invalidateToken();
      return false;
  }

  getPilotId() {
    var token = this.getToken(), pilotId = -1;
  
    if (token) {
        var base64Url = token.split('.')[1],
        base64 = base64Url.replace('-', '+').replace('_', '/'),
        parsedToken = JSON.parse(atob(base64));
        pilotId = parsedToken.sub;
    }
  
    return pilotId;
  }

  login(creds) {
    return this.httpClient.post(environment.API_URL + this.LOGIN_SVC, creds);
  }
}
