import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AirportModel } from 'src/app/models/airport.model';
import { PilotLandingModel } from 'src/app/models/pilot-landing.model';
import { AuthService } from 'src/app/services/auth.service';
import { PilotService } from 'src/app/services/pilot.service';


@Component({
  selector: 'app-lastflight',
  templateUrl: './lastflight.component.html',
  styleUrls: ['./lastflight.component.css']
})

export class LastFlightComponent implements OnInit {
  pilotId;
  pilotDetails : PilotLandingModel;
  isValidPilot : boolean = false;
  loading : boolean = false;
  hasLastFlight : boolean = true;
  unk_apt_info : AirportModel = {id: -1, country: "(Country Unknown)", iata: "unk", icao: "unk", latitude: "0", longitude: "0", name: "(Name Unknown)", region: "unk"};


  constructor(private pilotService : PilotService, private authService : AuthService, private route : ActivatedRoute,private router : Router) {

   }

  ngOnInit(): void {
    if (this.authService.isAuthenticated() ) {
      this.loading=true;
      this.pilotId = this.authService.getPilotId();
      this.pilotService.get(this.pilotId).subscribe(
        (responseData) => {
          this.pilotDetails = responseData;
          // Check data
          if (!this.pilotDetails.hasOwnProperty("lastFlightAC")) {
            this.hasLastFlight=false;
          }
          if (this.pilotDetails.hub=="TUL") {
            this.pilotDetails.hub = "TRAINING";
          }

          // Check airport infos arrays - when null (apt not found in db), populate with dummy values
          if (this.pilotDetails.lastFlightArrAirportInfo === null) {
            this.pilotDetails.lastFlightArrAirportInfo = this.unk_apt_info;
          }
          if (this.pilotDetails.lastFlightDepAirportInfo === null) {
            this.pilotDetails.lastFlightDepAirportInfo = this.unk_apt_info;
          }

          this.isValidPilot=true;
          this.loading=false;
        },
        (error) => {
          this.loading=false;
        }
      )
    }
  }

  openManualPirep() {
    this.router.navigate(['/pilot/manualpirep']);
  }
}
