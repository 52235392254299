<form name="rejectionForm" class="css-form" #rejectionForm="ngForm" (ngSubmit)="rejectSubmit(rejectionForm.value)">
    <div class="border border-rounded pl-2 pt-1 mb-1 mt-3 pr-2">
        <div class="row">
            <div class="col-sm-12 font-weight-bold text-danger" style="font-size:1.2em">
                <u>Rejection Information</u>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <label for="slctRejectionReason">Rejection Reason:</label>
                <select name="slctRejectionReason" id="slctRejectionReason" class="form-control" ngModel
                    #slctRejectionReason="ngModel" required>
                    <option *ngFor="let reason of APP_REJECTION_REASONS;let i = index"
                        [value]="APP_REJECTION_REASONS[i].name">
                        {{reason.name}}
                    </option>
                </select>
                <div class="alert alert-danger"
                    *ngIf="slctRejectionReason.invalid && (slctRejectionReason.dirty || slctRejectionReason.touched)">
                    <div *ngIf="slctRejectionReason.errors?.required">
                        You must choose a reason.
                    </div>
                </div>
                <div class="form-group" *ngIf="slctRejectionReason.value=='Other'">
                    <label for="txtSpecificReason">Specific Reason:</label>
                    <input type="text" class="form-control" id="txtSpecificReason" name="txtSpecificReason"
                        ngModel #txtSpecificReason="ngModel" minlength="5" maxlength="50"
                        pattern="^[\w\-,.?#!()\s]+$" required />
                    <div class="alert alert-danger"
                        *ngIf="txtSpecificReason.invalid && (txtSpecificReason.dirty || txtSpecificReason.touched)">
                        <div *ngIf="txtSpecificReason.errors?.required">
                            You must enter a reason.
                        </div>
                        <div
                            *ngIf="txtSpecificReason.errors?.minlength || txtSpecificReason.errors?.maxlength || txtSpecificReason.errors?.pattern">
                            Reason is invalid: must be 5 to 50 alphanumeric characters plus -,.?#!()
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-md-12">
                <label class="checkbox-inline">
                    <input type="checkbox" id="addToDNR" name="addToDNR" #addToDNR="ngModel" ngModel>
                    - Add to Do Not Rehire List?
                </label>
            </div>
        </div>   
        <div class="row mb-3">
            <div class="col-md-12">
                <div class="button-group float-right">
                    <button type="button" class="btn btn-light btn-sm" id="rejectFormCancel" (click)="rejectCancel()">Cancel</button>
                    <button type="submit" class="btn btn-danger float-right" id="rejectFormSubmitButton" >Yes, Reject This App</button>
                </div>        
            </div>
        </div>    
    </div>
</form>