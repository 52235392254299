import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HubRosterDetailsModel } from 'src/app/models/hubrosterdetails.model';
import { PilotRosterModel } from 'src/app/models/pilotroster.model';
import { DataSharingService } from 'src/app/services/datasharing.service';
import { PilotService } from 'src/app/services/pilot.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-hrhubroster',
  templateUrl: './hrhubroster.component.html',
  styleUrls: ['./hrhubroster.component.css']
})
export class HrhubrosterComponent implements OnInit {
  @Output() onHRHubRosterUpdated = new EventEmitter<string>();
  @Input() hubRoster: PilotRosterModel[] = [];
  @Input() hubDetails: HubRosterDetailsModel;
  @Input() staffMember : PilotRosterModel;

  pilotToUpdate: PilotRosterModel;
  pilotToTerminate: PilotRosterModel;
  hasPilotToTerminate: boolean = false;

  TERMINATION_REASONS = environment.TERMINATION_REASONS;

  constructor(private dss: DataSharingService, private pilotService: PilotService) { }

  //modal vars
  @Output() modalMessage: string = "";
  @Output() modalTitle: string = "";
  @Output() modalStatus: string = "";
  @Output() modalPurpose: string = "";
  @Output() showCancelButton: boolean = false;
  @Output() showMiniClose: boolean = false;
  @Output() modalName: string = 'actionConfirmationModal';

  ngOnInit(): void {
  }

  //MODAL FUNCTIONS
  // the Send Term Warning and Clear Warning dialogs are processed through here using our default avamodal
  // the Start Term Process modal is special due to the different content layout and reason processing required and is handled below
  onModalClosed(returnMessage: { status: string, purpose: string }) {
    if (returnMessage.purpose == 'issuewarningmodal' && returnMessage.status == 'ok') {
      console.log(this.pilotToUpdate);
      this.pilotService.postStatus(this.pilotToUpdate.pilotId, environment.PILOT_STATUS.TERMINATION_WARNING_STATUS_ID).subscribe(
        (responseData) => {
          this.onHRHubRosterUpdated.emit('reload_roster');
        },
        (error) => {
          console.log(`WARN ${error}`);
        }
      );
    }

    if (returnMessage.purpose == 'clearwarningModal' && returnMessage.status == 'ok') {
      console.log(this.pilotToUpdate);
      this.pilotService.postStatus(this.pilotToUpdate.pilotId, environment.PILOT_STATUS.ACTIVE_STATUS_ID).subscribe(
        (responseData) => {
          this.onHRHubRosterUpdated.emit('reload_roster');
        },
        (error) => {
          console.log(`WARN ${error}`);
        }
      );
    }
    this.dss.showModal.next({ modalName: this.modalName, showModal: false });
  }

  issueWarning(pilot) {
    this.modalStatus = 'danger';
    this.showMiniClose = false;
    this.showCancelButton = true;
    this.modalTitle = 'Confirm Warning';
    this.modalMessage = `Are you sure you would like to send a Termination Warning to ${pilot.callsign} ? `;
    this.modalPurpose = "issuewarningmodal";
    this.pilotToUpdate = pilot;
    this.dss.showModal.next({ modalName: this.modalName, showModal: true });
  }

  clearWarning(pilot) {
    this.modalStatus = 'info';
    this.showMiniClose = false;
    this.showCancelButton = true;
    this.modalTitle = 'Confirm Clear';
    this.modalMessage = `Are you sure you would like to clear ${pilot.callsign}'s previous termination warning and set them back to ACTIVE status? `;
    this.modalPurpose = "clearwarningModal";
    this.pilotToUpdate = pilot;
    this.dss.showModal.next({ modalName: this.modalName, showModal: true });
  }

  // START TERM process handling
  startTermination(pilot) {
    this.pilotToTerminate = pilot;
    this.hasPilotToTerminate = true;
  }

  executeTermination(termForm) {
    let termReason = termForm.termReasonSelect;
    if (termReason=='Other') {
      termReason = termForm.txtSpecificReason;
    }
    console.log(termReason);
    this.pilotService.initiateTermination(this.pilotToTerminate.pilotId, termReason).subscribe(
      (responseData) => {
        this.onHRHubRosterUpdated.emit('reload_roster');
      },
      (error) => {
        console.log(`WARN ${error}`);
      }
    );
  }
}
