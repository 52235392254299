import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationService } from 'src/app/services/application.service';

@Component({
  selector: 'app-rejectionbox',
  templateUrl: './rejectionbox.component.html',
  styleUrls: ['./rejectionbox.component.css']
})
export class RejectionboxComponent implements OnInit {
  @Output() onAppRectionUpdate = new EventEmitter<string>();
  @Input() appId : number;

  APP_REJECTION_REASONS = [
    {
      "id": "1",
      "name": "You have repeatedly failed to complete your probationary requirements."
    },
    {
      "id": "2",
      "name": "You have repeatedly been hired, terminated or resigned."
    },
    {
      "id": "3",
      "name": "You have exhibited inappropriate behavior that is detrimental to AvA."
    },
    {
      "id": "4",
      "name": "You have filed false PIREPs."
    },
    {
      "id": "5",
      "name": "You do not have the minimum age of 15."
    },
    {
      "id": "6",
      "name": "No VATSIM record was found for the VATSIM id in the application."
    },
    {
      "id": "7",
      "name": "VATSIM record was found, but not VATSIM activity was found. (Must have at least 15 hours)"
    },
    {
      "id": "8",
      "name": "AvA Training Hub is currently closed until further notice.  Pilots with less than 100 hours on VATSIM are not being hired."
    },
    {
      "id": "99",
      "name": "Other"
    }
  ];

  constructor(private appService : ApplicationService, private router : Router) { }

  ngOnInit(): void {
  }

  rejectCancel() {
    this.onAppRectionUpdate.emit("cancel");
  }
  rejectSubmit(rejectionForm) {
    console.log(rejectionForm);

    let reason = rejectionForm.slctRejectionReason;
    if (rejectionForm.txtSpecificReason!=undefined) {
      reason = rejectionForm.txtSpecificReason;
    }
    if (rejectionForm.addToDNR=='') {
      rejectionForm.addToDNR = false;
    }
    let payload = {
      reason : reason,
      isDNR : rejectionForm.addToDNR
    }

    console.log(payload);

    this.appService.rejectApplication(this.appId,payload).subscribe(
      (responseData) => {
        console.log(responseData);
        this.onAppRectionUpdate.emit('rejected');
      },
      (error) => {
        this.router.navigate(['/techerror']);
      }
    )

  }
}
