import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EventModel } from 'src/app/models/event.model';
import { EventService } from 'src/app/services/event.service';

@Component({
  selector: 'app-eventcalendar',
  templateUrl: './eventcalendar.component.html',
  styleUrls: ['./eventcalendar.component.css']
})
export class EventcalendarComponent implements OnInit {
  loading : boolean = false;
  events : EventModel[] = [];
  hasEvents : boolean = false;

  constructor(private eventService : EventService, private router : Router) { }

  ngOnInit(): void {
    this.loading = true;
    this.loadEvents();
  }

  loadEvents() {
    this.eventService.get().subscribe(
      (responseData) => {
        this.events = responseData;
        console.log(this.events);

        this.loading = false;
        this.hasEvents=true;
        if (this.events.length==0) this.hasEvents=false;
      },
      (error) => {
        this.loading = false;
        this.hasEvents=false;
        if (error.status = 404) {
        } else {
          this.router.navigate(['/techerror']);
        }
      }
    );
  }
}
