<div class="container-fluid no-gutters">
    <div class="row no-gutters" >
        <nav id="sideBarMenu" class="avasidebar border-right">
            <div class="sidebar-sticky border-bottom">
                <ul class="nav flex-column">
                    <li class="nav-item">
                        <a class="nav-link" [routerLink]="['/pilot',pilotId,'lastflight']" routerLinkActive="active">
                            <i class="fas fa-plane-arrival" title="Last Flight"></i>
                            <span class="menu-text">
                                Last Flight
                            </span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [routerLink]="['/pilot',pilotId,'logbook']" routerLinkActive="active">
                            <i class="far fa-list-alt" title="Logbook"></i>
                            <span class="menu-text">
                                Logbook
                            </span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [routerLink]="['/pilot',pilotId,'dispatcher']" routerLinkActive="active">
                            <i class="far fa-map" title="Dispatcher"></i>
                            <span class="menu-text">
                                Dispatcher
                            </span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [routerLink]="['/pilot',pilotId,'stats']" routerLinkActive="active">
                            <i class="fas fa-chart-bar" title="Type Certifications"></i>
                            <span class="menu-text">
                                Certs
                            </span>
                        </a>
                    </li>
                    <li class="nav-item ">
                        <a class="nav-link" [routerLink]="['/pilot',pilotId,'profile']" routerLinkActive="active">
                            <i class="far fa-address-card" title="Profile"></i>
                            <span class="menu-text">
                                Profile
                            </span>
                        </a>
                    </li>
                    <hr class="aa-line">
                    <li class="nav-item">
                        <a class="nav-link" [routerLink]="['/pilot',pilotId,'roster']" routerLinkActive="active">
                            <i class="far fa-address-book" title="Hub Roster"></i>
                            <span class="menu-text">
                                Hub Roster
                            </span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [routerLink]="['/pilot',pilotId,'training']" routerLinkActive="active">
                            <i class="fas fa-chalkboard-teacher" title="Training"></i>
                            <span class="menu-text">
                                Training
                            </span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [routerLink]="['/pilot',pilotId,'comms']" routerLinkActive="active">
                            <i class="fas fa-broadcast-tower" title="Comms"></i>
                            <span class="menu-text">
                                Comms
                            </span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [routerLink]="['/pilot',pilotId,'software']" routerLinkActive="active">
                            <i class="fas fa-download" title="Software"></i>
                            <span class="menu-text">
                                Software
                            </span>
                        </a>
                    </li>                    
                    <hr class="aa-line">
                    <li class="nav-item">
                        <a class="nav-link" [routerLink]="['/pilot/pireps']" routerLinkActive="active">
                            <i class="far fa-file-alt" title="Pirep"></i>
                            <span class="menu-text">
                                {{pirepButtonText}}
                            </span>
                        </a>
                    </li>
                </ul>
            </div>
        </nav>

        <div class="avacontent">
            <div *ngIf="notams.length>0">
                <app-notam *ngFor="let notam of notams" [notam]="notam"></app-notam>
            </div>
            <router-outlet></router-outlet>
        </div>
    </div>
</div>