import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { MenuLinkModel } from 'src/app/models/menulink.model';
import { NotamModel } from 'src/app/models/notam.model';
import { AuthService } from 'src/app/services/auth.service';
import { DataSharingService } from 'src/app/services/datasharing.service';
import { NotamService } from 'src/app/services/notam.service';
import { PilotService } from 'src/app/services/pilot.service';

@Component({
  selector: 'app-manage',
  templateUrl: './manage.component.html',
  styleUrls: ['./manage.component.css']
})
export class ManageComponent implements OnInit {
  loading : boolean = false;
  pilotId : number =0;
  menuLinks : MenuLinkModel[] = [];
  hmLinks : MenuLinkModel[] = [];
  staffLinks : MenuLinkModel[] = [];
  vpLinks : MenuLinkModel[] = [];
  ceoLinks : MenuLinkModel[] = [];

  notams : NotamModel[] = [];
  constructor(private router : Router, private pilotService : PilotService, private authService : AuthService, private dss : DataSharingService, private notamService : NotamService) { }

  ngOnInit(): void {
    this.menuLinks = [];
    this.loading=true;
    if (this.authService.isAuthenticated()) {
      this.pilotId = this.authService.getPilotId();    
      this.pilotService.getLinks(this.pilotId).subscribe(
        (responseData) => {
          if (responseData.hasOwnProperty('staffMenuLinks')) {
            this.menuLinks = responseData['staffMenuLinks'];

            if (this.menuLinks.hasOwnProperty('staffLinks')) {
              this.staffLinks = this.menuLinks['staffLinks'];
            }
            
            if (this.menuLinks.hasOwnProperty('hmLinks')) {
              this.hmLinks = this.menuLinks['hmLinks'];
              this.hmLinks=this.hmLinks.concat(this.staffLinks);
            }

            if (this.menuLinks.hasOwnProperty('vpLinks')) {
              this.vpLinks = this.menuLinks['vpLinks'];
            }

            if (this.menuLinks.hasOwnProperty('ceoLinks')) {
              this.ceoLinks = this.menuLinks['ceoLinks'];
            }
            console.log(this.hmLinks,this.staffLinks, this.vpLinks, this.ceoLinks);
            this.loading=false;
          }
        },
        (error) => {
            console.log('WARNING: ' + error.message);
            if (error.status === 500) {
              this.router.navigate(['/techerror']);
            }
        }
      );    
      this.loadNotams();
    }
  }

  loadNotams() {
    console.log("loading notams on manage screen");
    this.notams=[];
    if (this.authService.isAuthenticated()) {
      this.pilotId = this.authService.getPilotId();
      this.notamService.getSecuredNotams().subscribe(
        (responseData: NotamModel[]) => {
          this.notams = responseData;
        },
        (error) => {
            console.log('WARNING: ' + error.message);
            if (error.status === 500) {
              this.notams=[];
            }
        }
      );
    } else {
      //TODO change this to reset the cert and what not so it works better during token timeout
      console.warn("not authenticated or something weird");
      this.router.navigate(['/']);
    }
  }
}
