<div class="card " *ngIf="!loading">
    <div class="card-header panel-american-blue-grey-red text-light">Flight Review & Booking</div>
    <div class="card-body">

        <form name="bookingform" class="css-form" (ngSubmit)="onSubmit(bookingform.value)" #bookingform="ngForm"
            *ngIf="!loading">
            <div class="row">
                <div class="col-sm-2">
                    <span class="pull-right"><b>Flight:</b></span>
                </div>
                <div class="col-sm-10">
                    {{flightDetails.callSign}} <span
                        style="font-size:.75em;color:deepskyblue">{{flightDetails.registration}}</span>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-2">
                    <span class="pull-right"><b>Departing:</b></span>
                </div>
                <div class="col-sm-10">
                    {{flightDetails.depAirport}} &#64; {{flightDetails.depTime}}
                </div>
            </div>

            <div class="row">
                <div class="col-sm-2">
                    <span class="pull-right"><b>Arriving:</b></span>
                </div>
                <div class="col-sm-10">
                    {{flightDetails.destAirport}} &#64; {{flightDetails.arrTime}}
                </div>
            </div>

            <div class="row">
                <div class="col-sm-2">
                    <span class="pull-right"><b>Flight Time:</b></span>
                </div>
                <div class="col-sm-10">
                    <td>{{flightDetails.flightTime}}</td>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-2">
                    <span class="pull-right"><b>Equipment:</b></span>
                </div>
                <div class="col-sm-10">
                    {{flightDetails.aircraftIcao}} - {{flightDetails.aircraftShortName}}
                </div>
            </div>
            <hr class="aa-line" />
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label for="slctRoute">Last 5 PIREP Routes Filed:</label>
                        <a href="http://flightaware.com/live/findflight?origin={{flightDetails.depAirport}}&destination={{flightDetails.destAirport}}"
                            target="_blank">
                            <img class="float-right" title="Click to see flights on FlightAware"
                                src="assets/img/flightaware_blue.png" style="height: 2em" alt="FlightAware" />
                        </a>
                        <a href="http://simbrief.com/system/dispatch.php?airline=AAL&fltnum={{pilotDetails.callSign}}&type={{flightDetails.aircraftIcao}}&orig={{flightDetails.depAirport}}&dest={{flightDetails.destAirport}}&manualrmk=joinava&units=LBS&reg={{flightDetails.registration}}"
                            target="_blank">
                            <img class="float-right mr-n4 mt-1" title="Click to open simBrief"
                                src="assets/img/simbrief_logo.png" style="height: 2em" alt="simBrief" />
                        </a>
                        <select name="slctRoute" id="slctRoute" class="form-control" ngModel #slctRoute="ngModel"
                            (change)="routeSelected(slctRoute.value)">
                            <option *ngFor="let route of routes;let i = index" [value]="route.id">
                                {{route.route}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label for="txtRoute">Entered Route: (you can select from one of the above or type your
                            own)</label>
                        <textarea rows="5" class="form-control" id="txtRoute" name="txtRoute" [(ngModel)]="lookedUpRoute" #txtRoute="ngModel"
                            minlength="3" maxlength="500" pattern="^[\w\s]+$" required></textarea>
                        <div class="alert alert-danger" *ngIf="txtRoute.invalid && (txtRoute.dirty || txtRoute.touched)">
                            <div *ngIf="txtRoute.errors?.required">
                                You must enter a valid route.
                            </div>
                            <div *ngIf="txtRoute.errors?.minlength || txtRoute.errors?.maxlength">
                                Minimum of 3 chars | Maximum 500
                            </div>
                            <div *ngIf="txtRoute.errors?.pattern">
                                You have entered an invalid character
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-3">
                    <div class="form-group">
                        <label for="slctAcars">ACARS to schedule with:</label>
                        <select name="slctAcars" id="slctAcars" class="form-control" [(ngModel)]="slctAcars">
                            <option *ngFor="let acars of selectedAcars;let i = index" [value]="acars">
                                {{acars}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="button-group ">
                        <button type="button" class="btn btn-secondary btn-sm" id="routeFormCancel"
                            (click)="routeCancel()">Cancel</button>
                        <button type="submit" class="btn btn-primary" id="routeFormSubmit"
                            [disabled]="bookingform.invalid">
                            Schedule Flight
                        </button>
                    </div>
                </div>
            </div>
            <br />
        </form>
    </div>
</div>




<span *ngIf="loading">
    <div class="d-flex justify-content-center " style="margin-top: 10em;margin-bottom: 10em;">
        <div class="spinner-border text-primary" style="width: 6rem; height: 6rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</span>

<app-avamodal [modalName]="modalName" [modalMessage]="modalMessage" [modalTitle]="modalTitle" [showMiniClose]="showMiniClose"
    [showCancelButton]="showCancelButton" [modalStatus]="modalStatus" [buttonText]="'Ok'" [modalPurpose]="modalPurpose"
    (modalClosed)="onModalClosed($event)"></app-avamodal>
