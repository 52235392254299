<form name="ccChangeForm" class="css-form" #ccChangeForm="ngForm">
    <div class="card border-info">
        <div class="card-header ">
            <div class="row">
                <div class="col-sm-8 mt-1">
                    ({{member.callsign}}) {{member.lastName}}, {{member.firstName}} of {{member.hubShortName}}  <span class="text-muted">[{{member.vatsimId}}]</span>
                </div>
                <div class="col-sm-4">
                    <button type="button" class="btn btn-sm btn-warning float-right" id="btnStartTransfer"
                        (click)="onChangeCallsignClick()" style="font-size:.75em">
                        <i class="fas fa-binoculars"></i>&nbsp;View
                    </button>
                </div>
            </div>
        </div>
        <div class="card-body" *ngIf="showCCFormRow">
            <div class="row">
                <div class="col-md-3" >
                    <label>New Callsign:</label>
                    <select name="newCallsign" id="newCallsign" class="form-control" ngModel #newCallsign="ngModel"
                        required >
                        <option *ngFor="let callsign of availableCallsigns;let i = index"
                            [value]="callsign">
                            {{callsign}}
                        </option>
                    </select>
                    <div class="alert alert-danger"
                        *ngIf="newCallsign.invalid && (newCallsign.dirty || newCallsign.touched)">
                        <div *ngIf="newCallsign.errors?.required">
                            You must enter a Manager's Comment for your Transfer.
                        </div>
                    </div>
                </div>
                <div class="col-md-6" >
                    <label>Comments:</label>
                    <input type="text" id="comments" name="comments" class="form-control" ngModel
                        #comments="ngModel" required minlength="3" maxlength="100"
                        pattern="^[\w\-,.?#!()\s]+$" [disabled]="!newCallsign.valid && !newCallsign.dirty"/>
                    <div class="alert alert-danger"
                        *ngIf="comments.invalid && (comments.dirty || comments.touched)">
                        <div *ngIf="comments.errors?.required">
                            You must enter a Manager's Comment for your Transfer.
                        </div>
                        <div
                            *ngIf="comments.errors?.minlength || comments.errors?.maxlength || comments.errors?.pattern">
                            Manager's Comment is invalid: must be 10 to 100 alphanumeric characters plus -,.?#!()
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-md-12 ">
                    <button type="button" class="btn btn-sm btn-success float-right" *ngIf="!loading && comments.dirty && comments.valid" (click)="executeCC(ccChangeForm.value)">
                        <span class="spinner-border spinner-border-sm" *ngIf="loading" role="status"
                            aria-hidden="true"></span>
                        <i class="fas fa-thumbs-up"></i> Change Callsign
                    </button>
                    <button type="button" class="btn btn-success float-right disabled" *ngIf="loading ">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Changing callsign...
                    </button>
                </div>
            </div>
        </div>
    </div>
</form>
