<div *ngIf="hasEvents">
    <span class="leftnavTitle">
        <a [routerLink]="['/eventcalendar']">Upcoming Events Calendar</a>
    </span>
    <div class="leftnavText">
        <table border="0" class="table table-sm table-borderless table-responsive" [cellPadding]="3" [cellSpacing]="3" style="font-size: .90rem">
            <tr *ngFor="let event of events">
                <td>
                    <a href="{{event.attributes.url}}" alt="{{event.attributes.name}}}" title="Desc:{{event.attributes.description}}" target="_blank">
                        <b style="color:#bc1640">{{event.attributes.startDate}} - {{(event.attributes.startTime.length>5)? (event.attributes.startTime | slice:0:5):(event.attributes.startTime)}}z </b>
                        <br />{{event.attributes.name}}
                        <br />
                        <span *ngIf="isAuthenticated" style="color:lightseagreen">
                            Tag: <b>{{event.attributes.trackingTag}}</b>
                        </span>
                    </a>
                </td>
            </tr>
        </table>
    </div>
</div>
