import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageModel } from 'src/app/models/message.model';
import { NotamModel } from 'src/app/models/notam.model';
import { AuthService } from 'src/app/services/auth.service';
import { MessageService } from 'src/app/services/message.service';
import { NotamService } from 'src/app/services/notam.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  loading : boolean = true;
  notams : NotamModel[] = [];
  messages : MessageModel[] = [];
  featuredDestination : MessageModel;
  welcomeMessage : MessageModel;
  isCollapsedHorizontal : boolean = false;
  hasFD : boolean = false;

  constructor(private authService : AuthService, private notamService : NotamService, private messageService : MessageService, private router : Router ) { }

  initMessages() {
    //so dumb I have to do this even though I put DEFAULTS in the fucking model...sometimes angular is stupid...
  }
  ngOnInit(): void {
    this.loading=true;
    this.notams=[];
    this.hasFD=false;
    this.featuredDestination = new MessageModel;
    this.welcomeMessage = new MessageModel;''

    this.loadMessages();
    this.loadPublicNotams();
  }

  loadMessages() { 
    this.messageService.getMessages().subscribe(
      (responseData: MessageModel[]) => {
        this.messages = responseData;
        let wmsg = this.messages.filter((element) => {
          return (element.msgtype == 1);
        });
        this.welcomeMessage = wmsg[0];

        wmsg = this.messages.filter((element) => {
          return (element.msgtype == 2);
        });
        this.featuredDestination = wmsg[0]; 
        this.hasFD=true;         
        console.log(this.featuredDestination);
        this.loading = false;
      },
      (error) => {
          console.log('WARNING: ' + error.message);
          if (error.status === 500) {
            this.router.navigate(['/techerror']);
          }
      }
    );
  }

  loadPublicNotams() {
    this.notams=[];
      this.notamService.getPublicNotams().subscribe(
        (responseData: NotamModel[]) => {
          this.notams = responseData;
          console.log(this.notams);
        },
        (error) => {
            console.log('WARNING: ' + error.message);
            if (error.status === 500) {
              this.router.navigate(['/techerror']);
            }
        }
      );
  }

  ngOnDestroy() {
    this.notams=null;
  }

}
