<div class="container-fluid no-gutters mt-2" *ngIf="!loading">
    <div class="card shadow rounded-top rounded-lg">
        <div class="card-header panel-american-blue">
            <div class="row">
                <div class="col-12">
                    American Virtual Airlines Statistics
                </div>
            </div>
        </div>
        <div class="card-body ">
            <div class="row">
                <div class="col-sm-12 ">
                    <ul class="nav nav-pills">
                        <li class="nav-item">
                            <a [routerLink]="[]" class="nav-link " (click)="statsMenuClick('flights')" [ngClass]="{'active' : activeMenu=='flights'}">
                                Flights
                            </a>
                        </li>
                        <li class="nav-item">
                            <a [routerLink]="[]" class="nav-link " (click)="statsMenuClick('hours')" [ngClass]="{'active' : activeMenu=='hours'}">
                                Hours
                            </a>
                        </li>
                        <li class="nav-item">
                            <a [routerLink]="[]" class="nav-link " (click)="statsMenuClick('landings')" [ngClass]="{'active' : activeMenu=='landings'}">
                                Landings
                            </a>
                        </li>
                        <li class="nav-item">
                            <a [routerLink]="[]" class="nav-link " (click)="statsMenuClick('airports')" [ngClass]="{'active' : activeMenu=='airports'}">
                                Airports
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <hr class="aa-line">
            <div class="row">
                <div class="col-sm">
                    <div class="row" *ngIf="activeMenu=='flights'">
                        <div class="col">
                            <app-flightstats></app-flightstats>
                        </div>
                    </div>
                    <div class="row" *ngIf="activeMenu=='hours'">
                        <div class="col-md-12 mt-2">
                            <app-hoursstats></app-hoursstats>
                        </div>
                    </div>
                    <div class="row" *ngIf="activeMenu=='landings'">
                        <div class="col-md-12 mt-2">
                            <app-landingstats></app-landingstats>
                        </div>
                    </div>
                    <div class="row" *ngIf="activeMenu=='airports'">
                        <div class="col-md-12 mt-2">
                            <app-airportstats></app-airportstats>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

