<div class="flex-item">
    <div style="display: block;">
        <canvas baseChart width="400" height="150px" 
            [datasets]="ofr.chartdata" 
            [labels]="ofr.labels"
            [options]="lineChartOptions" 
            [colors]="lineChartColors" 
            [legend]="lineChartLegend"
            [chartType]="lineChartType" 
            (chartHover)="chartHovered($event)" 
            (chartClick)="chartClicked($event)"
            *ngFor="let ofr of pilotDetails.lastOfrs">
        </canvas>
    </div>
</div>