import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WeatherService {
  WEATHER_SVC = '/metars/'

  constructor(private httpClient: HttpClient) { }

  getMetar(icao) {
    let url = environment.API_URL + this.WEATHER_SVC + icao;
    return this.httpClient.get(url).pipe(map(
      (data) => {
        //degugger;
        return data['data'];
      }
    ));
  }

}
