import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Color } from 'ng2-charts';
import { CertModel } from 'src/app/models/cert.model';
import { AuthService } from 'src/app/services/auth.service';
import { HubRosterService } from 'src/app/services/hubroster.service';
import { PilotService } from 'src/app/services/pilot.service';

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.css']
})
export class StatsComponent implements OnInit {
  hasCerts: boolean = false;
  loading: boolean = false;
  pilotId: number = -99;
  certs : CertModel[] = [];
  certDonutLabels = [ 'Logged Hours','Remaining Hours'];
  certChartColors : Color[] = [{
    backgroundColor: ['#0019bf', '#2989d8']
   }];
  certChartType : string = 'doughnut';
  certChartOptions = { plugins: {legend:false},  aspectRatio: 1, responsive: true, maintainAspectRatio: false, title : {display: false}};
  constructor(private authService: AuthService, private route: ActivatedRoute, private pilotService: PilotService) { }

  showCertHelp : boolean = false;

  ngOnInit(): void {
    this.loading = true;
    this.pilotId = this.authService.getPilotId();

    this.pilotService.getTypeCerts(this.pilotId).subscribe(
      (responseData) => {
        this.certs = responseData;
        this.hasCerts = true;
        this.loading = false;
      },
      (error) => {
        this.hasCerts = false;
        this.loading = false;
      }
    )
  }
  getStarCount(cert) {
    return new Array(cert.attributes.starCount);
  }
}
