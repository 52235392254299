<section id="technicalError">
    <div class="container text-center" style="padding-bottom:100px" >
        <h2>A Technical Error has occurred.</h2>
        <p>
            We're sorry, but there appears to be a technical problem at American Virtual Airlines at the moment that is preventing
            further processing. Most likely you have lost connection to the servers, or they are offline.
        </p>
        <p>
            Please check your connection, and try again.
        </p>
        <p class="text-monospace" style="color: green;font-weight: bold;">
            If you continue to have issues, please visit the AvA Discord and submit an error report in the Technical Support Channel.
        </p>
        <p>
            We apologize for the delay.  Have a nice day.
        </p>
    </div>
</section>

