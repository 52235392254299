<footer id="footer mb-5 ml-auto">

    <div class="footer-brand" >
        <div class="row">
            <div class="col-md-12" style="font-size:.7rem">
                <a href="http://www.vatsim.net" target="_blank">
                    <img src="assets/img/logo_vatsim.png" height="50px">
                </a>
                <br/>
                <hr class="aa-line mt-5"/>
                <p class="copyrightyears mt-5">&copy;2001-{{ thisYear | date:'YYYY'}}</p>
                <p >
                    If you are looking for the REAL American Airlines, please visit <a href="http://aa.com">AA.COM</a>
                </p>
                <p style="font-size:.7rem">
                    This site is NOT affiliated with American Airlines in ANY WAY! It is intended for VIRTUAL SIMULATION for flight simulation
                    enthusiasts ONLY!
                </p>
                <img src="assets/img/logo4.png" alt="American Virtual Airlines" class="mt-3 mb-2">
            </div>
        </div>
    </div>
</footer>
