<form name="profileForm" class="css-form" (ngSubmit)="onSubmit(profileForm.value)" #profileForm="ngForm" *ngIf="!loading">
    <div class="row">
        <div class="col-md-3">
            <div class="form-group">
                <label for="firstName">First Name:</label>
                <input type="text" id="firstName" name="firstName" class="form-control" [(ngModel)]="profile.firstName"
                    #firstName="ngModel" minlength="1" maxlength="40" pattern="^[a-zA-Z]*$" required />
                <div class="alert alert-danger" *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)">
                    <div *ngIf="firstName.errors?.required">
                        You must enter a first name.
                    </div>
                    <div
                        *ngIf="firstName.errors?.minlength || firstName.errors?.maxlength || firstName.errors?.pattern">
                        First Name is invalid: must be 1 to 40 alpha only characters.
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="form-group">
                <label for="lastName">Last Name:</label>
                <input type="text" id="lastName" name="lastName" class="form-control" [(ngModel)]="profile.lastName"
                    #lastName="ngModel" minlength="1" maxlength="40" pattern="^[a-zA-Z]*$" required />
                <div class="alert alert-danger" *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)">
                    <div *ngIf="lastName.errors?.required">
                        You must enter a last name.
                    </div>
                    <div *ngIf="lastName.errors?.minlength || lastName.errors?.maxlength || lastName.errors?.pattern">
                        Last Name is invalid: must be 1 to 40 alpha only characters.
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-2">
            <div class="form-group">
                <label for="age">Age:</label>
                <input type="number" id="age" name="age" class="form-control" [(ngModel)]="profile.age" #age="ngModel"
                    minlength="2" maxlength="3" min="13" max="110" required />
                <div class="alert alert-danger" *ngIf="age.invalid && (age.dirty || age.touched)">
                    <div *ngIf="age.errors?.required">
                        You must enter an age.
                    </div>
                    <div *ngIf="age.errors?.minlength || age.errors?.maxlength || age.errors?.min || age.errors?.max">
                        Age must be a number between 13 and 110.
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label for="email">Email:</label>
                <input type="email" id="email" name="email" class="form-control" [(ngModel)]="profile.email"
                    #email="ngModel" email required />
                <div class="alert alert-danger" *ngIf="email.invalid && (email.dirty || email.touched)">
                    <div *ngIf="email.errors?.required">
                        You must enter an email.
                    </div>
                    <div *ngIf="email.errors?.email">
                        You must enter a valid email
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3">
            <div class="form-group">
                <label for="city">City:</label>
                <input type="text" id="city" name="city" class="form-control" [(ngModel)]="profile.city" #city="ngModel"
                    minlength="2" maxlength="40" pattern="^[a-zA-Z]*$" required />
                <div class="alert alert-danger" *ngIf="city.invalid && (city.dirty || city.touched)">
                    <div *ngIf="city.errors?.required">
                        You must enter a city.
                    </div>
                    <div *ngIf="city.errors?.minlength || city.errors?.maxlength || city.errors?.pattern">
                        City is invalid: must be 2 to 40 alpha only characters.
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-2">
            <div class="form-group">
                <label for="state">State/Province:</label>
                <input type="text" id="state" name="state" class="form-control" [(ngModel)]="profile.state"
                    #state="ngModel" minlength="2" maxlength="40" pattern="^[a-zA-Z]*$" required />
                <div class="alert alert-danger" *ngIf="state.invalid && (state.dirty || state.touched)">
                    <div *ngIf="state.errors?.required">
                        You must enter a state.
                    </div>
                    <div *ngIf="state.errors?.minlength || state.errors?.maxlength || state.errors?.pattern">
                        State is invalid: must be 2 to 40 alpha only characters.
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-2">
            <div class="form-group">
                <label for="country">Country:</label>
                <input type="text" id="country" name="country" class="form-control" [(ngModel)]="profile.country"
                    #country="ngModel" minlength="2" maxlength="40" pattern="^[\w\s]+$" required />
                <div class="alert alert-danger" *ngIf="country.invalid && (country.dirty || country.touched)">
                    <div *ngIf="country.errors?.required">
                        You must enter a country.
                    </div>
                    <div *ngIf="country.errors?.minlength || country.errors?.maxlength || country.errors?.pattern">
                        Country is invalid: must be 2 to 40 alpha only characters.
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 d-flex justify-content-end">
            <button type="submit" class="btn btn-info" [disabled]="!profileForm.valid || (!profileForm.touched && !profileForm.dirty)" id="btnSubmit">
                Update Details
            </button>
        </div>
    </div>
</form>
<span *ngIf="loading">
<div class="d-flex justify-content-center " style="margin-top: 10em;margin-bottom: 10em;">
    <div class="spinner-border text-primary" style="width: 6rem; height: 6rem;" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>
</span>