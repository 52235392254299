import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dispatcher',
  templateUrl: './dispatcher.component.html',
  styleUrls: ['./dispatcher.component.css']
})
export class DispatcherComponent implements OnInit {
  loading : boolean = false;
  activeMenu : string = 'ff';

  constructor() { }

  ngOnInit(): void {
    this.loading=false;
  }

  hrMenuClick(menuChoice) {
    this.activeMenu=menuChoice;
  }

  OnDispatcherTabChange(event) {
    console.log(event);
    this.hrMenuClick(event);
  }
}
