import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { DataSharingService } from 'src/app/services/datasharing.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { EquipmentService } from 'src/app/services/equipment.service';

@Component({
  selector: 'app-charterflight',
  templateUrl: './charterflight.component.html',
  styleUrls: ['./charterflight.component.css']
})
export class CharterflightComponent implements OnInit {
  @Output() onDispatcherTabChange = new EventEmitter<string>();
  loading : boolean = false;
  equipmentList = [];
  selectedAcars = ["smartCARS", "XAcars"];
  acars = this.selectedAcars[0];
  @Output() modalMessage: string = "";
  @Output() modalTitle: string = "";
  @Output() modalStatus: string = "";
  @Output() modalPurpose: string = "";
  @Output() showCancelButton: boolean = false;
  @Output() modalName : string = '';
  showMiniClose: boolean = false;

  constructor(private dss : DataSharingService, private equipmentService : EquipmentService, private dispatcherService : DispatcherService, private router : Router) { }

  ngOnInit(): void {
    this.loadEquipment();
  }

  loadEquipment() {
    //fill in the aircraft dropdown.
    this.equipmentService.get().subscribe(
      (responseData) => {
        this.equipmentList = responseData['data'];
        this.loading=false;
      },
      (error) => {
        this.loading = false;
      }
    )
  }

  private convertTime(timeToConvert : NgbTimeStruct) {
    let hours = timeToConvert['hour'];
    var mins = timeToConvert['minute'];
    var minsString : string = mins + '';
    var hoursString : string = hours + '';
    if (mins<10) { minsString = '0' + mins;}
    if (hours<10) { hoursString = '0' + hours;}
    console.log(hoursString,minsString);

    var convertedTime = hoursString + ':' + minsString;
    console.log(convertedTime);
    return convertedTime;
  }

  onSubmit(flight){
    console.log(flight);

    flight.departureTime = "00:00";
    flight.arrivalTime = '00:00';
    flight.duration = this.convertTime(flight.duration);
    this.dispatcherService.scheduleCharterFlight(flight).subscribe(
      (responseData) => {
        console.log(responseData);
        this.loading=false;
        this.handleScheduleFlightSuccess(responseData);
      },
      (error) => {
        console.log(error.message);
        this.router.navigate(['/techerror']);
      }
    );

    this.loading=false;
  }

  handleScheduleFlightSuccess(data) {
    this.modalStatus='success';
    this.showMiniClose=false;
    this.modalTitle = 'Charter Flight Scheduled Successfully!';
    this.modalMessage = 'Flight: ' + data.acarsId + ' was successfully booked into the ' + this.acars + ' ACARS system.';
    this.modalPurpose = "confirmation";
    this.dss.showModal.next({modalName : this.modalName, showModal :true});
  }

  onModalClosed(returnMessage: {status: string, purpose: string}) {
    if (returnMessage.purpose == 'confirmation' && returnMessage.status == 'ok') {
      this.dss.showModal.next({modalName : this.modalName, showModal :false});
      this.onDispatcherTabChange.emit('bf');
    } else if (returnMessage.status == 'cancel'){
      console.log('canceled out of the modal');
    }

    this.dss.showModal.next({modalName : this.modalName, showModal :false});
  }
}
