<div class="container mt-2 mb-3">
    <div class="row">
        <div class="col-md-12">
            <div class="card shadow rounded-top rounded-lg">
                <div class="card-header panel-american-coral">
                    <div class="row">
                        <div class="col-10 mt-2">
                            <span *ngIf="!isAdding">Manage NOtice To AirMens</span>
                            <span *ngIf="isAdding">Add NOTAM</span>
                        </div>
                        <div class="col-sm-2 float-right">
                            <button class="btn btn-success btn-sm float-right" id="btnAddNotam" (click)="addNotam()" *ngIf="!isAdding">
                                Add NOTAM
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card-body" *ngIf="!loading ">
                    <div *ngIf="isAdding">
                        <app-notamdetail [notam]="blankNotam" [isNew]="true" (onNotamUpdateComplete)="onNotamUpdateComplete($notam)"></app-notamdetail>
                    </div>
                    <div class="row" *ngIf="!hasNotams && !isAdding">
                        <div class="col-sm-12">
                            <p>I'm sorry but there are no Notams to manage at this time.</p>
                        </div>
                    </div>
                    <div *ngIf="hasNotams && !isAdding">
                        <div class="card mt-2 " *ngFor="let notam of notams">
                            <div class="card-header bg-ava-info">
                                <div class="row">
                                    <div class="col-sm-8">
                                        <i class="far fa-eye text-success mr-2" *ngIf="notam.isActive" title="This NOTAM is active and should be displaying."></i>
                                        <i class="far fa-eye-slash mr-2 text-danger" *ngIf="!notam.isActive" title="This NOTAM is not active and not displaying."></i>
                                        <b>{{notam.title}}</b> -- <span style="font-size:.7rem">ends {{notam.endDate | date:'fullDate'}} </span>
                                    </div>
                                    <div class="col-sm-4">
                                        <button class="btn btn-info btn-sm float-right" type="button" data-toggle="collapse" [attr.data-target]="'#notamDetailCollapse-' + notam.notamId" aria-expanded="true" aria-controls="notamDetailCollapse" title="Edit Notam # {{notam.notamId}}">
                                            <i class="fas fa-exchange-alt"></i> Edit
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="collapse" id="notamDetailCollapse-{{notam.notamId}}">
                                <div class="card-body">
                                    <app-notamdetail [notam]="notam" [isNew]="false" (onNotamUpdateComplete)="onNotamUpdateComplete($event)"></app-notamdetail>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container text-center" *ngIf="loading">
    <div class="lds-hourglass"></div>
    <p>Loading...</p>
</div>