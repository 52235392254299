import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TerminationService {
  TERMINATION_REQUEST_SVC = '/terminationRequests';

  constructor(private httpClient: HttpClient) { }

  getTerminations() {
    return this.httpClient.get(environment.API_URL + this.TERMINATION_REQUEST_SVC).pipe(map(
      (data) => {
        // this.apps = data['data'];
        return data['data'];
      }
    ))
  }

  updateTerminationStatus(terminationId,payload, status) {
    let url = environment.API_URL + this.TERMINATION_REQUEST_SVC + '/' + terminationId + '/' + status;
    return this.httpClient.post(url, payload);
  }
}
