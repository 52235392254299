import { Component, DebugEventListener, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { StaffPositionModel } from 'src/app/models/staffposition.model';
import { DataSharingService } from 'src/app/services/datasharing.service';
import { MembersearchService } from 'src/app/services/membersearch.service';
import { StaffService } from 'src/app/services/staff.service';

@Component({
  selector: 'app-staffpositiondetails',
  templateUrl: './staffpositiondetails.component.html',
  styleUrls: ['./staffpositiondetails.component.css']
})
export class StaffpositiondetailsComponent implements OnInit {
  @Input() position : StaffPositionModel;
  @Input() levels : any[];
  @Output() onPositionUpdateComplete = new EventEmitter<string>();

  loading : boolean = false;
  showVatsimIdMessage : boolean = false;
  foundPilot : any;
  isVatsimIdPilotFound : boolean = false;

  constructor(private router : Router, private memberSearchService : MembersearchService, private staffService : StaffService, private dss : DataSharingService) { }

  ngOnInit(): void {
    if (this.position.attributes.vacant==0) {
      this.isVatsimIdPilotFound = true;
    }
  }

  onSubmit(positionDetailForm, pId) {
    console.log(positionDetailForm);
    console.log(pId);

    let payload = {
      shortTitle        : positionDetailForm.txtShortTitle,
      longTitle         : positionDetailForm.txtLongTitle,
      level             : positionDetailForm.slAccessLevel,
      vatsimId          : positionDetailForm.txtVatsimId,
      email             : positionDetailForm.txtEmail
    }

    console.log(payload);

    this.staffService.updatePosition(this.position.id, payload).subscribe(
      (responseData) => {
        this.loading = false;
        this.showVatsimIdMessage=false;
        this.isVatsimIdPilotFound = false;
        this.onPositionUpdateComplete.emit("positionUpdated");
      },
      (error) => {
        this.loading = false;
        if (error.status = 404) {
          this.foundPilot=null;
          this.showVatsimIdMessage=true;
          this.isVatsimIdPilotFound=false;
        } else {
          this.router.navigate(['/techerror']);
        }
      }
    );
  }

  lookUpVatsimId(vatsimIdToFind) {
    this.foundPilot=null;
    this.showVatsimIdMessage=false;
    console.log(vatsimIdToFind);

    this.memberSearchService.search(vatsimIdToFind,'').subscribe(
      (responseData) => {
        this.foundPilot = responseData[0];
        console.log(this.foundPilot);
        this.loading = false;
        this.showVatsimIdMessage=true;
        this.isVatsimIdPilotFound = true;
      },
      (error) => {
        this.loading = false;
        if (error.status = 404) {
          this.foundPilot=null;
          this.showVatsimIdMessage=true;
          this.isVatsimIdPilotFound=false;
        } else {
          this.router.navigate(['/techerror']);
        }
      }
    );
  }

  unassign() {
    this.staffService.unassign(this.position.id).subscribe(
      (responseData) => {
        this.foundPilot = responseData[0];
        console.log(this.foundPilot);
        this.loading = false;
        this.showVatsimIdMessage=false;
        this.isVatsimIdPilotFound = false;
        this.onPositionUpdateComplete.emit("positionUnassigned");
      },
      (error) => {
        this.loading = false;
        if (error.status = 404) {
          this.foundPilot=null;
          this.showVatsimIdMessage=true;
          this.isVatsimIdPilotFound=false;
        } else {
          this.router.navigate(['/techerror']);
        }
      }
    );
  }
}
