<form name="positionDetailForm" class="css-form" #positionDetailForm="ngForm" (ngSubmit)="onSubmit(positionDetailForm.value, position.id)">
    <div class="row">
        <div class="col-sm-2">
            <div class="form-group">
                <label for="txtShortTitle">Short Title:</label>
                <input type="text" id="txtShortTitle" name="txtShortTitle" class="form-control" #txtShortTitle="ngModel" ngModel [(ngModel)]="position.attributes.shortTitle" minlength="3" maxlength="100" pattern="^[\w\-,.?#!/'()\s]+$" required />
                <div class="alert alert-danger" *ngIf="txtShortTitle.invalid && (txtShortTitle.dirty || txtShortTitle.touched)">
                    <div *ngIf="txtShortTitle.errors?.required">
                        You must enter a short name for this position.
                    </div>
                    <div *ngIf="txtShortTitle.errors?.minlength || txtShortTitle.errors?.maxlength || txtShortTitle.errors?.pattern">
                        Short Title is invalid: must be 10 to 100 alphanumeric characters plus -,.?#!()
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-5">
            <div class="form-group">
                <label for="txtLongTitle">
                    Long Title:
                </label>
                <input type="text" id="txtLongTitle" name="txtLongTitle" class="form-control" #txtLongTitle="ngModel" ngModel [(ngModel)]="position.attributes.longTitle" minlength="3" maxlength="50" pattern="^[\w\-,.?#!/'()\s]+$" required />
                <div class="alert alert-danger" *ngIf="txtLongTitle.invalid && (txtLongTitle.dirty || txtLongTitle.touched)">
                    <div *ngIf="txtLongTitle.errors?.required">
                        You must enter a long name for this position.
                    </div>
                    <div *ngIf="txtLongTitle.errors?.minlength || txtLongTitle.errors?.maxlength || txtLongTitle.errors?.pattern">
                        Long Title is invalid: must be 3 to 50 alphanumeric characters
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-2 justify-content-right">
            <label>Access Level:</label>
            <div class="form-group">
                <select name="slAccessLevel" id="slAccessLevel" class="form-control" ngModel #slAccessLevel="ngModel" [(ngModel)]="position.attributes.level" required>
                    <option *ngFor="let level of levels;let i = index" [value]="levels[i].id">
                        {{level.name}}
                    </option>
                </select>
                <div class="alert alert-danger" *ngIf="slAccessLevel.invalid && (slAccessLevel.dirty || slAccessLevel.touched)">
                    <div ng-show="slAccessLevel.errors?.required">
                        You must pick a hub to transfer to...
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-3 justify-content-right">
            <label>Date Assigned:</label>
            <input type="text" class="form-control" id="txtDateAssigned" name="txtDateAssigned" #txtDateAssigned ngModel [(ngModel)]="position.attributes.dateAssigned" readonly />
        </div>
    </div>
    <div class="row">
        <div class="col-sm-3">
            <div class="form-group">
                <label for="txtVatsimId">Vatsim ID Assigned:</label>
                <div class="input-group">
                    <input type="number" id="txtVatsimId" name="txtVatsimId" class="form-control" ngModel [(ngModel)]="position.attributes.vatsimId" #txtVatsimId="ngModel" required minlength="6" maxlength="8" min="800000" max="99999999" />
                    <div class="input-group-append">
                        <button class="btn btn-info border" type="button" id="button-addon2" title="Search for an AvA vatsim ID to assign to..." (click)="lookUpVatsimId(txtVatsimId.value)">
                            <i class="fas fa-search"></i>
                        </button>
                    </div>
                    <div class="alert alert-danger" *ngIf="txtVatsimId.invalid && (txtVatsimId.dirty || txtVatsimId.touched)">
                        <div *ngIf="txtVatsimId.errors?.required">
                            You must enter a valid VATSIM ID.
                        </div>
                        <div *ngIf="txtVatsimId.errors?.minlength || txtVatsimId.errors?.maxlength || txtVatsimId.errors?.pattern || txtVatsimId.errors?.min || txtVatsimId.errors?.max">
                            VATSIM ID is invalid: must be 800000 to 9999999.
                        </div>
                    </div>
                </div>
                <span class="text-info ml-2" style="font-size:.8rem;" id="txtFoundName" *ngIf="!txtVatsimId.$invalid && txtVatsimId.$dirty || showVatsimIdMessage">
                    <span *ngIf="isVatsimIdPilotFound">
                        ({{foundPilot.callsign}}) {{foundPilot.firstName}} {{foundPilot.lastName}}
                    </span>
                    <span class="text-danger" *ngIf="!isVatsimIdPilotFound">
                        Not found
                    </span>
                </span>
            </div>
        </div>
        <div class="col-sm-9">
            <div class="form-group">
                <label for="txtEmail">
                    AvA Email: <span class="text-warning" style="font-size: .75rem;">[make sure to coordinate with VP-IT on mailbox updates]</span>
                </label>
                <div class="input-group">
                    <input type="text" id="txtEmail" name="txtEmail" class="form-control" #txtEmail="ngModel" ngModel [(ngModel)]="position.attributes.email" minlength="3" maxlength="50" pattern="^[\w\-,.?#!/'()\s]+$" required />
                    <div class="input-group-append">
                        <div class="input-group-text">{{txtEmail.value}}&#64;joinava.org</div>
                    </div>
                    <br/>
                    <div class="alert alert-danger" *ngIf="txtEmail.invalid && (txtEmail.dirty || txtEmail.touched)">
                        <div *ngIf="txtEmail.errors?.required">
                            You must enter an email id.
                        </div>
                        <div *ngIf="txtEmail.errors?.minlength || txtEmail.errors?.maxlength || txtEmail.errors?.pattern">
                            Email is invalid: must be 3 to 50 alphanumeric characters
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-sm-12 ">
            <button type="button" class="btn btn-warning " *ngIf="position.attributes.vacant==0" (click)="unassign()">
                <i class="fas fa-eraser mr-2"></i>Unassign
            </button>
            <button type="submit" class="btn btn-success float-right" [disabled]="positionDetailForm.invalid || !positionDetailForm.dirty || !isVatsimIdPilotFound">
                <i class="fas fa-check mr-2"></i>Save
            </button>
        </div>
    </div>
</form>

<!-- <app-avamodal [modalName]="modalName" [modalMessage]="modalMessage" [modalTitle]="modalTitle" [showMiniClose]="showMiniClose" [showCancelButton]="showCancelButton" [modalStatus]="modalStatus" [buttonText]="'Ok'" [modalPurpose]="modalPurpose" (modalClosed)="onModalClosed($position)"></app-avamodal> -->
