import { Component, Input, OnInit } from '@angular/core';
import { NotamModel } from 'src/app/models/notam.model';

@Component({
  selector: 'app-notam',
  templateUrl: './notam.component.html',
  styleUrls: ['./notam.component.css']
})
export class NotamComponent implements OnInit {
  @Input() notam: NotamModel;
  
  constructor() { }

  ngOnInit(): void {
  }

}
