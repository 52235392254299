<form name="transferForm" class="css-form" (ngSubmit)="onSubmit(transferForm.value)" #transferForm="ngForm" *ngIf="!loading">
    <div class="row">
        <div class="col-md-6">
            <div class="form-group">
                <div class="form-group">
                    <label for="hubChoice">Transfer to Hub:</label>
                    <select name="hubChoice" id="hubChoice" class="form-control" ngModel
                        #hubChoice="ngModel" required>
                        <option *ngFor="let transferHub of transferableHubs;let i = index"
                            [value]="transferableHubs[i].hubId">
                            {{transferHub.longName}}
                            ({{transferHub.shortName}})
                        </option>

                    </select>
                    <div class="alert alert-danger"
                        *ngIf="hubChoice.invalid && (hubChoice.dirty || hubChoice.touched)">
                        <div ng-show="hubChoice.errors?.required">
                            You must pick a hub to transfer to...
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label for="reason">Reason:</label>
                <input type="text" id="reason" name="reason" class="form-control" ngModel
                    #reason="ngModel"  required minlength="10" maxlength="100" pattern="^[\w\-,.?#!()\s]+$"
                    />
                <div class="alert alert-danger" *ngIf="reason.invalid && (reason.dirty || reason.touched)">
                    <div *ngIf="reason.errors?.required">
                        You must enter a reason for your Transfer.
                    </div>
                    <div *ngIf="reason.errors?.minlength || reason.errors?.maxlength || reason.errors?.pattern">
                        Reason is invalid: must be 10 to 100 alphanumeric characters plus -,.?#!()
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 d-flex justify-content-end">
            <button type="submit" class="btn btn-warning" [disabled]="!transferForm.valid || (!transferForm.touched && !transferForm.dirty)" >
                Request Transfer
            </button>
        </div>
    </div>
</form>

<app-avamodal [modalName]="modalName" [modalMessage]="modalMessage" [modalTitle]="modalTitle" [showMiniClose]="showMiniClose"
[showCancelButton]="showCancelButton" [modalStatus]="modalStatus" [buttonText]="'Ok'"
[modalPurpose]="modalPurpose" (modalClosed)="onModalClosed($event)"></app-avamodal>

<span *ngIf="loading">
    <div class="d-flex justify-content-center " style="margin-top: 10em;margin-bottom: 10em;">
        <div class="spinner-border text-primary" style="width: 6rem; height: 6rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</span>
