<div >
    <div class="row">
        <div class="col-lg-12">
            <div class="alert alert-danger alert-dismissable">
                <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
                <i class="fa fa-info-circle"></i>
                <strong>NOTAM!</strong>
                {{notam.title}} -- {{notam.message}}
            </div>
        </div>
    </div>
</div>
