import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MessageModel } from '../models/message.model';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  MESSAGES_SVC = '/messages';
  messages: MessageModel[] = [];

  constructor(private httpClient: HttpClient) { }

  getMessages() {
    this.messages = [];
    return this.httpClient.get(environment.API_URL + this.MESSAGES_SVC).pipe(map(
      (data) => {
        for (const key in data) {
          if (Object.prototype.hasOwnProperty.call(data, key)) {
            for (const index in data[key]) {
              if (Object.prototype.hasOwnProperty.call(data[key], index)) {
                const element = data[key][index];
                this.messages.push(element);
              }
            }
          }
        }
        return this.messages;
      }))
  }

  postMessage(id, payload) {
    let url = environment.API_URL + this.MESSAGES_SVC + '/' + id;
    console.log(url);
    return this.httpClient.put(url, payload);
  }

  getTrainingPage() {
    this.messages = [];
    return this.httpClient.get(environment.API_URL + this.MESSAGES_SVC + '/3').pipe(map(
      (data) => {
        return data;
      }))
  }
}
