import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PirepModel } from '../models/pirep.model';
import { PirepGetModel } from '../models/pirepget.model';
import { PirepPostModel } from '../models/pireppost.model';

@Injectable({
  providedIn: 'root'
})
export class PirepService {
  PILOT_SVC = "/pilots";
  PIREPS_SVC = "/pireps";
  PIREP_REJECTION_SVC = '/rejectedPireps';
  REVIEWABLE_PIREPS_SVC = '/reviewablepireps';
  REVIEW_STATES_SVC = '/reviewstates';

  APPROVE_PIREP_STATE = 0;
  REJECT_PIREP_STATE = 3;

  pirepGetModel: PirepGetModel = {
    pirep: {
      ac: '',
      acars: '',
      acarsId: '',
      arrAirport: '',
      arrTime: '',
      cargoWeight: 0,
      comments: '',
      depAirport: '',
      depTime: '',
      equipment: 0,
      flightDate: '',
      flightDuration: '',
      flownFor: '',
      fuelConsumed: 0,
      landingRate: 0,
      ofrs: [],
      onlineHours: '',
      passengers: 0,
      pirepId: 0,
      pirepOwnerId: 0,
      reviewState: 0,
      route: '',
      arrivalAirportInfo : null,
      departureAirportInfo : null
    }, actions: []
  };

  constructor(private httpClient: HttpClient) { }

  postPirep(pirep: PirepPostModel, pilotId: number) {
    return this.httpClient.post(environment.API_URL + this.PILOT_SVC + '/' + pilotId + this.PIREPS_SVC, pirep);
  }

  updatePirep(pirep: PirepPostModel, pilotId: number, pirepId: number) {
    return this.httpClient.put(environment.API_URL + this.PILOT_SVC + '/' + pilotId + this.PIREPS_SVC + '/' + pirepId, pirep);
  }

  deletePirep(pirepId: number,pilotId: number) {
    return this.httpClient.delete(environment.API_URL + this.PIREPS_SVC + '/' + pirepId);
  }

  getPirep(pirepId: number) {
    console.log(pirepId);
    return this.httpClient.get(environment.API_URL + this.PIREPS_SVC + '/' + pirepId).pipe(map(
      (data) => {
        if (Object.prototype.hasOwnProperty.call(data, "data")) {
          if (Object.prototype.hasOwnProperty.call(data['data'], "attributes")) {
            try {
              this.pirepGetModel.pirep = data['data']['attributes'];
            } catch (error) {
              console.log(error);
            }
            console.log(this.pirepGetModel);
          }
        }
        if (Object.prototype.hasOwnProperty.call(data, "actions")) {
          this.pirepGetModel['actions'] = data['actions'];
        }
        return this.pirepGetModel;
      }));
  }

  getReviewablePireps() {
    return this.httpClient.get(environment.API_URL + this.REVIEWABLE_PIREPS_SVC ).pipe(map(
      (data) => {
        console.log(data["data"]);
        return data["data"];
      }));
  }

  rejectPirep(pirepToDelete, reason) {
    //reviewablepireps/{pireId}/reviewstates/3
    let payload = {
      pirepId : pirepToDelete.attributes.pirepId,
      reason : reason
    };

    console.log(payload)
    return this.httpClient.put(environment.API_URL + this.REVIEWABLE_PIREPS_SVC + '/' + pirepToDelete.attributes.pirepId + this.REVIEW_STATES_SVC + '/' + this.REJECT_PIREP_STATE,payload);
  }

  acceptPirep(pirepToAccept) {
    //reviewablepireps/{pireId}/reviewstates/3
    let payload = {
      pirepId : pirepToAccept.attributes.pirepId,
    };

    console.log(payload)
    return this.httpClient.put(environment.API_URL + this.REVIEWABLE_PIREPS_SVC + '/' + pirepToAccept.attributes.pirepId + this.REVIEW_STATES_SVC + '/' + this.APPROVE_PIREP_STATE,payload);
  }

  getRejected() {
    return this.httpClient.get(environment.API_URL + this.PIREP_REJECTION_SVC ).pipe(map(
      (data) => {
        console.log(data["data"]);
        return data["data"];
      }));
  }

  deleteRejected(rpId) {
    return this.httpClient.delete(environment.API_URL + this.PIREP_REJECTION_SVC + '/' + rpId);
  }

  reverseRejected(rpId, payload) {
    let url = environment.API_URL + this.PIREP_REJECTION_SVC + '/' + rpId;
    return this.httpClient.post(url, payload);
  }
}
