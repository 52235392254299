import { HttpClient } from '@angular/common/http';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PilotDetailsModel } from '../models/pilotdetails.model';

@Injectable({
  providedIn: 'root'
})
export class PilotService {
  PILOT_SVC = '/pilots';
  CERTS_SVC = '/typecerts';
  PILOT_PROFILES_SVC = '/pilotProfiles/';
  PILOT_DETAILS_SVC = '/pilotdetails/';
  PILOT_LOA_SVC = '/loas';
  LINKS_SVC = '/links';
  PILOT_STATUS_SVC = '/statuses/';
  PILOT_CHANGE_CALLSIGN_SVC = '/callsigns';

  pilotDetails : PilotDetailsModel;

  constructor(private httpClient: HttpClient) { }

  getActiveRoster() {
    let url = environment.API_URL + this.PILOT_SVC;
    return this.httpClient.get(url).pipe(map(
      (data) => {
        return data;
      }))
  }
  get(pilotId) {
    let url = environment.API_URL + this.PILOT_SVC  + '/' + pilotId;
    return this.httpClient.get(url).pipe(map(
      (data) => {
        return data['data']['attributes'];
      }))
  }

  getDetails(pilotId) {
    let url = environment.API_URL + this.PILOT_DETAILS_SVC + pilotId ;
    return this.httpClient.get(url).pipe(map(
      (data) => {
        this.pilotDetails = data['attributes'];
        return this.pilotDetails;
      }
    ))
  }

  getPireps(pilotId, pageId?) {
    let url = environment.API_URL + this.PILOT_SVC + '/' + pilotId + "/pireps";

    if (pageId) {
      url += '/' + pageId;
    }
    console.log('Getting pilot pireps from: ' + url);
    return this.httpClient.get(url).pipe(map(
      (data) => {
        return data;
      }))
  }

  getTypeCerts(pilotId) {
    let url = environment.API_URL + this.PILOT_SVC + '/' + pilotId + this.CERTS_SVC;

    return this.httpClient.get(url).pipe(map(
      (data) => {
        for (const key in data) {
          for ( const index in data[key]) {
            if (Object.prototype.hasOwnProperty.call(data[key], index)) {
              let cert = data[key][index]['attributes'];
              if (cert.certType==0 || cert.certType==1) {
                data[key][index]['chartHours'] = [cert.loggedHours,cert.remainingHours];
              }
            }
          }
        }

        return data['data'];
      }))
  }

  getProfile(pilotId) {
    let url = environment.API_URL + this.PILOT_PROFILES_SVC + pilotId ;
    return this.httpClient.get(url).pipe(map(
      (data) => {
        return data;
      }
    ))
  }

  putProfile(pilotId, profile) {
    let url = environment.API_URL + this.PILOT_PROFILES_SVC + pilotId;
    return this.httpClient.put(url, profile);
  }

  getLinks(pilotId) {
    let url = environment.API_URL + this.PILOT_SVC + '/' + pilotId + this.LINKS_SVC;
    return this.httpClient.get(url).pipe(map(
      (data) => {
        return data;
      }
    ))
  }

  postStatus(pilotId, status) {
    let url = environment.API_URL + this.PILOT_SVC + '/' + pilotId + this.PILOT_STATUS_SVC + status;
    return this.httpClient.put(url,null);
  }

  initiateTermination(pilotId, reason) {
    let url = environment.API_URL + this.PILOT_SVC + '/' + pilotId + this.PILOT_STATUS_SVC + environment.PILOT_STATUS.TERMINATION_IN_PROGRESS_STATUS_ID;
    let reasonData = {
      reason : reason,
    }
    return this.httpClient.put(url,reasonData);
  }

  changeCallsign(pilotId, payload) {
    let url = environment.API_URL + this.PILOT_SVC + '/' + pilotId + this.PILOT_CHANGE_CALLSIGN_SVC;
    return this.httpClient.put(url,payload);

  }
}
