import { Component, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { DataSharingService } from 'src/app/services/datasharing.service';

@Component({
  selector: 'app-changecallsign',
  templateUrl: './changecallsign.component.html',
  styleUrls: ['./changecallsign.component.css']
})
export class ChangecallsignComponent implements OnInit {
  loading : boolean = false;
  searchSuccessful : boolean = false;
  searchResults : any[] = [];
  hasMemberSearchResults : boolean = false;
  searchComplete : boolean = false;

  @Output() modalMessage: string = "";
  @Output() modalTitle: string = "";
  @Output() modalStatus: string = "";
  @Output() modalPurpose: string = "";
  @Output() showCancelButton: boolean = false;
  @Output() modalName : string;
  showMiniClose: boolean = false;

  constructor(private dss : DataSharingService, private router : Router) { }

  ngOnInit(): void {
    this.searchResults = [];
    this.hasMemberSearchResults= false;
    this.modalName = 'changecallsignconfirmationModal';
  }

  handleSearchResults(searchResults) {
    this.searchResults = [];
    this.searchComplete=true;
    if (searchResults.length>0) {
      this.hasMemberSearchResults=true;
      this.searchResults = searchResults;
    } else {
      this.hasMemberSearchResults=false;
    }
    console.log(searchResults);
    this.loading=false;
  }

  onCCComplete($event) {
    console.log($event);
    this.ngOnInit();
  }
}
