import { HttpClient } from '@angular/common/http';
import { ElementSchemaRegistry } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ActiveLOAModel } from '../models/activeloa.model';
import { LOARequestModel } from '../models/loarequestmodel';

@Injectable({
  providedIn: 'root'
})
export class LoaService {
  LOA_REQUESTS_SVC = '/loarequests';
  LOAS_SVC = '/loas';
  LOAS_ACTIVE_SVC = '/activeLoas';
  LOA_CLEAR_SVC = '/pilots';
  LOA_ACCEPTANCES_SVC = '/loaacceptances';
  LOA_REJECTIONS_SVC = '/loarejections';
  LOA_CLEARANCES_SVC = '/loaclearances';

  loas : ActiveLOAModel[] = [];
  loaRequests : LOARequestModel[] = [];

  constructor(private httpClient: HttpClient) { }

  postLOA(loa) {
    let url = environment.API_URL + this.LOA_REQUESTS_SVC;
    return this.httpClient.post(url, loa);
  }

  getActiveLOAs() {
    return this.httpClient.get(environment.API_URL + this.LOAS_SVC).pipe(map(
      (data) => {
        this.loas = [];
        for (const key in data) {
          if (Object.prototype.hasOwnProperty.call(data, key)) {
            for ( const index in data[key]) {
              if (Object.prototype.hasOwnProperty.call(data[key], index)) {
                const element = data[key][index];
                this.loas.push(element.attributes);
              }
            }
          }
        }
      return this.loas;
    }))
  }

  getLOARequests() {
    return this.httpClient.get(environment.API_URL + this.LOA_REQUESTS_SVC).pipe(map(
      (data) => {
        this.loaRequests = [];
        for (const key in data) {
          if (Object.prototype.hasOwnProperty.call(data, key)) {
            for ( const index in data[key]) {
              if (Object.prototype.hasOwnProperty.call(data[key], index)) {
                const element = data[key][index];
                element.attributes.toggledOpen=false;
                this.loaRequests.push(element.attributes);
              }
            }
          }
        }
      return this.loaRequests;
    }))
  }

  updateLOAStatus(comments,loaId,status) {
    let url = environment.API_URL + this.LOA_REQUESTS_SVC + '/' + loaId;
    console.log(url);
    let loaData = {
      status : status,
      comments : comments
    }
    return this.httpClient.put(url, loaData);
  }

  clear(pilotIdToClear) {
    let url = environment.API_URL + this.LOA_CLEAR_SVC + '/' + pilotIdToClear + this.LOAS_SVC;
    console.log(url);
    return this.httpClient.put(url,{});

  }
}
