import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FlightModel } from 'src/app/models/flight.model';
import { OfrModel } from 'src/app/models/ofr.model';
import { ReviewablePirepModel } from 'src/app/models/reviewablepirep.model';

@Component({
  selector: 'app-pireptoreview',
  templateUrl: './pireptoreview.component.html',
  styleUrls: ['./pireptoreview.component.css']
})
export class PireptoreviewComponent implements OnInit {
  loading : boolean = false;
  @Output() onAcceptReject = new EventEmitter<{status: string, pirep: ReviewablePirepModel}>();

  @Input() pirep : ReviewablePirepModel;
  constructor() { }

  ngOnInit(): void {
    console.log(this.pirep);
  }

  acceptPirep(pirep) {
    this.onAcceptReject.emit({status : 'accept', pirep : pirep});
  }

  rejectPirep(pirep) {
    this.onAcceptReject.emit({status: 'reject',pirep : pirep});
  }
}
