<div class="container mt-2" >
    <div class="row">
        <div class="col-md-12">
            <div class="card shadow rounded-top rounded-lg">
                <div class="card-header panel-american-coral">
                    <div class="row">
                        <div class="col-12">
                            Manage LOAs
                        </div>
                    </div>
                </div>
                <div class="card-body " *ngIf="!loading">
                    <div class="row mt-2 " *ngIf="hasLOARequests">
                        <div class="col-md-12">
                            <div class="card shadow rounded-top rounded-lg">
                                <div class="card-header panel-american-grey">
                                    <div class="row">
                                        <div class="col-md-12">
                                            LOA Requests
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body ">
                                    <div class="row mt-2 ">
                                        <div class="col-md-12">
                                            <div class="accordion mt-2" id="loaRequestsAccordion"
                                                *ngFor="let loaRequest of loaRequests; let i = index;">
                                                <div class="card border-secondary">
                                                    <div class="card-header bg-secondary text-light" id="heading-{{i}}">
                                                        <div class="col-md-12">
                                                            <b>
                                                            ({{loaRequest.callsign}}) {{loaRequest.firstName}}
                                                            {{loaRequest.lastName}} - {{loaRequest.duration}}
                                                        </b>
                                                            <i 
                                                                [ngClass]="loaRequests[i].toggledOpen ? 'fas fa-chevron-up float-right' : 'fas fa-chevron-down collapsed float-right'"
                                                                data-toggle="collapse"
                                                                [attr.data-target]="'#collapse-' + i"
                                                                aria-expanded="false"
                                                                [attr.aria-controls]="'#collapse-' + i" (click)="onToggle(i)"
                                                                class="cursor-pointer">
                                                            </i>
                                                        </div>
                                                    </div>

                                                    <div id="collapse-{{i}}" class="collapse" toggle="true" aria-labelledby="heading-{{i}}" data-parent="#loaRequestsAccordion">
                                                        <div class="card-body">
                                                            <div class="card border">
                                                                <h5 class="card-header bg-light ava-mini-card-header" >LOA Information</h5>
                                                                <div class="card-body">
                                                                    <div class="row ">
                                                                        <div class="col-md-4">
                                                                            VATSIM ID: <a href="http://stats.vatsim.net/search_id.php?id={{loaRequest.vatsimId}}" target="_blank">{{loaRequest.vatsimId}}</a>
                                                                        </div>
                                                                        <div class="col-md-4">
                                                                            Duration: {{loaRequest.duration}}
                                                                        </div>
                                                                        <div class="col-md-4">
                                                                            Reason: {{loaRequest.reason}}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> 
                                                            <div class="card border mt-2 mb-2" *ngIf="loaRequest.personnelRecords.length>0">
                                                                <div class="card-header bg-light ava-mini-card-header" >Personnel Record Entries</div>
                                                                <div class="card-body">
                                                                    <div class="row">
                                                                        <div class="col-3"><i><u>Date</u></i></div>
                                                                        <div class="col-6"><i><u>Action</u></i></div>
                                                                        <div class="col-3"><i><u>Comments</u></i></div>
                                                                    </div>
                                                                    <div class="row" *ngFor="let record of loaRequest.personnelRecords">
                                                                        <div class="col-3">
                                                                            {{record.Date_Action}}
                                                                        </div>
                                                                        <div class="col-6">
                                                                            {{record.Action}}
                                                                        </div>
                                                                        <div class="col-3">
                                                                            <span *ngIf="record.Comments">{{record.Comments}}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <app-loaresponseform [loa]="loaRequest" *ngIf="loaRequest.isApprovable" (onLOAUpdate)="loaUpdated('acceptreject')"></app-loaresponseform>
                                                            <div *ngIf="!loaRequest.isApprovable">
                                                                <span style="color:orangered">
                                                                    Per policy, Hub Manager's cannot approve LOAs of 90+ days.  
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2 " *ngIf="hasActiveLOAs">
                        <div class="col-md-12">
                            <div class="card shadow rounded-top rounded-lg">
                                <div class="card-header panel-american-grey">
                                    <div class="row">
                                        <div class="col-md-12">
                                            Current Pilots on LOA
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body ">
                                    <div class="row mt-2 ">
                                        <div class="col-md-12 ">
                                            <app-activeloatable [activeLOAs]="activeLOAs" (onLOACleared)="loaUpdated('clear')"></app-activeloatable>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<span *ngIf="loading">
    <div class="d-flex justify-content-center " style="margin-top: 10em;margin-bottom: 10em;">
        <div class="spinner-border text-primary" style="width: 6rem; height: 6rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</span>