import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageModel } from 'src/app/models/message.model';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: 'app-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.css']
})
export class TrainingComponent implements OnInit {
  loading : boolean = false;
  isFound : boolean = false;
  trainingPage : MessageModel = new MessageModel;

  constructor(private router : Router, private messageService : MessageService) { }

  ngOnInit(): void {
    this.loading=true;
    this.trainingPage.title='';
    this.loadTrainingPage();
  }

  loadTrainingPage() {
    this.messageService.getTrainingPage().subscribe(
      (responseData: MessageModel) => {
        this.trainingPage = responseData['messages'][0];
        console.log(this.trainingPage);
        this.loading = false;
        this.isFound = true;
      },
      (error) => {
          console.log('WARNING: ' + error.message);
          if (error.status === 500) {
            this.router.navigate(['/techerror']);
          } else if (error.status===404) {
            this.loading=false;
            this.isFound = false;
          }
      }
    );    
  }

}
