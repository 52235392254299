import { Component, OnInit } from '@angular/core';
import { PersonnelRecordModel } from 'src/app/models/personnelrecord.model';

@Component({
  selector: 'app-personnelrecords',
  templateUrl: './personnelrecords.component.html',
  styleUrls: ['./personnelrecords.component.css']
})
export class PersonnelrecordsComponent implements OnInit {
  loading : boolean = false;
  searchSuccessful : boolean = false;
  searchResults : any[] = [];
  hasMemberSearchResults : boolean = false;
  searchComplete : boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  handleSearchResults(searchResults) {
    this.searchResults = [];
    this.searchComplete=true;
    if (searchResults.length>0) {
      this.hasMemberSearchResults=true;
      this.searchResults = searchResults;
    } else {
      this.hasMemberSearchResults=false;
    }
    console.log(searchResults);
    this.loading=false;
  }
}
