import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProfileModel } from 'src/app/models/profile.model';
import { AuthService } from 'src/app/services/auth.service';
import { PilotService } from 'src/app/services/pilot.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  loading: boolean = false;
  pilotId: number;
  profile : ProfileModel;
  activeHRMenu : string = 'pr';
  statsLinks : any[] = [];

  constructor(private authService: AuthService, private route: ActivatedRoute,private pilotService : PilotService) { }

  ngOnInit(): void {
    this.statsLinks = [];
    this.loading = true;
    this.pilotId = this.route.snapshot.params['pilotId'];

    this.pilotService.getProfile(this.pilotId).subscribe(
      (responseData) => {
        console.log(responseData);
        this.profile = responseData['data']['attributes'];
        this.statsLinks = responseData['data']['statsLinks'];
        console.log(this.statsLinks);
        this.loading=false;
      },
      (error) => {
        this.loading=false;
      }
    )
  }

  onSubScreenComplete(event) {
    console.log(event);
    this.hrMenuClick('pr');
    this.ngOnInit();
  }

  hrMenuClick(menuChoice) {
    this.activeHRMenu=menuChoice;
  }
}
