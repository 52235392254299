import { Component, Input, OnInit } from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { ACARSPointModel } from 'src/app/models/acarspoint.model';
import { OfrModel } from 'src/app/models/ofr.model';
import { PilotLandingModel } from 'src/app/models/pilot-landing.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-last-flight-map',
  templateUrl: './last-flight-map.component.html',
  styleUrls: ['./last-flight-map.component.css']
})
export class LastFlightMapComponent implements OnInit {
  @Input() pilotDetails: PilotLandingModel;
  markers = [];
  lines = [];
  center: google.maps.LatLngLiteral;
  gOptions: google.maps.MapOptions;
  depLat: number ;
  depLng: number ;
  arrLat: number ;
  arrLng: number ;

  constructor() { }

  ngOnInit(): void {
    let styles : any = environment.GOOGLE_MAP_STYLES;
    this.gOptions = {
      zoom: 5,
      gestureHandling: 'greedy',
      styles: styles,
    }
    this.setDepArrEndpoints();
    this.loadMapDetails();
  }

  setDepArrEndpoints() {
    this.depLat= parseFloat(this.pilotDetails.lastFlightDepAirportInfo.latitude);
    this.depLng = parseFloat(this.pilotDetails.lastFlightDepAirportInfo.longitude);
    this.arrLat = parseFloat(this.pilotDetails.lastFlightArrAirportInfo.latitude);
    this.arrLng = parseFloat(this.pilotDetails.lastFlightArrAirportInfo.longitude);
  }

  loadMapDetails() {
    this.loadEndPointMarkers();
    this.loadDirectLine();
    this.loadRouteLine();
    this.centerMap();
  }

  centerMap() {
    this.center = {
      lat: (this.depLat + this.arrLat) / 2,
      lng: (this.depLng + this.arrLng) / 2
    }
  }

  loadEndPointMarkers() {
    let departureAirportMarker = this.buildAvaAirportMarker(this.pilotDetails.lastFlightDepAirportInfo.id,
                                                            this.pilotDetails.lastFlightDepAirportInfo.latitude,
                                                            this.pilotDetails.lastFlightDepAirportInfo.longitude,
                                                            this.pilotDetails.lastFlightDepAirportInfo.icao,
                                                            this.pilotDetails.lastFlightDepAirportInfo.name,
                                                            'departure',
                                                            this.pilotDetails.lastFlightDepTime);
    this.markers.push(departureAirportMarker);

    let arrivalAirportMarker = this.buildAvaAirportMarker(this.pilotDetails.lastFlightArrAirportInfo.id,
                                                          this.pilotDetails.lastFlightArrAirportInfo.latitude,
                                                          this.pilotDetails.lastFlightArrAirportInfo.longitude,
                                                          this.pilotDetails.lastFlightArrAirportInfo.icao,
                                                          this.pilotDetails.lastFlightArrAirportInfo.name,
                                                          'arrival',
                                                          this.pilotDetails.lastFlightArrTime);
    this.markers.push(arrivalAirportMarker);
  }

  buildAvaAirportMarker(id, latitude, longitude, icao, name, markerType, time) {
    console.log(time);
    return {
      id: id,
      position: new google.maps.LatLng(latitude, longitude),
      title: '(' + icao + ') ' + name + ' @ ' + time,
      options: {
        draggable: false,
        icon: '/assets/img/map/APT.png',
        anchor: { x: 8, y: 4 },
        animation: google.maps.Animation.DROP
      },
    }
  }

  buildLine(coords, color, opacity) {
    return {
      path : coords,
      geodesic : true,
      strokeColor : color,
      strokeOpacity : opacity,
      strokeWeight : 3
    }
  }

  loadRouteLine() {
    let coords = [];
    this.pilotDetails['lastOfrs'].forEach((lastOfr: OfrModel) => {
      console.log(lastOfr.acarsData);
      lastOfr.acarsData.forEach((acarsPoint: ACARSPointModel) => {
        coords.push(
          new google.maps.LatLng(acarsPoint.latitude, acarsPoint.longitude),
        );
      });
    })
    this.lines.push(this.buildLine(coords,'yellow',1.0));
  }

  loadDirectLine() {
    let coords = [];
    coords.push(
      new google.maps.LatLng(this.depLat,this.depLng)
    );
    coords.push(
      new google.maps.LatLng(this.arrLat,this.arrLng)
    );
    this.lines.push(this.buildLine(coords,'red',.45));
  }

}
