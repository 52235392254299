<div *ngIf="loading==false">
    <div class="card shadow rounded-top rounded-lg " style="margin: 5px 10px 30px 10px">
        <div class="card-header panel-american-blue">
            <div class="row">
                <div class="col-md-12 col-xs-12">
                    Logbook
                    </div>
            </div>
        </div>
        <div class="card-body" *ngIf="pireps.length<=0 && !loading">
            It appears you have not filed a PIREP yet. If this is in error, please report it via Discord.
        </div>
        <div class="card-body " *ngIf="pireps.length>0 && !loading">
            <div class="row d-flex justify-content-end">
                <app-logbookpaginator [nextPageId]="nextPageId" [prevPageId]="prevPageId" [totalPireps]="pirepCount"
                    (onPageChange)="onPageChange($event)"></app-logbookpaginator>
            </div>
            <div class="row">
                <div class="col-sm-12">
                <div class="table-responsive">
                    <table class="table table-condensed table-bordered table-hover table-striped text-center">
                        <thead>
                            <th class="text-center">#</th>
                            <th class="text-center">Id</th>
                            <th class="text-center">Edit?</th>
                            <th class="text-center">Date</th>
                            <th class="text-center">Aircraft</th>
                            <th class="text-center">Departure</th>
                            <th class="text-center">Arrival</th>
                            <th class="text-center">Dep Time</th>
                            <th class="text-center">Arr Time</th>
                            <th class="text-center">Landing</th>
                            <th class="text-center">Hours</th>
                            <th class="text-center">Flown For</th>
                            <th class="text-center">ACARS</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let pirep of pireps; let i=index"
                                [ngClass]="{'pirep-warning' : pirep.attributes.reviewState==1, 'panel-american-blue' : pirep.attributes.reviewState==2 }">
                                <td>{{i+1}}</td>
                                <td>{{pirep.attributes.pirepId}}</td>
                                <td>
                                    <a [routerLink]="['/pilot/pireps',pirep.attributes.pirepId]"
                                        routerLinkActive="active" *ngIf="pirep.attributes.reviewState==0 && pirep.attributes.acarsId==''">
                                        <i class="fas fa-pencil-alt"
                                            title="Edit PIREP: {{pirep.attributes.pirepId}}" alt="Edit"></i>
                                    </a>
                                    <a [routerLink]="['/pilot/pireps',pirep.attributes.pirepId]"
                                        routerLinkActive="active" *ngIf="pirep.attributes.reviewState==0 && pirep.attributes.acarsId!=''">
                                        <i class="far fa-eye"
                                            title="Review PIREP: {{pirep.attributes.pirepId}}" alt="Review"></i>
                                    </a>
                                </td>

                                <td>{{pirep.attributes.flightDate}}</td>
                                <td>{{pirep.attributes.ac}}</td>
                                <td>{{pirep.attributes.depAirport}}</td>
                                <td>{{pirep.attributes.arrAirport}}</td>
                                <td>{{pirep.attributes.depTime}}</td>
                                <td>{{pirep.attributes.arrTime}}</td>
                                <td>
                                    <span *ngIf="pirep.attributes.landingRate<0 && pirep.attributes.landingRate>=-100"
                                        title="{{pirep.attributes.landingRate}}">
                                        <i class="fas fa-plane-departure" style="color:purple"></i>
                                    </span>
                                    <span
                                        *ngIf="pirep.attributes.landingRate<-100 && pirep.attributes.landingRate>=-400"
                                        title="{{pirep.attributes.landingRate}}">
                                        <i class="fas fa-plane" style="color:seagreen"></i>
                                    </span>
                                    <span *ngIf="pirep.attributes.landingRate<-400"
                                        title="{{pirep.attributes.landingRate}}">
                                        <i class="fas fa-plane-arrival" style="color:red"></i>
                                    </span>
                                    <span *ngIf="pirep.attributes.landingRate==null" title="No landing data">
                                        <i class="fas fa-plane-slash"></i>
                                    </span>
                                    <span *ngIf="pirep.attributes.landingRate>0" title="{{pirep.attributes.landingRate}}">
                                        <i class="fas fa-plus"></i>
                                    </span>
                                    <span *ngIf="pirep.attributes.landingRate==0" title="Perfect 0 landing rate - you are a star!">
                                        <i class="fas fa-star" style="color: #FFD43B;"></i>
                                    </span>
                                </td>

                                <td>{{pirep.attributes.hours}}</td>
                                <td>{{pirep.attributes.flownFor}}</td>
                                <td>
                                    <span title="{{pirep.attributes.acarsId}}" *ngIf="pirep.attributes.acarsId!=''">
                                        <i class="fas fa-check"></i>
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                </div>
            </div>
            <div class="row d-flex justify-content-end">
                <app-logbookpaginator [nextPageId]="nextPageId" [prevPageId]="prevPageId"
                    [totalPireps]="pirepCount" (onPageChange)="onPageChange($event)"></app-logbookpaginator>
            </div>
        </div>

    </div>
</div>

<span *ngIf="loading">
    <div class="d-flex justify-content-center " style="margin-top: 10em;margin-bottom: 10em;">
        <div class="spinner-border text-primary" style="width: 6rem; height: 6rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</span>
