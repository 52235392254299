import { Component, OnInit } from '@angular/core';
import { EventModel } from 'src/app/models/event.model';
import { EventService } from 'src/app/services/event.service';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})
export class EventsComponent implements OnInit {
  loading : boolean =false;
  hasEvents : boolean = false;
  isAdding : boolean = false;
  showAddEventForm: boolean = false;

  events : EventModel[] = [];
  blankEvent : EventModel;

  constructor(private eventService : EventService) { }

  ngOnInit(): void {
    this.hasEvents=false;
    this.isAdding=false;
    this.loading=true;
    this.events=[];
    this.loadEvents();
  }

  loadEvents() {
    this.eventService.getManaged().subscribe(
      (responseData) => {
        this.events = responseData;
        console.log(this.events);
        this.loading = false;
        this.hasEvents = true;
      },
      (error) => {
        if (error.status = 404) {
          this.hasEvents=false;
        }
        this.loading = false;
      }
    );
  }

  onEventUpdateComplete(event) {
    console.log(event);
    this.ngOnInit();
  }

  addEvent() {
    this.isAdding=true;
    this.showAddEventForm=true;

    let todaysDate = new Date();
    let todaysDateString = todaysDate.getFullYear() + '-' + (todaysDate.getMonth()+1) + '-' + todaysDate.getDate();
    let todaysTimeString = todaysDate.getHours() + ':' + todaysDate.getMinutes();

    this.blankEvent= new EventModel;

    this.blankEvent['attributes'] = {
      bannerUrl : "",
      description : "",
      duration : 1,
      eventId : 0,
      name : "",
      startDate : todaysDateString,
      startTime : todaysTimeString,
      trackingTag : "",
      url : ""
    }
    console.log(this.blankEvent);
  }
}
