<div class="border border-rounded pl-2 pt-1 mb-1" >
    <div class="row">
        <div class="col-sm-12 font-weight-bold text-info" >
            <u>Application Criteria</u>
        </div>
    </div>
    <div class="row" >
        <div class="col-md-4">
            <label class="mr-2">Is on the DNR List?</label>
            <span *ngIf="application.attributes.dnrRecords.length>0">
                Yes <i class="fa fa-flag" style="color:red;font-size:18px"></i><br />
            </span>
            <span *ngIf="application.attributes.dnrRecords.length<=0">No</span>
        </div>
        <div class="col-md-4">
            <label class="mr-2">Any Resignations?</label>
            <span *ngIf="application.attributes.resignationRecords.length>0">
                Yes <i class="fa fa-flag" style="color:red;font-size:18px"></i>
            </span>
            <span *ngIf="application.attributes.resignationRecords.length<=0">No</span>
        </div>
        <div class="col-md-4">
            <label class="mr-2">Any Terminations?</label>
            <span *ngIf="application.attributes.terminationRecords.length>0">
                Yes <i class="fa fa-flag" style="color:red;font-size:18px"></i>
            </span>
            <span *ngIf="application.attributes.terminationRecords.length<=0">No</span>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4">
            <label>Understand VATSIM only:</label>&nbsp;
            <i class="fa fa-check-square" style="color:green;font-size:18px"
                *ngIf="application.attributes.confirmVatsim==1"></i>
            <i class="fa fa-exclamation-circle" style="color:red;font-size:18px"
                *ngIf="application.attributes.confirmVatsim==0"></i>
        </div>
        <div class="col-md-4">
            <label>Understand 1x Speed:</label>&nbsp;
            <i class="fa fa-check-square" style="color:green;font-size:18px"
                *ngIf="application.attributes.confirmSpeed==1"></i>
            <i class="fa fa-exclamation-circle" style="color:red;font-size:18px"
                *ngIf="application.attributes.confirmSpeed==0"></i>
        </div>
        <div class="col-md-4">
            <label>Understand 2 Pirep/mth:</label> &nbsp;
            <i class="fa fa-check-square" style="color:green;font-size:18px"
                *ngIf="application.attributes.confirmPireps==1"></i>
            <i class="fa fa-exclamation-circle" style="color:red;font-size:18px"
                *ngIf="application.attributes.confirmPireps==0"></i>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-md-6 text-right">
            <label>First Hub Choice:</label><br />
            <span>{{application.attributes.firstHubChoiceShortName}}
                ({{application.attributes.firstHubChoiceLongName}})</span>
        </div>
        <div class="col-md-6 text-left">
            <label>Second Hub Choice:</label><br />
            <span>{{application.attributes.secondHubChoiceShortName}}
                ({{application.attributes.secondHubChoiceLongName}})</span>
        </div>
    </div>
</div>

<div class="border border-rounded pl-2 pt-1 mb-1 mt-3 pr-2" >
    <div class="row">
        <div class="col-sm-12 font-weight-bold text-info" >
            <u>Member Info</u>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4">
            <label>First Name:</label> {{application.attributes.firstName}}
        </div>
        <div class="col-md-4">
            <label>Last Name:</label> {{application.attributes.lastName}}
        </div>
        <div class="col-md-4">
            <label class="mr-2">Age:</label>
            <span
                [ngClass]="{'text-warning || font-weight-bold' : application.attributes.age<18 }">{{application.attributes.age}}</span>
            <i class="fa fa-flag ml-1 text-warning" style="font-size:18px" *ngIf="application.attributes.age<18"></i>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4">
            <label>Email:</label> {{application.attributes.email}}
        </div>
        <div class="col-md-4">
            <label>Years Simming:</label> {{application.attributes.yearsSim}}
        </div>
        <div class="col-md-4">
            <label class="mr-2">Real World Exp:</label>
            <span
                *ngIf="application.attributes.realWorldExperience.length>0">{{application.attributes.realWorldExperience}}</span>
            <span *ngIf="application.attributes.realWorldExperience.length==0">No</span>
        </div>
    </div>
</div>

<div class="border border-rounded pl-2 pt-1 mb-1 mt-3 pr-2" >
    <div class="row">
        <div class="col-sm-8 font-weight-bold text-info" >
            <u>VATSIM Info</u>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4">
            <label>VATSIM ID:</label>
            <a href="http://stats.vatsim.net/search_id.php?id={{application.attributes.vatsimId}}"
                target="_blank">{{application.attributes.vatsimId}}</a>
        </div>
        <div class="col-md-4">
            <label>Suspended:</label> &nbsp;
            <i class="fa fa-check-square" style="color:green;font-size:18px"
                *ngIf="application.attributes.vatsimData.data.susp_date==null"> NO </i>
            <i class="fa fa-exclamation-circle" style="color:red;font-size:18px"
                *ngIf="application.attributes.vatsimData.data.susp_date!=null">
                {{application.attributes.vatsimData.data.susp_date}}
            </i>
        </div>
        <div class="col-md-4">
            <label>Joined:</label>
            <span class="ml-2">{{application.attributes.vatsimData.data.reg_date | date : 'YYYY-MM-dd'}}</span>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4">
            <label>VATSIM Hours:</label>
            <span [ngClass]="{'text-danger || font-weight-bold' : application.attributes.vatsimData.hours.pilot<15}">
                {{application.attributes.vatsimData.hours.pilot}}
                <i class="fa fa-flag" style="color:red;font-size:18px"
                    *ngIf="application.attributes.vatsimData.hours.pilot<15"></i>
            </span>
        </div>
        <div class="col-md-2">
            <label>ATC Hours:</label>
            <span [ngClass]="{'text-success' : application.attributes.vatsimData.hours.atc>0}"
                class="ml-2">{{application.attributes.vatsimData.hours.atc}}</span>
        </div>
        <div class="col-md-2">
            <label>SUP Hours:</label>
            <span [ngClass]="{'text-success' : application.attributes.vatsimData.hours.sup>0}"
                class="ml-2">{{application.attributes.vatsimData.hours.sup}}</span>
        </div>
        <div class="col-sm-3">
            <label>Pilot Rating:</label>
            <span class="ml-2">{{application.attributes.vatsimData.data.pilotrating}}</span>
        </div>
    </div>
</div>
<div class="border border-rounded pl-2 pt-1 mb-1 pr-2" *ngIf="application.attributes.personnelRecords.length>0">
    <div class="row">
        <div class="col-sm-12 font-weight-bold text-info">
            <u>Personnel Records</u>
        </div>
    </div>
    <div class="row" style="font-size:.75em">
        <div class="col-md-12">
            <table class="table table-bordered table-hover responsive">
                <thead>
                    <tr>
                        <th scope="col">Date</th>
                        <th scope="col">Action</th>
                        <th scope="col">Comments</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let prec of application.attributes.personnelRecords;let i = index">
                        <td>{{prec.Date_Action}}</td>
                        <td>{{prec.Action}}</td>
                        <td>{{prec.Comments}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<div class="border border-rounded pl-2 pt-1 mb-1 mt-3 pr-2" >
    <div class="row">
        <div class="col-sm-8 font-weight-bold text-info" >
            <u>Optional Info</u>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4">
            <label>Staff Interest:</label>
            <span *ngIf="application.attributes.confirmStaffInterest">Yes</span>
            <span *ngIf="!application.attributes.confirmStaffInterest">No</span>
        </div>
        <div class="col-md-4">
            <label class="mr-1">Staff Experience:</label>
            <span *ngIf="application.attributes.confirmStaffExp">Yes</span>
            <span *ngIf="!application.attributes.confirmStaffExp">No</span>
        </div>
        <div class="col-md-4">
            <label>Staff Position Held:</label> {{application.attributes.staffCapacity}}
            <span *ngIf="!application.attributes.staffCapacity">N/A</span>
        </div>
    </div>
    <div class="row ">
        <div class="col-md-4">
            <label>Web Design Experience:</label>
            <span *ngIf="application.attributes.confirmWebDesignExp">Yes</span>
            <span *ngIf="!application.attributes.confirmWebDesignExp">No</span>
        </div>
        <div class="col-md-4  col-md-offset-4">
            <label>AvA Reference:</label> {{application.attributes.avaInterest}}
            <span *ngIf="!application.attributes.avaInterest">N/A</span>
        </div>
        <div class="col-md-4  col-md-offset-4">
            <label>Vatsim Experience:</label> {{application.attributes.avaInterest}}
            <span *ngIf="!application.attributes.avaInterest">N/A</span>
        </div>
    </div>
</div>
<div class="row pl-2 pt-1 mb-1 mt-5 pr-2" *ngIf="isShowingDefaultButtons">
    <div class="col-md-6 button-group">
        <!-- <button class="btn btn-secondary btn-sm" id="btnAppDelete"
            ng-click="vm.appDelete(application.attributes.appId)"><i class="glyphicon glyphicon-remove-sign"></i>
            Delete </button> -->
        <button class="btn btn-danger" id="btnAppReject" (click)="appRejectStart()">
            <i class="fa fa-thumbs-down"></i> Reject 
        </button>
    </div>
    <div class="col-md-6 ">
        <div class="btn-group float-right" id="btnAccpt" (click)="appAcceptStart()">
            <button type="button" class="btn btn-success">
                <i class="fa fa-thumbs-up"></i>Accept
            </button>
        </div>
    </div>
</div>
<div class="row pl-2 pt-1 mb-1 pr-2" *ngIf="isAccepting">
    <div class="col-md-12">
        <app-acceptbox [application]="application" (onAppAcceptUpdate)="onAppRectionUpdate($event)"></app-acceptbox>
    </div>
</div>
<div class="row pl-2 pt-1 mb-1 pr-2" *ngIf="isRejecting">
    <div class="col-md-12">
        <app-rejectionbox [appId]="application.attributes.appId" (onAppRectionUpdate)="onAppRectionUpdate($event)"></app-rejectionbox>
    </div>
</div>
