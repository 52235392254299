<div class="container mt-2 mb-3">
    <div class="row">
        <div class="col-md-12">
            <div class="card shadow rounded-top rounded-lg">
                <div class="card-header panel-american-coral">
                    <div class="row">
                        <div class="col-10 mt-2">
                            <span *ngIf="!isAdding">Manage Events</span>
                            <span *ngIf="isAdding">Add Event</span>
                        </div>
                        <div class="col-sm-2 float-right">
                            <button class="btn btn-success btn-sm float-right" id="btnAddEvent" (click)="addEvent()" *ngIf="!isAdding">
                                Add Event
                            </button>
                        </div>                        
                    </div>
                </div>
                <div class="card-body" *ngIf="!loading ">
                    <div *ngIf="isAdding">
                        <app-eventdetail [event]="blankEvent" [isNew]="true" (onEventUpdateComplete)="onEventUpdateComplete($event)"></app-eventdetail>
                    </div>
                    <div class="row" *ngIf="!hasEvents && !isAdding">
                        <div class="col-sm-12">
                            <p>I'm sorry but there are no Events to manage at this time.</p>
                        </div>
                    </div>
                    <div *ngIf="hasEvents && !isAdding">
                        <div class="card mt-2 " *ngFor="let event of events" >
                            <div class="card-header bg-ava-info">
                                <div class="row">
                                    <div class="col-sm-8">
                                        [{{event.attributes.startDate | date:'fullDate'}} at {{(event.attributes.startDate + ' ' + event.attributes.startTime) | date:'HH:mm'}}] <b>{{event.attributes.name}}</b>
                                    </div>
                                    <div class="col-sm-4">
                                        <button class="btn btn-info btn-sm float-right" type="button" data-toggle="collapse" 
                                            [attr.data-target]="'#eventDetailCollapse-' + event.attributes.eventId"
                                            aria-expanded="true" aria-controls="eventDetailCollapse">
                                            <i class="fas fa-exchange-alt"></i> Edit
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="collapse" id="eventDetailCollapse-{{event.attributes.eventId}}">
                                <div class="card-body">                        
                                    <app-eventdetail [event]="event" [isNew]="false" (onEventUpdateComplete)="onEventUpdateComplete($event)"></app-eventdetail>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<span *ngIf="loading">
    <div class="d-flex justify-content-center " style="margin-top: 10em;margin-bottom: 10em;">
        <div class="spinner-border text-primary" style="width: 6rem; height: 6rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</span>