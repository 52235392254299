<div class="container mt-2 mb-3">
    <div class="row">
        <div class="col-md-12">
            <div class="card shadow rounded-top rounded-lg">
                <div class="card-header panel-american-coral">
                    <div class="row">
                        <div class="col-10">
                            <span>Change Callsign</span>
                        </div>
                    </div>
                </div>
                <div class="card-body" *ngIf="!loading ">

                    <app-membersearch (onMemberSearchComplete)="handleSearchResults($event)"></app-membersearch>

                    <div class="row" *ngIf="searchComplete && !hasMemberSearchResults ">
                        <div class="col-sm-12">
                            <p>I'm sorry but no records were found matching your results.</p>
                        </div>
                    </div>
                    <div *ngIf="searchComplete && hasMemberSearchResults && !loading">
                        <div class="card-body ">
                            <div class="row mt-2 " *ngFor="let pilot of searchResults; let i = index;">
                                <div class="col-md-12" *ngIf="hasMemberSearchResults && searchComplete">
                                    <app-ccformdetail [member]="pilot" (onCCComplete)="onCCComplete($event)">
                                    </app-ccformdetail>
                                </div>
                            </div>
                            <div class="col-md-12" *ngIf="!hasMemberSearchResults && searchComplete">
                                No pilots found.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>