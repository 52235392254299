import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { HiringHubModel } from 'src/app/models/hiringhub.model';
import { DataSharingService } from 'src/app/services/datasharing.service';
import { HubService } from 'src/app/services/hub.service';
import { TransferService } from 'src/app/services/transfer.service';

@Component({
  selector: 'app-hrtransferrow',
  templateUrl: './hrtransferrow.component.html',
  styleUrls: ['./hrtransferrow.component.css']
})
export class HrtransferrowComponent implements OnInit {
  @Output() onHRTransferComplete = new EventEmitter<string>();
  @Input() pilot : any;
  loading : boolean = false;
  showTransferRow : boolean = false;
  transferableHubs: HiringHubModel[] = [];
  availableCallsigns: string[];
  //modal vars
  @Output() modalMessage: string = "";
  @Output() modalTitle: string = "";
  @Output() modalStatus: string = "";
  @Output() modalPurpose: string = "";
  @Output() showCancelButton: boolean = false;
  @Output() showMiniClose: boolean = false;
  @Output() modalName : string = 'hrTransferConfirmationModal';
  @Output() modalButtonText : string = 'Ok';


  constructor(private router : Router, private hubService : HubService, private transferService : TransferService, private dss : DataSharingService) { }

  ngOnInit(): void {
    console.log(this.pilot);
  }

  onTransferButtonClick() {
    this.modalName = this.modalName + this.pilot.pilotId;
    this.showTransferRow=!this.showTransferRow;
    console.log(this.showTransferRow);

    this.loadHubs();

  }

  loadHubs() {
    console.log(this.pilot.hubId, this.pilot.lastName);

    this.transferableHubs = [];
    this.loading=true;
    this.hubService.getTransferableHubsByPilotId(this.pilot.hubId).subscribe(
      (responseData: HiringHubModel[]) => {
        this.transferableHubs = responseData;
        this.loading=false;
      },
      (error) => {
        console.log(error);
        this.router.navigate(['/techerror']);
        this.loading=false;
        //tod
      }
    );
  }
  newHubSelected(hubChoice) {
    console.log(hubChoice);
    this.loadCallsigns(hubChoice);
  }

  loadCallsigns(hubChoice) {
    this.availableCallsigns = [];
    this.loading=true;
    this.hubService.getAvailableCallsignsForAHub(hubChoice).subscribe(
      (responseData) => {
        this.availableCallsigns = responseData;
        console.log(this.availableCallsigns);
        this.loading=false;
      },
      (error) => {
        console.log(error);
        this.router.navigate(['/techerror']);
        this.loading=false;
        //tod
      }
    );
  }

  executeTransfer(transferRequestForm) {
    console.log(transferRequestForm);
    var transferData = {"pilotId" : this.pilot.pilotId, "newCallsign":transferRequestForm.newCallsign, "newHubId":transferRequestForm.newHub,"comment":transferRequestForm.comments};
    console.log(transferData);

    this.loading = true;
    this.transferService.postHRTransfer(transferData).subscribe(
      (responseData) => {
        console.log(responseData);
        this.loading=false;
        this.showSuccessModal(responseData);
      },
      (error) => {
        console.log(error);
        this.router.navigate(['/techerror']);
        this.loading=false;
      }
    )
  }

  onModalClosed(returnMessage: {status: string, purpose: string}) {
    this.dss.showModal.next({modalName : this.modalName, showModal :false});
    if (returnMessage.purpose == 'hrtransferstatussuccess' && returnMessage.status == 'ok') {
      this.onHRTransferComplete.emit('success');
    }
  }

  showSuccessModal(data) {
    this.dss.showModal.next({modalName : this.modalName, showModal :false});
    this.modalStatus='success';
    this.showMiniClose=false;
    this.showCancelButton=false;
    this.modalTitle = 'Transfer Success';
    this.modalMessage = this.pilot.callsign + ' has been successfully transfered.';
    this.modalPurpose = "hrtransferstatussuccess";
    this.dss.showModal.next({modalName : this.modalName, showModal :true});
  }
}
