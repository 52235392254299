import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ResignationRequestModel } from 'src/app/models/resignation.model';
import { ResignationService } from 'src/app/services/resignation.service';

@Component({
  selector: 'app-resignations',
  templateUrl: './resignations.component.html',
  styleUrls: ['./resignations.component.css']
})
export class ResignationsComponent implements OnInit {
  loading : boolean = false;
  hasResignations : boolean = false;
  resignations : ResignationRequestModel[] = [];

  constructor(private router : Router, private resService : ResignationService) { }

  ngOnInit(): void {
    this.loading =true;
    this.hasResignations=false;
    this.resignations=[];
    this.loadResignations();
  }

  loadResignations() {
    this.resService.getResignations().subscribe(
      (responseData) => {
        this.resignations = responseData;
        console.log(this.resignations);
        this.loading = false;
        this.hasResignations = true;
      },
      (error) => {
        this.loading = false;
        this.hasResignations=false;
        this.resignations=[];
        if (error.status = 404) {
          this.hasResignations=false;
        } else {
          this.router.navigate(['/techerror']);
        }
      }
    );
  }

  onResignationUpdate(event) {
    console.log(event);
    this.ngOnInit();
  }
}
