import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ResignationService {
  RESIGNATION_SVC = '/resignations';
  RESIGNATION_REQUEST_SVC = "/resignationRequests";
  PILOT_SVC = '/pilots/';

  constructor(private httpClient: HttpClient) { }

  postResignation(resForm, pilotId) {
    let url = environment.API_URL + this.PILOT_SVC + pilotId + this.RESIGNATION_SVC;
    return this.httpClient.post(url, resForm);
  }

  getResignations() {
    return this.httpClient.get(environment.API_URL + this.RESIGNATION_REQUEST_SVC).pipe(map(
      (data) => {
        // this.apps = data['data'];
        return data['data'];
      }
    ))
  }

  updateResignationStatus(resignationId,payload, status) {
    let url = environment.API_URL + this.RESIGNATION_REQUEST_SVC + '/' + resignationId + '/' + status;
    return this.httpClient.post(url, payload);
  }

}
