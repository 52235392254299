<div class="alert alert-warning" role="alert" autoscroll="true">
    Important Note: The Human Resources Department attempts to process applications at least every other day, if not on a daily basis.
</div>
<section id="gettingStarted">
    <div class="container">
        <h1>Join AvA Today!</h1>
        <p>
            Welcome to the American Virtual Airlines Human Resources Department! Here is where you will begin your virtual career with AvA!
        </p>
    </div>
    <div class="container pirep-form-border">
        <h3>How to get started</h3>
        <hr class="aa-line"/>
        <button (click)="skipToForm()" *ngIf="showSkipButton">Skip Quiz</button>
        <ul>
            <li>
                <h4>Step 1 - You must be a member of VATSIM</h4>
                <p>
                    In order to join AvA, you must have a valid VATSIM ID. You can get an ID and learn how to fly online at the
                        <a href="https://www.vatsim.net/join" target="_blank">VATSIM website</a>.
                </p>
                <hr/>
            </li>
            <li>
                <h4>Step 2 - Install and configure software</h4>
                <p>
                    You will need the following in order to fly with AvA:
                </p>
                <ul>
                    <li>Flight Simulator - one of the Microsoft Flight Simulators or XPlane or P3D</li>
                    <li>ACARS - either smartCARS or XAcars</li>
                    <li>
                        VATSIM Client - depending on your flight simulator you will need to download the appropriate VATSIM client<br/>
                        <i>
                            NOTE: these applications are available for download on the <a href="https://my.vatsim.net" target="_blank">my.VATSIM.net</a> website.
                            under the Learning Center | VATSIM Basics drop down or <a href="https://my.vatsim.net/learn/vatsim-basics/section/5">via this link.</a>
                        </i>
                    </li>
                </ul>
                <hr/>
            </li>
            <li>
                <h4>Step 3 - Read our <a href="https://joinava.org/policymanual" target="_blank">Policy Manual</a></h4>
                <p>
                    We do not have many policies, but the ones we do have we enforce.  It is your responsibility to undertsand
                    and adhere to them.
                </p>
                <hr/>
            </li>
            <li>
                <h4>Step 4 - Pass the mini quiz</h4>
                <p>
                    On the next page you will be asked to answer a small quiz intended to show to us your understanding
                    of the requirements of flying American Virtual Airlines.  All you need to do is read the Policy Manual
                    as all the answers are directly in the manual.
                </p>
                <hr/>
            </li>
            <li>
                <h4>Step 5 - Fill out an application</h4>
                <p>
                    As part of the sign on process, if you meet prequalifications (i.e. not on our Do Not Rehire list, and 75+ of flying),
                    you will be automatically accepted into our training facility.  If you do not meet prequal, our training department will
                    assess your application within a 7 day time frame to determine your eligibility.
                </p>
                <p>
                    You will be asked to pick a primary Note, we only hire into specific hubs depending on capacity in
                    order to keep a healthy hub balance.  Don't worry too much about this as you can always transfer to another hub once you
                    have completed your probationary period.
                </p>
                <hr/>
            </li>
            <li>
                <h4>Step 6 - Please be patient!</h4>
                <p>
                    If you are notified your application is under review, please be patient and double check your spam folders
                    as we will be communicating with you via email.  This review process can take up to seven days to complete
                    depending on availability of our team.
                </p>
            </li>
        </ul>
        <div class="container text-center">
            <p>
                <a (click)="onNext()" class="letsgetstarted">LET'S GET STARTED!</a>
            </p>
        </div>
    </div>
    <br/>
</section>

