import { Component, Input, OnInit } from '@angular/core';
import { ChartOptions, ChartType } from 'chart.js';
import { Color, Label, SingleDataSet } from 'ng2-charts';
import { HubHealthModel } from 'src/app/models/hubhealth.model';

@Component({
  selector: 'app-hubhealth',
  templateUrl: './hubhealth.component.html',
  styleUrls: ['./hubhealth.component.css']
})
export class HubhealthComponent implements OnInit {
  @Input() hubHealthInfo : HubHealthModel;

  // Pie
  public pieChartOptions: ChartOptions = {
    responsive: true,
    legend: { display : false }
  };
  public pieChartLabels: Label[] = ["Active", "Inactive", "Warned", "LOA", "Probation"];;
  public pieChartData: SingleDataSet;
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [];
  public pieChartColors: Color[] = [{backgroundColor: ['#21f900','#f9a600','#f90000','#00a5f9','#848380']}];
  
  
  constructor() { }

  ngOnInit(): void {
    this.pieChartData=[this.hubHealthInfo.activePilots, this.hubHealthInfo.inactivePilots, this.hubHealthInfo.warnedPilots, this.hubHealthInfo.loaPilots, this.hubHealthInfo.probiePilots];
  }

}
