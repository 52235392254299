import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApplicationModel } from '../models/application.model';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {
  PILOTAPPS_SVC = '/pilotApps';

  apps: ApplicationModel[] = [];

  constructor(private httpClient: HttpClient) { }

  getApplications() {
    // this.hiringHubs = [];
    return this.httpClient.get(environment.API_URL + this.PILOTAPPS_SVC).pipe(map(
      (data) => {
        this.apps = data['data'];
        // for (const key in data) {
        //   if (Object.prototype.hasOwnProperty.call(data, key)) {
        //     for (const index in data[key]) {
        //       if (Object.prototype.hasOwnProperty.call(data[key], index)) {
        //         const element = data[key][index];
        //         this.hiringHubs.push(element);
        //       }
        //     }
        //   }
        // }
        return this.apps;
      }
    ))
  }

  rejectApplication(appId,payload) {
    console.log(payload);
    let url = environment.API_URL + this.PILOTAPPS_SVC + '/' + appId + '/reject';
    console.log(url);
    return this.httpClient.put(url, payload);
  }

  acceptApplication(appId,payload) {
    console.log(payload);
    let url = environment.API_URL + this.PILOTAPPS_SVC + '/' + appId + '/accept';
    console.log(url);
    return this.httpClient.put(url, payload);
  }
}
