import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';
import { EventModel } from 'src/app/models/event.model';
import { AuthService } from 'src/app/services/auth.service';
import { DataSharingService } from 'src/app/services/datasharing.service';
import { EventService } from 'src/app/services/event.service';

@Component({
  selector: 'app-eventsummary',
  templateUrl: './eventsummary.component.html',
  styleUrls: ['./eventsummary.component.css']
})
export class EventsummaryComponent implements OnInit {
  hasEvents : boolean = true;
  events : EventModel[] = [];
  private activatedDSS: Subscription;
  isAuthenticated=false;

  constructor(private router : Router, private eventService : EventService, private authService : AuthService  ) {

   }

  ngOnInit(): void {
    this.hasEvents=false;
    this.loadEvents();
    this.isAuthenticated = this.authService.isAuthenticated();
  }

  loadEvents() {
    this.eventService.getEventSummary().subscribe(
      (responseData) => {
        this.events = responseData;
        console.log(this.events);

        this.hasEvents=true;
        if (this.events.length==0) this.hasEvents=false;
      },
      (error) => {
        this.hasEvents=false;
        if (error.status = 404) {
        } else {
          this.router.navigate(['/techerror']);
        }
      }
    );
  }

}
