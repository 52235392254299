import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DnrService {
  DNR_SVC = '/dnrs/';

  constructor(private httpClient : HttpClient) { }

  removeMemberFromDNRList(dnrToRemove) {
    let url = environment.API_URL + this.DNR_SVC + dnrToRemove;

    return this.httpClient.delete(url).pipe(map(
      (data) => {
        return data;
      }
    ));
  }
}
