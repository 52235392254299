import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OrgChartPositionModel } from 'src/app/models/orgchartposition.model';
import { StaffService } from 'src/app/services/staff.service';

@Component({
  selector: 'app-orgchart',
  templateUrl: './orgchart.component.html',
  styleUrls: ['./orgchart.component.css']
})
export class OrgchartComponent implements OnInit {
  loading : boolean = false;
  hasPositions : boolean = false;
  orgPositions : OrgChartPositionModel[] = [];
  ceoOrgPosition : OrgChartPositionModel;
  execVPPositions : OrgChartPositionModel[] = [];
  vpPositions : OrgChartPositionModel[]=[];
  hmPositions : OrgChartPositionModel[]=[];
  ahmPositions : OrgChartPositionModel[] = [];

  constructor(private staffService : StaffService, private router : Router) { }

  ngOnInit(): void {
    this.loading=true;
    this.hasPositions=false;
    this.loadStaffPositions();
  }

  loadStaffPositions() {
    this.staffService.getOrgChart().subscribe(
      (responseData) => {
        this.orgPositions = responseData["data"];

        this.orgPositions.forEach(element => {
          if (element.attributes.longTitle.indexOf("Chief Executive Officer")==0) {
            this.ceoOrgPosition = element;
          }
          if (element.attributes.longTitle.indexOf("Executive Vice President")==0) {
            this.execVPPositions.push(element);
          } else {
            if (element.attributes.longTitle.indexOf("Vice President")==0 || element.attributes.longTitle.indexOf("Assistant")==0) {
              this.vpPositions.push(element);
            }
          }
          if (element.attributes.longTitle.indexOf("Hub Manager")>0) {
            this.hmPositions.push(element);
          }
          if (element.attributes.shortTitle.lastIndexOf("AHM")==3 && element.attributes.shortTitle.length==6) {
            this.ahmPositions.push(element);
          }
        });

        console.log(this.ceoOrgPosition);
        console.log(this.execVPPositions);
        console.log(this.vpPositions);
        console.log(this.hmPositions);

        this.loading = false;
        this.hasPositions=true;
      },
      (error) => {
        this.loading = false;
        this.hasPositions=false;
        if (error.status = 404) {
        } else {
          this.router.navigate(['/techerror']);
        }
      }
    );
  }

}
