<div *ngIf="isMapLoaded">
    <hr class="aa-line" />
    <div class="row ml-3 mr-5">
        <span class="leftnavTitle"><u>Pilots Online: {{pilotCount}} ATC Online: {{atcCount}}</u></span>
    </div>
    <div class="row ml-0 mr-0 pl-0">
        <div class="col-md-3 mt-0 ml-0 ">
            <div class="row " *ngIf="hasPilots">
                <span class="leftnavTitle ml-3">Pilots</span>
            </div>
            <div class="row" style="cursor:pointer;" *ngFor="let pilot of pilotFlights" (mouseover)="onMouseOver(pilot)" (mouseout)="onMouseOut(pilot)" (click)="onMouseClick(pilot)">
                <div class="col-lg-12 ml-3" style="display: block !important">
                    <b>{{pilot.attributes.callsign}}</b>&nbsp;{{pilot.attributes.name}}
                    <br />
                    <span style="font-size:.75rem; color:brown">
                        {{pilot.attributes.departureAirport}} to {{pilot.attributes.destinationAirport}} ({{pilot.attributes.aircraftType}})
                    </span>
                </div>
            </div>
            <div class="row " *ngIf="hasATC">
                <span class="leftnavTitle ml-3">ATC</span>
            </div>
            <div class="row" *ngFor="let controller of liveControllers">
                <div class="col-lg-12 ml-3" style="display: block !important">
                    <b>{{controller.attributes.callsign}}</b>&nbsp;{{controller.attributes.name}}
                    <br />
                    <span style="font-size:.75rem; color:brown">
                        {{controller.attributes.frequency}}
                    </span>
                </div>
            </div>
        </div>
        <div class="col-md-9 mt-2 mb-2 pr-3">
            <div class="aspect-ratio-box">
                <div class="aspect-ratio-box-inside">
                    <div class="flexbox-lefting shadow">
                        <google-map [options]="gOptions" width="100%" height="750px" [center]="center" *ngIf="isMapLoaded">
                            <map-marker id="{{marker.id}}marker" #someElem="mapMarker" *ngFor="let marker of markers" [position]="marker.position" [label]="marker.label" [title]="marker.title" [options]="marker.options" (mapMouseout)="onMapMouseOut(marker)" (mapMouseover)="onMapMouseOver(marker)" (mapClick)="onMarkerClick(someElem,marker)" (click)="onMarkerClick(someElem,marker)">
                            </map-marker>
                            <map-polyline #lineElem *ngFor="let line of lines" [options]="line"></map-polyline>

                            <map-info-window>
                                <div *ngIf="isFlightInfoWindow">
                                    <div style="width:300px">
                                        <b>{{infoContent.attributes.callsign}} -- {{infoContent.attributes.name}}</b>
                                        <hr class="aa-line" />
                                        <div class="container-fluid">
                                            <div class="row">
                                                <div class="col-sm-4"><b>From</b></div>
                                                <div class="col-sm-4"><b>To</b></div>
                                                <div class="col-sm-4"><b>Aircraft</b></div>
                                            </div>
                                            <div class="row">
                                                <div class="col-sm-4">{{infoContent.attributes.departureAirport}}</div>
                                                <div class="col-sm-4">{{infoContent.attributes.destinationAirport}}</div>
                                                <div class="col-sm-4">{{infoContent.attributes.aircraftType}}</div>
                                            </div>
                                        </div>
                                        <hr class="sexy-narrow-line" />
                                        <div class="container-fluid">
                                            <div class="row">
                                                <div class="col-sm-4"><b>Altitude</b></div>
                                                <div class="col-sm-4"><b>Heading</b></div>
                                                <div class="col-sm-4"><b>Airpeed</b></div>
                                            </div>
                                            <div class="row">
                                                <div class="col-sm-4">{{infoContent.attributes.altitude}}</div>
                                                <div class="col-sm-4">{{infoContent.attributes.heading}}</div>
                                                <div class="col-sm-4">{{infoContent.attributes.groundSpeed}}</div>
                                            </div>
                                        </div>
                                        <hr class="sexy-narrow-line" />
                                        <div class="container-fluid">
                                            <div class="row">
                                                <div class="col-sm-12"><b>Route: </b> {{infoContent.attributes.route}}</div>
                                            </div>
                                            <div class="row">
                                                <div class="col-sm-12"><b>Remarks:</b> {{infoContent.attributes.remarks}}</div>
                                            </div>
                                        </div>
                                        <!-- <br/>
                                        <hr class="aa-line"/>
                                        <a href="https://vatstats.net/pilots/{{infoContent.id}}" target="_blank">View on VatStats</a> -->
                                    </div>
                                </div>
                                <div *ngIf="!isFlightInfoWindow">
                                    <div style="width:450px">
                                        <div class="container-fluid">
                                            <div class="row">
                                                <span class="airportName">{{infoContent.airportId}} Information</span>
                                            </div>
                                            <div class="row">
                                                <span>{{infoContent.airportName}}, {{infoContent.airportCity}}, {{infoContent.airportCountry}}</span>
                                            </div>
                                            <hr class="aa-line row-fluid" />
                                            <div class="row">
                                                <p><b>METAR</b>: {{infoContent.weather}}</p>
                                            </div>
                                        </div>
                                        <div class="container-fluid">
                                            <div class="row">
                                                <div class="col-md-6" *ngIf="infoContent.hasInbounds">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <b><u>Inbounds:</u></b>
                                                            <div class="row-fluid" *ngFor="let inbound of infoContent.inbounds">
                                                                {{inbound.callsign}} from {{inbound.departureAirport}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6" *ngIf="infoContent.hasOutbounds">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <b><u>Outbounds:</u></b>
                                                            <div class="row-fluid" *ngFor="let outbound of infoContent.outbounds">
                                                                {{outbound.callsign}} to {{outbound.destinationAirport}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </map-info-window>
                        </google-map>
                    </div>
                </div>
            </div>
            <span class="text-muted mt-1" style="font-size:.75rem">As of: {{lastUpdateTime}} </span>
        </div>
    </div>
</div>