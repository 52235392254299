<div *ngIf="!loading">
    <div class="row m-0">
        <div class="col-md-12">
            <div *ngIf="notams.length>0" class="mt-2">
                <app-notam *ngFor="let notam of notams" [notam]="notam"></app-notam>
            </div>
        </div>
    </div>

    <div class="row ml-0 mr-0 pl-0">
        <div class="col-md-3 mt-0 ml-0 ">
            <div class="row leftnavRow ml-0 pr-3">
                <app-goalsummary class="p-0 w-85"></app-goalsummary>
            </div>
            <div class="row leftnavRow ml-0 pr-3">
                <app-eventsummary></app-eventsummary>
            </div>
            <div class="row leftnavRow ml-0">
                <app-leadingedge></app-leadingedge>
            </div>
            <div class="row leftnavRow ml-0 mb-0 w-95" style="font-size:.75rem;" *ngIf="hasFD">
                <p (click)="isCollapsedHorizontal = !isCollapsedHorizontal" style="cursor:pointer" class="leftnavTitle">
                    {{featuredDestination.title}} <i class="fas " [ngClass]="{'fa-arrow-alt-circle-left' : isCollapsedHorizontal, 'fa-arrow-alt-circle-right' : !isCollapsedHorizontal}"></i>
                </p>
            </div>
        </div>
        <div class="col-md-9 mt-2 mb-2 p-0 pr-3">
            <app-carousel [welcomeMessage]="welcomeMessage" *ngIf="!isCollapsedHorizontal"></app-carousel>
            <div class="p-5" *ngIf="isCollapsedHorizontal" style="padding:20px 20px 20px 20px; height:470px;border:1px solid silver;box-shadow: 8px 8px 10px #aaa">
                <h2>{{featuredDestination.title}}</h2>
                <hr class="aa-line">
                <p [innerHtml]="featuredDestination.message"></p>
            </div>
        </div>
    </div>

    <app-hmflightmap></app-hmflightmap>
</div>

<span *ngIf="loading">
    <div class="d-flex justify-content-center " style="margin-top: 10em;margin-bottom: 10em;">
        <div class="spinner-border text-primary" style="width: 6rem; height: 6rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</span>