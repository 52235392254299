import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PilotLandingModel } from 'src/app/models/pilot-landing.model';
import { PilotDetailsModel } from 'src/app/models/pilotdetails.model';
import { PirepModel } from 'src/app/models/pirep.model';
import { ProfileModel } from 'src/app/models/profile.model';
import { AuthService } from 'src/app/services/auth.service';
import { PilotService } from 'src/app/services/pilot.service';

@Component({
  selector: 'app-hrlogbookprofile',
  templateUrl: './hrlogbookprofile.component.html',
  styleUrls: ['./hrlogbookprofile.component.css']
})
export class HrlogbookprofileComponent implements OnInit {
  @Input() profilePilot : ProfileModel;
  @Input() profilePilotId : number;
  @Input() staffMember : PilotDetailsModel;
  @Input() perms : any;

  pilotDetails: PilotLandingModel;
  isValidPilot: boolean = false;
  loading: boolean = false;
  // hasLastFlight: boolean = true;
  pireps: PirepModel[] = [];
  links: any[] = [];
  pirepCount : number = 0;
  nextPageId: number = -1;
  prevPageId: number = 0;

  constructor(private pilotService: PilotService, private authService: AuthService, private route: ActivatedRoute) {

  }

  ngOnInit(): void {
    if (this.authService.isAuthenticated()) {
      this.loading = true;
      console.log(this.perms);
      this.loadPireps(0);
    }
  }

  loadPireps(pageToLoad : number) {
    this.loading = true;
    let __pilotService = this.pilotService.getPireps(this.profilePilotId);
    if (pageToLoad>0) {
      __pilotService = this.pilotService.getPireps(this.profilePilotId, pageToLoad);
    }
    __pilotService.subscribe(
      (responseData) => {
        //TODO build a links model instead of the hokey array
        this.pireps = responseData['data'];
        console.log(this.pireps);
        this.links = responseData['links'];
        this.pirepCount = this.links['pirepCount'];
        this.nextPageId = this.links['nextPage'];
        this.prevPageId = this.links['prevPage'];

        this.loading = false;
      },
      (error) => {
        if (error.status=404) {
          this.pireps = [];
        }
        this.loading = false;
    });
  }
  onPageChange(returnMessage: {pageToLoad: number}) {
    console.log(returnMessage.pageToLoad);
    this.loadPireps(returnMessage.pageToLoad);
  }


}
