import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-home-content',
  templateUrl: './home-content.component.html',
  styleUrls: ['./home-content.component.css']
})
export class HomeContentComponent implements OnInit {
  @Output() newPageName = new EventEmitter<string>();

  constructor() { }

  public showSkipButton = false;
  ngOnInit(): void {
    if (!environment.production) {
      this.showSkipButton = true;
    }
  }

  onNext() {
    this.newPageName.emit("suexam");
  }

  // This method allows you to go directly to the signup form, bypassing the quiz.
  // The button is only available when running locally
  skipToForm() {
    this.newPageName.emit("susignup");
  }
}
