import { Attribute, Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
  selector: '[appCheckEquals][formControlName],[appCheckEquals][formControl],[appCheckEquals][ngModel]',
  providers: [{provide: NG_VALIDATORS, useExisting: AppCheckEqualsDirective, multi: true}]  
})
export class AppCheckEqualsDirective implements Validator {
  constructor( @Attribute('appCheckEquals') public appCheckEquals: string) {}

  validate(c: AbstractControl): { [key: string]: any } {
      // self value (e.g. retype password)
      let v = c.value;

      // control value (e.g. password)
      let e = c.root.get(this.appCheckEquals);
      // value not equal
      if (e && v !== e.value) return {
        appCheckEquals: false
      }
      return null;
  }

}
