<div class="d-flex justify-content-center">
    <div class="card shadow rounded-top rounded-lg mt-2" *ngIf="!loading">
        <div class="card-header panel-american-blue-grey-red">
            <div class="row">
                <div class="col-md-12">
                    Upcoming Events
                </div>
            </div>
        </div>
        <div class="card-body " *ngIf="!hasEvents && !loading">
            <h3>Wow! We surprisingly have no events in the systems right now!</h3>
        </div>
        <div class="card-body" *ngIf="hasEvents">
            <div class="row " *ngFor="let event of events">
                <div class="col-md-12">
                    <div class="card shadow mt-2">
                        <div class="card-header panel-american-coral ">
                            <div class="row">
                                <div class="col-12">
                                    [{{event.attributes.startDate | date:'EEE - MMM dd, yyyy' }} at {{(event.attributes.startTime.length>5)? (event.attributes.startTime | slice:0:5):(event.attributes.startTime)}}z]
                                    <a href="{{event.attributes.url}}" target="_blank" *ngIf="event.attributes.url!=null && event.attributes.url!=''"><b>{{event.attributes.name}}</b></a>
                                    <b *ngIf="!event.attributes.url">{{event.attributes.name}}</b>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-12" *ngIf="event.attributes.bannerUrl">
                                    <div class="aspect-ratio-box">
                                        <div class="aspect-ratio-box-inside">
                                            <div class="flexbox-centering">
                                                <img class="aspect-ratio-box-inside" src="{{event.attributes.bannerUrl}}">
                                                {{event.attributes.bannerUrl}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                    <label>Date:</label> {{event.attributes.startDate}}
                                </div>
                                <div class="col-sm-12 well well-sm">
                                    <label>Time:</label> {{(event.attributes.startTime.length>5)? (event.attributes.startTime | slice:0:5):(event.attributes.startTime)}}z
                                </div>
                                <div class="col-sm-12 well well-sm">
                                    <label>Duration:</label> {{event.attributes.duration}}hrs
                                </div>
                                <div class="col-sm-12 well well-sm" ng-if="vm.isAuthenticated">
                                    <label>Tracking Tag:</label> {{event.attributes.trackingTag}}
                                </div>
                                <div class="col-sm-12">
                                    <label for="txtDescription">Description:</label><br />
                                    <div class="border border-success rounded p-3">
                                        <p [innerHTML]="event.attributes.description"></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container text-center" *ngIf="loading">
    <div class="lds-hourglass"></div>
    <p>Loading...</p>
</div>