<div class="row border rounded-lg">
    <div class="col-md-12">
        <span class="text-info font-weight-bold">
            Rejected Pirep {{rp.attributes.pirepId}} Details:
        </span>
    </div>
    <div class="col-md-4">
        <label>Pilot's VATSIM ID:</label> <a href="http://stats.vatsim.net/search_id.php?id={{rp.attributes.pilotVatsimId}}" target="_blank"> {{rp.attributes.pilotVatsimId}}</a>
    </div>
    <div class="col-md-4 ">
        <label class="horizontal">From:</label> {{rp.attributes.depAirport}}<br />
        <label class="horizontal">To:</label> {{rp.attributes.arrAirport}}
    </div>
    <div class="col-md-4">
        <label>Flight Date:</label> {{rp.attributes.flightDate}}<br />
        <label>Route:</label> {{rp.attributes.route}}
    </div>
</div>
<div class="row border rounded-lg mt-2">
    <div class="col-md-12">
        <span class="text-info font-weight-bold">
            Rejection Details:
        </span>
    </div>
    <div class="col-md-12">
        <label>Rejecting Manager:</label> {{rp.attributes.rejectorStaff}}
    </div>
    <div class="col-md-12">
        <label>Rejection Reason:</label> {{rp.attributes.reason}}
    </div>
    <!-- <div class="col-md-12">
        <label>Date:</label> {{rp.attributes.rejectDate}}
    </div> -->
</div>
<form name="rpDetailForm" class="css-form" #rpDetailForm="ngForm" >
    <div class="row mt-3 border rounded-lg mt-2">
        <div class="col-md-12">
            <div class="form-group">
                <label for="reason">Accept or Reverse Reasoning:</label>
                <input type="text" id="reason" name="reason" class="form-control" ngModel #reason="ngModel" required minlength="10" maxlength="100" pattern="^[\w\-,.?#!()\s]+$" />
                <div class="alert alert-danger" *ngIf="reason.invalid && (reason.dirty || reason.touched)">
                    <div *ngIf="reason.errors?.required">
                        You must enter a reason for your LOA.
                    </div>
                    <div *ngIf="reason.errors?.minlength || reason.errors?.maxlength || reason.errors?.pattern">
                        Reason is invalid: must be 10 to 100 alphanumeric characters plus -,.?#!()
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12 mb-2">
            <button type="button" class="btn btn-danger" [disabled]="reason?.invalid" (click)="acceptRejection(rpDetailForm.value)" title="Permanently deletes the PIREP">
                Accept Rejection
            </button>
            <button type="button" class="btn btn-success float-right" [disabled]="reason?.invalid" (click)="reverseRejection(rpDetailForm.value)" title="Restores Rejected PIREP into Pilot's logbook.">
                Reverse Rejection
            </button>
        </div>
    </div>
</form>

<app-avamodal [modalName]="modalName" [modalMessage]="modalMessage" [modalTitle]="modalTitle" [showMiniClose]="showMiniClose" [showCancelButton]="showCancelButton" [modalStatus]="modalStatus" [buttonText]="'Ok'" [modalPurpose]="modalPurpose" (modalClosed)="onModalClosed($event)"></app-avamodal>