<div class="container-fluid no-gutters" *ngIf="!loading">
    <form name="pirepForm" class="css-form" (ngSubmit)="onSubmit(pirepForm.value)" #pirepForm="ngForm"
        style="margin: 5px 10px 30px 10px">
        <div class="card shadow rounded-top rounded-lg">
            <div class="card-header panel-american-blue">
                <div class="row">
                    <div class="col-6">
                        <span *ngIf="isEditing">
                            EDIT PIREP: {{pirepId}}
                        </span>
                        <span *ngIf="!isEditing">
                            NEW PIREP
                        </span>
                    </div>
                    <div class="col-6 d-flex justify-content-end" *ngIf="pirep.acars.length>0">
                        <span>{{pirep.acars}}</span>
                    </div>
                </div>
            </div>
            <div class="card-body ">
                <div class="row ">
                    <div class="col-md-4 no-gutters">
                        <div class="form-group">
                            <label for="flightDate">Flight Date:</label>
                            <div class="input-group ngb-dp-today ">
                                <input class="form-control" placeholder="yyyy-mm-dd" name="flightDate"
                                    [(ngModel)]="flightDate" ngbDatepicker #d="ngbDatepicker" required>
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d.toggle()"
                                        type="button"><i class="far fa-calendar-alt"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col .offset-md-2">
                        <div class="form-group">
                            <label for="flightDuration" disabled>Duration:</label>
                            <ngb-timepicker name="flightDuration" [(ngModel)]="flightDuration" required
                                [spinners]="false" disabled></ngb-timepicker>
                            <div class="alert alert-danger"
                                *ngIf="!isValidDuration && (pirepForm.dirty || pirepForm.touched)">
                                You must enter a valid departure and arrival times.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row ">
                    <div class="col-md-4">
                        <div class="card ">
                            <div class="card-header bg-ava-info">Departure</div>
                            <div class="card-body">
                                <div class="form-group">
                                    <label for="depAirport">Airport:</label>
                                    <input type="text" id="depAirport" name="depAirport" class="form-control"
                                        [(ngModel)]="pirep.depAirport" #depAirport="ngModel" minlength="4" maxlength="4"
                                        pattern="^[a-zA-Z0-9]*$" required
                                        oninput="this.value = this.value.toUpperCase()" />
                                    <div class="alert alert-danger"
                                        *ngIf="depAirport.invalid && (depAirport.dirty || depAirport.touched)">
                                        <div *ngIf="depAirport.errors?.required">
                                            You must enter a departure airport.
                                        </div>
                                        <div
                                            *ngIf="depAirport.errors?.minlength || depAirport.errors?.maxlength || depAirport.errors?.pattern">
                                            Airport is invalid: length must be 4 and only alphanumerics.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="depTime">Time (UTC):</label>
                                    <ngb-timepicker name="depTime" [(ngModel)]="depTime" [spinners]="false" (change)="onDateTimeChange()">
                                    </ngb-timepicker>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card  ml-1">
                            <div class="card-header  bg-ava-info">Arrival</div>
                            <div class="card-body">
                                <div class="form-group">
                                    <label for="arrAirport">Airport:</label>
                                    <input type="text" id="arrAirport" name="arrAirport" class="form-control"
                                        [(ngModel)]="pirep.arrAirport" #arrAirport="ngModel" minlength="4" maxlength="4"
                                        pattern="^[a-zA-Z0-9]*$" required
                                        oninput="this.value = this.value.toUpperCase()" />
                                    <div class="alert alert-danger"
                                        *ngIf="arrAirport.invalid && (arrAirport.dirty || arrAirport.touched)">
                                        <div *ngIf="arrAirport.errors?.required">
                                            You must enter an arrival airport.
                                        </div>
                                        <div
                                            *ngIf="arrAirport.errors?.minlength || arrAirport.errors?.maxlength || arrAirport.errors?.pattern">
                                            Airport is invalid: length must be 4 and only alphanumerics.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="arrTime">Time (UTC):</label>
                                    <ngb-timepicker name="arrTime" [(ngModel)]="arrTime" required [spinners]="false"
                                        (change)="onDateTimeChange()">
                                    </ngb-timepicker>
                                    <div class="alert alert-danger"
                                        *ngIf="arrTime.invalid && (arrTime.dirty || arrTime.touched)">
                                        <div *ngIf="arrTime.errors?.required">
                                            You must enter an arrival airport.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card  ml-1">
                            <div class="card-header  bg-ava-info">Aircraft</div>
                            <div class="card-body">
                                <div class="form-group">
                                    <label for="equipment">Aircraft:</label>
                                    <select name="equipment" id="equipment" class="form-control"
                                        [(ngModel)]="pirep.equipment" #equipment="ngModel" required>
                                        <option *ngFor="let equipment of equipmentList;let i = index"
                                            [value]="equipment.equipmentId">
                                            {{equipment.description}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-4">
                        <div class="card  mt-1">
                            <div class="card-header  bg-ava-info">Details</div>
                            <div class="card-body">
                                <div class="form-group">
                                    <label for="passengers">Souls:</label>
                                    <input type="number" id="passengers" name="passengers" class="form-control"
                                        [(ngModel)]="pirep.passengers" #passengers="ngModel" minlength="1" maxlength="3"
                                        min="1" max="999" required />
                                    <div class="alert alert-danger"
                                        *ngIf="passengers.invalid && (passengers.dirty || passengers.touched)">
                                        <div
                                            *ngIf="passengers.errors?.minlength || passengers.errors?.maxlength || passengers.errors?.min || passengers.errors?.max">
                                            Souls onboard should be between 1 and 999.
                                        </div>
                                        <div *ngIf="passengers.errors?.required">
                                            You must enter the number of passengers on board
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="fuelConsumed">Fuel Used in Pounds:</label>
                                    <input type="number" id="fuelConsumed" name="fuelConsumed" class="form-control"
                                        [(ngModel)]="pirep.fuelConsumed" #fuelConsumed="ngModel" minlength="3"
                                        maxlength="7" min="100" max="999999" required />
                                    <div class="alert alert-danger"
                                        *ngIf="fuelConsumed.invalid && (fuelConsumed.dirty || fuelConsumed.touched)">
                                        <div
                                            *ngIf="fuelConsumed.errors?.minlength || fuelConsumed.errors?.maxlength || fuelConsumed.errors?.min || fuelConsumed.errors?.max">
                                            Fuel used should be between 100 and 999999.
                                        </div>
                                        <div *ngIf="fuelConsumed.errors?.required">
                                            You must enter the fueld consumed on this flight
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="cargoWeight">Cargo in Pounds:</label>
                                    <input type="number" id="cargoWeight" name="cargoWeight" class="form-control"
                                        [(ngModel)]="pirep.cargoWeight" #cargoWeight="ngModel" minlength="1"
                                        maxlength="7" min="0" max="999999" required />
                                    <div class="alert alert-danger"
                                        *ngIf="cargoWeight.invalid && (cargoWeight.dirty || cargoWeight.touched)">
                                        <div
                                            *ngIf="cargoWeight.errors?.minlength || cargoWeight.errors?.maxlength || cargoWeight.errors?.min || cargoWeight.errors?.max">
                                            Fuel used should be between 0 and 999999.
                                        </div>
                                        <div *ngIf="cargoWeight.errors?.required">
                                            You must enter the cargo weight flown on this flight
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="card ml-1 mt-1">
                            <div class="card-header  bg-ava-info">Comments & Route</div>
                            <div class="card-body">
                                <div class="form-group">
                                    <label for="comments">Comments:</label>
                                    <input type="text" id="comments" name="comments" class="form-control"
                                        [(ngModel)]="pirep.comments" #comments="ngModel" pattern="^[\w\-,.'?#!()\s]+$"
                                        maxlength="100" />
                                    <div class="alert alert-danger"
                                        *ngIf="comments.invalid && (comments.dirty || comments.touched)">
                                        <div *ngIf="comments.errors?.maxlength">
                                            You can only have 100 chars of comments
                                        </div>
                                        <div *ngIf="comments.errors?.pattern">
                                            You have entered an invalid character
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="route">Route:</label>
                                    <textarea class="form-control" id="route" name="route" [(ngModel)]="pirep.route"
                                        rows="4" #route="ngModel" pattern="^[\w\-#()\s]+$" required minlength="3"
                                        maxlength="750">
                                        </textarea>
                                    <div class="alert alert-danger"
                                        *ngIf="route.invalid && (route.dirty || route.touched)">
                                        <div *ngIf="route.errors?.required">
                                            You must enter a valid route.
                                        </div>
                                        <div *ngIf="route.errors?.minlength || route.errors?.maxlength">
                                            Minimum of 3 chars | Maximum 750
                                        </div>
                                        <div *ngIf="route.errors?.pattern">
                                            You have entered an invalid character
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12 ">
                        <div class="button-group float-right">
                            <button type="button" class="btn btn-danger" id="btnDelete" *ngIf="isEditing"
                                (click)="onDeleteClick()">Delete</button>
                            <button type="button" class="btn btn-light" id="btnCancel"
                                (click)="onCancelClick()">Cancel</button>
                            <button type="submit" class="btn btn-primary" *ngIf="canUpdate"
                                [disabled]="!pirepForm.valid" id="btnSubmit">{{submitButtonText}}</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </form>
    <div class="card shadow rounded-top rounded-lg" style="margin: 5px 10px 30px 10px" *ngIf="isEditing && !loading">
        <div class="card-header panel-american-blue">
            <div class="row">
                Flight Map & Performance
            </div>
        </div>
        <div class="card-body ">
            <div class="row ">
                <div class="col no-gutters">
                    <section id="flightmap" >
                        <div class="card rounded-0">
                            <div class="card-body">
                                <app-flightmap [flightDetails]='flightDetails'></app-flightmap>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <div class="row ">
                <div class="col no-gutters">
                    <section id="flightmap" >
                        <div class="card rounded-0">
                            <div class="card-body">
                                <app-flightchart [flightDetails]='flightDetails'></app-flightchart>
                            </div>
                        </div>
                    </section>
                </div>
            </div>            
        </div>
    </div>
</div>

    <app-avamodal [modalName]="modalName" [modalMessage]="modalMessage" [modalTitle]="modalTitle" [showMiniClose]="showMiniClose"
        [showCancelButton]="showCancelButton" [modalStatus]="modalStatus" [buttonText]="'Ok'"
        [modalPurpose]="modalPurpose" (modalClosed)="onModalClosed($event)"></app-avamodal>

    <span *ngIf="loading">
        <div class="d-flex justify-content-center " style="margin-top: 10em;margin-bottom: 10em;">
            <div class="spinner-border text-primary" style="width: 6rem; height: 6rem;" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </span>