<div class="container mt-2 mb-3">
    <div class="row">
        <div class="col-md-12">
            <div class="card shadow rounded-top rounded-lg">
                <div class="card-header panel-american-coral">
                    <div class="row">
                        <div class="col-10 mt-2">
                            <span >Manage Applications</span>
                        </div>
                    </div>
                </div>
                <div class="card-body" *ngIf="!loading ">
                    <div class="row" *ngIf="!hasApps ">
                        <div class="col-sm-12">
                            <p>I'm sorry but there are no Applications at this time.</p>
                        </div>
                    </div>
                    <div *ngIf="hasApps">
                        <div class="card mt-2 " *ngFor="let app of applications" >
                            <div class="card-header bg-ava-info">
                                <div class="row">
                                    <div class="col-sm-8">
                                        {{app.attributes.firstName}} {{app.attributes.lastName}} - App #{{app.attributes.appId}}
                                    </div>
                                    <div class="col-sm-4">
                                        <button class="btn btn-info btn-sm float-right" type="button" data-toggle="collapse" 
                                            [attr.data-target]="'#appDetailCollapse-' + app.attributes.appId"
                                            aria-expanded="true" aria-controls="appDetailCollapse-{{app.attributes.appId}}">
                                            <i class="fas fa-binoculars"></i> Review
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="collapse show" id="appDetailCollapse-{{app.attributes.appId}}">
                                <div class="card-body">                        
                                    <app-applicationdetail [application]="app" (onApplicationUpdate)="onApplicationUpdate($event)"></app-applicationdetail>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </div>
    </div>
</div>

<!-- 
<span *ngIf="loading">
    <div class="d-flex justify-content-center " style="margin-top: 10em;margin-bottom: 10em;">
        <div class="spinner-border text-primary" style="width: 6rem; height: 6rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</span> -->

<div class="container text-center" *ngIf="loading">
    <div class="lds-hourglass" ></div>
    <p>Loading...</p>
</div>