import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HubHealthModel } from '../models/hubhealth.model';
import { VAHealthModel } from '../models/vahealth.model';

@Injectable({
  providedIn: 'root'
})
export class HubRosterService {
  HUBROSTERS_SVC = '/hubrosters';
  HRHUBROSTERS_SVC = '/hrhubrosters/';
  HRHUBLINKS_SVC = '/hrhublinks';

  constructor(private httpClient: HttpClient) { }

  get(hubId: number) {
    let url = environment.API_URL + this.HUBROSTERS_SVC;
    if (hubId > 0) {
      url += '/' + hubId;
    }
    console.log("HubRoster URL: " + url);
    return this.httpClient.get(url).pipe(map(
      (data) => {
        // for (const key in data) {
        //   if (Object.prototype.hasOwnProperty.call(data, key)) {
        //     for (const index in data[key]) {
        //       if (Object.prototype.hasOwnProperty.call(data[key], index)) {
        //         const element = data[key][index];
        //         // this.hiringHubs.push(element);
        //       }
        //     }
        //   }
        // }
        return data;
      }
    ))
  }

  getHRRoster(hubId: number) {
    let url = environment.API_URL + this.HRHUBROSTERS_SVC +  hubId;

    console.log("HRHubRoster URL: " + url);
    return this.httpClient.get(url).pipe(map(
      (data) => {
        return data;
      }
    ))
  }

  getHRHubLinks() {
    let url = environment.API_URL + this.HRHUBLINKS_SVC;

    console.log("HRHubLinks URL: " + url);
    return this.httpClient.get(url).pipe(map(
      (data) => {
        return data;
      }
    ))
  }
}
