import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-signupexam',
  templateUrl: './signupexam.component.html',
  styleUrls: ['./signupexam.component.css']
})
export class SignupexamComponent implements OnInit {
  @Output() newPageName = new EventEmitter<string>();
  isExamPassed : boolean = false;
  ageToJoinSuccess : boolean = true;
  onlineNetworkSuccess : boolean = true;
  flyReqsSuccess : boolean = true;
  flightHoursSuccess : boolean = true;
  callsignReqSuccess : boolean = true;
  hubsReqSuccess : boolean = true;


  @ViewChild('examForm') examForm : NgForm;

  constructor(@Inject(DOCUMENT) private document: Document) { }

  ngOnInit(): void {
    // this.document.body.scrollTop = 1;
    window.scroll(0,0);
  }

  onSubmit( examForm : NgForm) {
    if (examForm.dirty) {
      this.isExamPassed = this.gradeExam(examForm.value);
    }
  }

  onNext() {
    this.newPageName.emit("susignup");
  }

  gradeExam( examForm : { agetojoin : number, onlineNetwork : number, flyReqs : number, flightHours : number, callsignReq : number, hubsReq : number}) {
    console.log(examForm);
    let isExamPassed : boolean = true;

    this.ageToJoinSuccess = true;
    if (examForm.agetojoin != 15) {
      this.ageToJoinSuccess = false;
      isExamPassed = false;
    }
    this.onlineNetworkSuccess = true;
    if (examForm.onlineNetwork != 1 ) {
      this.onlineNetworkSuccess = false;
      isExamPassed = false;
    }
    this.flyReqsSuccess = true;
    if (examForm.flyReqs != 2 ) {
      this.flyReqsSuccess = false;
      isExamPassed = false;
    }
    this.flightHoursSuccess = true;
    if (examForm.flightHours != 400 ) {
      this.flightHoursSuccess = false;
      isExamPassed = false;
    }
    this.callsignReqSuccess = true;
    if (examForm.callsignReq != 4 ) {
      this.callsignReqSuccess = false;
      isExamPassed = false;
    }
    this.hubsReqSuccess = true;
    if (examForm.hubsReq != 9 ) {
      this.hubsReqSuccess = false;
      isExamPassed = false;
    }
    return isExamPassed;
  }
}
