import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FlightModel } from 'src/app/models/flight.model';
import { AuthService } from 'src/app/services/auth.service';
import { DataSharingService } from 'src/app/services/datasharing.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { PilotService } from 'src/app/services/pilot.service';

@Component({
  selector: 'app-scheduleflight',
  templateUrl: './scheduleflight.component.html',
  styleUrls: ['./scheduleflight.component.css']
})
export class ScheduleflightComponent implements OnInit {
  @Output() onScheduleComplete = new EventEmitter<string>();
  @Input() flightDetails: any;
  loading : boolean = false;
  routes : string[] = [];
  selectedAcars : string[] = [];

  pilotDetails : any;
  lookedUpRoute : string = '';
  slctAcars : string = '';

  altitude : string = '';
  pilotId : number = 0;

  @Output() modalMessage: string = "";
  @Output() modalTitle: string = "";
  @Output() modalStatus: string = "";
  @Output() modalPurpose: string = "";
  @Output() showCancelButton: boolean = false;
  @Output() modalName : string = 'scheduleFlightModal';
  showMiniClose: boolean = false;

  constructor(private dispatcherService : DispatcherService, private router : Router, private authService : AuthService,
              private pilotService : PilotService, private dss : DataSharingService) { }

  ngOnInit(): void {
    this.loading =true;
    this.loading = false;
    this.pilotDetails = { callSign : '' };
    this.loadSchedule();
    this.loadPilot();
  }

  onSubmit(bookingInfo) {
    this.loading=true;
    this.bookFlight(bookingInfo);
  }

  bookFlight(bookingInfo) {
    this.dispatcherService.scheduleFlight(this.pilotId, this.flightDetails.scheduleId, bookingInfo.txtRoute,bookingInfo.slctAcars ).subscribe(
      (responseData) => {
        this.loading = false;
        this.handleScheduleFlightSuccess(responseData);
      },
      (error) => {
        //degugger;
        console.log(error.message);
        this.router.navigate(['/techerror']);
        this.loading = false;
      }
    )
  }

  handleScheduleFlightSuccess(data) {
    this.modalStatus='success';
    this.showMiniClose=true;
    this.modalTitle = 'Flight Scheduled Successfully!';
    this.modalMessage = 'Flight: ' + data.acarsId + ' was successfully booked into the ' + this.slctAcars + ' ACARS system.';
    this.modalPurpose = "confirmation";
    this.dss.showModal.next({modalName : this.modalName, showModal :true});
  }

  onModalClosed(returnMessage: {status: string, purpose: string}) {
    this.dss.showModal.next({modalName : this.modalName, showModal :false});
    this.onScheduleComplete.emit('bf');
  }

  routeSelected(routeId) {
    this.lookedUpRoute = this.routes[routeId]['route'];
    console.log(this.lookedUpRoute);
  }


  /********************************************************************
   * Service Caller
   * When the component loads we call out to see where the pilot is
   * and then load the possible destinations from that airport
   */
   loadSchedule() {
    this.dispatcherService.getSchedule(this.flightDetails.scheduleId).subscribe(
      (responseData) => {
        this.routes = responseData['schedule']['attributes']['routes'];
        this.altitude = responseData['schedule']['attributes']['altitude'];
        this.selectedAcars = responseData['acars'];
        this.slctAcars = this.selectedAcars[0];
        this.pilotId = this.authService.getPilotId();
        this.loading = false;
      },
      (error) => {
        //degugger;
        this.router.navigate(['/techerror']);
        this.loading = false;
        this.loading = false;
      }
    )
  }

  loadPilot() {
    this.pilotId = this.authService.getPilotId();

    this.pilotService.get(this.pilotId).subscribe(
      (responseData) => {
        this.pilotDetails = responseData;
        this.pilotDetails.callSign = responseData.callSign.substr(3);
        console.log(this.pilotDetails);
      },
      (error) => {
        this.loading=false;
      }
    )
  }

  routeCancel() {
    this.onScheduleComplete.emit('ff');
  }
}
