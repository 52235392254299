<div class="col-md-12">
    <div class="card shadow rounded-top">
        <div class="card-header bg-ava-info ">
            <div class="row">
                <div class="col-2">
                    #{{pirep.id}}
                </div>
                <div class="col-6 text-center ">
                    {{pirep.attributes.callsign}} - {{pirep.attributes.firstName}}
                    {{pirep.attributes.lastName}} ({{pirep.attributes.flownFor}})
                </div>
                <div class="col-sm-4">
                    <span class="float-right">
                        VATSIM ID:&nbsp;<a href="http://stats.vatsim.net/search_id.php?id={{pirep.attributes.vatsimId}}" target="_blank">{{pirep.attributes.vatsimId}}</a>
                    </span>
                </div>
            </div>
        </div>
        <div class="card-body" style="font-size: .8em">
            <div class="row">
                <div class="col-sm-3">
                    <table class="table table-condensed text-center">
                        <thead>
                            <th class="text-center">In CAT?</th>
                            <th class="text-center">Online?</th>
                            <th class="text-center">In Altitude?</th>
                        </thead>
                        <tbody>
                            <!-- <i class="fas fa-flag"></i>
                            <i class="fas fa-exclamation"></i>
                            <i class="fas fa-check"></i> -->

                            <td [ngClass]="{'cat-green' : pirep.attributes.acCat<=pirep.attributes.pilotCat, 'cat-red' : pirep.attributes.acCat>pirep.attributes.pilotCat}">
                                <i class="fas fa-check text-center" title="{{pirep.attributes.acCat}}/{{pirep.attributes.pilotCat}}" *ngIf="pirep.attributes.acCat<=pirep.attributes.pilotCat"></i>
                                <i class="fas fa-exclamation text-center" title="{{pirep.attributes.acCat}}/{{pirep.attributes.pilotCat}}" *ngIf="pirep.attributes.acCat>pirep.attributes.pilotCat"></i>
                            </td>
                            <td [ngClass]="{'cat-green' : pirep.attributes.onlineHours==pirep.attributes.hours, 'cat-red' : pirep.attributes.onlineHours!=pirep.attributes.hours}">
                                <i class="fas fa-flag text-center" title="FH: {{pirep.attributes.hours}} OH: {{pirep.attributes.onlineHours}}" *ngIf="pirep.attributes.onlineHours!=pirep.attributes.hours"></i>
                                <i class="fas fa-check text-center" title="FH: {{pirep.attributes.hours}} OH: {{pirep.attributes.onlineHours}}" *ngIf="pirep.attributes.onlineHours==pirep.attributes.hours"></i>
                                <span class="text-center" title="Before we started recording" *ngIf="pirep.attributes.wasFlownOnline==-1">N/A</span>
                            </td>
                            <td [ngClass]="{'cat-green' : pirep.attributes.inAltitude==1, 'cat-red' : pirep.attributes.inAltitude==0}">
                                <i class="fas fa-flag text-center" title="Flight started/ended above 10,000agl" *ngIf="pirep.attributes.inAltitude==0"></i>
                                <i class="fas fa-check text-center" title="Flight started & ended below 10,000agl" *ngIf="pirep.attributes.inAltitude==1"></i>
                                <span class="text-center" title="Before we started recording" *ngIf="pirep.attributes.inAltitude==-1">N/A</span>
                            </td>
                        </tbody>
                    </table>
                </div>
                <div class="col-sm-9">
                    <table class="table table-condensed text-center">
                        <thead>
                            <th class="text-center">Date</th>
                            <th class="text-center">Aircraft</th>
                            <th class="text-center">Departure</th>
                            <th class="text-center">Arrival</th>
                            <th class="text-center">Dep Time</th>
                            <th class="text-center">Arr Time</th>
                            <th class="text-center">Hours</th>
                            <th class="text-center">Online Hrs</th>
                        </thead>
                        <tbody>
                            <td>{{pirep.attributes.flightDate}}</td>
                            <td>{{pirep.attributes.ac}}</td>
                            <td>{{pirep.attributes.depAirport}}</td>
                            <td>{{pirep.attributes.arrAirport}}</td>
                            <td>{{pirep.attributes.depTime}}</td>
                            <td>{{pirep.attributes.arrTime}}</td>
                            <td [ngClass]="{'cat-red' : pirep.attributes.onlineHours!=pirep.attributes.hours}">
                                {{pirep.attributes.hours}}</td>
                            <td [ngClass]="{'cat-red' : pirep.attributes.onlineHours!=pirep.attributes.hours}">
                                {{pirep.attributes.onlineHours}}</td>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row" *ngIf="pirep.attributes.acarsId.length>0">
                <div class="col-sm-12">
                    <b>Pirep Filing Method: </b> <br />
                    {{pirep.attributes.acarsId}} <br /><br />
                </div>
            </div>
            <div class="row" *ngIf="pirep.attributes.acarsId.length==0">
                <div class="col-sm-12">
                    <b>Pirep Filing Method: </b> <br />
                    Manual <br /><br />
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <b>Pirep Comments: </b> <br />
                    <span *ngIf="pirep.attributes.comments">
                    {{pirep.attributes.comments}} <br /><br />
                </span>
                </div>
            </div>
            <div class="row" *ngIf="pirep.attributes.ofrs.length>0">
                <div class="col-sm-12">
                    <b>Flight Plan Remarks: </b> <br />
                    {{pirep.attributes.ofrs[0].remarks}} <br /><br />
                </div>
            </div>
            <div class="row" *ngIf="!loading">
                <div class="col-sm-9">
                    <div class="card shadow rounded-top rounded-lg" *ngIf="!pirep.attributes.ofrs.length>0">
                        <div class="card-header ">
                            <div class="row">
                                No Online Flight Records (OFRs) found for this flight.
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row mb-2">
                                <div class="col-md-12">
                                    <span class="ml-3" *ngFor="let statLink of pirep.statsLinks">
                                        <a href="{{statLink.href}}" target="_blank">
                                            <img src="{{statLink.meta.icon}}" alt="{{statLink.meta.text}}"
                                                height="32em" />
                                        </a>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card shadow rounded-top rounded-lg" *ngFor="let ofr of pirep.attributes.ofrs;let i=index">
                        <div class="card-header ">
                            <div class="row">
                                Online Flight Recording # {{ofr.ofrId}}
                            </div>
                        </div>
                        <div class="card-body ">
                            <app-flightchart [flightDetails]='pirep.flightDetails'>
                            </app-flightchart>
                        </div>
                    </div>
                </div>
                <div class="col-sm-3">
                    <a (click)="acceptPirep(pirep)" *ngIf="pirep.attributes.reviewState<2" title="Approve Pirep {{pirep.attributes.pirepId}}" class="btn btn-success btn-sm float-right">
                        <i class="fas fa-thumbs-up"></i> Approve
                    </a>
                    <a (click)="rejectPirep(pirep)" *ngIf="pirep.attributes.reviewState<2" title="Reject Pirep {{pirep.attributes.pirepId}}" class="btn btn-danger btn-sm float-right" style="margin-right:1em" data-toggle="modal" data-target="#exampleModal">
                        <i class="fas fa-thumbs-down"></i> Reject
                    </a>
                </div>
            </div>
        </div>
    </div>