<div class="container mt-2 mb-3">
    <div class="row">
        <div class="col-md-12">
            <div class="card shadow rounded-top rounded-lg">
                <div class="card-header panel-american-coral">
                    <div class="row">
                        <div class="col-10">
                            <span>Manage Monthly Goals</span>
                        </div>
                    </div>
                </div>
                <div class="card-body" *ngIf="!loading ">
                    <form name="goalsForm" class="css-form" #goalsForm="ngForm" (ngSubmit)="onSubmit(goalsForm.value)">
                        <div class="form-group">
                            <div class="row mt-2" *ngFor="let mth of monthNames; index as i">
                                <div class="col-sm-2">
                                    <label class="mt-2 float-right">{{mth}}:</label>
                                </div>
                                <div class="col-sm-2 ml-sm-n3">
                                    <input type="number" class="form-control" id="month{{i}}" name="month{{i}}" [(ngModel)]="goals[i].value" placeholder="0" #month{{i}}=ngModel
                                    required minlength="1" maxlength="4" max="9999" min="1" [disabled]="loading" (change)="onChange(i)">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-2">
                                    <label class="mt-2 float-right">Yearly Goal:</label>
                                </div>
                                <div class="col-sm-2 ml-n2 mt-2 font-weight-bold text-success">
                                    {{totalHours}}
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <div class="row">
                            <div [ngClass]="{'col-sm-2 || offset-sm-10 ' : !isPreview, 'col-sm-12' : isPreview}">
                                <button class="btn btn-success btn-sm float-right" id="btnSaveFDMessage">
                                    <i class="far fa-save mr-2"></i>Save
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container text-center" *ngIf="loading">
    <div class="lds-hourglass"></div>
    <p>Loading...</p>
</div>