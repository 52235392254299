import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "src/app/services/auth.service";

@Injectable({providedIn: 'root'})
export class ManagerGuard implements CanActivate {

    constructor (private authService : AuthService, private router : Router) {}
    
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        if (this.authService.isManager()) {
            return this.authService.isManager();
        } else {
            this.router.navigateByUrl('/');
            return false;
        }
    }
}