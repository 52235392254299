<form name="loaForm" class="css-form" (ngSubmit)="onSubmit(loaForm.value)" #loaForm="ngForm" *ngIf="!loading">
    <div class="row">
        <div class="col-md-6">
            <div class="form-group">
                <label for="duration">Number of Days Requested (* min. 10):</label>
                <input type="number" id="duration" name="duration" class="form-control" ngModel
                    #duration="ngModel" required minlength="1" maxlength="3" min="10" max="365"
                    />
                <div class="alert alert-danger" *ngIf="duration.invalid && (duration.dirty || duration.touched)">
                    <div *ngIf="duration.errors?.required">
                        You must enter a duration in days for your LOA.
                    </div>
                    <div *ngIf="duration.errors?.minlength || duration.errors?.maxlength || duration.errors?.pattern || duration.errors?.min || duration.errors?.max">
                        LOA Duration is invalid: must be 10 to 365 days.
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label for="reason">Reason:</label>
                <input type="text" id="reason" name="reason" class="form-control" ngModel
                    #reason="ngModel"  required minlength="10" maxlength="100" pattern="^[\w\-,.?#!()\s]+$"
                    />
                <div class="alert alert-danger" *ngIf="reason.invalid && (reason.dirty || reason.touched)">
                    <div *ngIf="reason.errors?.required">
                        You must enter a reason for your LOA.
                    </div>
                    <div *ngIf="reason.errors?.minlength || reason.errors?.maxlength || reason.errors?.pattern">
                        Reason is invalid: must be 10 to 100 alphanumeric characters plus -,.?#!()
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 d-flex justify-content-end">
            <button type="submit" class="btn btn-warning" [disabled]="!loaForm.valid || (!loaForm.touched && !loaForm.dirty)" >
                Request LOA
            </button>
        </div>
    </div>
</form>

<app-avamodal [modalName]="modalName" [modalMessage]="modalMessage" [modalTitle]="modalTitle" [showMiniClose]="showMiniClose"
[showCancelButton]="showCancelButton" [modalStatus]="modalStatus" [buttonText]="'Ok'"
[modalPurpose]="modalPurpose" (modalClosed)="onModalClosed($event)"></app-avamodal>

<span *ngIf="loading">
    <div class="d-flex justify-content-center " style="margin-top: 10em;margin-bottom: 10em;">
        <div class="spinner-border text-primary" style="width: 6rem; height: 6rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</span>
