import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MapService {

  constructor(private httpClient: HttpClient) { }
  MAPPING_INFO_SVC = '/mappingdata';

  getMappingData() {
    return this.httpClient.get(environment.API_URL + this.MAPPING_INFO_SVC).pipe(map(
      (data ) => {
        // for (const key in data) {
        //   if (Object.prototype.hasOwnProperty.call(data, key)) {
        //     for (const index in data[key]) {
        //       if (Object.prototype.hasOwnProperty.call(data[key], index)) {
        //         const element = data[key][index];
        //         this.goals.push(element);
        //       }
        //     }
        //   }
        // }
        return data; //this.goals;
      }))
  }
}
