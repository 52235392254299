import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { AvAStatModel } from 'src/app/models/avastat.model';
import { StatsService } from 'src/app/services/stats.service';

@Component({
  selector: 'app-airportstats',
  templateUrl: './airportstats.component.html',
  styleUrls: ['./airportstats.component.css']
})
export class AirportstatsComponent implements OnInit {
  loading: boolean = false;
  windowScrolled = false;

  airportStats: AvAStatModel[] = [];

  monthlyDepartureChartData: ChartDataSets[];
  monthlyDestinationChartData: ChartDataSets[];

  lineChartLegend = false;
  radarChartType : ChartType = 'radar';

  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

  constructor(private router: Router, private statsService: StatsService) { }

  ngOnInit(): void {
    //add listener for scroll up button
    window.addEventListener('scroll', () => {
      this.windowScrolled = window.pageYOffset > 200;
    });
    this.loading = true;
    this.loadFlightStats();
  }

  tabTop() {
    window.scrollTo(0, 0);
  }

  airportRadarColors = [      { // grey#2989d8
    pointBackgroundColor: 'rgba(41,137,216,.5)',
  }];

  airportRadarOptions = {
    scale: {
        ticks : {
            beginAtZero:true
        }
    }
  };

  loadFlightStats() {
    this.statsService.getAirportStats().subscribe(
      (responseData: AvAStatModel[]) => {
        console.log(responseData)
        this.airportStats = responseData;
        this.loadUpDataSets();
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        if (error.status = 404) {
        } else {
          this.router.navigate(['/techerror']);
        }
      }
    );
  }

  loadUpDataSets() {

    this.loadMonthlyDepartures();
    this.loadMonthlyDestinations();
    // this.loadMonthlyByHubData();
    // this.loadYearlyByHubData();
    // this.loadMonthlyTotalsChartData();
    // this.loadYearlyTotalsData();


  }

  loadMonthlyDepartures() {
    this.monthlyDepartureChartData = [
      {
        data: this.airportStats['monthlyDepartures'].data,
        label: '',
      }
    ];
  }

  loadMonthlyDestinations() {
    this.monthlyDestinationChartData = [
      {
        data: this.airportStats['monthlyDestinations'].data,
        label: '',
      }
    ];
  }

  // loadYearlyByPilot() {
  //   this.yearlyByPilotChartData = [
  //     {
  //       data: this.airportStats['yearlyHoursByPilot'].data,
  //       label: '',
  //       backgroundColor: this.COLOR_GRID
  //     }
  //   ];
  // }

  // loadMonthlyByHubData() {
  //   this.monthlyByHubChartData = [
  //     {
  //       data: this.airportStats['monthlyHoursByHub'].data,
  //       label: '',
  //       backgroundColor: this.COLOR_GRID
  //     }
  //   ];
  // }

  // loadYearlyByHubData() {
  //   this.yearlyByHubChartData = [
  //     {
  //       data: this.airportStats['yearlyHoursByHub'].data,
  //       label: '',
  //       backgroundColor: this.COLOR_GRID
  //     }
  //   ];
  // }

  // loadMonthlyTotalsChartData() {
  //   this.monthlyTotalsChartData =[];
  //   this.monthlyTotalsChartData = [
  //     {
  //       data: this.airportStats['monthlyHourTotals'].data,
  //       label: '',
  //       backgroundColor: this.COLOR_GRID
  //     }
  //   ];
  // }

  // loadYearlyTotalsData() {
  //   this.yearlyTotalsChartData = [
  //     {
  //       data: this.airportStats['yearlyHoursTotals'].data,
  //       label: '',
  //       backgroundColor: this.COLOR_GRID
  //     }
  //   ];
  // }
}
