<div class="container mt-2 mb-3">
    <div class="row">
        <div class="col-md-12">
            <div class="card shadow rounded-top rounded-lg">
                <div class="card-header panel-american-coral">
                    <div class="row">
                        <div class="col-12">
                            Manage Hub Details
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-12">
                            <app-vahealthpanel></app-vahealthpanel>
                        </div>
                    </div>
                    <div class="card mt-2 " *ngFor="let hub of hubs">
                        <div class="card-header bg-ava-info">
                            <div class="row">
                                <div class="col-sm-8">
                                    <div class="row">
                                        <div class="col-sm-6">
                                            #{{hub.attributes.hubId}}
                                            ({{hub.attributes.shortName}}) {{hub.attributes.longName}}
                                        </div>
                                        <div class="col-sm-2">
                                            Pilots: {{hub.attributes.hubHealth.attributes.totalPilots}}
                                        </div>
                                        <div class="col-sm-4 float-right">
                                            Hiring:
                                            <i class="fa fa-check" style="color:green" *ngIf="hub.attributes.isHiring==1"></i>
                                            <i class="fa fa-ban" style="color:red" *ngIf="hub.attributes.isHiring==0"></i>
                                        </div>
                                        <!-- <div class="float-right">
                                            CAT Limit:
                                            <i class="fa fa-check" style="color:green" *ngIf="hub.attributes.hasCatLimit==1"></i>
                                            <i class="fa fa-ban" style="color:red" *ngIf="hub.attributes.hasCatLimit==0"></i>
                                        </div> -->
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <button class="btn btn-info btn-sm float-right" type="button" data-toggle="collapse" [attr.data-target]="'#hubDetailCollapse-' + hub.attributes.hubId" aria-expanded="true" aria-controls="hubDetailCollapse">
                                        <i class="fas fa-exchange-alt"></i> Edit
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="collapse " id="hubDetailCollapse-{{hub.attributes.hubId}}">
                            <div class="card-body">
                                {{hub.attributes.hubManager.F_Name}}
                                <app-hubinfodetail [hub]="hub" [isNew]="false" (onHubUpdateComplete)="onHubUpdateComplete($hub)"></app-hubinfodetail>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
