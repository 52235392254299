
<div class="d-flex justify-content-center">
    <div class="card shadow rounded-top rounded-lg mt-2">
        <div class="card-header panel-american-coral">
            <div class="row">
                <div class="col-md-12">
                    Password Reset Utility
                </div>
            </div>
        </div>
        <div class="card-body">
            <form name="resetPasswordForm" class="css-form" (ngSubmit)="onSubmit(resetPasswordForm.value)" #resetPasswordForm="ngForm">
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="vatsimId">VATSIM ID:</label>
                            <input type="number" id="vatsimId" name="vatsimId" class="form-control" ngModel #vatsimId="ngModel" minlength="1" maxlength="7" max="5000000" min="800000" required />
                            <div class="alert alert-danger" *ngIf="vatsimId.invalid && (vatsimId.dirty || vatsimId.touched)">
                                <div *ngIf="vatsimId.errors?.required">
                                    You must enter your VATSIM ID. You can obtain one at <a href="https://www.vatsim.net" target="_blank">VATSIM.net</a>
                                </div>
                                <div *ngIf="vatsimId.errors?.minLength || vatsimId.errors?.maxLength">
                                    VATSIM Id must be between 800000 and 5000000.
                                </div>
                                <div *ngIf="vatsimId.errors?.number">
                                    Only numeric is allowed
                                </div>
                                <div *ngIf="vatsimId.errors?.max || vatsimId.errors?.min">
                                    VATSIM Id must be between 800000 and 5000000.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-10">
                        <div class="form-group">
                            <label for="email">Email:</label>
                            <input type="email" id="email" name="email" class="form-control" ngModel #email="ngModel" email required />
                            <div class="alert alert-danger" *ngIf="email.invalid && (email.dirty || email.touched)">
                                <div *ngIf="email.errors?.required">
                                    You must enter your email
                                </div>
                                <div *ngIf="email.errors?.email">
                                    Enter a valid email
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Checkbox to allow hiding captcha when in test mode -->
                <div class="row" *ngIf="isCaptchaSkippable">
                  <div class="col-md-12">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" value="" id="pwdreset_skip_captcha" name="skipCaptcha" [(ngModel)]="skipCaptcha">
                      <label class="form-check-label" for="pwdreset_skip_captcha">Skip Captcha</label>
                    </div>
                  </div>
                </div>

                <!-- Captcha and Submit/Cancel buttons-->
                <div class="row">
                    <div class="col-md-12" *ngIf="!skipCaptcha">
                        <re-captcha (resolved)="resolved($event)" siteKey="{{siteKey}}"></re-captcha>
                    </div>
                    <div class="row mt-4">
                        <div class="col-md-12 ">
                                <div class="button-group ">
                                    <button class="btn btn-light" id="btnCancel">Cancel</button>
                                    <button type="submit" class="btn btn-primary" id="btnPwdResetSubmit" [disabled]="(isCaptchaResolved==false && !skipCaptcha) || resetPasswordForm.invalid">
                                        Submit
                                    </button>
                                </div>
                        </div>
                    </div>
                </div>
            </form>

        </div>
    </div>
</div>
<app-avamodal [modalName]="modalName" [modalMessage]="modalMessage" [modalTitle]="modalTitle" [showMiniClose]="showMiniClose"
[showCancelButton]="showCancelButton" [modalStatus]="modalStatus" [buttonText]="'Ok'"
[modalPurpose]="modalPurpose" (modalClosed)="onModalClosed($event)"></app-avamodal>

<!-- <span *ngIf="loading">
<div class="d-flex justify-content-center " style="margin-top: 10em;margin-bottom: 10em;">
    <div class="spinner-border text-primary" style="width: 6rem; height: 6rem;" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>
</span> -->
