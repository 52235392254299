<div class="container">
    <h2 class="text-info mt-3">
        Our History
    </h2>
    <div class="row">
        <div class="col-sm-12">
            <div class="row">
                <div class="col-sm-9">
                    <p>
                        American Virtual Airlines (AvA) was founded in 2001 and became the first Virtual Airline to be affiliated with VATSIM.
                        American Virtual Airlines has won numerous awards for professionalism and online presence, including,
                        Best New Virtual Airline Of The Year 2002 by FSPilot.com, and the top North American Virtual Airline in Vflight.org's
                        Best International VA for 2003.
                    </p>
                    <p>
                        American Virtual Airlines has over 500 pilots who fly out of our 8 United States-based Hubs on AAL flights as well as
                        Codeshare flights though our Virtual OneWorld partnership with several world-wide VAs.
                    </p>
                </div>
                <div class="col-sm-3">
                    <img src="assets/img/history.jpg"/>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">

                    <p>
                        American Virtual Airlines flies exclusively on the VATSIM Network, and provides our pilots with a full range of
                        resources to enhance their flying enjoyment. These resources include an Automated Pilot Reporting (PIREP) System with
                        Pilot Rosters, Corporate and Hub-based training programs, a flight dispatch service, a comprehensive Fleet department,
                        including the latest and best freeware aircraft for both American and American Eagle, web-based flight planning tools,
                        and much more.
                    </p>

                    <p>
                        American Virtual Airlines has also held some of the largest and most successful online events in the history of VATSIM.
                    </p>

                    <p>
                        I invite you to become part of a great group of flight simmers who are proud to fly AMERICAN!
                    </p>
                </div>
            </div>

        </div>
    </div>
        <div class="message-margins">&nbsp;</div>
</div>
