<form name="acceptForm" class="css-form" #acceptForm="ngForm" (ngSubmit)="acceptSubmit(acceptForm.value)">
    <div class="border border-rounded pl-2 pt-1 mb-1 mt-3 pr-2" style="border-color: green ! important">
        <div class="row">
            <div class="col-sm-12 font-weight-bold text-success" style="font-size:1.2em">
                <u>Accept Information</u>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <label for="hubChoice">Assigned Hub:</label>
                <select name="hubChoice" id="hubChoice" class="form-control" ngModel #hubChoice="ngModel" required
                    (change)="onHubChoice($event.target.value)">
                    <option *ngFor="let hiringHub of hiringHubs;let i = index" [value]="hiringHubs[i].hubId">
                        {{hiringHubs[i].longName}}
                        ({{hiringHub.shortName}})
                    </option>
                </select>
            </div>
            <div class="col-md-3" >
                <label for="slctRejectionReason">New Callsign:</label>
                <select name="callsignChoice" id="callsignChoice" class="form-control" ngModel #callsignChoice="ngModel" required
                    (change)="onCallsignChoice($event.target.value)" [disabled]="isLoading || (!hubChoice.dirty && !hubChoice.valid)">
                    <option *ngFor="let callsign of availableCallsigns;let i = index" [value]="callsign">
                        {{callsign}}
                    </option>
                </select>
            </div>
            <div class="col-md-5" *ngIf="callsignPicked">
                <label for="initialHours">Initial Hours:</label><br/>
                {{initialHours}} &#64; 70% = <span [ngStyle]="{'color' : initialHours*.7>15 ? 'seagreen' : 'red'}"><b>{{(initialHours*.7)}}</b></span>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-12">
                <div class="button-group float-right">
                    <button type="button" class="btn btn-light btn-sm" id="acceptFormCancel"
                        (click)="acceptCancel()">Cancel</button>
                    <button type="submit" class="btn btn-success float-right" id="acceptFormSubmitButton"
                        [disabled]="isLoading || (!hubChoice.dirty && !hubChoice.valid) || (!callsignChoice.dirty && !callsignChoice.valid)">
                        Accept {{pickedCallsign}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</form>
